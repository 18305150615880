import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { addysEqual, rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'


function TradeRow(props) {

	var event = props.event;
	var eventDetail = props.details;
	var token = props.fighter;

	var size = 80;

	// event Offer(uint256 tokenId, address seller, uint256 askAmount);
    // event CounterOffer(uint256 tokenId, address buyer, address seller, uint256 bidAmount);
    // event AcceptedOffer(uint256 tokenId, address seller, address buyer, uint256 saleAmount);
    // event AcceptedCounterOffer(uint256 tokenId, address seller, address buyer, uint256 saleAmount);
    // event CancelledOffer(uint256 tokenId, address seller);
    // event CancelledCounterOffer(uint256 tokenId, address buyer, address seller);
    // event FooBuyback(uint256 amount);
    // event FundedTreasury(uint256 amount);




	var txUrl = 'https://ftmscan.com/tx/'+event.transactionHash;
	var shortTx = event.transactionHash.substr(0,6)+'...'+event.transactionHash.substr(60, 6);
	var data = event.data.substr(2, event.data.length-2);
    var d1 = data.substr(64*0, 64);
    var d2 = data.substr(64*1, 64);
    var d3 = data.substr(64*2, 64);
    var d4 = data.substr(64*3, 64);

    var tokenId = parseInt(d1, 16);

    var priceString=0;
    var descString = '';
    if(event.type=='offer') {
    	var seller = '0x'+d2.substr(d2.length-40, 40);
    	var askAmount = parseInt(d3, 16);
    	
    	priceString=askAmount;
    	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'You have offered';
	  	}
    }

    if(event.type=='counterOffer') {
    	var buyer = '0x'+d2.substr(d2.length-40, 40);
    	var seller = '0x'+d3.substr(d3.length-40, 40);
    	var bidAmount = parseInt(d4, 16);
    	priceString=bidAmount;
    	

    	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'Counter offer placed on your';
	  	} else if(addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have placed a counter offer on';
	  	} else if(addysEqual(seller, EVM.connectedAccount) && addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have placed a counter offer on your own';
	  	}

    	
    }

    if(event.type=='cancelledOffer') {
    	var seller = '0x'+d2.substr(d2.length-40, 40);
    	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'You have cancelled your offer of';
	  	}
    	
	}
	if(event.type=='cancelledCounterOffer') {
    	var buyer = '0x'+d2.substr(d2.length-40, 40);
    	var seller = '0x'+d3.substr(d3.length-40, 40);
    	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'Counter offer cancelled on your';
	  	} else if(addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have cancelled your counter offer on';
	  	} else if(addysEqual(seller, EVM.connectedAccount) && addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have cancelled your own counter offer on';
	  	}
    	
    }
    if(event.type=='acceptedOffer') {
	  	var seller = '0x'+d2.substr(d2.length-40, 40);
	  	var buyer = '0x'+d3.substr(d3.length-40, 40);
	  	var saleAmount = parseInt(d4, 16);
	  	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'Your offer was accepted on';
	  	} else if(addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have accepted an offer on';
	  	} else if(addysEqual(seller, EVM.connectedAccount) && addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have accepted your own offer on';
	  	}
	  	
	  	priceString=saleAmount;
	}

	if(event.type=='acceptedCounterOffer') {
	  	var seller = '0x'+d2.substr(d2.length-40, 40);
	  	var buyer = '0x'+d3.substr(d3.length-40, 40);
	  	var saleAmount = parseInt(d4, 16);
	  	
	  	if(addysEqual(seller, EVM.connectedAccount)) {
	  		descString = 'Counter offer accepted on your';
	  	} else if(addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have accepted a counter offer on';
	  	} else if(addysEqual(seller, EVM.connectedAccount) && addysEqual(buyer, EVM.connectedAccount)) {
	  		descString = 'You have accepted a counter offer on your own';
	  	}
	  	priceString=saleAmount;
	}

	function ftmNum() {
		if(priceString>0) {
			return (<>{Math.round(priceString/Math.pow(10,18)*100)/100}<br/><div className="trade_event_ftm">FTM</div></>);
		}
		return (<></>);
	}



	if(token && token.image) {
		return (

			<div className="trade_event_row">
				<div className={"trade_event_fantum_image"}>
					<a href={"/token/"+token.id}><img width={size} height={size} src={token.image} /></a>
				</div>
				<div className={"trade_event_text"}>
				{descString} <b><a href={"/token/"+token.id}>{token.name}</a></b> at <a href={txUrl}>{shortTx}</a>
				</div>
				<div className={"trade_event_price"}>
				{ftmNum()}
				</div>
		    	
		    </div>

		);
	} else {
		return (<></>);
	}
	


}

export default TradeRow;