import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rollName, rollNum, rScore, getObjFromJSONUrl } from './../helpers.js'
import {
  useParams
} from "react-router-dom";



var receivedContent = 0;
var lastConnectedAccount=false;



function Market(props) {

	let { num, ob, o, q } = useParams();
	
	if(!ob) {
		ob='price';
	}
	if(!o) {
		o='asc';
	}
	// if(num=='query') {
	// 	num=15;
	// }

	if(!num) {
		num = 20;
	}
	if(num>100) {
		num=100;
	}


	document.title = subs.properSiteName+": Market";

	// var [events, setEvents] = useState([]);

	// var [eventDetails, setEventDetails] = useState([]);

	const [render, setRender] = useState(0);
	
	const [isLoading, setIsLoading] = useState(true);
	//const [isLoadingSales, setIsLoadingSales] = useState(false);

	// function load() {



	// 	// if(!EVM.connectedAccount) {
	// 	// 	return;
	// 	// }

	// 	console.log('load()?');

	// 	setIsLoading(true);

	// 	setIsLoadingSales(true);

	// 	(async () => {
	// 		// var eventsURL = subs.apis[subs.apiId].url+'recent_sales';
	//   //       const eventsURLResp = await fetch(eventsURL);
	//   //       const eventsObj = await eventsURLResp.json();

	//   	var eventsObj=[];
	//         //console.log(eventsObj.length);
	// 		if(eventsObj.length > 0) {



	// 			 if(EVM.connectedAccount && EVM.ftc_contract) {
	// 				// Get prices and store in array..

	// 				var someEventDetails = [];
	// 		    	for(var i=0;i<eventsObj.length;i++) {
			    		
	// 		    		var event = eventsObj[i];

	// 		    		//console.log(event);

	// 		    		if(event) {
	// 		    			var data = event.data.substr(2, event.data.length-2);

 //    						var d1 = data.substr(64*0, 64);
 //    						var tokenId = parseInt(d1, 16);


	// 			    		var fantumName = await EVM.fum_contract.methods.nameOf(tokenId).call();


	// 			    		someEventDetails[i] = fantumName;
	// 			    		// const newEventDetails = someEventDetails.concat({
	// 					    //   id: i, 
	// 					    //   name: fantumName
	// 					    // }); 
	// 					    // someEventDetails=newEventDetails;
	// 		    		}



			    		  
	// 		    	}

	// 		    	setEventDetails(someEventDetails);
			    	

	// 		    }

	// 		    setEvents(eventsObj);
	// 	        //console.log(eventsObj);
		    	
	// 			receivedContent=1;
				
	// 			setRender(render+1);
	// 			setIsLoading(false);
	// 			setIsLoadingSales(false);
	// 		}


	// 	})();
	// }


	useEffect(() => {

		setInterval(function() {



			if(EVM.connectedAccount != lastConnectedAccount) {
				//receivedContent=0;
				//load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(receivedContent==0) {
				//load();
			}

			// if(gridTokens.length > 0) {
			// 	setRender(render+1);
			// }

		}, 1000);

	}, []);

// 	function printEvent(event, index) {

// 		// if(!EVM.connectedAccount) {
// 		// 	return;
// 		// }

// 	if(!event) {
//     return;
//   }

//   var nextEvent = events[index+1];

//   var txUrl = 'https://ftmscan.com/tx/'+event.transactionHash;

//   var shortTx = event.transactionHash.substr(0,6)+'...'+event.transactionHash.substr(60, 6);
//   //console.log(event);


//     // event Offer(uint256 tokenId, address seller, uint256 askAmount);
//     // event CounterOffer(uint256 tokenId, address buyer, address seller, uint256 bidAmount);
//     // event AcceptedOffer(uint256 tokenId, address seller, address buyer, uint256 saleAmount);
//     // event AcceptedCounterOffer(uint256 tokenId, address seller, address buyer, uint256 saleAmount);
//     // event CancelledOffer(uint256 tokenId, address seller);
//     // event CancelledCounterOffer(uint256 tokenId, address buyer, address seller);
//     // event FooBuyback(uint256 amount);
//     // event FundedTreasury(uint256 amount);

//   var data = event.data.substr(2, event.data.length-2);

//     var d1 = data.substr(64*0, 64);
//     var d2 = data.substr(64*1, 64);
//     var d3 = data.substr(64*2, 64);
//     var d4 = data.substr(64*3, 64);

//   if(event.type=='acceptedOffer') {


//   	var tokenId = parseInt(d1, 16);

//   	var seller = '0x'+d2.substr(d2.length-40, 40);
//   	var buyer = '0x'+d3.substr(d3.length-40, 40);

//   	var saleAmount = parseInt(d4, 16);

//   	var addUrl='/search/'+buyer;
//   	var addShort = buyer.substr(0,6)+'...'+buyer.substr(38, 42);

//   	var ntu = eventDetails[index];
//   	if(!EVM.connectedAccount) {
//   		ntu = '#'+tokenId;
//   	}

// 	return (<div key={index}><div className="history_line"><a href={'/token/'+tokenId}><b>{ntu}</b></a> bought for <b>{Math.round(saleAmount/Math.pow(10,18)*100)/100} FTM</b> to <a href={addUrl}>{addShort}</a></div></div>);
  	


    
//   }

//   if(event.type=='acceptedCounterOffer') {


//   	var tokenId = parseInt(d1, 16);

//   	var seller = '0x'+d2.substr(d2.length-40, 40);
//   	var buyer = '0x'+d3.substr(d3.length-40, 40);

//   	var saleAmount = parseInt(d4, 16);


//   	var addUrl='/search/'+buyer;
//   	var addShort = buyer.substr(0,6)+'...'+buyer.substr(38, 42);

//   	var ntu = eventDetails[index];
//   	if(!EVM.connectedAccount) {
//   		ntu = '#'+tokenId;
//   	}

//   	return (<div key={index}><div className="history_line"><a href={'/token/'+tokenId}><b>{ntu}</b></a> sold for <b>{Math.round(saleAmount/Math.pow(10,18)*100)/100} FTM</b> by <a href={addUrl}>{addShort}</a></div></div>);


    
//   }


	
// }

	if(vars.comingSoonSiteMode) { return(<></>); }

	return (<>
        <div className="white_page">

            <Grid title="For Sale" name="market" num={num} orderBy={ob} order={o} query={q} />
            {/*<h3>Recent Sales</h3>

            <br/>
            <div className={isLoadingSales ? '' : 'hidden'}>
            <div id="smallRotatingSkelly" className={!isLoadingSales ? '' : 'rotate'}></div>
            </div>
            

				{events.map((event, index) => (
					<div key={index}>
					{printEvent(event, index)}
					</div>
				))}*/}
				<br/>
				<br/>
				<div className="clear">
				</div>
		</div>
    </>);

}

export default Market;