import React, {useState, useEffect} from 'react';
import * as EVM from './../evm.js';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import { getObjFromJSONUrl } from './../helpers.js';
import { ReactSession } from 'react-client-session';
import CircleIcon from './CircleIcon';

var receivedBalance=false;

var receivedAPY=false;

var gettingAPR=false;

function Footer() {

  function updateFooterBalance() {
    if(EVM.connectedAccount) {
        (async () => {
          try {

            var aBalance = await EVM.web3.eth.getBalance(EVM.connectedAccount.toString());  
            setBalance(aBalance);

            

            
            receivedBalance=true;

          } catch (error) {
            console.log(error);
          }
        })();
      }
  }

  const [balance, setBalance] = useState(0);
  const [currentAPY, setCurrentAPY] = useState(false);


  async function setAPY() {

    if(gettingAPR) {
      return;
    }

    gettingAPR = true;

    const serverAPY = await getObjFromJSONUrl(
      subs.apis[subs.apiId].url+'lpAPY'
    );

    gettingAPR = false;
    receivedAPY=true;
    setCurrentAPY(Math.round(serverAPY*100));
  }


  useEffect(() => {
    }, []);

   setInterval(function() {

    if(!receivedAPY) {
      setAPY();
      
    }

    if(EVM.connectedAccount && !receivedBalance) {

      updateFooterBalance();
      
    } else if(!EVM.connectedAccount) {
      setBalance(0);
      receivedBalance=false;
    }

  }, 100);

   var iconSize = 32;

   function hasEVM() {
    if(EVM.connectedAccount) {
         return (<>
      
      </>);
    }
   
   }

function duelContracts() {

  if(EVM.connectedAccount ) {
    return (<><br/><a href={"https://ftmscan.com/address/"+EVM.GY_ADD[0]}>GY</a> / <a href={"https://ftmscan.com/address/"+EVM.DUEL_ADD[0]}>FDC</a> / <a href={"https://ftmscan.com/token/"+EVM.RIP_ADD[0]}>$RIP</a></>);
  }
  
}
function evmConnected() {

  if(vars.devInfo) {

    if(EVM.connectedAccount) {


    return (<>
        <span className="footerP">{EVM.connectedAccount}</span><br/>
        <span className="footerP">Balance: {(balance/Math.pow(10,18)).toFixed(2)}</span><br/>
        <span className="footerP">FUM: {EVM.FUM_ADD[0]}</span>
        </>);

    } else {
      return (<>
        <span className="footerP">Blockchain not connected...</span>
        </>);
    }

    
  }
}

function printApy() {
  if(currentAPY) {
    return currentAPY+'%';
  }
  return '???';
}

return ( 
  <div>
    <div className="dark_footer_box">
      <span className="footer_text_link">
        <img className="footer_darkbox_icon" src="/img/icons/steak.png" width="26" height="26" /><a href="/stake">STAKE</a>&nbsp;
        <img className="footer_darkbox_icon" src="/img/icons/farm.png" width="26" height="26" /><a href="/farm">FARM</a>&nbsp;

        <span className={(((!EVM.isTester && subs.craftingPrivate && !subs.subsite) || subs.subsite=='rarity' || subs.subsite=='gothums') ? 'hidden' : '')}><img className="footer_darkbox_icon" src="/img/icons/crafting.png" width="26" height="26" /><a href="/crafting">CRAFT</a></span>
      </span>
    </div>

    <div className="footer_holder_holder">
      <div className="footer_holder">

        <div className={"footer_block "+(EVM.connectedAccount ? '' : 'footer_block_half')}>   
          <b>INFORMATION</b><br/>
          <img className="footer_block_icon" src="/img/icons/faq.png" width="14" height="14" /><a href="/faq">FAQ</a><br/>
          <span className={(!subs.subsite ? '' : '')}><img className={"footer_block_icon"} src="/img/icons/guide.png" width="14" height="14" /><a href="/guide">GUIDE</a><br/></span>
          <span className={(!subs.subsite || subs.subsite=='rarity' ? '' : '')}><a href="/trade_history">TRADE HISTORY</a><br/></span>
          
          {/*<img className="footer_block_icon" src="/img/icons/chart.png" width="14" height="14" /><a href="https://charts.zoocoin.cash/?exchange=ZooDex&pair=FOO-WFTM">CHART</a><br/>*/}
            
        </div>
        
              <div className={!EVM.connectedAccount ? 'hidden' : ''+" footer_block"}> 
          
          <span className={!subs.subsite ? '' : 'hidden'}>
            <b>DUELS</b><br/>
          
            <img className="footer_block_icon" src="/img/icons/graveyard.png" width="14" height="14" /><a href="/graveyard">GRAVEYARD</a><br/>
            <img className="footer_block_icon" src="/img/icons/leaders.png" width="14" height="14" /><a href="/leaders">LEADERS</a><br/>
            
            <a href="/duel_history">DUEL HISTORY</a><br/>
          </span>
        </div>

        <div className={"footer_block "+(EVM.connectedAccount ? '' : 'footer_block_half')}>    
          <b>CONTRACTS</b><br/>
          <a href="https://ftmscan.com/token/0xfbc3c04845162f067a0b6f8934383e63899c3524">$FOO</a> / <a href="https://ftmscan.com/token/0x57c47A330022edAe0a2eC8d6546689508e1278e8">$sFOO</a> / <span className={(!subs.subsite || subs.subsite=='rarity' ? '' : 'hidden')}><a href="https://ftmscan.com/token/0x0C600D41b9C7656e60C0bf76D79B1532b4770D0C">$FUM</a></span><span className={(subs.subsite=='gothums' ? '' : 'hidden')}><a href="https://ftmscan.com/token/0x9Ee6d281D77DEdB1D65684C9137B7B3Dc54f5d23">$GUM</a></span><br/>
          <span className={(!subs.subsite || subs.subsite=='rarity' ? '' : 'hidden')}><a href={"https://ftmscan.com/address/"+EVM.FTC_ADD[0]}>FTC</a> / <a href="https://ftmscan.com/address/0xDDc62679854a60cF4DBF7335BAEAd232a426e97d">FLP</a> / <a href="https://ftmscan.com/address/0xCdCfd0C4462c62343851a04e498924Dc583bCa5a">FI</a></span><span className={(subs.subsite=='gothums' ? '' : 'hidden')}><a href={"https://ftmscan.com/address/"+EVM.GTC_ADD[0]}>GTC</a> / <a href={"https://ftmscan.com/token/"+EVM.CNDY_ADD[0]}>$CNDY</a></span>
          <span className={(!subs.subsite ? '' : 'hidden')}>{duelContracts()}</span>
          <br/>
        </div>

      </div>
    </div>

    <div className="switch_footer_box">
      Switch to:
      <img onClick={()=>{ window.open('https://fantums.com', '_self')}} className={"switch_logo "+(!ReactSession.get("subsite") ? 'hidden': '')} src="/logo.png" width={200} height={((51/249)*200)} />
      <img onClick={()=>{ window.open('https://rarity.fantums.com', '_self')}} className={"switch_logo "+(ReactSession.get("subsite")=='rarity' ? 'hidden' : '')} src="/logo_rm.png" width={200} height={((51/249)*200)} />
      <img onClick={()=>{ window.open('https://gothums.com', '_self')}} className={"switch_logo "+(ReactSession.get("subsite")=='gothums' ? 'hidden' : '')} src="/logo_gm.png" width={200} height={((51/249)*200)} />
    </div>


    <br/>

    <span className="footer_text_link">

    
      <CircleIcon href="https://spookyswap.finance/swap?outputCurrency=0xfbc3c04845162f067a0b6f8934383e63899c3524" size={iconSize} src="/img/spooky_icon.png" />·
      <CircleIcon href="https://spookyswap.finance/bridge" size={iconSize} src="/img/bridge_icon.png" />·
      <CircleIcon href="https://app.sushi.com/swap?outputCurrency=0xfbc3c04845162f067a0b6f8934383e63899c3524" size={iconSize} src="/img/sushi_icon.png" />·
      <CircleIcon href="https://app.sushi.com/swap?outputCurrency=0x659D813beB17435acF822CdD80DB0c7090755771" size={iconSize} src="/img/sushi_icon.png" />·
      <CircleIcon href="https://charts.zoocoin.cash/charts?exchange=SushiSwap&pair=0xC9A3AD55ef7B859e0c5FDB81E4F4B6a709D758fD-inverted" size={iconSize} src="/img/chart_icon.png" />·
      <CircleIcon href="https://fantums.com/discord" size={iconSize} src="/img/discord_icon.png" />·
      <CircleIcon href="https://twitter.com/FantumsOfOpera" size={iconSize} src="/img/twitter_icon.png" />


    </span>
    <br/>
    


    <div className="footer_cont">
      {evmConnected()}
      <br/><br/><br/>
    </div> 
  </div>

);

}

export default Footer;