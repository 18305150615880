import React, {useState, useEffect} from 'react';

import Alert from 'react-bootstrap/Alert';

import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import FantumGen from './FantumGen';
import TokenAboveText from './TokenAboveText';
import {
  useParams
} from "react-router-dom";
import { addysEqual, rankToName, rankNum, rollName, rollNum, rScore, sleep, descForFilename,  fileKeyForLayerUsingHash, oddsForLayerUsingHash, nameWithNonBreakingSpaces, callMethod, percentileNum, modal, getObjFromJSONUrl } from './../helpers.js'
import { rScoreGivenHash, arrayOfHashCharacters, outfitForLayerName } from './../generate.js'

import Button from 'react-bootstrap/Button';

import 'react-responsive-modal/styles.css';


import TextField from "@material-ui/core/TextField";

import * as colors from './../colors.js';
import IconTint from 'react-icon-tint';
import { ReactSession } from 'react-client-session';

const tinygradient = require('tinygradient');
const tinycolor = require('tinycolor2');

const fetch = require('node-fetch');

var ordinal = require('ordinal');

const BigNumber = require('bignumber.js');

  
var gettingInfo=false;

var hasContent = false;

var tokenIds = 0;

var tokenSaleColor='black';

var hasContracts=false;

var currAccount = '';

var currentName = '';

var currAccountSetOnce = false;

var oddsArr = [];

var receivedRipApproval=false;

var resPrice = 20 * Math.pow(10, 18);

var gotResPrice=false;

var currentOwner = false;
//var oddsAPIArr = [];

var levelProgress = 0;

var transferAddressWasSet=false;

var currentBlockTimestamp = false;


var liveFantum=false;


var mmWasConnected = false;

var gettingInfoAt=0;

function Token(props) {

  ReactSession.setStoreType("localStorage");


  const [openGoldClaim, setOpenGoldClaim] = useState(false);
  const [isSummApprovedGoldClaim, setIsSummApprovedGoldClaim] = useState(false);
  const [isApprovingSummGoldClaim, setIsApprovingSummGoldClaim] = useState(false);
  const [isClaimingGold, setIsClaimingGold] = useState(false);


  const [rarityDisplayMode, setRarityDisplayMode] = useState(false);



  var [popRarToggle, setPopRarToggle] = useState(true);

  var [events, setEvents] = useState([]);

  var [isApproved, setIsApproved] = useState(false);
  var [transferIsApproved, setTransferIsApproved] = useState(false);


  var [isOwner, setIsOwner] = useState(false);
  var [tokenNotForSale, setTokenNotForSale] = useState(false);
  var [counterOffersForToken, setCounterOffersForToken] = useState([]);

  var [gradientString1, setGradientString1] = useState('');
  var [gradientString2, setGradientString2] = useState('');
  var [gradientString3, setGradientString3] = useState('');



  var [totalFantums, setTotalFantums] = useState(0);


  var [rankScoreLayers, setRankScoreLayers] = useState([]);
  var [rankScoreLayersT, setRankScoreLayersT] = useState([]);

  //var [oddsArray, setOddsArray] = useState([]);







  let { id, fn } = useParams();


  

  if(fn) {
    fn = fn.replace(":", "");
    //console.log('fn: '+fn);
  }
  if(id) {
   // id = id.replace("/", "");
    //console.log('id: '+id);
    id = parseInt(id);
  }




  const [render, setRender] = useState(0);

  // const [hash, setHash] = useState([]);

  // const [price, setPrice] = useState(0);
  // const [name, setName] = useState('');
  // const [owner, setOwner] = useState('0x0');

  const [priceOfNameChange, setPriceOfNameChange] = useState(0);

  const [fantum, setFantum] = useState({});

   


   //const [currAccount, setCurrAccount] = useState("");


  // useEffect(() => {

  //  (async () => {

  //    const rarity = await getObjFromJSONUrl(
  //      'http://api.fantums.com:9000/rankN/'+token.id
  //    );
  //    setNewRarity(rarity[0]);


  //  })();

  // }, []);



  //const [rankScore, setRankScore] = useState(-1);

  useEffect(() => {

    if(ReactSession.get("subsite")=='rarity') {
      setRarityDisplayMode(true);
    }
    

    // if(fn) {
    //   (async () => {
    //     var nameURL = subs.apis[subs.apiId].url+'tokenByName/'+fn;
    //     const nameResp = await fetch(nameURL);
    //     const nameId = await nameResp.json();

    //     if(nameId!='That name was not found.') {
    //       id = nameId;
    //     }
    //     console.log(nameId);
        


        

    //    })();
    // }
     


    // document.title = 'Fantum #'+id;

    

      setInterval(async function() {

        //console.log('EVM.FMR_ADD[0]: '+EVM.FMR_ADD[0]);
        if(EVM.connectedAccount) {
          var cBlock = await EVM.web3.eth.getBlockNumber();
          if(cBlock) {
            var currBlock = await EVM.web3.eth.getBlock(cBlock);
            currentBlockTimestamp = currBlock.timestamp;
            // console.log('currentBlockTimestamp: '+currentBlockTimestamp);
            
            
            // if(fantum && fantum.summoner) {
            //   console.log('advLog: '+fantum.summonerLog);
            // }
          }
          
        }

        

        //console.log('EVM.web3.eth.getBlockNumber(): '+currentBlockTimestamp);

        // hasContent=false;
        // getInfo();

        checkRipApproval();
      }, 10000);
   
      setInterval(function() {

        if(!gotResPrice) {
          getResPrice();
        }

        if(!receivedRipApproval) {
          checkRipApproval();
        }

        if(!hasContracts && EVM.fum_contract) {
          hasContracts=true;
          getInfo();
        }

        if(!currAccountSetOnce && EVM.connectedAccount && EVM.connectedAccount.toString()!=currAccount.toString()) {
          currAccount = EVM.connectedAccount.toString();
           currAccountSetOnce=true;

        }

        //console.log(EVM.connectedAccount);
          if(currAccountSetOnce && EVM.connectedAccount && EVM.connectedAccount.toString()!=currAccount.toString()) {
            
            // //setCurrAccount();
            // hasContent=false;
            // gettingInfo=false;
            // getInfo();
            currAccount = EVM.connectedAccount.toString();


            if(id) {
              window.open(/token/+id, '_self');
            } else {
              getInfo();
            }
            
            //console.log('Updating account... '+currAccount);
          }

          //console.log('Do we hasContent? '+hasContent);
           if(!hasContent) {
            getInfo();

           }
        }, 1000);


    }, []);


  function hasConnectedAccountMadeCounterOffer() {

    for(var i=0;i<counterOffersForToken.length;i++) {
      if(EVM.connectedAccount.toString().toLowerCase() == counterOffersForToken[i].address.toString().toLowerCase()) {
        return true;
      }
    }

    return false;

  }

  async function getResPrice() {
    if(!EVM.connectedAccount) {
      return;
    }
    if(!EVM.gy_contract) {
      return;
    }
    let rp = await EVM.gy_contract.methods.getResPrice().call();
    if(rp) {
      resPrice = rp;
      gotResPrice=true;
    }
  }

  function isParent() {
     if(!fantum.id) {
      return false;
    }
    if(!EVM.connectedAccount) {
      return false;
    }
    if(EVM.connectedAccount.toString().toLowerCase() == fantum.parentOfFum.toString().toLowerCase()) {
      return true;
    }
    if(EVM.connectedAccount.toString().toLowerCase() == fantum.owner.toString().toLowerCase()) {
      return true;
    }
    return false;
    
  }

  function isFormerOwner() {

    //console.log(EVM.connectedAccount);
    if(!fantum.id) {
      return false;
    }
    if(!EVM.connectedAccount) {
      return false;
    }
    //console.log(fantum.former_owner);
    if(EVM.connectedAccount.toString().toLowerCase() == fantum.former_owner.toString().toLowerCase()) {
      return true;
    }
    return false;
  }

  function hasWeapon() {

    if(!fantum.id) {
      return;
    }

    var outfitNum = outfitForLayerName('rh', arrayOfHashCharacters(fantum.hash));
    //console.log(outfitNum);
    if(outfitNum > 1) {
      return true;
    }
    return false;
  }

  function isSeller() {

    if(isOwner) {
      return true;
    }
    //console.log(EVM.connectedAccount);
    if(!fantum.id) {
      return false;
    }
    if(EVM.connectedAccount.toString().toLowerCase() == fantum.seller.toString().toLowerCase()) {
      return true;
    }
    return false;
  }

  function isOfferer(address) {
    //console.log(EVM.connectedAccount+'--'+address.toStr);


    if(EVM.connectedAccount.toString().toLowerCase()==address.toString().toLowerCase()) {
      return true;
    }
    return false;
  }

  async function getEvents() {
    if(!id) {
      return;
    }

    var eventsURL = subs.apis[subs.apiId].url+'events/'+id;
    const eventsURLResp = await fetch(eventsURL);
    const eventsObj = await eventsURLResp.json();
    setEvents(eventsObj);
  }

  function completeDisplay(tokenURI, tokenURIObj, ownerMeetsFooReq, fantum, nameOf, owner, seller, price, dead, formerOwner, summoner, atDayCare, parentOfFum, hashOf) {
    //console.log('We even do this?');
    if(!nameOf) {
      //owner = tokenURIObj.owner;
      nameOf = tokenURIObj.name;
      
      price = tokenURIObj.price;

      dead = tokenURIObj.dead;

      formerOwner = tokenURIObj.former_owner;


      //summoner = false;
      // summonerLevel = '';
      // summonerXp = '';
      // summonerClassName = '';
      // summonerXpRequired = '';
      // summonerLog = '';
      // summonerAbilityScores='';

    
      
  
    }




    if(!summoner) {
      summoner = tokenURIObj.summoner;
    }

    if(!owner) {
      owner = tokenURIObj.owner;
    }

    if(!parentOfFum) {
      parentOfFum = '0x0000000000000000000000000000000000000000';
    }


    if(!formerOwner) {
      formerOwner = '0x0000000000000000000000000000000000000000';
    }
    
    //seller = ftcSeller
    if(!seller) {
      seller = tokenURIObj.seller;
    }
    if(!seller) {
      seller = '0x0000000000000000000000000000000000000000';
    }

    var rareObj = tokenURIObj.rarity;

    var pn = percentileNum(Math.round(100-rareObj/tokenIds*100));
    var rn = rollNum(tokenURIObj.hash.toString());

    //console.log('pn: '+pn);
    //console.log('rn: '+rn);
    var gradient2;

    if(price>0) {
       gradient2 = tinygradient([
      {color: tinycolor.mix(vars.rarityTextColorsNew[pn], vars.rarityTextColorsNew[pn], 0), pos: 0},
        {color: tinycolor.mix(vars.rarityTextColorsNew[pn], vars.rarityTextColorsNew[pn], 100), pos: 1}
      ]);
      
    } else {
       gradient2 = tinygradient([
      {color: tinycolor.mix(vars.rarityTextColors[rn], vars.rarityTextColors[rn], 0), pos: 0},
        {color: tinycolor.mix(vars.rarityTextColors[rn], vars.rarityTextColors[rn], 100), pos: 1}
      ]);
    }
    setGradientString2(gradient2.css());

    var gradient1;
    gradient1 = tinygradient([
      {color: tinycolor.mix(vars.rarityTextColors[rn], vars.rarityTextColorsNew[pn], 0), pos: 0},
        {color: tinycolor.mix(vars.rarityTextColors[rn], vars.rarityTextColorsNew[pn], 100), pos: 1}
      ]);
    setGradientString1(gradient1.css());

     var gradient3 = tinygradient([
      {color: tinycolor.mix(vars.rarityTextColorsNew[pn], vars.rarityTextColorsNew[pn], 0), pos: 0},
        {color: tinycolor.mix(vars.rarityTextColorsNew[pn], vars.rarityTextColorsNew[pn], 100), pos: 1}
      ]);
    setGradientString3(gradient3.css());

    currentName = nameOf;

    //console.log('owner here: '+owner);

    currentOwner = owner;


    if(tokenURIObj.hash) {
      hashOf = tokenURIObj.hash;
    }

    fantum = {
        id: tokenURIObj.id, 
        hash: hashOf, 
        owner: owner,
        name: nameOf,
        seller: seller,
        price: price,
        tokenURI: tokenURI,
        image: tokenURIObj.image,
        //price: tokenURIObj.price,
        level: tokenURIObj.level,
        dead: dead,
        lwtdk: tokenURIObj.lwtdk,
        elo: tokenURIObj.elo,
        former_owner: formerOwner,
        replay: tokenURIObj.replay,
        popRare: tokenURIObj.rarity,
        ownerMeetsFooReq: ownerMeetsFooReq,
        totalFantums: tokenURIObj.totalFantums,

        summoner: summoner,
        // summonerLevel: tokenURIObj.summoner.level,
        // summonerXp: tokenURIObj.summoner.xp,
        // summonerClass: tokenURIObj.summoner.className,
        // summonerXpRequired: tokenURIObj.summoner.xpRequired,
        // summonerLog: tokenURIObj.summoner.log,
        // summonerAbilityScores: tokenURIObj.summoner.abilityScores,

        atDayCare: atDayCare,
        parentOfFum: parentOfFum

      };
      hasContent = true;
      liveFantum=fantum;
      setFantum(fantum);

      setTotalFantums(tokenURIObj.totalFantums);

      if(ReactSession.get("justMintedId")) {
          ReactSession.set("justMintedId", false);
      }

      levelProgress = fantum.level%Math.floor(fantum.level)*100;

      //console.log('levelProgress: '+levelProgress+' fantum.level: '+fantum.level);

      if(levelProgress > 100) {
        levelProgress=100;
      }
      if(levelProgress < 0) {
        levelProgress=0;
      }

      (async () => {
        // MIGHT BE TEMPORARY
        
        getRankScoreLayers(fantum.id);

        if((fantum.dead)) {
          tokenSaleColor='green';
        }

        //setRankScoreLayers(rankScore[0]);

        // for(var i=0;i<14;i++) {

        //   const rankScoreForLayer = await getObjFromJSONUrl(
        //    'http://api.fantums.com:9000/rankScore/'+i+'/'+fantum.id
        //   );
        //   oddsAPIArr[i] = rankScoreForLayer[0];
        //   //setRankScore(rankScore[0]);

        // }


      })();


      gettingInfo=false;

     // console.log(fantum);

      document.title = fantum.name+' #'+fantum.id;

      setRender(render+1);
  }

  async function getRankScoreLayers(anId) {
    const rsl = await getObjFromJSONUrl(
      subs.apis[subs.apiId].url+'rankScoreLayers/'+anId
    );
    setRankScoreLayers(rsl[0]);
    setRankScoreLayersT(rsl[1]);
  }

  function getInfo() {

    //console.log('Attempting to getInfo...');

    if(gettingInfoAt > Date.now()/1000 - 10) {
      return;
    }
    if(!id) {
      return;
    }

    //console.log('Getting info..');
    // if(hasContent) {
    //   return;
    // }



    // if(!EVM.fum_contract) {
    //   return;
    // }

    // if(!EVM.connectedAccount) {
    //   return;
    // }
    gettingInfoAt = Date.now()/1000;
    gettingInfo=true;

    (async () => {

      try {

        var tokenURI = subs.apis[subs.publicApiId].url+'token1/'+id;
        if(ReactSession.get("justMintedId")) {
          console.log('Just minted!');
          
          tokenURI = subs.apis[subs.apiId].url+'tokenFirstLoad/'+id;
        }

        console.log(tokenURI);

        const response = await fetch(tokenURI);
        const tokenURIObj = await response.json();
        var fantum;

        var nameOf;

        // var totalFantumsURL = subs.apis[subs.apiId].url+'totalFantums';
        // const totalResp = await fetch(totalFantumsURL);
        // const totalObj = await totalResp.json();


        tokenIds =  totalFantums;
        //setTotalFantums(totalObj);


        getEvents();


        if(tokenURIObj.id) {

          var owner;
          var seller;
          var price;
          var formerOwner;
          var hashOf;
    
          if(!mmWasConnected) {
            completeDisplay(tokenURI, tokenURIObj, ownerMeetsFooReq, fantum, nameOf, owner, seller, price, formerOwner, false, false, false, hashOf);
          }
          //console.log('oh');
          if(EVM.ftc_contract && EVM.connectedAccount) {

            var useContract = EVM.fum_contract;
            var ttuContract = EVM.ftc_contract;
            if(subs.subsite=='gothums') {
              useContract = EVM.gum_contract;
              ttuContract = EVM.gtc_contract;
            }


            //console.log('eh?');

            mmWasConnected=true;


            
            

            var priceOfOfferedFantum = await ttuContract.methods.priceOfToken(id).call();
            var ownerOfOfferedFantum = await ttuContract.methods.sellerOfToken(id).call();
            let coft = await ttuContract.methods.getCounterOffersForToken(id).call();
            var newCounterOffers = [];
            for(var i=0;i<coft.length;i++) {
              let bid = await ttuContract.methods.getBidFromAddressOnToken(id, coft[i]).call();
              var counterOwnerMeetsFooReq = await ttuContract.methods.doesAddressMeetFooReq(coft[i].toString()).call();
              //console.log(id, coft[i], bid);
              newCounterOffers.push(
                {bid: bid, address: coft[i], fooReq: counterOwnerMeetsFooReq
              });
            }
            setCounterOffersForToken(newCounterOffers);
            if(priceOfOfferedFantum <= 0) {
              setTokenNotForSale(true);
            }
            price = priceOfOfferedFantum;


            seller = ownerOfOfferedFantum;

            // if(subs.subsite=='gothums') {
            //   seller='';
            //   price=0;
            // }

            var ownerMeetsFooReq;
            if(seller) {
              ownerMeetsFooReq = await ttuContract.methods.doesAddressMeetFooReq(seller.toString()).call();
            }


            
            


            owner = await useContract.methods.ownerOf(tokenURIObj.id).call();

            var dead = false;
            console.log('owner: '+owner);
            if(owner.toString().toLowerCase() === EVM.connectedAccount.toString().toLowerCase()) {
              setIsOwner(true);
            }
            if(owner.toString().toLowerCase() === EVM.GY_ADD[0].toString().toLowerCase()) {
              dead = true;
            }

            let namedChangedCount = await useContract.methods.nameChangedTimesOf(tokenURIObj.id).call();
            var namedChangePrice=0;
            namedChangePrice = await useContract.methods.nameChangePriceOf(namedChangedCount).call();
            setPriceOfNameChange(namedChangePrice);

            nameOf = await useContract.methods.nameOf(tokenURIObj.id).call();

            let approved = await useContract.methods.getApproved(tokenURIObj.id).call();
            if(approved=='0x0000000000000000000000000000000000000000') {
              approved=false;
            }
            setIsApproved(approved);


            formerOwner = await EVM.gy_contract.methods.ownerOfToken(tokenURIObj.id).call();
            if(formerOwner=='0x0000000000000000000000000000000000000000') {
              formerOwner=false;
            }


            let summoner = await EVM.fmr_contract.methods.getSummoner(tokenURIObj.id).call();

            let summonerLevel = await EVM.fmr_contract.methods.getLevel(tokenURIObj.id).call();

            let summonerXp = await EVM.fmr_contract.methods.getXp(tokenURIObj.id).call();

            let summonerClass = await EVM.fmr_contract.methods.getClass(tokenURIObj.id).call();

            let summonerClassName = await EVM.fmr_contract.methods.getClassString(tokenURIObj.id).call();

            let summonerXpRequired = await EVM.fmr_contract.methods.getXpRequired(tokenURIObj.id).call();


            let summonerLog = await EVM.fmr_contract.methods.getAdventurersLog(tokenURIObj.id).call();
            //summonerLog = summonerLog / 100;

            // console.log('summonerXp: '+summonerXp);
            // console.log('summonerXpRequired: '+summonerXpRequired);


            //currentBlockTimestamp = await EVM.web3.eth.getBlockNumber();
            var cBlock = await EVM.web3.eth.getBlockNumber();
            var currBlock = await EVM.web3.eth.getBlock(cBlock);
            currentBlockTimestamp = currBlock.timestamp;
 

            //console.log('summonerLevel: '+summonerLevel);

            //console.log('summonerLog: '+summonerLog);
            //console.log('summoner: '+summoner);

            if(summoner==0) {
              summoner=false;
            }
            let summonerCharacterCreated = await EVM.rra_contract.methods.getCharacterCreated(tokenURIObj.id).call();
            let summonerAbilityScores = await EVM.rra_contract.methods.getAbilityScores(tokenURIObj.id).call();
            //console.log(summonerAbilityScores);
            if(!summonerCharacterCreated) {
              summonerAbilityScores=false;
            }

            var summonerObj=false;
            if(summoner) {

              let claimableGold = await EVM.rmgld_contract.methods.claimable(summoner).call();
              let gold = await EVM.rmgld_contract.methods.balanceOf(summoner).call();

              summonerObj = {
                id: summoner,
                className: summonerClassName,
                level: summonerLevel,
                xp: summonerXp,
                class: summonerClass,
                xpRequired: summonerXpRequired,
                log: summonerLog,
                characterCreated: summonerCharacterCreated,
                abilityScores: summonerAbilityScores,
                claimableGold: claimableGold,
                gold: gold
              };
            }
            


            let atDayCare = await EVM.fad_contract.methods.isFumAtDaycare(tokenURIObj.id).call();
            let parentOfFum = await EVM.fad_contract.methods.parentOfFum(tokenURIObj.id).call();
            if(!atDayCare) {
              parentOfFum=false;
            }


            hashOf = await useContract.methods.hashOf(tokenURIObj.id).call();

            //claimable(uint summoner)

            //console.log('atDayCare: '+atDayCare);


            // seller = await ttuContract.methods.sellerOfToken(tokenURIObj.id).call();
            completeDisplay(tokenURI, tokenURIObj, ownerMeetsFooReq, fantum, nameOf, owner, seller, price, dead, formerOwner, summonerObj, atDayCare, parentOfFum, hashOf);
          } else {
            //completeDisplay(tokenURI, tokenURIObj, ownerMeetsFooReq, fantum, nameOf, owner, seller, price, formerOwner, false, false, false, false, false, false);
          }

          
        }

        // setHash(aHash);
        // setName(name);
        // setPrice(price/Math.pow(10,18));
        // setOwner(owner);

        //hasContent = true;
        gettingInfo=false;

      } catch (error) {
        console.log(error);
      }
    })();
      
  }





async function transferFantum(event) {

  event.preventDefault();


  if(transferAddressWasSet.toString() != enteredTransferAddress.toString()) {
    return;
  }

  var sendingTo = transferAddressWasSet.toString();



  // VALIDATE THIS ADDRESS

  if(!EVM.web3.utils.isAddress(sendingTo)) {
    alert('Invalid address.');
    return;
  }

  if(sendingTo.length!=42) {
    alert('Invalid Fantom address.');
    return;
  }
  

  // SEE IF APPROVED....

  if(!EVM.fum_contract) {
    return;
  }
  var useContract = EVM.fum_contract;
  if(subs.subsite=='gothums') {
    useContract = EVM.gum_contract;
  }

  var justApproved=false;
  let approved = await useContract.methods.getApproved(fantum.id).call();
  if(approved.toString()==sendingTo || sendingTo == transferIsApproved.toString()) {
    setTransferIsApproved(sendingTo);
    justApproved=true;
    console.log('Just approved?');
  } else {
    console.log('Not just approved?');
  }


  //console.log('transferIsApproved: '+transferIsApproved);
  if(!justApproved) {

    console.log('Approving tokenId for transfer...: '+fantum.id);

      setApprovingTransfer(true);
      await callMethod(useContract.methods.approve(sendingTo, fantum.id), 0, EVM.connectedAccount,  300000, async function(err, res) {
        setApprovingTransfer(false);
        if(err) {
              console.log(err.message);
            } else {
              setTransferIsApproved(sendingTo);
              console.log('approved!');
            }
      });


    } else {

      setTransferring(true);
      await callMethod(useContract.methods.transferFrom(EVM.connectedAccount.toString(), sendingTo, fantum.id), 0, EVM.connectedAccount,  300000, async function(err, res) {
       
        if(err) {
           setTransferring(false);
              console.log(err.message);
            } else {
               console.log('transferred!');

              await waitUntilOwnerIsThenReload(EVM.connectedAccount, function() {
                setOpenTransfer(false);
                setTransferring(false);
              });

            }
      });

    }

}

async function checkApprovalAndSetEnteredTransferAddress(address) {

  if(!EVM.fum_contract) {
    alert('MetaMask not ready yet...');
    transferAddressWasSet=false;
    return;
  }


  setEnteredTransferAddress(address);
  transferAddressWasSet=address;

  var useContract = EVM.fum_contract;
  if(subs.subsite=='gothums') {
    useContract = EVM.gum_contract;
  }


  let approved = await useContract.methods.getApproved(fantum.id).call();
  if(approved==enteredTransferAddress.toString()) {
    setTransferIsApproved(enteredTransferAddress.toString());
  }


}

function transferButtonText() {

   //console.log('transferIsApproved: '+transferIsApproved);

  if(transferIsApproved && transferIsApproved==enteredTransferAddress.toString()) {

    if(transferring) {
      return (<>Transferring...</>);
    } else {
      return (<>Transfer</>);
    }

  } else {

     if(approvingTransfer) {
      return (<>Approving...</>);
    } else {
      return (<>Approve</>);
    }


  }



}


async function cancelMyOffer() {

  setCancellingOffer(true);

  var ttuContract = EVM.ftc_contract;
  if(subs.subsite=='gothums') {
    ttuContract = EVM.gtc_contract;
  }

    await callMethod(ttuContract.methods.cancelOffer(fantum.id), 0, EVM.connectedAccount, 4000000, async function(err, res) {
      setCancellingOffer(false);
      if(err) {
            console.log(err.message);
          } else { 

           
            await waitUntilOwnerIsThenReload(EVM.connectedAccount, function() {
              
            });

            hasContent=false;
            getInfo();

          }
    });

  }

  async function submitBuyFantum(event) {

    if(!EVM.fum_contract) {
      return;
    }
    event.preventDefault();

    //console.log('we get here??');
    setBuyingNow(true);

    var sendAmount = EVM.web3.utils.fromWei(fantum.price.toString(), 'ether') * ((vars.fees.taker)/100+1.0);

     var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }

    await callMethod(ttuContract.methods.acceptOffer(fantum.id), EVM.web3.utils.toWei(sendAmount.toString(), 'ether'), EVM.connectedAccount, 3000000, function(err, res) {
      if(err) {
            console.log(err.message);
          } else {
            window.open("/wallet", '_self');
          }
          setBuyingNow(false);
    });
  }

  async function buyFantum() {

    setOpenBuyNow(true);

  }

  async function acceptCounterOffer(address) {

     var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }


    await callMethod(ttuContract.methods.acceptCounterOffer(fantum.id, address), 0, EVM.connectedAccount, 4000000, function(err, res) {
      if(err) {
            console.log(err.message);
          } else {
            window.open("/wallet", '_self');
          }
    });
  }

  async function cancelCounterOffer() {

     var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }

    await callMethod(ttuContract.methods.cancelCounterOffer(fantum.id), 0, EVM.connectedAccount, 4000000, async function(err, res) {
      if(err) {
            console.log(err.message);
          } else {
              hasContent=false;
             getInfo();
             await sleep(1000);
            hasContent=false;
            getInfo();
          }
    });

  }

async function submitCounterOffer(event) {

  event.preventDefault();

  setCounterOffering(true);
 // var sendAmount = enteredCounterOfferPrice * 1.1;
  var offerPrice = enteredCounterOfferPrice * ((vars.fees.maker+vars.fees.offer)/100+1.0);

  //offerPrice = parseInt(offerPrice);

  var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }

    await callMethod(ttuContract.methods.counterOffer(fantum.id), EVM.web3.utils.toWei(offerPrice.toString(), 'ether'), EVM.connectedAccount, 700000, async function(err, res) {
      if(err) {
            console.log(err.message);
          } else {
             setOpenCounterOffer(false);
              hasContent=false;
              getInfo();
              await sleep(1000);
              hasContent=false;
              getInfo();
          }

          setCounterOffering(false);
    });
}

async function putUpCounterOffer() {

  var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }


  let doesAddressMeetFooReq = await ttuContract.methods.doesAddressMeetFooReq(EVM.connectedAccount.toString()).call();
  let fooReq1 = await ttuContract.methods.getFooReq().call();
  setFooReq(fooReq1);
  if(!doesAddressMeetFooReq) {
    setOpenFooReq(true);
    //alert('You need at least '+(fooReq/Math.pow(10,18))+' FOO in your wallet to make counter offers on Fantums...');
  } else {
    setOpenCounterOffer(true);
  }
}



async function putUpForSale() {

  var ttuContract = EVM.ftc_contract;
    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }


  let doesAddressMeetFooReq = await ttuContract.methods.doesAddressMeetFooReq(EVM.connectedAccount.toString()).call();
  let fooReq1 = await ttuContract.methods.getFooReq().call();
  setFooReq(fooReq1);
  if(!doesAddressMeetFooReq) {
    //alert('You need at least '+(fooReq/Math.pow(10,18))+' FOO in your wallet to sell Fantums...');
    setOpenFooReq(true);
  } else {
    if(!isApproved) {
      setOpenApprove(true);
    } else {
      setOpenSell(true);
    }
  }

  
}

async function checkDayApproval() {

  if(!EVM.connectedAccount) {
    return;
  }

  if(!EVM.fum_contract) {
    return;
  }

  var useContract = EVM.fum_contract;
  if(subs.subsite=='gothums') {
    useContract = EVM.gum_contract;
  }

  
  let a4a = await useContract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FAD_ADD[0]).call();
  // let approved = await EVM.fum_contract.methods.getApproved(fantum.id).call();
  // if(approved=='0x0000000000000000000000000000000000000000') {
  //   setIsFumApprovedRra(false);
  // } else if(approved.toString().toLowerCase()==EVM.RRA_ADD[0].toLowerCase()) {
  //   setIsFumApprovedRra(true);
  // }

  setIsFumApprovedDaycare(a4a);

}

async function checkRraApproval() {

  if(!EVM.connectedAccount) {
    return;
  }

  if(!EVM.fum_contract) {
    return;
  }

  var useContract = EVM.fum_contract;
  if(subs.subsite=='gothums') {
    useContract = EVM.gum_contract;
  }
  
  let a4a = await useContract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.RRA_ADD[0]).call();
  // let approved = await EVM.fum_contract.methods.getApproved(fantum.id).call();
  // if(approved=='0x0000000000000000000000000000000000000000') {
  //   setIsFumApprovedRra(false);
  // } else if(approved.toString().toLowerCase()==EVM.RRA_ADD[0].toLowerCase()) {
  //   setIsFumApprovedRra(true);
  // }

  setIsFumApprovedRra(a4a);

}

async function rm_approve_claim() {
  if(!EVM.connectedAccount) { return; }
  if(!EVM.rgld_contract) { return; }

  if(!fantum.summoner) { return; }

  if(!isSummApprovedGoldClaim) {

    setIsApprovingSummGoldClaim(true);

    await callMethod(EVM.fum_contract.methods.setApprovalForAll(EVM.RGLD_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
      
      if(err2) {
        setIsApprovingSummGoldClaim(false);
        console.log(err2.message);
      } else {
        while(!isSummApprovedGoldClaim) {
          await sleep(1000);
          await checkGoldClaimApproval();
          console.log('Checking gold claim... '+isSummApprovedGoldClaim);
        }
        setIsApprovingSummGoldClaim(false);
      }
    });

   
  }

}

async function checkGoldClaimApproval() {
  if(!EVM.connectedAccount) { return; }
  if(!EVM.rar_contract) { return; }
  let a4a = await EVM.fum_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.RGLD_ADD[0]).call();
  setIsSummApprovedGoldClaim(a4a);
  console.log('Checking gold claim approval... '+a4a);
}

async function rm_claim_gold() {

  if(!EVM.rgld_contract) { return; }
  if(!EVM.connectedAccount) { return; }

  if(!fantum.id) { return; }

  var goldBefore = fantum.summoner.gold;

  setIsClaimingGold(true);
  await callMethod(EVM.rgld_contract.methods.claim(fantum.id), 0, EVM.connectedAccount,  900000, async function(err, res) {
    
    setOpenGoldClaim(false);
    if(err) {
      setIsClaimingGold(false);
        console.log(err.message);
      } else {
        while(liveFantum.summoner.gold == goldBefore) {
          hasContent=false; getInfo();
          await sleep(5000);
          console.log('Waiting for summoner GOLD to update '+liveFantum.summoner.gold);
        }
        setIsClaimingGold(false);
        hasContent=false;
        getInfo();
      }
  });

}

async function rm_approve_day() {
  if(!EVM.fad_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  if(!isFumApprovedDaycare) {

    setIsApprovingDaycare(true);

      await callMethod(EVM.fum_contract.methods.setApprovalForAll(EVM.FAD_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
      
      if(err2) {
        setIsApprovingDaycare(false);
        console.log(err2.message);
      } else {
        while(!isFumApprovedDaycare) {
          await sleep(1000);
          await checkDayApproval();
        }
        setIsApprovingDaycare(false);
      }
    });
  }

}

async function rm_approve_rra() {
  if(!EVM.rra_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  if(!isFumApprovedRra) {

    // await callMethod(EVM.fum_contract.methods.approve(EVM.RRA_ADD[0], fantum.id), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
      

    //   if(err2) {
    //     console.log(err2.message);
    //   } else {

    //     while(!isFumApprovedRra) {
    //       await sleep(1000);
    //       await checkRraApproval();
    //     }

        
    //   }

    setIsApprovingRra(true);

      await callMethod(EVM.fum_contract.methods.setApprovalForAll(EVM.RRA_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
      if(err2) {
        setIsApprovingRra(false);
        console.log(err2.message);
      } else {
        while(!isFumApprovedRra) {
          await sleep(1000);
          await checkRraApproval();
        }
        setIsApprovingRra(false);
      }
    });
  }





}

async function rm_drop_off() {

  alert('Daycare is currently full, dropOff has been disabled!');
  return;

  if(!EVM.fad_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  if(!isFumApprovedDaycare) {
    setIsFumApprovedDaycare(false);
    return;
  }

  setIsDroppingOff(true);

  await callMethod(EVM.fad_contract.methods.fumDropOff(fantum.id), 0, EVM.connectedAccount,  9000000, async function(err, res) {
    
    setOpenRMDaycare(false);
    if(err) {
        setIsDroppingOff(false);
          console.log(err.message);
        } else {
           while(!liveFantum.atDayCare) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for FUM to go to daycare... '+liveFantum.atDayCare);
          }
          setIsDroppingOff(false);
          window.open("/token/"+liveFantum.id, '_self');
          hasContent=false;
          getInfo();

        }
  });

}

async function rm_pick_up() {

  if(!EVM.fad_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  setIsPickingUp(true);
  await callMethod(EVM.fad_contract.methods.fumPickUp(fantum.id), 0, EVM.connectedAccount,  9000000, async function(err, res) {
    
    setOpenRMDaycare(false);
    if(err) {
      setIsPickingUp(false);
          console.log(err.message);
        } else {
          while(liveFantum.atDayCare) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for FUM to go home... '+liveFantum.atDayCare);
          }
          setIsPickingUp(false);
          hasContent=false;
          getInfo();
        }
  });

}

async function rm_spend_points() {

  if(!EVM.rra_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }


  if(!isFumApprovedRra) {
    setIsFumApprovedRra(false);
    return;
  }

  setIsSpendingPoints(true);
  await callMethod(EVM.rra_contract.methods.pointBuy(fantum.id, 
    parseInt(enteredRMStrength).toString(), 
    parseInt(enteredRMDexterity).toString(), 
    parseInt(enteredRMConstitution).toString(), 
    parseInt(enteredRMIntelligence).toString(), 
    parseInt(enteredRMWisdom).toString(), 
    parseInt(enteredRMCharisma).toString()), 0, EVM.connectedAccount,  500000, async function(err, res) {
    
    setOpenRMPointSelect(false);
    if(err) {
        setIsSpendingPoints(false);
          console.log(err.message);
        } else {
          //window.open("/token/"+id, '_self');
          
          while(!liveFantum.summonerAbilityScores) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for points...');
            console.log(liveFantum.summonerAbilityScores);
          }
          setIsSpendingPoints(false);
          hasContent=false;
          getInfo();

          
        }
  });


}


async function summon(classNum) {

  if(!EVM.fmr_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  await callMethod(EVM.fmr_contract.methods.fumSummon(fantum.id, classNum), 0, EVM.connectedAccount,  300000, async function(err, res) {
    
    setOpenRMClassChoose(false);
    if(err) {
          console.log(err.message);
        } else {
          //window.open("/token/"+id, '_self');
          
          while(!liveFantum.summoner) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for summoner... '+liveFantum.summoner);
          }

          hasContent=false;
          getInfo();

          
        }
  });


}



async function adventure() {

  if(!EVM.fmr_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  await callMethod(EVM.fmr_contract.methods.fumAdventure(fantum.id), 0, EVM.connectedAccount,  300000, async function(err, res) {
  
    if(err) {
          console.log(err.message);
        } else {
          //window.open("/token/"+id, '_self');
          
          hasContent=false;
          getInfo();

          
        }
  });


}

async function spendXp() {

  if(!EVM.fmr_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  await callMethod(EVM.fmr_contract.methods.fumSpendXp(fantum.id, 50000000000000000000), 0, EVM.connectedAccount,  300000, async function(err, res) {
  
    if(err) {
          console.log(err.message);
        } else {
          //window.open("/token/"+id, '_self');
          
          hasContent=false;
          getInfo();

          
        }
  });


}

async function levelUp() {

  if(!EVM.fmr_contract) {
    return;
  }
  if(!EVM.connectedAccount) {
    return;
  }

  var levelBefore = fantum.summoner.level;

  await callMethod(EVM.fmr_contract.methods.fumLevelUp(fantum.id), 0, EVM.connectedAccount,  300000, async function(err, res) {
  
    if(err) {
          console.log(err.message);
        } else {
          //window.open("/token/"+id, '_self');

          while(liveFantum.summoner.level == levelBefore) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for summoner level to change... '+liveFantum.summoner.level);
          }

          
          hasContent=false;
          getInfo();

          
        }
  });


}




async function submitApprove(event) {
  event.preventDefault();


  if(!isApproved) {

    var useContract = EVM.fum_contract;
    if(subs.subsite=='gothums') {
      useContract = EVM.gum_contract;
    }

      setApproving(true);

      var tradeAddToUse = EVM.FTC_ADD[0];
      if(subs.subsite=='gothums') {
        tradeAddToUse = EVM.GTC_ADD[0];
      }


      await callMethod(useContract.methods.approve(tradeAddToUse, fantum.id), 0, EVM.connectedAccount,  300000, async function(err, res) {
        setApproving(false);
        if(err) {
              console.log(err.message);
            } else {
              //window.open("/token/"+id, '_self');
              
              hasContent=false;
              getInfo();
              setOpenApprove(false);
              setOpenSell(true);
              await sleep(1000);
              hasContent=false;
              getInfo();
              
            }
      });


    }

  

}

async function submitSell(event) {

  event.preventDefault();

 setOpenRename(false);

    var offerPrice = parseInt(enteredSellPrice);
    //var sendAmount = offerPrice * 1.1;

    console.log('tokenId: '+id);
    

    BigNumber.set({ DECIMAL_PLACES: 0, ROUNDING_MODE: 0 });
    offerPrice =  BigNumber(offerPrice).times(Math.pow(10, 18));

    var offerFee = offerPrice.times(vars.fees.offer/100);

    console.log('offerPrice: '+offerPrice.toFixed());
    console.log('offerFee: '+offerFee.toFixed());
    
    setSelling(true);

    var ttuContract = EVM.ftc_contract;

    if(subs.subsite=='gothums') {
      ttuContract = EVM.gtc_contract;
    }
    var tradeAddToUse = EVM.FTC_ADD[0];
    if(subs.subsite=='gothums') {
      tradeAddToUse = EVM.GTC_ADD[0];
    }


    await callMethod(ttuContract.methods.offer(id, offerPrice.toFixed()), offerFee.toFixed().toString(), EVM.connectedAccount,  600000, async function(err, res) {
        setSelling(false);
      if(err) {
            console.log(err.message);
          } else {
            //window.open("/token/"+id, '_self');
            
            
            await waitUntilOwnerIsThenReload(tradeAddToUse, function() {
              setOpenSell(false);
            });

            hasContent=false;
            getInfo();
          }
    });

  


  

}

async function checkRipApproval() {

  if(!EVM.connectedAccount) {
    return;
  }

  if(!EVM.fum_contract) {
    return;
  }

  let allowance = await EVM.rip_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.GY_ADD[0]).call();
  //console.log(EVM.GY_ADD[0]+' allowance: '+allowance);
  receivedRipApproval=true;

  if(parseInt(allowance) >= resPrice) {
    //console.log('RIP IS APPROVED FOR A RES');
    setIsRipApproved(true);
  }
}

async function deathTokenCheck() {

  if(!EVM.gy_contract) {
    return;
  }

  let aResPrice =  await EVM.gy_contract.methods.getResPrice().call();
  resPrice = aResPrice;
  await checkRipApproval();

  let rip_balance =  await EVM.rip_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
  if(parseInt(rip_balance) < parseInt(resPrice)) {
    alert('You do not have enough death tokens to resurrect this Fantum!');
    return false;
  }
  return true;
}

async function callApproveRip() {
  if(!EVM.fum_contract) {
    return;
  }

  //var dtc = await deathTokenCheck(); if(!dtc) { return; }

  console.log('resPrice: '+resPrice);

  await callMethod(EVM.rip_contract.methods.approve(EVM.GY_ADD[0], (resPrice).toString()), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
    if(err2) {
      console.log(err2.message);
    } else {
      await checkRipApproval();
    }
  });
}
async function callInfiniteApproveRip() {
  if(!EVM.fum_contract) {
    return;
  }
  //var dtc = await deathTokenCheck(); if(!dtc) { return; }

  await callMethod(EVM.rip_contract.methods.approve(EVM.GY_ADD[0], vars.maxUint256), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
    if(err2) {
      console.log(err2.message);
    } else {
      await checkRipApproval();
    }
  });
}

async function callResurrection() {
  if(!EVM.fum_contract) {
    return;
  }
  var dtc = await deathTokenCheck(); if(!dtc) { return; }

  setResurrecting(true);
  await callMethod(EVM.gy_contract.methods.resFUM(fantum.id), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
    if(err2) {
      setResurrecting(false);
      console.log(err2.message);
    } else {
      

      await waitUntilOwnerIsThenReload(EVM.connectedAccount, function() {
        setResurrecting(false);
        setOpenResurrect(false);
      });


    }
  });
}

async function waitUntilOwnerIsThenReload(ownerIs, callback) {

  var refreshes=0;
  var foundNewOwner=false;
  do {

    await sleep(2000);

    console.log('checking for new owner... ');
    if(addysEqual(currentOwner, ownerIs)) {
      hasContent=false;
      getInfo();
    } else {
      callback();
      foundNewOwner=true;
    }
    refreshes++;
  } while(!foundNewOwner && refreshes < 20);

  await sleep(4000);

  window.open('/token/'+fantum.id, '_self');

}

async function submitRename(event) {

  if(!EVM.fum_contract) {
    return;
  }
  var useContract = EVM.fum_contract;
  if(subs.subsite=='gothums') {
    useContract = EVM.gum_contract;
  }

  event.preventDefault();
  setOpenRename(false);
 // setCurrAccount(EVM.connectedAccount.toString());

  let namedChangedCount = await useContract.methods.nameChangedTimesOf(fantum.id).call();
  let namedChangePrice = await useContract.methods.nameChangePriceOf(namedChangedCount).call();
  setPriceOfNameChange(namedChangePrice);
  console.log(namedChangePrice);


  var nameBefore = fantum.name;
  setRenaming(true);
  var meth;
  if(subs.subsite=='gothums') {
    meth = useContract.methods.nameGothum(fantum.id, enteredRename.trim());
  } else {
    meth = useContract.methods.nameFantum(fantum.id, enteredRename.trim());
  }
  await callMethod(meth, namedChangePrice, EVM.connectedAccount,  600000, async function(err, res) {
    setRenaming(false);
    if(err) {
          console.log(err.message);
          
        } else {

          while(liveFantum.name == nameBefore) {
            hasContent=false; getInfo();
            await sleep(5000);
            console.log('Waiting for name to change... '+liveFantum.name);
          }

          
          hasContent=false;
          getInfo();


        }
  });
}

function renameButtonText() {

    if(renaming) {
        return (
        <>
        RENAMING...
        </>
        );
    } else {
        return (
        <>
        RENAME
        </>
        );
    }
}


function descForLayer(layerIndex, needsOdds, isPop) {

  var layerName;

  var i=0;
  for(var aLayerName in subs.layerData) {
    if(layerIndex==i) {
      layerName = aLayerName;
      break;
    }
    i++;
  }


  if(!layerName) {
    return;
  }

  var fileKey = fileKeyForLayerUsingHash(layerName, fantum.hash);

  //var descForLayer = descForLayerUsingHash(layerName, fantum.hash);


  var descForLayer = descForFilename(fileKey);

  // if(!descForLayer) {
  //   descForLayer='N';
  // }

  if(isPop) {


    if(descForLayer) {
      return (<>
        <span className="block_details"><i>{rankScoreLayers ? rankScoreLayers[layerIndex] : ''}</i> </span><br/>
      </>);
    }
     

  } else {


    if(!needsOdds && descForLayer) {
      return (<>
        <span className="block_details">{subs.layerDataNames[layerIndex].toUpperCase()}: <b><a href={'/search/'+fileKey}>{descForLayer.toUpperCase()}</a></b> </span><br/>
      </>);
    } else {
     // var odds = oddsForLayerUsingHash(layerName, fantum.hash);
      var odds;
      if(rankScoreLayers) {
        odds = Math.round(rankScoreLayers[layerIndex] / totalFantums * 100 * 100)/100;
      }
      odds=0;

      //oddsArr = oddsArray.concat();

      oddsArr[layerIndex] = odds;

      //setOddsArray(oddsArr);

      //console.log(oddsArr);

      if(descForLayer) {
        return (<>
        <span className="block_details"><i>{odds}%</i> </span><br/>
      </>);
      }
      
    }

    // if(!descForLayer) {
    //   if(needsOdds) {
    //     return (<>
    //     <span className="block_details"><i>{odds}%</i> </span><br/>
    //   </>);
    //   } else {
    //     return (<>
    //       <span className="block_details">{fileKey}</span><br/>
    //     </>);
    //   }
      
    // }


  }
  

  

  
  
}

// function popTotal() {

//   var totalNum = 0;
//   for(var i=0;i<rankScoreLayers.length;i++) {
//     if(rankScoreLayers[i]) {
//       totalNum = totalNum + rankScoreLayers[i];
//     }
    
//   }

//   //console.log('totalNum: '+totalNum);
//   return (<>Total in Population (inc. hidden): <b>{Math.round(totalNum*100)/100}</b></>);

// }

// function rarityTotal() {

//   //var oddsArr = oddsArray.concat();

//   var totalNum = 0;
//   for(var i=0;i<oddsArr.length;i++) {
//     if(oddsArr[i]) {
//       //console.log('adding: '+oddsArr[i]);
//       totalNum = totalNum + oddsArr[i];
//     }
    
//   }

//   //console.log('totalNum: '+totalNum);
//   return (<>Total by Rarity (includes hidden): {Math.round(totalNum*100)/100}</>);

// }

function oddOrEven(index) {

      var odd_even=0;
      if(index%2==0) {
        odd_even=1;
      }

      return ("counter_offer_"+odd_even+' '+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM()));
    }


    function offererAddress(offerer) {
      if(offerer.address.toString().toLowerCase()==EVM.connectedAccount.toString().toLowerCase()) {
        return 'You have';
      } else {
        return offerer.address.substr(0, 6)+'...'+offerer.address.substr(38, 42);
      }
      
    }

function printEvent(event, index) {

  //console.log(event);

  if(!event) {
    return;
  }

  var nextEvent = events[index+1];

  var txUrl = 'https://ftmscan.com/tx/'+event.transactionHash;

  var shortTx = event.transactionHash.substr(0,6)+'...'+event.transactionHash.substr(60, 6);
  //console.log(event);
  if(event.type=='mint') {
    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">{subs.properSiteName} #{fantum.id} Minted at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='rename') {

    var renamedTo='';
    var data = event.data.substr(2, event.data.length-2);
    if(subs.subsite=='gothums') {
      renamedTo = hex_to_ascii(data.substr(64*4, 64));
    } else {
      renamedTo = hex_to_ascii(data.substr(64*3, 64));
    }
    
    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Renamed to "<b>{renamedTo}</b>" at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='transfer') {


    if(nextEvent && nextEvent.type=='mint' && event.transactionHash==nextEvent.transactionHash) {
      return (<div key={index}></div>);
    }

   // var renamedTo='';
    var data = event.data.substr(2, event.data.length-2);

    //renamedTo = hex_to_ascii(data.substr(64*3, 64));
    var to1 = event.topics[2].substr(2, event.topics[2].length-2);
    var xferredTo = '0x'+to1.substr(to1.length-40, 40);
    var toUrl = 'https://ftmscan.com/address/'+xferredTo;

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Transferred to <a href={toUrl}>{xferredTo.substr(0,6)+'...'+xferredTo.substr(38,4)}</a> at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='resurrection') {

   var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);


    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Resurrected at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='death') {

   var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);


    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Sent to Graveyard at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='offer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = parseInt(data.substr(64*2, 64), 16);

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Offered for {(Math.round((d3/Math.pow(10,18))*10000)/10000)} FTM at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='cancelledOffer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = parseInt(data.substr(64*2, 64), 16);

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Cancelled Offer at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='acceptedOffer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = parseInt(data.substr(64*2, 64), 16);
    var d4 = parseInt(data.substr(64*3, 64), 16);

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Accepted Offer, {(Math.round((d4/Math.pow(10,18))*10000)/10000)} FTM at <a href={txUrl}>{shortTx}</a></span></div>);
  }

  if(event.type=='counterOffer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = parseInt(data.substr(64*2, 64), 16);
    var d4 = parseInt(data.substr(64*3, 64), 16);

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Counter Offered for {(Math.round((d4/Math.pow(10,18))*10000)/10000)} FTM at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='cancelledCounterOffer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = parseInt(data.substr(64*2, 64), 16);

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line">Cancelled Counter Offer at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  if(event.type=='acceptedCounterOffer') {
    //console.log(event);

    var data = event.data.substr(2, event.data.length-2);

    var d1 = parseInt(data.substr(64*0, 64), 16);
    var d2 = parseInt(data.substr(64*1, 64), 16);
    var d3 = data.substr(64*2, 64);
    var d4 = parseInt(data.substr(64*3, 64), 16);

     var xferredTo = '0x'+d3.substr(d3.length-40, 40);
     var toUrl = 'https://ftmscan.com/address/'+xferredTo;

    return (<div key={index} className={oddOrEven(index)}><span className="token_logs_line"><a href={toUrl}>{xferredTo.substr(0,6)+'...'+xferredTo.substr(38,4)}</a> Accepted Counter Offer, {(d4/Math.pow(10,18))} FTM at <a href={txUrl}>{shortTx}</a></span></div>);
  }
  
}

function hex_to_ascii(str1)
 {
  var hex  = str1.toString();
  var str = '';
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
 }


 const [cancellingOffer, setCancellingOffer] = useState(false); //tracks TX
 const [buyingNow, setBuyingNow] = useState(false); //tracks TX

// MODAL STUFF
const [openRename, setOpenRename] = useState(false);  
const [enteredRename, setEnteredRename] = useState("");
const [renaming, setRenaming] = useState(false); //tracks TX

const [openApprove, setOpenApprove] = useState(false);
const [approving, setApproving] = useState(false); //tracks TX

const [openSell, setOpenSell] = useState(false);
const [enteredSellPrice, setEnteredSellPrice] = useState(10);
const [selling, setSelling] = useState(false); //tracks TX

const [openTransfer, setOpenTransfer] = useState(false);
const [enteredTransferAddress, setEnteredTransferAddress] = useState('');
const [approvingTransfer, setApprovingTransfer] = useState(false); //tracks TX
const [transferring, setTransferring] = useState(false); //tracks TX

const [openResurrect, setOpenResurrect] = useState(false);
const [resurrecting, setResurrecting] = useState(false); //tracks TX
const [approvingRip, setApprovingRip] = useState(false); //tracks TX
const [approveResurrection, setApproveResurrection] = useState(false); //tracks TX
const [isRipApproved, setIsRipApproved] = useState(false);

const [openCounterOffer, setOpenCounterOffer] = useState(false);  
const [enteredCounterOfferPrice, setEnteredCounterOfferPrice] = useState(10);
const [counterOffering, setCounterOffering] = useState(false); //tracks TX

const [openFooReq, setOpenFooReq] = useState(false);
const [fooReq, setFooReq] = useState(0);

const [openRMClassChoose, setOpenRMClassChoose] = useState(false);
const [openRMPointSelect, setOpenRMPointSelect] = useState(false);
const [enteredRMStrength, setEnteredRMStrength] = useState(8);
const [enteredRMDexterity, setEnteredRMDexterity] = useState(8);
const [enteredRMConstitution, setEnteredRMConstitution] = useState(8);
const [enteredRMIntelligence, setEnteredRMIntelligence] = useState(8);
const [enteredRMWisdom, setEnteredRMWisdom] = useState(8);
const [enteredRMCharisma, setEnteredRMCharisma] = useState(8);
const [pointBuyTotal, setPointBuyTotal] = useState('Please enter your stat selection!');
const [isFumApprovedRra, setIsFumApprovedRra] = useState(false);
const [isApprovingRra, setIsApprovingRra] = useState(false);

const [openRMDaycare, setOpenRMDaycare] = useState(false);
const [isFumApprovedDaycare, setIsFumApprovedDaycare] = useState(false);
const [isApprovingDaycare, setIsApprovingDaycare] = useState(false);

const [isDroppingOff, setIsDroppingOff] = useState(false);
const [isPickingUp, setIsPickingUp] = useState(false);
const [isSpendingPoints, setIsSpendingPoints] = useState(false);

const [offerFee, setOfferFee] = useState(enteredSellPrice*vars.fees.offer/100);
const [makerFee, setMakerFee] = useState(enteredSellPrice*vars.fees.maker/100);
const [takerFee, setTakerFee] = useState(enteredSellPrice*vars.fees.taker/100);

const [openBuyNow, setOpenBuyNow] = useState(false);

function removeFromSaleText() {
  if(cancellingOffer) {
        return 'REMOVING FROM SALE...';
    } else {
        return 'REMOVE FROM SALE';
    }
}
function approveText() {
  if(approving) {
        return 'Approving...';
    } else {
        return 'Approve';
    }
}

function sellText() {
  if(selling) {
        return 'Selling...';
    } else {
        return 'Sell for '+(parseInt(enteredSellPrice)+parseInt(offerFee))+' FTM';
    }
}

function counterOfferText() {
  if(counterOffering) {
        return 'PLACING COUNTER OFFER...';
    } else {
        return 'COUNTER OFFER FOR '+Math.round(enteredCounterOfferPrice*((vars.fees.maker+vars.fees.offer)/100+1.0)*100)/100+' FTM';
    }
}

function buyNowText() {
  if(buyingNow) {
    return 'BUYING NOW...';
  } else {
    return 'BUY NOW FOR '+(Math.round(fantum.price/Math.pow(10,18)*10000)/10000)+' FTM';
  }
}

function buyTextInModal() {
  if(buyingNow) {
    return 'BUYING NOW...';
  } else {
    return 'BUY NOW FOR '+(Math.round(((fantum.price*vars.fees.taker/100)/Math.pow(10,18)+fantum.price/Math.pow(10,18))*10000)/10000)+' FTM';
  }
}

function layerDescs() {

}

function operaOrOperaGM() {

   if(subs.subsite=='gothums') {
    return 'opera_gm';
  } else {
    return 'opera';
  }
}

function operaOrGraveyard() {

  if(!EVM.connectedAccount) {
    if(!subs.subsite || subs.subsite=='rarity') {
      return 'opera';
    } else {
      return 'opera_gm';
    }
    
  }


  if(fantum && fantum.dead) {
    return 'graveyard';
  } else {
    if(!subs.subsite || subs.subsite=='rarity') {
      return 'opera';
    } else {
      return 'opera_gm';
    }
  }
}



function buttonName() {
  if(fantum.price > 0) {
    return 'dark';
  }
  if(fantum && fantum.dead) {
    return 'dark';
  }
  return 'secondary';
}

function duelWithFantum() {

  if(!fantum.id) {
    return false;
  }
  ReactSession.setStoreType("localStorage");
  ReactSession.set("duelling_token_is_fighting", false);
  ReactSession.set("duelling_fight_over_leftId", false);
  ReactSession.set("duelling_fight_over_rightId", false);
  ReactSession.set("duelling_chose_fighter", fantum.id);

  window.open('/duel', '_self');
}

function descLeft() {

   if(subs.subsite=='gothums') {
    return (<>
  {descForLayer(0)}
  {/*{descForLayer(1)}*/}
  {descForLayer(2)}
  {descForLayer(3)}
  {descForLayer(4)}
  {descForLayer(5)}
  {descForLayer(6)}
  {descForLayer(7)}
  {descForLayer(8)}
  {descForLayer(9)}
  {descForLayer(10)}
  {descForLayer(11)}
    </>);

  } else {
    return (<>
  {descForLayer(0)}
  {/*{descForLayer(1)}*/}
  {descForLayer(2)}
  {descForLayer(3)}
  {descForLayer(4)}
  {descForLayer(5)}
  {descForLayer(6)}
  {descForLayer(7)}
  {descForLayer(8)}
  {descForLayer(9)}
  {descForLayer(10)}
  {descForLayer(11)}
  {descForLayer(12)}
  {descForLayer(13)}
    </>);
  }



}

function descRight() {
  if(subs.subsite=='gothums') {
    return (<>
      {descForLayer(0, true)}
      {descForLayer(2, true)}
      {descForLayer(3, true)}
      {descForLayer(4, true)}
      {descForLayer(5, true)}
      {descForLayer(6, true)}
      {descForLayer(7, true)}
      {descForLayer(8, true)}
      {descForLayer(9, true)}
      {descForLayer(10, true)}
      {descForLayer(11, true)}
    </>);

  } else {
    return (<>
      {descForLayer(0, true)}
      {descForLayer(2, true)}
      {descForLayer(3, true)}
      {descForLayer(4, true)}
      {descForLayer(5, true)}
      {descForLayer(6, true)}
      {descForLayer(7, true)}
      {descForLayer(8, true)}
      {descForLayer(9, true)}
      {descForLayer(10, true)}
      {descForLayer(11, true)}
      {descForLayer(12, true)}
      {descForLayer(13, true)}
    </>);
  }
  
}


function descRight2() {
  if(subs.subsite=='gothums') {
    return (<>
      {descForLayer(0, true, true)}
      {descForLayer(2, true, true)}
      {descForLayer(3, true, true)}
      {descForLayer(4, true, true)}
      {descForLayer(5, true, true)}
      {descForLayer(6, true, true)}
      {descForLayer(7, true, true)}
      {descForLayer(8, true, true)}
      {descForLayer(9, true, true)}
      {descForLayer(10, true, true)}
      {descForLayer(11, true, true)}
    </>);

  } else {
    return (<>
      {descForLayer(0, true, true)}
      {descForLayer(2, true, true)}
      {descForLayer(3, true, true)}
      {descForLayer(4, true, true)}
      {descForLayer(5, true, true)}
      {descForLayer(6, true, true)}
      {descForLayer(7, true, true)}
      {descForLayer(8, true, true)}
      {descForLayer(9, true, true)}
      {descForLayer(10, true, true)}
      {descForLayer(11, true, true)}
      {descForLayer(12, true, true)}
      {descForLayer(13, true, true)}
    </>);
  }
  
}



function rezText() {
  if(resurrecting) {
    return 'RESURRECTING...';
  } else {
    return 'RESURRECT THIS FUM';
  }
}

function RMcalcPB(score) {

  var retInt=0;
    if (score <= 14) {
        retInt = parseInt(score - 8);
    } else {
        retInt = parseInt((Math.pow((score - 8), 2))/6);
    }

    if(retInt < 0) {
      retInt=0;
    }

    return retInt;
}

function wltdk() {

  if(fantum.lwtdk) {
    return (fantum.lwtdk[1]+fantum.lwtdk[4])+'W '+(fantum.lwtdk[0]+fantum.lwtdk[3])+'L '+fantum.lwtdk[2]+'T '+fantum.lwtdk[4]+'K '+fantum.lwtdk[3]+'D';
  }

  return '';
}


async function enteredRMPointsId(statId, val) {

  if(!EVM.connectedAccount) {
    pointBuyTotal=0;
  }
  // var valTU = val;
  // if(!val) {
  //   valTU=0;
  // }
  
  // if(val) {
  //   val = parseInt(val);
  // }
  
  if(val) {
    val = parseInt(val);
  }
  if(isNaN(val)) {

    return;
  }
  

  console.log('entered val: '+val);

  if(statId==0) { setEnteredRMStrength(val);  } 
  else if(statId==1) { setEnteredRMDexterity(val);  }
  else if(statId==2) { setEnteredRMConstitution(val); }
  else if(statId==3) { setEnteredRMIntelligence(val); }
  else if(statId==4) { setEnteredRMWisdom(val); }
  else if(statId==5) { setEnteredRMCharisma(val); }

  var useStrength=parseInt(enteredRMStrength);
  var useDexterity=parseInt(enteredRMDexterity);
  var useConstitution=parseInt(enteredRMConstitution);
  var useIntelligence=parseInt(enteredRMIntelligence);
  var useWisdom=parseInt(enteredRMWisdom);
  var useCharisma=parseInt(enteredRMCharisma);

  if(!val) {
    val = 0;
  }

  if(statId==0) { useStrength=val;  } 
  else if(statId==1) { useDexterity=val;  }
  else if(statId==2) { useConstitution=val; }
  else if(statId==3) { useIntelligence=val; }
  else if(statId==4) { useWisdom=val; }
  else if(statId==5) { useCharisma=val; }


  if(!enteredRMStrength) { useStrength=0 }
  if(!enteredRMDexterity) { useDexterity=0 }
  if(!enteredRMConstitution) { useConstitution=0; }
  if(!enteredRMIntelligence) { useIntelligence=0; }
  if(!enteredRMWisdom) { useWisdom=0; }
  if(!enteredRMCharisma) { useCharisma=0; }


  console.log(useStrength);
  console.log(useDexterity);
  console.log(useConstitution);
  console.log(useIntelligence);
  console.log(useWisdom);
  console.log(useCharisma);

  var pbt = RMcalcPB(useStrength)+RMcalcPB(useDexterity)+RMcalcPB(useConstitution)+RMcalcPB(useIntelligence)+RMcalcPB(useWisdom)+RMcalcPB(useCharisma);





  console.log(pbt);


  if(useStrength<8 || useDexterity<8 || useConstitution<8 || useIntelligence<8 || useWisdom<8 || useCharisma<8) {
    setPointBuyTotal('Stats must start at 8 points, spend more points!');
  } else if(pbt>=6 && pbt<32) {
    setPointBuyTotal('You have '+Math.floor(32-pbt)+' more points to spend.');
  } else if(pbt<6) {
    setPointBuyTotal('Spend more points!');
  } else if(pbt>32) {
    setPointBuyTotal('Too many points, please remove '+Math.floor(pbt-32)+'!');
  } else {

      let pbt2 = await EVM.rra_contract.methods.calculate_point_buy(
        useStrength.toString(),
        useDexterity.toString(),
        useConstitution.toString(),
        useIntelligence.toString(),
        useWisdom.toString(),
        useCharisma.toString()
      ).call();

      if(parseInt(pbt2)==pbt) {
        setPointBuyTotal('Perfect, all 32 points spent!');
      } else {
        setPointBuyTotal('Double check selection failed, please change stats!');
      }

    
  }

  

  
}

//console.log(fantum);
if(fantum.id && fantum.hash && fantum.name && (typeof fantum.owner === 'string' || fantum.owner instanceof String)) {

  //

  if(tokenIds < fantum.id) {
    tokenIds = fantum.id;
  }

  return ( 

    <>
    <div>

    {modal(openGoldClaim, () => setOpenGoldClaim(false), 'CLAIM GOLD', function() {

        return (<><br/>
          <span className="modal_text">Your character can claim {(fantum.summoner && !isNaN(fantum.summoner.claimableGold) ? (Math.round(fantum.summoner.claimableGold/Math.pow(10,18)*100)/100) : 0)} GOLD, claim it?</span><br/><br/>
          <br/>
          
          <div className={!isSummApprovedGoldClaim ? '' : 'hidden'}>
            <Button onClick={() => rm_approve_claim()} type="Submit" size="lg" variant="primary" className={"rm_class_choose_button"}>{isApprovingSummGoldClaim ? 'APPROVING CLAIM...' : 'APPROVE CLAIM'}</Button>
          </div>
          <div className={isSummApprovedGoldClaim ? '' : 'hidden'}>
            <Button onClick={() => rm_claim_gold()} size="lg" variant="primary" className={"rm_class_choose_button "}>{isClaimingGold ? 'CLAIMING GOLD...' : 'CLAIM GOLD'}</Button>
          </div>

         

          </>);
      })}

    {modal(openRMDaycare, () => setOpenRMDaycare(false), 'DAYCARE', function() {
        return (<><br/>
          <span className="modal_text">Send your FUM to Daycare to automatically adventure every day!</span><br/><br/>
          <br/>
          
          <div className={!fantum.atDayCare ? '' : 'hidden'}>
            <Button onClick={() => rm_approve_day()} type="Submit" size="lg" variant="primary" className={"rm_class_choose_button "+(!isFumApprovedDaycare ? '' : 'hidden')}>{isApprovingDaycare ? 'APPROVING DAYCARE...' : 'APPROVE DAYCARE'}</Button>
            <Button onClick={() => rm_drop_off()} size="lg" variant="primary" className={"rm_class_choose_button "+(isFumApprovedDaycare ? '' : 'hidden')}>{isDroppingOff ? 'DROPPING OFF...' : 'DROP OFF'}</Button>
          </div>
          <div className={fantum.atDayCare ? '' : 'hidden'}>
          <Button onClick={() => rm_pick_up()} size="lg" variant="primary" className={"rm_class_choose_button "}>{isPickingUp ? 'PICKING UP...' : 'PICK UP'}</Button>
          </div>

         

          </>);
      })}

    {modal(openRMPointSelect, () => setOpenRMPointSelect(false), 'ALLOCATE POINTS', function() {
        return (<><br/>
          <span className="modal_text">You have 32 points to spend on your adventurer <i>(this cannot be undone!)</i>. How will you spend them?</span><br/><br/>
          <span className="modal_text">8-14 costs 1 point</span><br/>
          <span className="modal_text">15-16 costs 2 points</span><br/>
          <span className="modal_text">17+ costs 3 points</span><br/><br/>
          

          <br/>
          STRENGTH:<TextField value={enteredRMStrength} label="" onChange={(e) => { enteredRMPointsId(0, e.target.value); }} /><br/>
          DEXTERITY:<TextField value={enteredRMDexterity} label="" onChange={(e) => { enteredRMPointsId(1, e.target.value); }} /><br/>
          CONSTITUTION:<TextField value={enteredRMConstitution} label="" onChange={(e) => { enteredRMPointsId(2, e.target.value); }} /><br/>
          INTELLIGENCE:<TextField value={enteredRMIntelligence} label="" onChange={(e) => { enteredRMPointsId(3, e.target.value); }} /><br/>
          WISDOM:<TextField value={enteredRMWisdom} label="" onChange={(e) => { enteredRMPointsId(4, e.target.value); }} /><br/>
          CHARISMA:<TextField value={enteredRMCharisma} label="" onChange={(e) => { enteredRMPointsId(5, e.target.value); }} /><br/><br/>

          {pointBuyTotal}<br/><br/>
          <Button onClick={() => rm_approve_rra()} type="Submit" size="lg" variant="primary" className={"rm_class_choose_button "+(!isFumApprovedRra ? '' : 'hidden')}>{isApprovingRra ? 'APPROVING...' : 'APPROVE'}</Button>
          <Button onClick={() => rm_spend_points()} size="lg" variant="primary" className={"rm_class_choose_button "+(isFumApprovedRra ? '' : 'hidden')}>{isSpendingPoints ? 'SPENDING POINTS...' : 'SPEND POINTS'}</Button>

          </>);
      })}

    {modal(openRMClassChoose, () => setOpenRMClassChoose(false), 'CHOOSE A CLASS', function() {
        return (<><br/>
          <span className="modal_text">Choose a Class if you'd like your Fantum to participate in the RM (Rarity Manifested) blockchain adventure game!</span><br/><br/>
          


          <Button onClick={() => summon(1)} size="lg" variant="primary" className={"rm_class_choose_button"}>BARBARIAN</Button>
          <Button onClick={() => summon(2)} size="lg" variant="primary" className={"rm_class_choose_button"}>BARD</Button>
          <Button onClick={() => summon(3)} size="lg" variant="primary" className={"rm_class_choose_button"}>CLERIC</Button>
          <Button onClick={() => summon(4)} size="lg" variant="primary" className={"rm_class_choose_button"}>DRUID</Button>
          <Button onClick={() => summon(5)} size="lg" variant="primary" className={"rm_class_choose_button"}>FIGHTER</Button>
          <Button onClick={() => summon(6)} size="lg" variant="primary" className={"rm_class_choose_button"}>MONK</Button>
          <Button onClick={() => summon(7)} size="lg" variant="primary" className={"rm_class_choose_button"}>PALADIN</Button>
          <Button onClick={() => summon(8)} size="lg" variant="primary" className={"rm_class_choose_button"}>RANGER</Button>
          <Button onClick={() => summon(9)} size="lg" variant="primary" className={"rm_class_choose_button"}>ROGUE</Button>
          <Button onClick={() => summon(10)} size="lg" variant="primary" className={"rm_class_choose_button"}>SORCERER</Button>
          <Button onClick={() => summon(11)} size="lg" variant="primary" className={"rm_class_choose_button"}>WIZARD</Button>

          </>);
      })}

    {modal(openResurrect, () => setOpenResurrect(false), 'RESURRECT THIS FANTUM', function() {
        return (<><br/>

          <img src="/img/mm_riplogo512.png" width="100" height="100" /><br/><br/>
          <span className="modal_text">Would you like to bring this Fantum back to life? The cost is <b>{resPrice/Math.pow(10,18)} death tokens</b> (RIP).</span><br/><br/>
          <span className={"modal_text "+(!isRipApproved ? '' : 'hidden')}>Begin by approving the graveyard to spend your RIP!<br/><br/></span>
          <span className={"modal_text "+(isRipApproved ? '' : 'hidden')}>Contract is approved!<br/><br/></span>
          
          <Button onClick={() => callApproveRip()} type="Submit" size="lg" variant="primary" className={"token_modal_button_pad_right  "+(!isRipApproved ? '' : 'hidden')}>APPROVE ONCE</Button>
          <Button onClick={() => callInfiniteApproveRip()} type="Submit" size="lg" variant="primary" className={"token_modal_button_pad_right "+(!isRipApproved ? '' : 'hidden')}>INFINITE APPROVE</Button>
          <Button onClick={() => callResurrection()} type="Submit" size="lg" variant="primary" className={isRipApproved ? '' : 'hidden'}>{rezText()}</Button>

          </>);
      })}

    {modal(openTransfer, () => setOpenTransfer(false), 'TRANSFER THIS '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to send this Fantum to another address? Enter that address here!</span><br/><br/>
          <span className="modal_text">Be careful, this cannot be undone!</span><br/><br/>
          <form onSubmit={transferFantum}>
          TRANSFER TO ADDRESS:<TextField value={enteredTransferAddress} label="" onChange={(e) => { checkApprovalAndSetEnteredTransferAddress(e.target.value); }} /><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{transferButtonText()}</Button><br/>
          </form>
          </>);
      })}

    {modal(openFooReq, () => setOpenFooReq(false), 'MORE FOO REQUIRED', function() {
        return (<><br/>
          <img src="/mm_logo512.png" width="100" height="100" /><br/><br/>
          <span className="modal_text">FOO trade features are currently in beta and limited to holders of at least <b>{(fooReq/Math.pow(10,18))} FOO</b>. Please acquire more FOO to be able to make Offers or Counter Offers!</span><br/><br/>
          <span className="modal_text"><a href="https://app.sushi.com/swap?outputCurrency=0xfbc3c04845162f067a0b6f8934383e63899c3524">Get FOO on Sushiswap</a></span><br/><br/>
          </>);
      })}

      {modal(openRename, () => setOpenRename(false), 'RENAME YOUR '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to give your Fantum a new name? Fantums can be renamed more than once, but the cost doubles each time.</span><br/>
          <form onSubmit={submitRename}>
          <br/>
          ENTER A NEW NAME:<TextField value={enteredRename} label="" onChange={(e) => { setEnteredRename(e.target.value); }} /><br/><br/>
          <Button type="Submit" size="lg" variant="primary">RENAME FOR {(priceOfNameChange/Math.pow(10, 18))} FTM</Button>
        </form>
          </>);
      })}

      {modal(openBuyNow, () => setOpenBuyNow(false), 'BUY '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to buy this Fantum for {fantum.price/Math.pow(10,18)} FTM?</span><br/>
          <form onSubmit={submitBuyFantum}>
          <br/>
           <span className="modal_text">Plus Taker Fee: {((fantum.price*vars.fees.taker/100)/Math.pow(10,18))} FTM ({vars.fees.taker}% TAKER FEE)</span><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{buyTextInModal()}</Button><br/>
        </form>
          </>);
      })}

      {modal(openApprove, () => setOpenApprove(false), 'SELL YOUR '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to sell this {subs.singularLowercaseNameOfFantums}? If so, Approve the {subs.properSiteName} exchange contract...</span><br/>
          <form onSubmit={submitApprove}>
          <br/>
          <Button type="Submit" size="lg" variant="primary">{approveText()}</Button>
        </form>
          </>);
      })}

      {modal(openSell, () => setOpenSell(false), 'SELL YOUR '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to sell this Fantum?</span><br/>
          <form onSubmit={submitSell}>
          <br/>
          ENTER SELL PRICE:<TextField value={enteredSellPrice} label="" onChange={(e) => { setOfferFee(e.target.value*vars.fees.offer/100); setMakerFee(e.target.value*vars.fees.maker/100); setEnteredSellPrice(e.target.value); }} /><br/><br/>
          <span className="modal_text">You'll receive {(enteredSellPrice-makerFee)} FTM if sold (MINUS {vars.fees.maker}% MAKER FEE)</span><br/>

          <span className="modal_text">Non-refundable Listing Fee: {offerFee} FTM ({vars.fees.offer}% OFFER FEE)</span><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{sellText()}</Button><br/><br/>
          
          
        </form>
          </>);
      })}

      {modal(openCounterOffer, () => setOpenCounterOffer(false), 'PLACE COUNTER OFFER', function() {
        return (<><br/>
          <span className="modal_text">Would you like to propose a Counter Offer?</span><br/>
          <form onSubmit={submitCounterOffer}>
          <br/>
          ENTER COUNTER OFFER PRICE:<TextField value={enteredCounterOfferPrice} label="" onChange={(e) => { setOfferFee(e.target.value*vars.fees.offer/100); setMakerFee(e.target.value*vars.fees.maker/100); setEnteredCounterOfferPrice(e.target.value); }} /><br/><br/>
          <span className="modal_text">Maker Fee: {makerFee} FTM ({vars.fees.maker}% MAKER FEE)</span><br/>
          <span className="modal_text">Non-refundable Listing Fee: {offerFee} FTM ({vars.fees.offer}% OFFER FEE)</span><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{counterOfferText()}</Button>
        </form>
          </>);
      })}

    </div>

  <div className={"token_page "+(parseInt(fantum.price)>0? 'token_page_opera_'+tokenSaleColor+'' : 'token_page_'+operaOrGraveyard())}>
    <div id={(parseInt(fantum.price)>0? 'token_opera_'+tokenSaleColor+'' : 'token_'+operaOrGraveyard())}>
      <div id="token_curtain">

            <TokenAboveText fantum={fantum} totalFantums={totalFantums} />



            <div className={"infoFantumContainerTint "+((fantum.dead)? '' : 'hidden')}>
              <a href={'/token/'+fantum.id}><IconTint color="#0054a6" maxWidth="280" maxHeight="280" src={!rarityDisplayMode ? fantum.image : (fantum.summoner ? fantum.image.replace('/img/', '/rm_img/') : fantum.image)} /></a>
            </div>
            <div className={"infoFantumContainer"}>
              <a href={'/token/'+fantum.id}><img width="280" height="280" src={!rarityDisplayMode ? fantum.image : (fantum.summoner ? fantum.image.replace('/img/', '/rm_img/') : fantum.image)} /></a>

              {/*<a href={'/token/'+fantum.id}><FantumGen cursor={true} hash={fantum.hash} size={rarityDisplayMode ? 320 : 320} rm={rarityDisplayMode ? (fantum.summoner ? 1 : 0) : 0} /></a>*/}
              
            </div>

            <span className={tokenIds > 0 ? "cs_6" : "hidden"}><b>#{fantum.id}</b> of <b>{totalFantums}</b><br/><span className="white_link">
            <a className={(EVM.connectedAccount && addysEqual(EVM.connectedAccount.toString(), vars.devAdd) ? '' : 'hidden')+' cursor_pointer'} onClick={()=>{window.open(subs.apis[subs.apiId].url+'regen/'+fantum.id, '_blank')}}>FIX</a><br/>
            <a className={((fantum.dead) ? '' : 'hidden')} href={"/duel/"+fantum.replay}>VIEW DEATH</a>
            </span></span>


            <br/>
            
            
            



        </div>
      </div>

      <div className={!subs.subsite ? '' : 'hidden'}>
      <div className={((rarityDisplayMode)? 'hidden' : '')+" token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')}>
      <div className={"level_bar "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+'border_'+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM()+'border')}>
          <div className="level_bar_level">Level <b>{Math.floor(fantum.level)}</b></div>
          <div  className={"level_bar_inner "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())} style={{width: levelProgress.toString()+'%'}}>
          
          </div>
        </div>

      </div>
      </div>

    
    <div className={"token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')}>
      <span className={"token_block_header "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><u>DETAILS</u></b></span>
      <div className={"token_block "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
      <div className="token_details_left">

      <span className={"block_details "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'hidden' : '')}>OWNER: <b><a href={'/search/'+fantum.owner}>{fantum.owner.substr(0, 6)+'...'+fantum.owner.substr(38, 42)}</a></b><br/></span>
        <div className={(fantum.seller!='0x0000000000000000000000000000000000000000'? '' : 'hidden')}><span className="block_details">SELLER: <b><a href={'/search/'+fantum.seller}>{fantum.seller.substr(0, 6)+'...'+fantum.seller.substr(38, 42)}</a></b></span><br/></div>
         <div className={((fantum.former_owner && fantum.dead)? '' : 'hidden')}><span className="block_details">LAST OWNER: <b><a href={'/search/'+fantum.former_owner}>{fantum.former_owner.substr(0, 6)+'..'+fantum.former_owner.substr(40, 42)}</a></b></span><br/></div>

        <span className={"block_details "+(parseInt(fantum.price)<=0? 'hidden' : '')}>PRICE: <b>{(Math.round(fantum.price/Math.pow(10,18)*1000)/1000)} FTM</b><br/></span>


        </div>
        <div className="token_details_right">

                <span className={(!subs.subsite || subs.subsite=='rarity'?'':'hidden')}><span className={"block_details "}>{rollName(fantum.hash.toString()).toUpperCase()} RARITY: </span><span className="block_details" className={"shadow_colored rarityColor_" + rollNum(fantum.hash)+"_roll"}><b>{rScore(fantum.hash.toString())}</b><br/></span></span>
                <span className={(!subs.subsite || subs.subsite=='rarity'?'hidden':'')}><span className={"block_details "}>RARITY: </span><span className="block_details" className={"shadow_colored rarityColor_" + rankNum(parseInt(fantum.popRare))+"_roll"}><b>{rankToName(parseInt(fantum.popRare)).toUpperCase()}</b><br/></span></span>

                {/*<span className={(parseInt(fantum.popRare)>0? '' : 'hidden')}><span className="block_details" >RANKED: </span><span className="block_details" className={"shadow_colored rarityColor_" + percentileNum(Math.round(100-parseInt(fantum.popRare)/totalFantums*100))+"_rank"}><b>{ordinal(parseInt(fantum.popRare)).toString().toUpperCase()}</b><br/></span></span>*/}
                <span className={(!subs.subsite? '' : 'hidden')}><span className="block_details" ></span><span className="block_details" className={"shadow_colored"}><b>{wltdk()}</b><br/></span></span>

               
        </div>

        <div className="clear"></div>
      </div>
    </div>




    <div className={(rarityDisplayMode && fantum.summoner? '' : 'hidden')}>
      <div className={"token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')}>
        <span className={"token_block_header "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><u>RARITY MANIFESTED</u></b></span>
        <div className={"token_block "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
          <div className="token_details_left">

            <span className={(fantum.summoner? '' : 'hidden')}><span className="block_details" >ID: </span><span className="block_details" className={"shadow_colored "}><b>{(fantum.summoner? fantum.summoner.id : '')}</b><br/></span></span>
            <span className={(fantum.summoner? '' : 'hidden')}><span className="block_details" >CLASS: </span><span className="block_details" className={"shadow_colored "}><b>{(fantum.summoner? fantum.summoner.className : '')}</b><br/></span></span>

            <span className={(fantum.summoner && !isNaN(fantum.summoner.claimableGold)? '' : 'hidden')}><span className="block_details" >GOLD: </span><span className="block_details" className={"shadow_colored "}><b>{(fantum.summoner ? Math.round(fantum.summoner.gold/Math.pow(10,18)*100)/100 : '')}</b><br/></span></span>
        
            <span className={(fantum.summoner? '' : 'hidden')}><span className="block_details" >{(fantum.summoner ? (fantum.summoner.className ? fantum.summoner.className.toUpperCase() : '') : '')} LEVEL: </span><span className="block_details" className={"shadow_colored "}><b>{(fantum.summoner ? fantum.summoner.level : '')}</b><br/></span></span>
                  
            <span className={(fantum.summoner? '' : 'hidden')}><span className="block_details" >{(fantum.summoner ? (fantum.summoner.className ? fantum.summoner.className.toUpperCase() : '') : '')} XP: </span><span className="block_details" className={"shadow_colored "}><b>{(fantum.summoner ? Math.round(fantum.summoner.xp/Math.pow(10,18)*100)/100 : '')}</b><br/></span></span>
            


          </div>
          <div className="token_details_right">
 
                <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >STRENGTH: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.strength : ''}</b><br/></span></span>
            <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >DEXTERITY: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.dexterity : ''}</b><br/></span></span>
            <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >CONSTITUTION: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.constitution : ''}</b><br/></span></span>
            
            <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >INTELLIGENCE: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.intelligence : ''}</b><br/></span></span>
            <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >WISDOM: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.wisdom : ''}</b><br/></span></span>
            <span className={(fantum.summoner && fantum.summoner.abilityScores? '' : 'hidden')}><span className="block_details" >CHARISMA: </span><span className="block_details" className={"shadow_colored "}><b>{fantum.summoner && fantum.summoner.abilityScores ? fantum.summoner.abilityScores.charisma : ''}</b><br/></span></span>
          </div>

          <div className="clear"></div>
        </div>
      </div>
      </div>
    
    <div className={!subs.subsite ? '' : ''}>
    <div className={"token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')}>
      <span  className={"token_block_header_left "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><u>ATTRIBUTES</u></b></span>
      <span  className={"token_block_header_right "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><span onClick={() => setPopRarToggle(true)} className={(popRarToggle ? 'selected_detail_link' : 'detail_link')}>RARITY %</span> <span onClick={() => setPopRarToggle(false)} className={(!popRarToggle ? 'selected_detail_link' : 'detail_link')}>POPULATION #</span></b></span>
      <div className="clear"></div>

      <div className={(!popRarToggle ? 'hidden' : '')}>
        <div className={"token_block "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
         <div className="token_details_left_more">
              {descLeft()}
              
        </div>
        <div className="token_details_right_less">
              {descRight()}
              
              {/*{totalOdds()}*/}
          </div>
          <div className="clear"></div>
          </div>
        </div>

        <div className={(popRarToggle ? 'hidden' : '')}>
          <div className={"token_block "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
        
         <div className="token_details_left_more">
         {descLeft()}
        </div>
        <div className="token_details_right_less">
        {descRight2()}
              
          </div>
{/*           <div className="token_details_full_right">
          Total Fantums {rankScoreLayersT[0]}
          </div>*/}

          <div className="clear"></div>
          </div>
        </div>


        
        
 
    </div>
    </div>

    



    <div className={"token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')+ ' '+((!fantum.summoner && !isSeller() && parseInt(fantum.price)<=0 && !((fantum.dead))) ? 'hidden' : '')+' '+((!EVM.connectedAccount) ? 'hidden' : '')}>
      <span className={"token_block_header "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><u><span className={" " + (isSeller() ? 'inline' : 'hidden')}>MY FANTUM</span> ACTIONS</u></b></span>
      <div className={"token_block actions "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>

              <div>

              <div className={"token_action_button "+(((fantum.dead) && isFormerOwner()) ? '' : 'hidden')}>
                <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => setOpenResurrect(true)} disabled={renaming}>RESURRECT</Button>
              </div>
              
              <div id="owner_buttons_not_for_sale" className={(isSeller()? '' : 'hidden')+' '+((parseInt(fantum.price)>0)? 'hidden' : '')}>
                
                <div className={"token_action_button "}>
                  <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => setOpenTransfer(true)}>TRANSFER</Button>
                </div>

                <div className={"token_action_button "+((subs.subsite=='gothums' || subs.subsite=='rarity' || ((fantum.dead) || !hasWeapon())) ? 'hidden' : '')}>
                  <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => duelWithFantum()}>DUEL</Button>
                </div>

                <div className={"token_action_button"}>
                  <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => setOpenRename(true)} disabled={renaming}>{renameButtonText()}</Button>
                </div>


                <div className={"token_action_button "+(!subs.subsite || subs.subsite=='rarity'?'':'')}>
                  <Button className={"wide_100"} size="" variant="primary" onClick={() => putUpForSale()} disabled={(selling || approving)}>SELL</Button>
                </div>

                <div className={rarityDisplayMode ? (fantum.summoner ? 'hidden' : '') : 'hidden'}>

                  <div className={"token_action_button "}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => setOpenRMClassChoose(true)}>CHOOSE YOUR CLASS</Button>
                  </div>

                </div>
                <div className={rarityDisplayMode && fantum.summoner ? '' : 'hidden'}>

                  <div className={"token_action_button "+(fantum.summoner && currentBlockTimestamp > fantum.summoner.log ? '' : 'hidden')}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => adventure()}>ADVENTURE (RECEIVE +250 XP/day)</Button>
                  </div>

                  <div className={"token_action_button "+(fantum.summoner && !isNaN(fantum.summoner.claimableGold) && (Math.round(fantum.summoner.claimableGold/Math.pow(10,18)*100)/100) > 0 ? '' : 'hidden')}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => { setOpenGoldClaim(true);  checkGoldClaimApproval(); } }>CLAIM {fantum.summoner && !isNaN(fantum.summoner.claimableGold) ? (Math.round(fantum.summoner.claimableGold/Math.pow(10,18)*100)/100) : 0} GOLD</Button>
                  </div>

                  

{/*                  <div className={"token_action_button "}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => spendXp()}>SPEND 50 XP</Button>
                  </div>*/}

                  <div className={"token_action_button "+(fantum.summoner && fantum.summoner.xp >= fantum.summoner.xpRequired? '' : 'hidden')}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => levelUp()}>LEVEL UP SUMMONER</Button>
                  </div>

                  <div className={"token_action_button "+(fantum.summoner && !fantum.summoner.abilityScores? '' : 'hidden')}>
                    <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => {  checkRraApproval(); setOpenRMPointSelect(true);  }}>ALLOCATE STAT POINTS</Button>
                  </div>



                </div>

              </div>

              <div id="owner_for_sale_buttons" className={(parseInt(fantum.price)>0? '' : 'hidden')+' '+((isSeller())? '' : 'hidden')}>

                <div className={"token_action_button"}>
                  <Button className={"wide_100"} size="" variant={buttonName()} onClick={cancelMyOffer} disabled={cancellingOffer}>{removeFromSaleText()}</Button>
                </div>
              </div>
              
              <div id="stranger_for_sale_buttons" className={(parseInt(fantum.price)>0? '' : 'hidden')+' '+(fantum.ownerMeetsFooReq? '' : 'hidden')}>
                <div className={"token_action_button "}>
                  <Button className={"wide_100 "+((isSeller())? 'hidden' : '')+' '+(isSeller()? 'hidden' : '')+' '+(parseInt(fantum.price)>0? '' : 'hidden')} size="" variant="primary" onClick={buyFantum}>{buyNowText()}</Button>
                </div>

                <div className={"token_action_button "+(hasConnectedAccountMadeCounterOffer() ? 'hidden' : '')}>
                  <Button className={"wide_100 "+((isSeller())? 'hidden' : '')+' '+(isSeller()? 'hidden' : '')} size="" variant={buttonName()} onClick={() => putUpCounterOffer()} disabled={counterOffering}>COUNTER OFFER</Button>
                </div>

              </div>

             {/*<div id="is_parent_buttons" className={rarityDisplayMode ? (fantum.summoner ? '' : 'hidden') : ((fantum.summoner && fantum.atDayCare) ? '' : 'hidden') }>
              <div className={"token_action_button "+(isParent() ? '' : 'hidden')}>
                <Button className={"wide_100"} size="" variant={buttonName()} onClick={() => { checkDayApproval(); setOpenRMDaycare(true); }}>{fantum.atDayCare? 'PICK UP FROM DAYCARE' : 'DROP OFF AT DAYCARE'}</Button>
              </div>
            </div>*/}


              

              <div className="clear"></div>
              </div>
        </div>
      </div>






      <div className={"token_block_background "+(parseInt(fantum.price)>0? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')+ ' '+((counterOffersForToken.length<=0) ? 'hidden' : '')}>
      <span className={"token_block_header "+(parseInt(fantum.price)>0? tokenSaleColor : operaOrOperaGM())}><b><u>COUNTER OFFERS</u></b></span>
      <div className={"token_block "+(parseInt(fantum.price)>0? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
      {counterOffersForToken.map((offerer, index) => (
                <div key={index} className={oddOrEven(index)}>
                  {offererAddress(offerer)} offered to purchase for {Math.round(offerer.bid/Math.pow(10,18)*100)/100} FTM
                  <div id="accept_counter_offer_link" className={" " + (isSeller() ? 'inline' : 'hidden')+' '+(fantum.ownerMeetsFooReq? '' : 'hidden')+' '+(offerer.fooReq? '' : 'hidden')}><a onClick={() => acceptCounterOffer(offerer.address)}>Accept This Counter Offer</a></div><div id="cancel_counter_offer_link" className={" " + (isOfferer(offerer.address) ? 'inline' : 'hidden')}><a  onClick={cancelCounterOffer}>Cancel Your Counter Offer</a></div>
                </div>

            ))}
        <div className="clear"></div>
      </div>
      </div>

      <div className={"token_block_background "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'_background' : 'token_block_'+operaOrOperaGM()+'background')}>
      <span className={"token_block_header "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? tokenSaleColor : operaOrOperaGM())}><b><u>HISTORY</u></b></span>
      <div className={"token_block "+((parseInt(fantum.price)>0 || fantum && (fantum.dead))? 'token_block_'+operaOrOperaGM()+''+tokenSaleColor+'' : 'token_block_'+operaOrOperaGM())}>
        {events.map((event, index) => (
            
              printEvent(event, index)
            

        ))}
        <div className="clear"></div>
      </div>
      </div>

      <div className="clear"></div>
      </div>

        </>






  );

} else {
  return (<><div id="rotatingSkelly" className='rotate'></div><br/><br/><br/><br/><br/>
              </>);
}

}

export default Token;