



const fooContractAddress = '0xfbc3c04845162f067a0b6f8934383e63899c3524';
const slpContractAddress = '0xc9a3ad55ef7b859e0c5fdb81e4f4b6a709d758fd';
const wftmContractAddress = '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83';
const comingSoonSiteMode = 0;

const devInfo = 0;

var duelAIAddress = '0x59FC47b8ED40353fDFa57a3447F139201cbC3B02';
var duelHousePercent = 9;
var duelBurnPercent = 1;



const devAdd = '0x00FBFCF036FE12012C849C3f7DD9Cb0AFB7D64C1';



const testingAddresses = [
  
  '0x00FBFCF036FE12012C849C3f7DD9Cb0AFB7D64C1',
  // '0x71e067942755aC4135569939bd51a4EBd5eDB346',
  '0x7E04033e1807c7507d804565cAbCdedb5Cf6a831',
  // '0x3ccdFc5E312b6D012AA0dB4CD3e79a55b2557484',
  // '0x2a06aECa18561D366A1b407BA792C8a3A9a70173',
  // '0xaeEDC2c68c5DD8F8446207c08160bC704fe10115',
  // '0x5d7618693d74874B48D1685ab777A2f45D1aF70A',
  '0xc4b426520EA2cACE98950d77801B416e886aFc1a'

];

const contractInfoURL = 'http://api.fantums.trade/contractInfo/';

const fees = {
  taker: 6,
  maker: 3,
  offer: 1
};


const tokenPageColors = {
  white: ['#FFFFFF', '#f2f2f2', '#ededed', '#000000', '#000000'],
  black: ['#000000', '#151515', '#202020', '#707070', '#FFFFFF'],
  opera: ['#000000', '#8c4728', '#7b3e23', '#703921', '#FFFFFF'],
  opera_gm: ['#000000', '#00101d', '#011627', '#FFFFFF', '#FFFFFF']
};

// MATCHES COMBO IDS FROM CONTRACT
const combos = [
  {id: 1, name: "Transmute Flame"},
  {id: 2, name: "Transmute Poison"},
  {id: 3, name: "Transmute Ice"},
  {id: 4, name: "Transmute Bloodstone"},
  {id: 5, name: "Transmute Crystal"},
  {id: 6, name: "Transmute Iron"},
  {id: 7, name: "Craft Death Potion"},
  {id: 8, name: "Perform Seppuku"},
  {id: 9, name: "Craft Chromatic Amulet"},
  {id: 10, name: "Craft Fiery Offense Potion"},
  {id: 11, name: "Craft Toxic Offense Potion"},
  {id: 12, name: "Craft Icy Offense Potion"},

  {id: 13, name: "Craft Bloody Defense Potion"},
  {id: 14, name: "Craft Mystic Defense Potion"},
  {id: 15, name: "Craft Steely Defense Potion"},

  {id: 16, name: "Procure a Lucky Rabbit's Foot"}

  // {id: 9, name: "Recipe of Fiery Offense"},
  // {id: 10, name: "Recipe of Toxic Offense"},
  // {id: 11, name: "Recipe of Frosty Offense"},
  // {id: 12, name: "Recipe of Crimson Defense"},
  // {id: 13, name: "Recipe of Elusive Defense"},
  // {id: 14, name: "Recipe of Iron Defense"},

  // {id: 9, name: "Recipe of Fiery Offense"},
  // {id: 10, name: "Recipe of Toxic Offense"},
  // {id: 11, name: "Recipe of Frosty Offense"},
  // {id: 12, name: "Recipe of Crimson Defense"},
  // {id: 13, name: "Recipe of Elusive Defense"},
  // {id: 14, name: "Recipe of Iron Defense"},

  // {id: 13, name: "Recipe of Seppuku"},
  // {id: 14, name: "Recipe of Everlasting Life I"},
  // {id: 15, name: "Recipe of Everlasting Life II"},
  //{id: 16, name: "Can Devs Do Something?"},
  // {id: 8, name: "Scroll of Ultimate Sacrifice"},
];

// MATCHES UP WITH STRUCT FROM CONTRACT
const itemVarieties = [
  'ERC20',
  'ERC721',
  'ERC1155'
];
const mintTypes = [
  'MINT_SELF',
  'SEND_ERC20',
  'SEND_ERC721',
  'SEND_ERC1155'
];

const rollLevels = [
  0.4,
  0.6,
  0.75
];

const percentileLevels = [
  60,
  85,
  90,
  97,
  99
];

const maxUint256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const rollNames = [
  'Common',
  'Uncommon',
  'Rare',
  'Epic'
];

const rollColors = [
  "#ffb400",
  "#ffa200",
  "#ff9000",
  "#ff7f00"
];

const rarityTextColors = [
  "#ffb400",
  "#0078ff",
  "#8a00ff",
  "#ff5a00"
];

const rarityTextColorsNew = [
  "#ffb400",
  "#0078ff",
  "#8223ff",
  "#ba1bd9",
  "#ff1da4",
  "#ff4621"
];





export {
  mintTypes,
  combos,
  itemVarieties,

  
  duelHousePercent,
  duelBurnPercent,
  devAdd,
  duelAIAddress,
  testingAddresses,
  maxUint256,
  fees,

  tokenPageColors,
  contractInfoURL,


  rollNames,
  rollLevels,
  devInfo,

  comingSoonSiteMode,
 
   rollColors,
   fooContractAddress,


   rarityTextColors,
   rarityTextColorsNew,
  percentileLevels,
  slpContractAddress,
  wftmContractAddress
}