import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import { numberWithCommas, tryCallUntilSuccessful } from './../helpers.js';
import {
  useParams
} from "react-router-dom";
import * as EVM from './../evm.js';


var md5 = require('md5');


var padding;
var bgColor='';
var lastConnectedAccount;

CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
  if (w < 2 * r) r = w / 2;
  if (h < 2 * r) r = h / 2;
  this.beginPath();
  this.moveTo(x+r, y);
  this.arcTo(x+w, y,   x+w, y+h, r);
  this.arcTo(x+w, y+h, x,   y+h, r);
  this.arcTo(x,   y+h, x,   y,   r);
  this.arcTo(x,   y,   x+w, y,   r);
  this.closePath();
  return this;
}




var sx;
var sy;

var addressToUse;

function IngredientIcon(props) {

	var [atu, setAtu] = useState(false);


	var [bgColor, setBgColor] = useState(props.bgColor);

	var [x, setX] = useState(0);
	var [y, setY] = useState(0);

	var [displayTooltip, setDisplayTooltip] = useState(false);

	var [ingredient, setIngredient] = useState({});

	var [render, setRender] = useState(0);




	document.onmousemove = handleMouseMove;
	function handleMouseMove(event) {

		


	    var eventDoc, doc, body;

	    event = event || window.event; // IE-ism

	    // If pageX/Y aren't available and clientX/Y are,
	    // calculate pageX/Y - logic taken from jQuery.
	    // (This is to support old IE)
	    if (event.pageX == null && event.clientX != null) {
	        eventDoc = (event.target && event.target.ownerDocument) || document;
	        doc = eventDoc.documentElement;
	        body = eventDoc.body;

	        event.pageX = event.clientX +
	          (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
	          (doc && doc.clientLeft || body && body.clientLeft || 0);
	        event.pageY = event.clientY +
	          (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
	          (doc && doc.clientTop  || body && body.clientTop  || 0 );
	    }

	    // Use event.pageX / event.pageY here


	    setX(event.pageX);
	    setY(event.pageY);
	    sx = event.pageX;
	    sy = event.pageY;


	}

	

	async function getIngredient() {

		

		if(!props.hasTooltip) {
			return;
		}

			if(!props.address) {
				return;
			}
			if(!props.tid) {
				return;
			}

      if(!EVM.fi_contract) {
          return;
      }

      //console.log('getIngredient?');

      var ingredient = EVM.ingredientForAddressAndId(props.address, props.tid);

      if(ingredient) {

      	//var itemInfo = EVM.ingredients[props.address+'-'+props.tid];

      	var newIngredient = {};
      	var balance;

      	if(ingredient.variety=='ERC20') {

      		var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, props.address);
      		var aBal = await tryCallUntilSuccessful(erc20_contract.methods.balanceOf(EVM.connectedAccount.toString()));
	      	//var aBal = await .call();

	      	balance = Math.round(EVM.web3.utils.fromWei(aBal.toString()));

      	} else if(ingredient.variety=='ERC721') {


      		var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, props.address);

	      	balance = await tryCallUntilSuccessful(erc721_contract.methods.balanceOf(EVM.connectedAccount.toString()));

	      	// balance = Math.round(EVM.web3.utils.fromWei(aBal.toString()));


      		
      	} else if(ingredient.variety=='ERC1155') {

      		let totalItems = await tryCallUntilSuccessful(EVM.fi_contract.methods.totalItems());


		      
		      for(var i=0;i<totalItems;i++) {

		      	var anItem = await tryCallUntilSuccessful(EVM.fi_contract.methods.items(i));
		      	//var anItem = await .call();


		      	//console.log('even in here?');

		      	if(anItem.id == props.tid && props.address == EVM.FI_ADD[0]) {
		      		// THIS IS OUR ITEM

		      		balance = await tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), anItem.id));
		      		//console.log('balanceOf ['+item.id+']: '+balanceOf);


		      	}

		      }
      		
      	}


      	newIngredient = ingredient;
      	ingredient.balance = balance;


      }

      
 
      //console.log('Get item? '+props.tid);
      setIngredient(newIngredient);
   

  }

	

  function renderImage() {

  	addressToUse = props.address;
  	setAtu(props.address);
  	//console.log('Setting atu to: '+props.address);
		if(props.address==EVM.FI_ADD[0]) {
			addressToUse = 'FIA';
			setAtu('FIA');
		}


		var canvas = document.getElementById('Canvas'+props.tkey);
	  var context = canvas.getContext("2d");
	  context.clearRect(0, 0, canvas.width, canvas.height);
	  



  	var img = new Image();
  	if(props.customImage) {
  		img.src = props.customImage;
  	} else {
  		if(props.address==EVM.FI_ADD[0]) {
  			img.src = "/img/items/FIA-"+props.tid+".png";
  		} else {
  			img.src = "/img/items/"+props.address+'-'+props.tid+".png";
  		}
  		
  	}
    //img.src = "/img/items/"+props.address+'-'+props.tid+".png";
    //console.log(props.hasTooltip+' img.src: '+"/img/items/"+props.address+'-'+props.tid+".png");
    img.onload = function (e) {



    	padding = (0 / 80) * props.size;
	    context.imageSmoothingEnabled = false;
	    context.drawImage(img, padding, padding, props.size-padding*2, props.size-padding*2);

	    if(props.balance) {
	    	var afs = props.fontSize;
	    	if(!afs) {
	    		afs = 14;
	    	}
	    	var fs = Math.floor(afs * props.size / 64);
	    	if(fs <= 1) {
	    		fs = 1;
	    	}
	    	if(fs >= 30) {
	    		fs = 30;
	    	}
	    	context.font = fs+"px 'VCR', 'VT323', monospace";
	    	context.textAlign = "end";
	    	context.fillStyle = '#000';

	    	if(props.pos=='tr') {
	    		context.fillText(props.balance, props.size+1, fs*0.9+1);
					context.fillText(props.balance, props.size+2, fs*0.9+2);
			    context.fillStyle = '#FFF';
					context.fillText(props.balance, props.size, fs*0.9);
	    	} else if(props.pos=='tl') {
	    		context.fillText(props.balance, fs*1.15+1, fs*0.9+1);
					context.fillText(props.balance, fs*1.15+2, fs*0.9+2);
			    context.fillStyle = '#FFF';
					context.fillText(props.balance, fs*1.15, fs*0.9);
	    	} else if(props.pos=='bl') {
	    		context.fillText(props.balance, fs*1.15+1, props.size-3);
					context.fillText(props.balance, fs*1.15+2, props.size-2);
			    context.fillStyle = '#FFF';
					context.fillText(props.balance, fs*1.15, props.size-fs*0.05);
	    	} else {
	    		context.fillText(props.balance, props.size+1, props.size-3);
					context.fillText(props.balance, props.size+2, props.size-2);
			    context.fillStyle = '#FFF';
					context.fillText(props.balance, props.size, props.size-fs*0.05);
	    	}

				
	    }
	    
	    // getIngredient();
	    // setRender(render+1); setRender(render+1);

    }


    //setRender(render+1); setRender(render+1);


  }


	useEffect(() => {
		

		renderImage();

 	}, []);


	if(!ingredient) {
		return (<></>);
	}

	

	
	return (<><div 
			onMouseOver={()=>{ if(props.hasTooltip) { setBgColor(props.bgColorOver); setDisplayTooltip(true); getIngredient(); } }} 
			onMouseOut={()=>{ if(props.hasTooltip) { setBgColor(props.bgColor); setDisplayTooltip(false); } }} 
			onClick={props.onClick} 
			className={"IngredientIcon_Container"+props.tkey} 
			style={{backgroundColor: bgColor, width: props.size+'px', height: props.size+'px', display: 'inline-block', cursor: props.hasTooltip?'pointer':'auto'}}
			>
				<canvas style={{imageRendering: 'pixelated', zIndex: '990'}} id={'Canvas'+props.tkey} width={props.size} height={props.size}></canvas>
				</div>
				<div id={"IngredientIcon_Tooltip"+props.tkey} style={{display: displayTooltip?'inline-block':'none', position: 'absolute', left: 10+sx+'px', top: 10+sy+props.shiftY+'px', zIndex: 99, color: '#FFF', backgroundColor: '#322522', padding: '20px', width: '300px', lineHeight: '1.5em'}}>
					<h5><b>{(props.customName?props.customName:ingredient.name)}</b></h5>
					<div className="item_box" style={{backgroundImage: (props.customImage?"url('"+props.customImage+"')":"url('/img/items/"+atu+'-'+props.tid+".png')")}}></div>

					<p>{ingredient.description}</p><br/>
					
					<p>My Balance:</p>
					<p><b>{ingredient.balance}</b></p>
				</div>
				</>
		);

	

}

export default IngredientIcon;