import React from 'react';
import ReactDOM from 'react-dom';

import './fonts/arcadepi.ttf'; 
import './fonts/pixeboy.woff'; 
import './fonts/pixeboy.ttf'; 
import './img/logo.png'; 

import './img/opera_sliver.png'; 
import './img/opera_white_sliver.png'; 
import './img/opera_black_sliver.png'; 

import './img/cs_sliver.png'; 
import './img/coming_soon2.png'; 
import './img/browse.png'; 
import './img/rotating_skelly.png';
import './img/dirt_skellys.png';
import './img/logoMobile.png'; 
import './img/mm_logo512.png'; 
import './img/dungeon_brick_landing.png'; 
import './img/black_landing.png'; 

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import './range_slider.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
