import React, {useState, useEffect} from 'react';
import FantumGen from './FantumGen';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";
import { rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'

function SnapBurned(props) {

    const [fooBurned, setFooBurned] = useState(0);

   

    useEffect(() => {

        getFooBurned();
        
    }, []);


    function numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }

    async function getFooBurned() {
        const burnedObj = await getObjFromJSONUrl(
            subs.apis[subs.apiId].url+'fooBurned'
        );
        setFooBurned(burnedObj[0]);

    }
    
    
    return (
        <>
        <br/><br/>
        <div id="fooBurnedCounter">
        <b>{numberWithCommas(Math.round(fooBurned/Math.pow(10,18)))}<div className="fooBurnedText">FOO RESTS HERE</div></b>
        </div>

        </>
    );

 


}
  
export default SnapBurned;