import React, {useState, useEffect} from 'react';
import AttributeBlock from './AttributeBlock';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { getObjFromJSONUrl } from './../helpers.js'

var receivedContent = 0;
var lastConnectedAccount=false;

var attObj = {};


var loadOnlyOnce = false;

var countForKeys = [];
var countMarketForKeys = [];

var loadedTimes = 0;

var theTotalFantums = 0;

var gettingTotal = false;

function Guide() {

	var [attributes, setAttributes] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	// const [countForKeys, setCountForKeys] = useState([]);
	// const [countMarketForKeys, setCountMarketForKeys] = useState([]);


	useEffect(() => {

		setInterval(function() {

			if(EVM.connectedAccount != lastConnectedAccount) {
				receivedContent=0;
				loadOnlyOnce=false;
				load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(receivedContent==0) {
				load();
			}

		}, 200);

	}, []);

	async function load() {

		if(loadOnlyOnce) {
			return;
		}


		var totalFantumsURL = subs.apis[subs.apiId].url+'totalFantums';
		if(!theTotalFantums && !gettingTotal) {
		gettingTotal=true;
		  const response1 = await fetch(totalFantumsURL);
		  const totalObj = await response1.json();
		  theTotalFantums = totalObj[0];
		  console.log('theTotalFantums: '+theTotalFantums);
		}

		// if(window.ethereum && !EVM.connectedAccount) {
		// 	return;
		// }

		loadedTimes++;

		console.log('loadedTimes: '+loadedTimes);

		loadOnlyOnce = true;

		const attObj = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'attributeCounts'
		);


		if(attObj) {

			//var j=0;
			for(var j=0;j<attObj.length;j++) {
			//for(var anObj in attObj) {

				var anObj = attObj[j];

				//console.log('anObj');
				//console.log(anObj);

				var fkString='';
				for(var somekey in anObj) {
					//console.log(j+' '+nameForLayerDataIndex(j)+' somekey: '+somekey);
					fkString=fkString+nameForLayerDataIndex(j)+'_'+somekey+',';
				}

				//const newCountForKeys = countForKeys;
				const resKey = await getObjFromJSONUrl(
					subs.apis[subs.apiId].url+'attributeCount/'+EVM.connectedAccount+'&'+fkString
				);
				countForKeys[j] = resKey;
				//setCountForKeys(newCountForKeys[j]);
				//console.log(newCountForKeys[j]);
				//console.log(j+' '+countForKeys.length+' '+fkString);
				
				
				//var newCountMarketForKeys = countMarketForKeys;
				const resKeyM = await getObjFromJSONUrl(
					subs.apis[subs.apiId].url+'attributeCount/market&'+fkString
				);
				countMarketForKeys[j] = resKeyM;
				//setCountMarketForKeys(newCountMarketForKeys[j]);
				//j++;
			}
			
			
				
			







			receivedContent=1;




			//attObj = attObj1;
			setAttributes(attObj);
			//console.log(attObj);
			//console.log(attributes);
			setIsLoading(false);
		}



		
	}

	Object.size = function(obj) {
	  var size = 0,
	    key;
	  for (key in obj) {
	    if (obj.hasOwnProperty(key)) size++;
	  }
	  return size;
	};

	function prettyNameForLayerDataIndex(index) {
		if(index<14) {
			return subs.layerDataNames[index];
		}
		if(index==14) {
			return 'Hair Color';
		}
	}

	function nameForLayerDataIndex(index) {
		var i=0;
		for(var layerName in subs.layerData) {
			if(index==i) {
				return layerName;
			}
			i++;
		}
		return '';
	}

	function getOdds(cname, key) {


		var dudesPerLayer=0;
		var chicksPerLayer=0;

		var renderOutfit = key;
		var fileName = cname+"_"+renderOutfit;

		if(fileName==cname+'_0') {
			fileName='0';
		}

		var outfit;

		var posOfOutfit = fileName.indexOf("_");
		var posOfNext = fileName.indexOf("_", posOfOutfit+1);
		if(posOfNext==-1) {
			posOfNext=fileName.length;
		}

		outfit = fileName.substr(posOfOutfit+1, posOfNext-(posOfOutfit+1));

		var gender = fileName.substr(posOfNext+1, 1);

		


		if(gender!='') {
			if(gender==0) {
				chicksPerLayer++;
			} else {
				dudesPerLayer++;
			}
		} else {
			dudesPerLayer++;
			chicksPerLayer++;
		}


		return [dudesPerLayer, chicksPerLayer];

	}


	function totalOptions() {

		var dudesMult=1;
		var chicksMult=1;

		for(var a=0;a<attributes.length;a++) {
			var dlt=0;
			var clt=0;

			var layerName=nameForLayerDataIndex(a);

			for(var key in attributes[a]) {
				var odds=getOdds(layerName, key);
				dlt=dlt+odds[0];
				clt=clt+odds[1];
				//console.log('processing '+layerName+' '+key);
			}

			// console.log('total dudes for '+layerName+': '+dlt);
			// console.log('total chicks for '+layerName+': '+clt);

			dudesMult=dudesMult*dlt;
			chicksMult=chicksMult*clt;

		}
		
		if(!subs.subsite) {
			return ( <>Explore the attributes which make up the {numberWithCommas(dudesMult)} possible male Fantums, and {numberWithCommas(chicksMult)} possible female Fantums!<br/><br/></> );
		} else {
			return ( <>Explore the attributes which make up the Gothums Collection!<br/><br/></> );
		}
		

	}

	function numberWithCommas(x) {
	    x = x.toString();
	    var pattern = /(-?\d+)(\d{3})/;
	    while (pattern.test(x))
	        x = x.replace(pattern, "$1,$2");
	    return x;
	}


// if(!EVM.isTester && subs.subsite=='gothums') {
//         return (<></>);
//     }

return ( 
<>
       <div className="white_page">
       		<div className="doc_container">
				<h3><b>Collector's Guide</b></h3><br/>

				<div className={isLoading ? '' : 'hidden'}>
					<div id="rotatingSkelly" className={!isLoading ? '' : 'rotate'}></div>


					<br/><br/><br/><br/>
				</div>
				<div className={!isLoading ? '' : 'hidden'}>
					<p>{!isLoading ? totalOptions() : ''}</p>
				</div>

				{attributes.map((obj, index) => (
					<AttributeBlock ttf={theTotalFantums} cfk={countForKeys[index]} cmfk={countMarketForKeys[index]} index={index} key={index} obj={obj} name={prettyNameForLayerDataIndex(index)} cname={nameForLayerDataIndex(index)} />
				))}

			</div>
			
		</div>

        </>


);

}

export default Guide;