import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';

import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { addysEqual, rankToName, rankNum, rollName, rollNum, rScore, sleep, descForFilename,  fileKeyForLayerUsingHash, oddsForLayerUsingHash, nameWithNonBreakingSpaces, callMethod, percentileNum, modal, getObjFromJSONUrl } from './../helpers.js'
import { rScoreGivenHash, arrayOfHashCharacters, outfitForLayerName } from './../generate.js'

var ordinal = require('ordinal');

function TokenAboveText(props) {


	if(!subs.subsite || subs.subsite=='rarity') {

		return (<>

			<span className={"fantum_name_info rarityColor_" + rollNum(props.fantum.hash)+"_roll"}>{nameWithNonBreakingSpaces(props.fantum.name)}</span>

            <span className={"fantum_info_two rarityColor_" + rollNum(props.fantum.hash)+"_roll"}><b>{rollName(props.fantum.hash.toString()).toUpperCase()} ({rScore(props.fantum.hash.toString())})<span className={(parseInt(props.fantum.popRare)>0? '' : 'hidden')}>,</span> <span className={"rarityColor_" + percentileNum(Math.round(100-parseInt(props.fantum.popRare)/props.totalFantums*100))+"_rank "+(parseInt(props.fantum.popRare)>0? '' : 'hidden')}>RANK {parseInt(props.fantum.popRare)}</span></b></span><br/><br/>
			      
			</>);

	} else {

		return (<>

			<span className={"fantum_name_info rarityColor_" + rankNum(parseInt(props.fantum.popRare))+"_roll"}>{nameWithNonBreakingSpaces(props.fantum.name)}</span>

            <span className={"fantum_info_two rarityColor_" + rankNum(parseInt(props.fantum.popRare))+"_roll"}><b>{rankToName(parseInt(props.fantum.popRare)).toUpperCase()}<span className={(parseInt(props.fantum.popRare)>0? '' : 'hidden')}></span> <span className={"rarityColor_" + rankNum(parseInt(props.fantum.popRare))+"_roll "+(parseInt(props.fantum.popRare)>0? '' : 'hidden')}>({parseInt(props.fantum.popRare)})</span></b></span><br/><br/>
			      
			</>);

	}
	

	


	

}

export default TokenAboveText;