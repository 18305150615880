import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import BuffBar from './BuffBar';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import {
  useParams
} from "react-router-dom";
import { genderFromHashArr, outfitForLayerName, arrayOfHashCharacters, layerByteVal } from './../generate.js';
import { addysEqual, rollNum, modal, getObjFromJSONUrl, callMethod, callMethodWithNonce, sleep } from './../helpers.js'
import { ReactSession } from 'react-client-session';

import SelectFighterFantum from './SelectFighterFantum';
import { motion } from "framer-motion";

import IconTint from 'react-icon-tint';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

var gettingPossibleFighters = false;

var receivedContent = false;


var chosenFighter = 0;

var tokenIsFighting = false;

var isApprovedFum = false;
var isApprovedFoo = false;

var aFighterFromGet = false;

var dots='...';

var checkedApprovalsYet = false;

var lastMatchId;

var checkingForEndOfDuel = false;


var gameState;

var lastSuggestedNonce = false;


var matchIdFromURL = false;

var duelledAtBlock = false;

var mainInterval=false;
var secondaryInterval=false;

var slfOnce=false;

var needsAnimateAfterImagesLoad=false;


var leftLoaded;
var rightLoaded;

var duelPrice=150000000000000000000;
var gotDuelPrice=false;

var fightersInQueue;


var clearCheckingForEnd=0;

var lastConnectedAccount=false;

var skipFighterOwnerCheckOn = false;

//var levelProgress  = 0;

var animateLevelChangeInterval=false;
var animateLevelChangeCounter=false;
var animateLevelChangeStep=false;
var changeInOurLevel=false;


var someLeftFighter=false;
var someRightFighter=false;

var loadedUrls = [];

var removeWeapon = false;


var currentFightStatement=0;
var lastTimeFightStatementChanged;

var spells = ['chains32m_once', 'fire32m_once', 'fireball32m_once', 'fireball32mr_once', 'slice32m_once', 'smoke32m_once'];

function Duel(props) {


	let { num } = useParams();
	if(num) {
		matchIdFromURL = num;
	}



	document.title = "Fantums: Duel";

	//const [connectedAccount, setConnectedAccount] = useState(undefined);



	const [levelProgress, setLevelProgress] = useState(0.0);
	const [isDeterminingState, setIsDeterminingState] = useState(true);

	const [leftFighter, setLeftFighter] = useState(false);
	const [rightFighter, setRightFighter] = useState(false);

	var [possibleFighters, setPossibleFighters] = useState([]);

	const [openChoose, setOpenChoose] = useState(false);
	const [isLoadingChoices, setIsLoadingChoices] = useState(false);
	const [isLoadingLeftFighter, setIsLoadingLeftFighter] = useState(false);

	// BUTTONS //
	const [changeDuellerDisabled, setChangeDuellerDisabled] = useState(false);
	const [approveFumDisabled, setApproveFumDisabled] = useState(true);
	const [approveFooDisabled, setApproveFooDisabled] = useState(true);
	const [fightDisabled, setFightDisabled] = useState(true);

	const [selectStyle, setSelectStyle] = useState('primary');
	const [approveFumStyle, setApproveFumStyle] = useState('outline-dark');
	const [approveFooStyle, setApproveFooStyle] = useState('outline-dark');
	const [fightStyle, setFightStyle] = useState('outline-dark');

	const [isApprovingFum, setIsApprovingFum] = useState(false);
	const [isApprovingFoo, setIsApprovingFoo] = useState(false);
	const [isCallingDuel, setIsCallingDuel] = useState(false);

	const [queuedFighters, setQueuedFighters] = useState([]);


	const [renderedGameState, setRenderedGameState] = useState(false);

	// const [leftLoaded, setLeftLoaded] = useState(false);
	// const [rightLoaded, setRightLoaded] = useState(false);


	const [render, setRender] = useState(0);

	var [isFighting, setIsFighting] = useState(false);


	var [lastMatchInfo, setLastMatchInfo] = useState([]);

	//const [gameState, setGameState] = useState(-1);

	var [animateToChangeInOurLevel, setAnimateToChangeInOurLevel] = useState(0.0);

	// var isTombstone;
	var levelNow;
	var levelBeforeLast;

	var maxLoadTimeWait=40;

	var retrievedExistingState=false;

	const [averageWait, setAverageWait] = useState(false);

	async function getAverageWaitTime() {

		return;

		const matchWait = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'matchWait'
		);

		var avgWait = matchWait;
		//console.log('Average wait time: '+avgWait);

		setAverageWait(avgWait/60);
		

	}

	async function getUsersFighters() {

		if(!EVM.connectedAccount) {
			return;
		}
		if(gettingPossibleFighters) {
			return;
		}

		///tokens/page=:page&num=:num&orderBy=:ob&order=:o&addr=:address&forSale=:fs&duel=:dl&q=
		gettingPossibleFighters = true;
		const tokensObj = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'tokens/page=1&num=1000&orderBy=level&order=DESC&addr='+EVM.connectedAccount.toString()+'&forSale=&duel=1&q='
		);
		//console.log(tokensObj[0]);
		var tokens = [];
  	for(var i=0;i<tokensObj[0].length;i++) {
  		var tokenObj = tokensObj[0][i];
  		var tokenURI = subs.apis[subs.apiId].url+'token/'+tokenObj.id;

  		var newTokens = [];
  		//if(tokenObj.price <= 0) {
			newTokens = tokens.concat({
	      id: tokenObj.id, 
	      hash: tokenObj.hash, 
	      owner: tokenObj.owner,
	      name: tokenObj.name,
	      tokenURI: tokenURI,
	      image: tokenObj.image,
	      image32: tokenObj.image32,
	      rarity: tokenObj.rarity,
	      price: tokenObj.price,
	      level: tokenObj.level,
	      totalFantums: parseInt(tokenObj.totalFantums)
	    }); 
  		//}
  		
	    tokens=newTokens;
  	}
    setPossibleFighters(tokens);

    gettingPossibleFighters = false;

    receivedContent = true;


    setIsLoadingChoices(false);

    //console.log(tokens);
	}


	async function getFighter(tokenId) {

		if(tokenId <= 0 || !tokenId) {
			return;
		}

		const response = await fetch(subs.apis[subs.apiId].url+'token/'+tokenId);
    var fighter = await response.json();

    fighter.imageRed = fighter.image32.replace('img32', 'imgRed');
    fighter.imageGreen = fighter.image32.replace('img32', 'imgGreen');
    fighter.imageBlue = fighter.image32.replace('img32', 'imgBlue');
    //console.log(fighter);

		



    return fighter;
	}



	async function getExistingState() {

		if(retrievedExistingState) {
			return;
		}

		retrievedExistingState = true;


		console.log('Get Existing State...');

		ReactSession.setStoreType("localStorage");

		setGameState(-1);

		if(matchIdFromURL) {
			// WE ARE VIEWING A MATCH, LETS FILL THE NECESSARY VARIABLES...


			const matchInfo = await getObjFromJSONUrl(
				subs.apis[subs.apiId].url+'match/'+matchIdFromURL
			);

			var lFighter = await getFighter(matchInfo.lfId);
			var rFighter = await getFighter(matchInfo.rfId);
			

			// ReactSession.set("duelling_fight_over_leftId", matchInfo.lfId);
			// ReactSession.set("duelling_fight_over_rightId", matchInfo.rfId);
			// ReactSession.set("duelling_token_is_fighting", matchInfo.lfId);

			var lfId = matchInfo.lfId;
			var rfId = matchInfo.rfId;


			chosenFighter = lFighter.id;
			aFighterFromGet = lFighter;
			await choseFighter(chosenFighter);

			setIsFighting(chosenFighter);
	    tokenIsFighting = chosenFighter;

			await checkForEndOfDuel();

		} else if(ReactSession.get("duelling_fight_over_leftId") && ReactSession.get("duelling_fight_over_rightId") && ReactSession.get("duelling_token_is_fighting")) {

			
			setGameState(1);


			console.log('We might have the end screen...');

			var lfId = ReactSession.get("duelling_fight_over_leftId");
			var rfId = ReactSession.get("duelling_fight_over_rightId");

			var aFighter = await getFighter(ReactSession.get("duelling_token_is_fighting"));
			if(!aFighter) {
				ReactSession.set("duelling_token_is_fighting", false);
				return;
			}
			chosenFighter = aFighter.id;
			aFighterFromGet = aFighter;
			await choseFighter(chosenFighter);

			setIsFighting(chosenFighter);
	    tokenIsFighting = chosenFighter;

			await checkForEndOfDuel();

		} else if(ReactSession.get("dueling_with_token")) {

			setGameState(1);

			setLeftFighter(false);
			setIsLoadingLeftFighter(true);

			var aFighter = await getFighter(ReactSession.get("dueling_with_token"));
			chosenFighter = aFighter.id;
			aFighterFromGet = aFighter;

			setIsLoadingLeftFighter(false);


			choseFighter(chosenFighter);

			console.log('Fighter has successfully joined fight queue ('+aFighter.id+')...');
	    setIsFighting(chosenFighter);
	    tokenIsFighting = chosenFighter;

		} else if(ReactSession.get("duelling_chose_fighter")) {

			

			setLeftFighter(false);
			setIsLoadingLeftFighter(true);

			var aFighter = await getFighter(ReactSession.get("duelling_chose_fighter"));
			chosenFighter = aFighter.id;
			aFighterFromGet = aFighter;

			console.log('aFighter? '+aFighter.id);
			// console.log(aFighter);
			setIsLoadingLeftFighter(false);

			setOffensiveSpell(weaponForFighter(choseFighter)+'_forever.gif');

			await choseFighter(chosenFighter);

		}



		setIsDeterminingState(false);
		

	}

	async function isFUMdead(tokenId) {


		// MAKE SURE THIS FUM IS ALIVE
		let ownerOf = await EVM.fum_contract.methods.ownerOf(tokenId).call();

		console.log('ownerOf: '+tokenId);
		console.log(ownerOf.toString().toLowerCase());
		console.log(EVM.connectedAccount[0].toString().toLowerCase());

		if(!matchIdFromURL && (gameState==0 || gameState==-1) && !checkingForEndOfDuel && ownerOf.toString().toLowerCase()!=EVM.connectedAccount[0].toString().toLowerCase() && ownerOf.toString().toLowerCase()!=EVM.DUEL_ADD[0].toString().toLowerCase()) {
			ReactSession.set("duelling_chose_fighter", false);
			console.log('This FUM is dead, cannot select.');

			//resetFightChangeFighter();

			return true;
		}
		return false;
	}

	async function choseFighter(tokenId) {




		console.log('choseFighter('+tokenId+')');

		if(!EVM.fum_contract) {
			return;
		}

		if(!tokenId) {
			return;
		}


		// let ownerOf = await EVM.fum_contract.methods.ownerOf(tokenId).call();
		// if(ownerOf=='0x0000000000000000000000000000000000000000') {
		// 	ownerOf=false;
		// }

		// if(ownerOf && EVM.connectedAccount &&  EVM.connectedAccount.toString().toLowerCase() != ownerOf.toString().toLowerCase()) {
		// 	resetFightChangeFighter();
		// 	alert('You are not the ownerOf this dueller!');
		// 	return;

		// }

		//setGameState(0);
    	

    	if(isFUMdead(tokenId)) {
    		resetFightChangeFighter();
    	}


    	ReactSession.setStoreType("localStorage");

    	ReactSession.set("duelling_chose_fighter", tokenId);

 			

    	//console.log('choseFighter: '+tokenId);
    	setOpenChoose(false);
    	chosenFighter = tokenId;

    	setLeftFighter(false);
		  setIsLoadingLeftFighter(true);

		  var loadedSomething=false;


		  var fighterObj=false;

		  for(var i=0;i<possibleFighters.length;i++) {
		  	if(possibleFighters[i].id==tokenId) {
		  		setLeftFighter(possibleFighters[i]);
		  		fighterObj=possibleFighters[i];
		  		
		  		setIsLoadingLeftFighter(false);
		  		loadedSomething=true;
		  	}
		  }

		  if(aFighterFromGet) {
		  	console.log('aFighterFromGet? '+aFighterFromGet.id);

		  	console.log(aFighterFromGet);
		  	setLeftFighter(aFighterFromGet);
		  	fighterObj=aFighterFromGet;
		  	
		  	setIsLoadingLeftFighter(false);
		  	loadedSomething=true;
		  	aFighterFromGet=false;

		  	
		  }

		  if(slfOnce) {
		  	slfOnce=false;
		  	if(leftFighter.id==tokenId) {
		  		setLeftFighter(leftFighter);
		  		fighterObj=leftFighter;
		  		
		  		setIsLoadingLeftFighter(false);
		  	} else if(rightFighter.id==tokenId) {
		  		setLeftFighter(rightFighter);
		  		fighterObj=rightFighter;
		  		
		  		setIsLoadingLeftFighter(false);

		  	}
		  	loadedSomething=true;
		  	
		  }

		  setGameState(0);

		  if(!loadedSomething) {
		  	setIsLoadingLeftFighter(false);

		  } else {
		  	console.log('Our Fighter: ');
			  console.log(leftFighter);

			  setOffensiveSpell(weaponForFighter(fighterObj)+'_forever.gif');


			  //setOffensiveSpell('/img/gif/sword_forever.gif');

			  // if(EVM.connectedAccount && leftFighter.owner.toString().toLowerCase() != EVM.connectedAccount.toString().toLowerCase()) {
			  // 	resetFightChangeFighter();
			  // 	alert('You do not own this fighter!');
			  // 	return;
			  // }


			  //setGameState(0);
			  

			  await checkApprovals();
		  }
		  //
		  

		  getFightFee();


			
		}

async function getFightersInTheQueue() {

  if(!EVM.duel_contract) {
    return;
  }

  let aFiq =  await EVM.duel_contract.methods.getFightersInQueue().call();
  console.log('fightersInQueue: '+aFiq.length);

  fightersInQueue = aFiq.length;


  if(aFiq.length > 0) {

  	var qedFighters = [];
  	for(var i=0;i<aFiq.length;i++) {
  		var aFIQ = aFiq[i];

  		let fiqName =  await EVM.fum_contract.methods.nameOf(aFIQ).call();
  		qedFighters.push([aFIQ, fiqName]);
  		if(i > 10) {
  			break;
  		}
  	}
  	
  	setQueuedFighters(qedFighters);

  }

  

 }


 async function advanceDots() {
 	if(dots=='...') {
			dots='';
		} else if(dots=='') {
			dots='.';
		} else if(dots=='.') {
			dots='..';
		} else if(dots=='..') {
			dots='...';
		}
		setRender(dots);
		//console.log(dots);
 }


 async function preloadImageCallback(src, callback) {
 		//console.log('Loading: '+src);
 		const img = new Image();
	  img.onload = () => { loadedUrls[src]=true; callback(src); }
	  img.src = src;
 }

	async function preloadAllSpells() {
		
		for(var i=0;i<spells.length;i++) {
			await preloadImageCallback('/img/gif/'+spells[i]+'.gif', function(src) {  });
		}
	}

	async function areAllSpellsLoaded() {
		for(var i=0;i<spells.length;i++) {
			if(!loadedUrls['/img/gif/'+spells[i]+'.gif']) {
				return false;
			}
		}
		return true;
	}


    useEffect(() => {


    	preloadAllSpells();

    	//setGameState(-1);

    	getExistingState();

    	
    	getAverageWaitTime();


			// getExistingDuel();
			// getExistingChosenFighter();

    	
			clearInterval(mainInterval);
    	mainInterval = setInterval(async function() {


    		if(!checkedApprovalsYet) {

    			await checkApprovals();

    		}



    		
    		if(clearCheckingForEnd >= 5) {
    			checkingForEndOfDuel=false;
    			clearCheckingForEnd=0;
    			//console.log('Clearing checks...');
    		} else {
    			clearCheckingForEnd++;
    		}
    		//console.log('checking stuff...');
    		await getFightersInTheQueue();

    		await checkForEndOfDuel();

    		if(!isApprovedFum || !isApprovedFoo) {
    			await checkApprovals();
    		}
    		
    		
			}, 2000);

    	clearInterval(secondaryInterval);
    	secondaryInterval = setInterval(async function() {

    		if(EVM.connectedAccount != lastConnectedAccount) {
					getUsersFighters();
					lastConnectedAccount = EVM.connectedAccount;
				}


				// if(!receivedContent && EVM.connectedAccount) {
				// 	getUsersFighters();
	   //  	}

	    	if(needsAnimateAfterImagesLoad) {
	    		//console.log('images loaded yet?');
	    		//console.log('leftFighter: '+leftFighter);
	    		//console.log(leftFighter);

	    		if((leftLoaded && rightLoaded && areAllSpellsLoaded()) || maxLoadTimeWait > 10*2) {
	    			console.log(needsAnimateAfterImagesLoad);
	    			setGameState(2, needsAnimateAfterImagesLoad);
	    			needsAnimateAfterImagesLoad=false;
	    		} else {
	    			maxLoadTimeWait++;
	    		}
	    	}

	    	if(!gotDuelPrice) {
	    		getFightFee();
	    	}


			}, 500);

			// setInterval(async function() {


			// 	await getLevelChange(leftFighter.id, rightFighter.id);

			// }, 15000);

    	
    }, []);


    async function preloadFighterImages(lf, rf) {

    	console.log('preloading fight images?');

    	const img = new Image();
		  img.onload = () => { console.log('image32 left loaded!'); loadedUrls[lf.image32]=true; setLeftLoadedIfPossible(); }
		  img.src = lf.image32;
		  const imgR = new Image();
		  imgR.onload = () => { loadedUrls[lf.imageRed]=true; setLeftLoadedIfPossible(); }
		  imgR.src = lf.imageRed;
		  const imgB = new Image();
		  imgB.onload = () => { loadedUrls[lf.imageBlue]=true; setLeftLoadedIfPossible(); }
		  imgB.src = lf.imageBlue;
		  const imgG = new Image();
		  imgG.onload = () => { loadedUrls[lf.imageGreen]=true; setLeftLoadedIfPossible(); }
		  imgG.src = lf.imageGreen;


		  const img2 = new Image();
		  img2.onload = () => { loadedUrls[rf.image32]=true; setRightLoadedIfPossible(); }
		  img2.src = rf.image32;
		  const img2R = new Image();
		  img2R.onload = () => { loadedUrls[rf.imageRed]=true; setRightLoadedIfPossible(); }
		  img2R.src = rf.imageRed;
		  const img2B = new Image();
		  img2B.onload = () => { loadedUrls[rf.imageBlue]=true; setRightLoadedIfPossible(); }
		  img2B.src = rf.imageBlue;
		  const img2G = new Image();
		  img2G.onload = () => { loadedUrls[rf.imageGreen]=true; setRightLoadedIfPossible(); }
		  img2G.src = rf.imageGreen;
		  

    }

    var size = 260;

    async function checkForEndOfDuel() {

    	if(checkingForEndOfDuel) {
    		return;
    	}

    	if(!tokenIsFighting) {
				return;
			}


			checkingForEndOfDuel=true;
			setGameState(1);
			

			ReactSession.set("duelling_token_is_fighting", tokenIsFighting);

			console.log('token is fighting ('+tokenIsFighting+')...');

			

    	if(!lastMatchInfo.lfId || !lastMatchInfo) {

    		if(!duelledAtBlock) {
					duelledAtBlock = ReactSession.get("duelled_at_block");
				}
    		console.log('We dont have info from the last match '+duelledAtBlock);
    			// SEE IF THIS TOKEN IS BACK IN USERS POCKET...
    			


    			

    			const ownerInfo = await getObjFromJSONUrl(
						subs.apis[subs.apiId].url+'matchOwnerOf/'+tokenIsFighting
					);
    			//let ownerOf = await EVM.fum_contract.methods.ownerOf().call();
    			let ownerOf = ownerInfo[0];

    			if(matchIdFromURL || (ownerOf.toString().toLowerCase()==EVM.connectedAccount.toString().toLowerCase() || ownerOf.toString().toLowerCase()==EVM.GY_ADD[0].toString().toLowerCase())) {
    				
    				//console.log('we get here?');
    				if(!matchIdFromURL) {

    					
    					
    					//getFightResult();
	    				//let lastMatchId = await EVM.duel_contract.methods.lastMatchWithTokenId(tokenIsFighting).call();
	    				const lastMatchInfo = await getObjFromJSONUrl(
								subs.apis[subs.apiId].url+'lastMatchWith/'+tokenIsFighting+'/atBlock/'+duelledAtBlock
							);
							lastMatchId = lastMatchInfo[0];
							//console.log('lastMatchId: '+lastMatchId);
    				} else {
    					lastMatchId = matchIdFromURL;
    				}
    				

    				//console.log('we get here too?');
    				// See if 
						if(lastMatchId==-1) {
							const fiq = await getObjFromJSONUrl(
								subs.apis[subs.apiId].url+'fightersInQueue'
							);
	    			//let ownerOf = await EVM.fum_contract.methods.ownerOf().call();
	    			//let fiq = fightersInQueue;

	    			var ourFighterIsInQueue=false;
	    			for(var i=0;i<fiq.length;i++) {
	    				if(fiq[i]==tokenIsFighting) {
	    					ourFighterIsInQueue=true;
	    					break;
	    				}
	    			}

	    			//console.log('and here??');

		    			if(!ourFighterIsInQueue) {
		    				// We should reset the page
		    				checkingForEndOfDuel=false;
		    				resetFight();
		    				return;
		    			}

						}

						//console.log('What about here?');

						
						// Owner changed and we dont have the last match, could be a situation here...
						// if(!lastMatchId || lastMatchId==-1) {
						// 	console.log('Do not have last match, clearing "finding opponent" variable...')
						// 	ReactSession.set("dueling_with_token", false);
						// 	checkingForEndOfDuel=false;
						// 	return;
						// }

						

    				console.log('lastMatchId: '+lastMatchId);
    				if(lastMatchId>-1) {

    					const matchInfo = await getObjFromJSONUrl(
								subs.apis[subs.apiId].url+'match/'+lastMatchId
							);

							if(!matchInfo.result || matchInfo.result==undefined) {
								checkingForEndOfDuel=false;
								return;
							}

							console.log('Fight is over....');

							removeWeapon = true;

							if(!matchIdFromURL) {
    						ReactSession.set("dueling_with_token", false);
    					}
    					
    					console.log('result: '+matchInfo.result);

    					console.log(matchInfo);

    					var lFighter = await getFighter(matchInfo.lfId);
    					var rFighter = await getFighter(matchInfo.rfId);

    					preloadFighterImages(lFighter, rFighter);
    					

    					if(!matchIdFromURL) {
    						ReactSession.set("duelling_fight_over_leftId", matchInfo.lfId);
    						ReactSession.set("duelling_fight_over_rightId", matchInfo.rfId);
    					}
    					


    					setRightFighter(rFighter);
    					setLeftFighter(lFighter);

    					someLeftFighter = lFighter;
    					someRightFighter = rFighter;

    					
    					


    					

    					setLastMatchInfo(matchInfo);

    					//await getLevelChange(matchInfo.lfId, matchInfo.rfId);

    					if(tokenIsFighting==matchInfo.lfId) {
    						changeInOurLevel = matchInfo.lChange;
    					} else if(tokenIsFighting==matchInfo.rfId) {
    						changeInOurLevel = matchInfo.rChange;
    					}

    					//isFighting
    					var useLevel;
    					if(matchInfo.lfId==tokenIsFighting) {
    						useLevel = matchInfo.lNow;
    					} else {
    						useLevel = matchInfo.rNow;
    					}
    					console.log('setting width to: '+Math.round(useLevel%Math.floor(useLevel)*100));
    					setLevelProgress(Math.round(useLevel%Math.floor(useLevel)*100));
				      if(levelProgress > 100) {
				        setLevelProgress(100);
				      }
				      if(levelProgress < 0) {
				        setLevelProgress(0);
				      }

    					tokenIsFighting=false;

    					setEndOfFightAnimation(false);
    					// START GAME WHEN BOTH IMAGES ARE LOADED
    					//if(leftLoaded && rightLoaded || true) {
    					if(leftLoaded && rightLoaded && areAllSpellsLoaded()) {
    						setGameState(2, matchInfo);
    					} else {
    						gameState=1;
    						maxLoadTimeWait=0;
    						needsAnimateAfterImagesLoad = matchInfo;
    						console.log('Not starting animation yet!');



    					}

    					

    					//setIsFighting(false);
    				}
    				
    			} else {
    				

    				console.log('Fight is ongoing! '+ownerOf+' == '+EVM.connectedAccount.toString().toLowerCase());
    				await advanceDots();
    			}
    			
    		}
    		checkingForEndOfDuel=false;
    }

    function chooseYourFighter() {

    	console.log('chooseYourFighter()');


    	//receivedContent=false;
    	//

    	if(possibleFighters.length <= 0 && !receivedContent) {
    		setIsLoadingChoices(true);
    	}



    	setGameState(-1);
    	
    	
    	setOpenChoose(true);
    }


    


		async function checkApprovals() {

			if(!EVM.connectedAccount) {
				return;
			}
			Promise.resolve(chosenFighter);
			if(chosenFighter) {

				let approved = await EVM.fum_contract.methods.getApproved(chosenFighter).call();
				let approvedForAll = await EVM.fum_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.DUEL_ADD[0]).call();
				//console.log(approved);
	      if(!approvedForAll && approved=='0x0000000000000000000000000000000000000000') {
	        isApprovedFum=false;
	        if(chosenFighter) {
	        	setApproveFumDisabled(false);
	        	setApproveFumStyle('primary');
	        } else {
	        	setApproveFumDisabled(true);
	        	setApproveFumStyle('outline-dark');
	        }
	        
	      } else {
	      	
	      	isApprovedFum=true;
	      	//console.log('isApprovedFUM: '+isApprovedFum+' '+EVM.DUEL_ADD[0]);
	      	setApproveFumDisabled(true);
	        setApproveFumStyle('secondary');
	      }

      }

      
      //console.log(approvedForAll);
      

      // let balOfTest = await EVM.foo_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      // console.log('balOfTest: '+balOfTest);

      // console.log('owner: '+EVM.connectedAccount.toString());
      // console.log('spender: '+EVM.DUEL_ADD[0]);
      if(!EVM.foo_contract) {
      	return;
      }


      let allowance = await EVM.foo_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.DUEL_ADD[0]).call();
      console.log(EVM.DUEL_ADD[0]+' allowance: '+allowance);
      if(!allowance) {
      	allowance = '0';
      }
      if(parseInt(EVM.web3.utils.fromWei(allowance)) < 100) {
        isApprovedFoo=false;


        //console.log('Allowance call failed or allowance less than required!');

        if(chosenFighter && isApprovedFum) {
        	setApproveFooDisabled(false);
					setApproveFooStyle('primary');
        } else if(isApprovedFum) {
        	setApproveFooDisabled(true);
					setApproveFooStyle('primary');
        } else {
        	setApproveFooDisabled(true);
	        setApproveFooStyle('outline-dark');
        }

      } else {

      	//console.log('Allowance call succeeded!');

      	isApprovedFoo=true;
      	setApproveFooDisabled(true);
	      setApproveFooStyle('secondary');
      }
      //console.log('isApprovedFoo: '+isApprovedFoo+' '+EVM.DUEL_ADD[0]);

      //console.log('chosenFighter: '+chosenFighter);
      if(chosenFighter && isApprovedFum && isApprovedFoo) {
      	setFightDisabled(false);
				setFightStyle('primary');
      } else if(!chosenFighter && isApprovedFum && isApprovedFoo) {
      	setFightDisabled(true);
				setFightStyle('outline-dark');
      } else if(isApprovedFum) {
      	setFightDisabled(true);
				setFightStyle('outline-dark');
      } else {
      	setFightDisabled(true);
        setFightStyle('outline-dark');
      }


      if(chosenFighter) {
      	setSelectStyle('secondary');
      } else {
      	setSelectStyle('primary');
      }


      checkedApprovalsYet=true;
		}


		async function fight() {

			var maxWaits=0;
			while(!EVM.connectedAccount && maxWaits<=10) {
				await sleep(1000);
				console.log('sleeping..');
				maxWaits++;
			}

			if(skipFighterOwnerCheckOn != chosenFighter) {
				// Does user even own this FUM?
				let ownerOf = await EVM.fum_contract.methods.ownerOf(chosenFighter).call();
				if(ownerOf=='0x0000000000000000000000000000000000000000') {
					ownerOf=false;
				}
				if(ownerOf && EVM.connectedAccount &&  EVM.connectedAccount.toString().toLowerCase() != ownerOf.toString().toLowerCase()) {
					resetFightChangeFighter();
					alert('You are not the ownerOf this duelist!');
					return;

				}

			}


			console.log('lastSuggestedNonce: '+lastSuggestedNonce);


			if(!isApprovedFum) {
				setIsApprovingFum(true);
				console.log('Approving FUM?');
				await callMethod(EVM.fum_contract.methods.setApprovalForAll(EVM.DUEL_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err, res) {
	        setIsApprovingFum(false);
	        if(err) {
	              console.log(err.message);
	        } else {
	         	await checkApprovals();


	          
	        }
	      });
			} else if(!isApprovedFoo) {
				setIsApprovingFoo(true);
				console.log('Approving FOO?');
				await callMethod(EVM.foo_contract.methods.approve(EVM.DUEL_ADD[0], vars.maxUint256), 0, EVM.connectedAccount,  300000, async function(err2, res2) {
	        setIsApprovingFoo(false);

	        console.log('done? '+err2+' '+res2);
	        console.log(res2);
	        if(err2) {
	              console.log(err2.message);
	        } else {
	         	await checkApprovals();


	        }
	      });
			} else {
				//console.log('duel_contract: '+EVM.DUEL_ADD[0]);
				setIsCallingDuel(true);

				//setIsDeterminingState(true);
					setChangeDuellerDisabled(true);
					setSelectStyle('outline-dark');

					//duelledAtDate = Date.now();

					await callMethod(EVM.duel_contract.methods.duel(chosenFighter), 0, EVM.connectedAccount, 700000, function(err3, res3) {

						setIsCallingDuel(false);

						setChangeDuellerDisabled(false);
						setSelectStyle('primary');

						//setIsDeterminingState(false);

		      	if(err3) {
		            console.log(err3.message);
		            lastSuggestedNonce = err3.nonce;
		            console.log('lastSuggestedNonce: '+lastSuggestedNonce);
		            // setIsFighting(false);
		            // tokenIsFighting = false;
		            // ReactSession.set("dueling_with_token", false);

		            
		          } else {
		          	getAverageWaitTime();
		          	//
		          	//console.log(res3);
		          	duelledAtBlock = res3.blockNumber;
		          	lastSuggestedNonce++;
		            console.log('Fighter has successfully joined fight queue ('+leftFighter.id+')...');
			          setIsFighting(chosenFighter);
			          tokenIsFighting = chosenFighter;
			          ReactSession.set("dueling_with_token", leftFighter.id);
			          ReactSession.set("duelled_at_block", duelledAtBlock);
							
		  
		          }
		    	});
		    	
			}
			



			

		}

		async function resetFightChangeFighter() {

			setOffensiveSpell('sword_forever.gif');
			leftLoaded=false;
			rightLoaded=false;
			removeWeapon=false;

			getAverageWaitTime();


			setIsDeterminingState(true);

			ReactSession.set("duelling_token_is_fighting", false);
			ReactSession.set("duelling_fight_over_leftId", false);
			ReactSession.set("duelling_fight_over_rightId", false);

			ReactSession.set("dueling_with_token", false);
			ReactSession.set("duelling_chose_fighter", false);


			chosenFighter=false;
			setIsFighting(false);
			tokenIsFighting=false;
			lastMatchInfo=false;
			// chosenFighter=false;
			setLastMatchInfo(false);

			setLeftFighter(false);
			setRightFighter(false);


			
			changeInOurLevel=false;




			await checkApprovals();

			setIsDeterminingState(false);

		}

		function resetDependsText() {

			if(matchIdFromURL) {
				return 'START A DUEL!';
			}
			return 'DUEL AGAIN!';
		}

		async function resetDepends() {

			setGameState(-1);

			setEndOfFightAnimation(false);

			if(matchIdFromURL) {
				resetFightChangeFighter();
				window.open('/duel', '_self');
				return;
			}



			slfOnce=true;

			removeWeapon=false;

			//var of = ourFighter();

			var weResult=ourResult();

			console.log('weResult: '+weResult);


			if(weResult==3) {
				// we died
				skipFighterOwnerCheckOn=false;
				resetFightChangeFighter();
			} else {
				skipFighterOwnerCheckOn=chosenFighter;
				resetFight();
			}

			// console.log('who owns? '+isFighting);
			// let ownerOf = await EVM.fum_contract.methods.ownerOf(isFighting).call();
			// console.log(ownerOf.toString().toLowerCase());
			// console.log(EVM.connectedAccount[0].toString().toLowerCase());
			// if(ownerOf.toString().toLowerCase()!=EVM.connectedAccount[0].toString().toLowerCase()) {
				
			// } else {
				
			// }

			//resetFight();
			
		}

		async function resetFight() {

			leftLoaded=false;
			rightLoaded=false;
			removeWeapon=false;

			getAverageWaitTime();

			setIsDeterminingState(true);

			ReactSession.set("duelling_token_is_fighting", false);
			ReactSession.set("duelling_fight_over_leftId", false);
			ReactSession.set("duelling_fight_over_rightId", false);
			ReactSession.set("dueling_with_token", false);
			// ReactSession.set("duelling_chose_fighter", false);

			setIsFighting(false);
			tokenIsFighting=false;
			

			// chosenFighter=false;
			

			if(ourFighterIs()=='left') {
				setRightFighter(false);
				chosenFighter = leftFighter.id;
			} else {
				chosenFighter=rightFighter.id;
				setLeftFighter(rightFighter);
				
				setRightFighter(false);

			}

			choseFighter(parseInt(chosenFighter));
			
			lastMatchInfo=false;
			setLastMatchInfo(false);

			changeInOurLevel=false;

			await checkApprovals();

			setIsDeterminingState(false);
			

		}

		function ourFighter() {
			var ofi = ourFighterIs();

			if(ofi=='left') {
				return leftFighter;
			}
			return rightFighter;
		}

		function ourFighterIs() {
			var ourFighterIs;

			// console.log(lastMatchInfo);
			// console.log(isFighting);

			if(parseInt(lastMatchInfo.lfId)==isFighting) {
				ourFighterIs='left';
			}
			if(parseInt(lastMatchInfo.rfId)==isFighting) {
				ourFighterIs='right';
			}
			return ourFighterIs;
		}

		function printRewards() {

			var ourFighterIs;
			if(lastMatchInfo.lfId==isFighting) {
				ourFighterIs='left';
			}
			if(lastMatchInfo.rfId==isFighting) {
				ourFighterIs='right';
			}

			var weResult=ourResult();

			var dp = duelPrice / Math.pow(10,18);
			var expectedWinnings = 0;

			//console.log('dp: '+dp);

			var eachPlayerContributesToContract = dp - (Math.floor(dp * (vars.duelBurnPercent/100)*100)/100) - (Math.floor(dp * (vars.duelHousePercent/100)*100)/100);
			var duelWinnings = eachPlayerContributesToContract * 2; // WINNER TAKES ENTIRE AMOUNT LEFT AFTER BURN AMOUNT AND HOUSE FEE
			var tieWinnings = dp - (Math.floor(dp * (vars.duelBurnPercent/100)*100)/100); // RETURN ALL MONIES TO EACH MINUS THE BURNT FOO ON THE CONTRACT


			if(weResult==0) {
				expectedWinnings = 0;
			} else if(weResult==1) {
				expectedWinnings = duelWinnings;
			} else if(weResult==2) {
				expectedWinnings = tieWinnings;
			} else if(weResult==3) {
				expectedWinnings = 0;
			} else if(weResult==4) {
				expectedWinnings = duelWinnings;
			}



			
				if(ourFighterIs=='left') {
					if(lastMatchInfo.lfReward > 0) {

						var surplusWinnings = parseInt(lastMatchInfo.lfReward)/Math.pow(10,18) - expectedWinnings;

						if(expectedWinnings > 0 && surplusWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo_icon.png" /> {expectedWinnings} FOO RECEIVED<br/><br/><img alt="FOO Icon" width="60" height="60" src="/img/foo2_icon.png" /> <i>+{Math.round(surplusWinnings*100)/100} BONUS FOO</i><br/><br/></>);
						} else if(expectedWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo_icon.png" /> {expectedWinnings} FOO RECEIVED<br/><br/></>);
						} else if(surplusWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo2_icon.png" /> <i>+{Math.round(surplusWinnings*100)/100} BONUS FOO</i><br/><br/></>);
						} else {
							return(<></>);
						}
						
					}
				}
				if(ourFighterIs=='right') {
					if(lastMatchInfo.rfReward > 0) {

						//console.log('actual reward: '+parseInt(lastMatchInfo.rfReward)/Math.pow(10,18));
						var surplusWinnings = parseInt(lastMatchInfo.rfReward)/Math.pow(10,18) - expectedWinnings;
						
						if(expectedWinnings > 0 && surplusWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo_icon.png" /> {expectedWinnings} FOO RECEIVED<br/><br/><img alt="FOO Icon" width="60" height="60" src="/img/foo2_icon.png" /> <i>+{Math.round(surplusWinnings*100)/100} BONUS FOO</i><br/><br/></>);
						} else if(expectedWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo_icon.png" /> {expectedWinnings} FOO RECEIVED<br/><br/></>);
						} else if(surplusWinnings > 0) {
							return(<><img alt="FOO Icon" width="60" height="60" src="/img/foo2_icon.png" /> <i>+{Math.round(surplusWinnings*100)/100} BONUS FOO</i><br/><br/></>);
						} else {
							return(<></>);
						}
						
					}
				}
			
			

		}

		function printDeathToken() {

			var ourFighterIs;
			if(lastMatchInfo.lfId==isFighting) {
				ourFighterIs='left';
			}
			if(lastMatchInfo.rfId==isFighting) {
				ourFighterIs='right';
			}

			



			if(ourFighterIs=='left' && lastMatchInfo.result==3) {
				return(<><img alt="FOO Icon" width="60" height="60" src="/img/rip_icon.png" /> 1 RIP RECEIVED<br/><br/></>);
			}
			if(ourFighterIs=='right' && lastMatchInfo.result==4) {
				return(<><img alt="FOO Icon" width="60" height="60" src="/img/rip_icon.png" /> 1 RIP RECEIVED<br/><br/></>);
			}

		}



		function chooseYourText() {
			if(chosenFighter) {
				return (<>CHANGE YOUR DUELIST</>);
			} else {
				return (<>SELECT A DUELIST</>);
			}
			
		}

		function ourResult() {
			var ourFighterIs;
			if(lastMatchInfo.lfId==isFighting) {
				ourFighterIs='left';
			}
			if(lastMatchInfo.rfId==isFighting) {
				ourFighterIs='right';
			}
			var weResult=0;
			if(ourFighterIs=='left') {
				if(parseInt(lastMatchInfo.result)==0) {
					weResult=0;
				} else if(parseInt(lastMatchInfo.result)==1) {
					weResult=1;
				} else if(parseInt(lastMatchInfo.result)==2) {
					weResult=2;
				} else if(parseInt(lastMatchInfo.result)==3) {
					weResult=3;
				} else if(parseInt(lastMatchInfo.result)==4) {
					weResult=4;
				}
			}
			if(ourFighterIs=='right') {
				if(parseInt(lastMatchInfo.result)==0) {
					weResult=1;
				} else if(parseInt(lastMatchInfo.result)==1) {
					weResult=0;
				} else if(parseInt(lastMatchInfo.result)==2) {
					weResult=2;
				} else if(parseInt(lastMatchInfo.result)==3) {
					weResult=4;
				} else if(parseInt(lastMatchInfo.result)==4) {
					weResult=3;
				}
			}
			return weResult;
		}
		function titleText() {



			
			if(isFighting) {


				

				if(lastMatchInfo.lfId) {

					if(!endOfFightAnimation) {
						return (<><br/></>);
					}


					// if(matchIdFromURL) {
					// 	usName = leftFighter.name;
					// }					var usName = 'YOU';



					var weResult=ourResult();
					
					if(matchIdFromURL) {
						if(weResult==0) {
							return (<><div className="duel_title"><i>{rightFighter.name} WINS THE DUEL!</i></div><br/></>);
						} else if(weResult==1) {
							return (<><div className="duel_title"><i>{leftFighter.name} WINS THE DUEL!</i></div><br/></>);
						} else if(weResult==2) {
							return (<><div className="duel_title"><i>THE DUEL IS A DRAW!</i></div><br/></>);
						} else if(weResult==3) {
							return (<><div className="duel_title"><i>{leftFighter.name} HAS DIED!</i></div><br/></>);
						} else if(weResult==4) {
							return (<><div className="duel_title"><i>{rightFighter.name} HAS DIED!</i></div><br/></>);
						}
					} else {
						if(weResult==0) {
							return (<><div className="duel_title"><i>YOU LOSE THE DUEL!</i></div><br/></>);
						} else if(weResult==1) {
							return (<><div className="duel_title"><i>YOU WIN THE DUEL!</i></div><br/></>);
						} else if(weResult==2) {
							return (<><div className="duel_title"><i>THE DUEL IS A DRAW!</i></div><br/></>);
						} else if(weResult==3) {
							return (<><div className="duel_title"><i>YOU LOSE, YOUR FANTUM HAS DIED!</i></div><br/></>);
						} else if(weResult==4) {
							return (<><div className="duel_title"><i>YOU WIN, YOUR OPPONENT HAS DIED!</i></div><br/></>);
						}
					}

					


				} else {

					if(matchIdFromURL) {
						return (<><div className="duel_title"><i>GETTING MATCH OUTCOME{dots}</i></div><br/></>);
					} else {
						return (<><div className="duel_title"><i>FINDING AN OPPONENT{dots}</i></div><br/></>);
					}
					
					
				}
			} else {

				return (<><div className="duel_title"><i>DUEL YOUR FANTUMS</i></div><br/></>);


				
			}
			
		}

		var fightStatements = [
				'Finding suitable opponent...',
				'Patience is a virtue...',
				'A lucky rabbit\’s foot may uncover mystery eggs during duels/ToT',
				'Collector\’s trophies provide extra strength in duels',
				'Not a big dueller? FOO may be single-staked for a share of the emissions!',
				'Eggs are tradable but will they hatch?',
				'Attack potions only provide a boost when paired with an appropriate weapon',
				'Each defense potion only provide defense against certain weapons',
				'A Chromatic Amulet increases the amount of minor elements that are received in duels',
				'Gothums are part of the Fantums Universe and are minting at gothums.com/mint',
				'Visit our partner DEX spookyswap.finance to swap $FOO, $CNDY, and $RIP'
			];

		function underText() {



			

			if(lastTimeFightStatementChanged==-1) {
				currentFightStatement = Math.floor(Math.random() * fightStatements.length);
				lastTimeFightStatementChanged=Date.now()/1000;
			}

			if(!lastTimeFightStatementChanged || lastTimeFightStatementChanged <= Date.now()/1000-10) {
				currentFightStatement = Math.floor(Math.random() * fightStatements.length);
				lastTimeFightStatementChanged=Date.now()/1000;
			}
			

			if(isFighting) {
				if(lastMatchInfo.lfId) {
					return (<></>);
				} else {
					if(fightersInQueue > 1) {
						return (<>{fightersInQueue} duelists in the queue... <br/>{fightStatements[currentFightStatement]}<br/></>);
					} else if(fightersInQueue == 1) {
						return (<>{fightersInQueue} duelists in the queue... <br/>{fightStatements[currentFightStatement]}<br/></>);
					} else {




						return (<>{fightStatements[currentFightStatement]}<br/></>);
					}
					
				}
			} else {
				return (<><br/><br/><br/></>);
			}

		}	

		function lfLevel() {
			//console.log('even draw? '+leftFighter.level);
			if(leftFighter && (endOfFightAnimation || gameState <2)) {
				return (<>{Math.floor(leftFighter.level)}</>);
			} else {
					return (<>?</>);
			}
			
		}
		function rfLevel() {
			if(rightFighter && (endOfFightAnimation || gameState <2)) {
				return (<>{Math.floor(rightFighter.level)}</>);
			} else {
					return (<>?</>);
			}
		}


		function approveFumText() {
			if(isApprovingFum) {
				return 'Approving FUM'+dots;
			} else {
				return 'Approve FUM';
			}
		}

		function approveFooText() {
			if(isApprovingFoo) {
				return 'Approving FOO'+dots;
			} else {
				return 'Approve FOO';
			}
		}

		async function getFightFee() {
			if(!EVM.connectedAccount) {
				return;
			}
			if(!EVM.duel_contract) {
				return;
			}
			let aDuelPrice =  await EVM.duel_contract.methods.getFightFee().call();
  		duelPrice = aDuelPrice;
  		console.log('Got duel price: '+duelPrice);
  		gotDuelPrice=true;
		}

		function fightForText() {
			if(isCallingDuel) {
				return (<i>DUELING...</i>);
			} else {
				return (<i>FIGHT FOR <b>{(duelPrice/Math.pow(10,18))} FOO</b></i>);
				//return (<i>FIGHT FOR <b>FREE</b></i>);
			}
		}

		function renderSteps() {

			if(!isApprovedFum && !isApprovedFoo) {
				return (<>
							<li>1. <Button className="duel_choose_fum" size="lg" variant={selectStyle} onClick={() => chooseYourFighter()} disabled={changeDuellerDisabled}><b><i>{chooseYourText()}</i></b></Button></li>
	        		<li>2. <Button className="duel_approve_fum" size="lg" variant={approveFumStyle} onClick={() => fight()} disabled={approveFumDisabled}><i>{approveFumText()}</i></Button></li>
	        		<li>3. <Button className="duel_approve_foo" size="lg" variant={approveFooStyle} onClick={() => fight()} disabled={approveFooDisabled}><i>{approveFooText()}</i></Button></li>
	        		<li>4. <Button className="duel_fight" size="lg" variant={fightStyle} onClick={() => fight()} disabled={fightDisabled}>{fightForText()}</Button></li>
				</>);
			} else if(isApprovedFum && !isApprovedFoo) {
				return (<>
							<li>1. <Button className="duel_choose_fum" size="lg" variant={selectStyle} onClick={() => chooseYourFighter()} disabled={changeDuellerDisabled}><b><i>{chooseYourText()}</i></b></Button></li>
	        		<li>2. <Button className="duel_approve_foo" size="lg" variant={approveFooStyle} onClick={() => fight()} disabled={approveFooDisabled}><i>{approveFooText()}</i></Button></li>
	        		<li>3. <Button className="duel_fight" size="lg" variant={fightStyle} onClick={() => fight()} disabled={fightDisabled}>{fightForText()}</Button></li>
				</>);
			} else if(!isApprovedFum && isApprovedFoo) {
				return (<>
							<li>1. <Button className="duel_choose_fum" size="lg" variant={selectStyle} onClick={() => chooseYourFighter()} disabled={changeDuellerDisabled}><b><i>{chooseYourText()}</i></b></Button></li>
	        		<li>2. <Button className="duel_approve_fum" size="lg" variant={approveFumStyle} onClick={() => fight()} disabled={approveFumDisabled}><i>{approveFumText()}</i></Button></li>
	        		<li>3. <Button className="duel_fight" size="lg" variant={fightStyle} onClick={() => fight()} disabled={fightDisabled}>{fightForText()}</Button></li>
				</>);
			} else if(isApprovedFum && isApprovedFoo) {
				return (<>
							<li>1. <Button className="duel_choose_fum" size="lg" variant={selectStyle} onClick={() => chooseYourFighter()} disabled={changeDuellerDisabled}><b><i>{chooseYourText()}</i></b></Button></li>
	        		<li>2. <Button className="duel_fight" size="lg" variant={fightStyle} onClick={() => fight()} disabled={fightDisabled}>{fightForText()}</Button></li>
				</>);
			}



			
		}

		function printMatchInfo() {


			if(!endOfFightAnimation) {
				return(<></>);
			}

			//console.log(lastMatchInfo.result);

			var ourFighterIs;
			if(lastMatchInfo.lfId==isFighting) {
				ourFighterIs='left';
			}
			if(lastMatchInfo.rfId==isFighting) {
				ourFighterIs='right';
			}

			if(ourFighterIs=='left') {
				
				if(lastMatchInfo.result==0) {
					return(<>{leftFighter.name} loses to {rightFighter.name}!</>);
				} else if(lastMatchInfo.result==1) {
					return(<>{leftFighter.name} beats {rightFighter.name}!</>);
				} else if(lastMatchInfo.result==2) {
					return(<>{leftFighter.name} draws with {rightFighter.name}!</>);
				} else if(lastMatchInfo.result==3) {
					return(<>{leftFighter.name} has been slain by {rightFighter.name}!</>);
				} else if(lastMatchInfo.result==4) {
					return(<>{leftFighter.name} slays {rightFighter.name}!</>);
				}

			}

			if(ourFighterIs=='right') {
				if(lastMatchInfo.result==0) {
					return(<>{rightFighter.name} beats {leftFighter.name}!</>);
				} else if(lastMatchInfo.result==1) {
					return(<>{rightFighter.name} loses to {leftFighter.name}!</>);
				} else if(lastMatchInfo.result==2) {
					return(<>{rightFighter.name} draws with {leftFighter.name}!</>);
				} else if(lastMatchInfo.result==3) {
					return(<>{rightFighter.name} slays {leftFighter.name}!</>);
				} else if(lastMatchInfo.result==4) {
					return(<>{rightFighter.name} has been slain by {leftFighter.name}!</>);
				}
			}

			return(<></>);
		}

		function setLeftLoadedIfPossible() {
			//console.log('possible load left?');
			//console.log('loadedUrls[leftFighter.image32]: '+loadedUrls[leftFighter.image32]);
			if(loadedUrls[someLeftFighter.image32] && loadedUrls[someLeftFighter.imageRed] && loadedUrls[someLeftFighter.imageGreen] && loadedUrls[someLeftFighter.imageBlue]) {
				//console.log('left fully loaded!');
				leftLoaded=true;
			}
		}

		function setRightLoadedIfPossible() {
			if(loadedUrls[someRightFighter.image32] && loadedUrls[someRightFighter.imageRed] && loadedUrls[someRightFighter.imageGreen] && loadedUrls[someRightFighter.imageBlue]) {
				//console.log('right fully loaded!');
				rightLoaded=true;
			}
		}

		// var mySVG = "<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 230'><defs><style>.cls-1{fill:"+vars.rollColors[outfit]+";}</style></defs><title>base</title><polygon class='cls-1' points='160 60 160 50 160 40 150 40 150 30 140 30 140 20 130 20 130 10 120 10 110 10 110 0 100 0 90 0 80 0 70 0 60 0 60 10 50 10 40 10 40 20 30 20 30 30 20 30 20 40 10 40 10 50 10 60 0 60 0 70 0 200 0 210 10 210 10 220 20 220 20 230 30 230 30 220 40 220 40 210 50 210 50 200 60 200 60 210 70 210 70 220 80 220 80 230 90 230 90 220 100 220 100 210 110 210 110 200 120 200 120 210 130 210 130 220 140 220 140 230 150 230 150 220 160 220 160 210 170 210 170 200 170 70 170 60 160 60'/></svg>";

		function renderRightFighterImg() {

			if(isDeterminingState) {
				return (<><img className={"fight_img "} alt="Right Fighter"  src="" /></>);
			}
			if(rightFighter.image32 == leftFighter.image32 && !isDeterminingState) {
				return (<><img className={"fight_img "} alt="Right Fighter"src="/img/question_mark.png" /></>);
			}

			return (<>
				<div className={'fight_img '+(rightFighter ? '' : 'hidden')} style={{backgroundImage: 'url('+rightFighter.image32+')'}}>
					<div className={'fight_img_inner '} style={rightInnerEffect}>
						<img id="rightGif" className="animatedGif" src={rightGifSrc} />
					</div>
				</div>

				{/*<img className={"fight_img "} alt="Right Fighter"  src={rightFighter.image32} onLoad={() => setRightLoadedIfPossible()} />*/}


				</>);

		}

		function renderLeftFighterImg() {

			if(isDeterminingState) {
				return (<><img className={"fight_img "} alt="Left Fighter" src="" /></>);
			}

			if(rightFighter.image32 == leftFighter.image32 && !isDeterminingState) {
				return (<><img className={"fight_img "} alt="Left Fighter"  src="/img/question_mark.png" /></>);
			}
			//<img className="animatedGif" src="/img/gif/smoke32f.gif" />
			return (<>

				<div className={'fight_img left_flip '+(leftFighter ? '' : 'hidden')} style={{backgroundImage: 'url('+leftFighter.image32+')'}}>

					<div className={'fight_img_inner '} style={leftInnerEffect}>
				  
						<img id="leftGif" className="animatedGif" src={leftGifSrc} />
					
					</div>
				</div>
				{/*// <img className={'fight_img left_flip '+(leftFighter ? '' : 'hidden')} alt="Left Fighter"  src={leftFighter.image32} onLoad={() => setLeftLoadedIfPossible()} />*/}


				</>);
		}

		function leftName() {
			if(leftFighter) {
				return leftFighter.name;
			} else {
				return '';
			}
			
		}
		function rightName() {
			if(rightFighter) {
				return rightFighter.name;
			} else {
				return '';
			}
			
		}

		//const [leftFighterImg, setLeftFighterImg] = useState(false);

		const [leftInnerEffect, setLeftInnerEffect] = useState({});
		const [rightInnerEffect, setRightInnerEffect] = useState({});

		const [leftGifSrc, setLeftGifSrc] = React.useState('/img/gif/blank.gif');
		const [rightGifSrc, setRightGifSrc] = React.useState('/img/gif/blank.gif');
		const [offensiveSpell, setOffensiveSpell] = React.useState('sword_forever.gif');


		const [lfX, setLfX] = React.useState(0);
		const [lfY, setLfY] = React.useState(0);
		const [lfScale, setLfScale] = React.useState(0);
		const [lfSkew, setLfSkew] = React.useState(0);
		const [lfDuration, setLfDuration] = React.useState(1.0);
		const [lfRepeat, setLfRepeat] = React.useState(false);


		const [rfX, setRfX] = React.useState(0);
		const [rfY, setRfY] = React.useState(0);
		const [rfScale, setRfScale] = React.useState(0);
		const [rfSkew, setRfSkew] = React.useState(0);
		const [rfDuration, setRfDuration] = React.useState(1.0);
		const [rfRepeat, setRfRepeat] = React.useState(false);


		const [rfTombHeight, setRfTombHeight] = React.useState(1);
		const [rfTombDuration, setRfTombDuration] = React.useState(0.333);
		const [rfTombRepeat, setRfTombRepeat] = React.useState(0);
		const [rfTombX, setRfTombX] = React.useState(0);
		const [rfTombY, setRfTombY] = React.useState(0);

		const [lfTombHeight, setLfTombHeight] = React.useState(1);
		const [lfTombDuration, setLfTombDuration] = React.useState(0.333);
		const [lfTombRepeat, setLfTombRepeat] = React.useState(0);
		const [lfTombX, setLfTombX] = React.useState(0);
		const [lfTombY, setLfTombY] = React.useState(0);


		const [isLTombstone, setIsLTombstone] = React.useState(false);
		const [isRTombstone, setIsRTombstone] = React.useState(false);

		const [rewardLinesScale, setRewardLinesScale] = React.useState(0);
		const [rewardLinesDuration, setRewardLinesDuration] = React.useState(0.333);


		const [textTitleScale, setTextTitleScale] = React.useState(1);
		const [textTitleDuration, setTextTitleDuration] = React.useState(0.333);


		const [endOfFightAnimation, setEndOfFightAnimation] = React.useState(false);

		const [countdownTextScale, setCountdownTextScale] = React.useState(1);
		const [countdownTextDuration, setCountdownTextDuration] = React.useState(1);
		

		const [progressXScale, setProgressXScale] =  React.useState(1.0);
		const [progressX, setProgressX] =  React.useState(-320.0);
		const [progressBarDuration, setProgressBarDuration] =  React.useState(0.333);
	
		var lungeDistance;

		if(!isMobile) {
			lungeDistance = window.innerWidth/8;
		} else {
			lungeDistance = window.innerWidth/8;
		}

		function setGameState(aGameState, mi) {

			setRenderedGameState(gameState);
			console.log('setRenderedGameState: '+gameState);

			if(aGameState==gameState) {
				return;
			}
			gameState = aGameState;

			


			console.log('setGameState: '+gameState);

			setProgressX(-280.0);
			setProgressXScale(0.0);

			setIsLTombstone(false);
			setIsRTombstone(false);

			setRewardLinesScale(0);


			setRfTombHeight(1);
			setLfTombHeight(1);
			setRfTombDuration(1.0);
			setRfTombY(260);

			setLfTombHeight(1);
			setRfTombHeight(1);
			setLfTombDuration(1.0);
			setLfTombY(260);

			setCountdownTextScale(4);


			setCountdownText('');
			setGetReadyText('');

			setGetReadyTextScale(1);

			setGetReadyTextDuration(0.75);
			setGetReadyTextRepeat(Infinity);
			setGetReadyTextY(0);


			if(gameState==-1) {

				setTextTitleScale(1);
				setLfRepeat(false);
				setLfDuration(1.0);
				setLfX(0);
				setLfY(0);
				setLfScale(1);
				setLfSkew(0);


				setRfRepeat(false);
				setRfDuration(1.0);
				setRfX(0);
				setRfY(0);
				setRfScale(1);
				setRfSkew(0);

				

			} else if(gameState==0) {
				setTextTitleScale(1);
				setLfRepeat(Infinity);
				setLfDuration(0.25);

				setLfX(0);
				setLfY([-2, 2]);

				setLfScale(1);
				setLfSkew(0);
				

			} else if(gameState==1) {
				
				setTextTitleScale(1);


				setLfRepeat(Infinity);
				setLfDuration(0.25);
				setLfX(0);
				setLfY([-2, 2]);
				setLfScale(1.0);
				setLfSkew([-1, -3]);


				setRfRepeat(Infinity);
				setRfDuration(0.25);
				setRfX(0);
				setRfY([-2, 2]);
				setRfScale(1.0);
				setRfSkew([1, 3]);


			} else if(gameState==2) {

				setGetReadyText('GET READY...');

				setEndOfFightAnimation(false);

				setCountdownText(3);
				setCountdownTextDuration(1);
				setCountdownTextScale(1);
				setGetReadyTextScale(1.1);


				setTimeout(async function() {
					setCountdownTextDuration(0);
					setCountdownTextScale(4);
					setCountdownText(2);
				}, 900);

				setTimeout(async function() {
					

					setCountdownTextDuration(1);
					setCountdownTextScale(1);
				}, 1000);

				setTimeout(async function() {
					setCountdownTextDuration(0);
					setCountdownTextScale(4);
					setCountdownText(1);
				}, 1900);
				setTimeout(async function() {
					

					setCountdownTextDuration(1);
					setCountdownTextScale(1);
				}, 2000);

				setTimeout(async function() {
					setGetReadyText('FIGHT!');
					setCountdownText('');
					
				}, 3000);

				setTimeout(async function() {
					setGetReadyTextRepeat(false);
					setGetReadyTextDuration(0.333);
					setGetReadyTextScale(20);
					setGetReadyTextY(260);

				}, 4000);

				setTimeout(async function() {
					setGetReadyTextDuration(0);
					setGetReadyText('');
					playGame(mi);
				}, 4333);
				
			}

		}

		function weaponForFighter(fighter) {
			console.log(fighter);
			if(!fighter.hash) {
				return 'blank';
			}
			var arrOfHash = arrayOfHashCharacters(fighter.hash);
			var g = genderFromHashArr(arrOfHash);
			// var lbv = layerByteVal('rh', false, arrOfHash);
			var of = outfitForLayerName('rh', arrOfHash);

			//console.log(arrOfHash);
			//console.log('of: '+of);

			if(of==2) {
				return 'flower';
			} else if(of==3) {
				return 'lantern';
			} else if(of==4) {
				return 'torch';
			} else if(of==5) {
				if(g==0) {
					return 'mask';
				} else {
					return 'sword';
				}
				
			} else if(of==6) {
				return 'candelabra';
			}
		}

		function spellEffectForFighter(fighter) {
			//console.log(fighter);
			if(!fighter.hash) {
				return 'blank';
			}
			var arrOfHash = arrayOfHashCharacters(fighter.hash);
			var g = genderFromHashArr(arrOfHash);
			// var lbv = layerByteVal('rh', false, arrOfHash);
			var of = outfitForLayerName('rh', arrOfHash);

			//console.log(arrOfHash);
			//console.log('of: '+of);

			if(of==2) {
				return ['smoke', 'g'];
			} else if(of==3) {
				return ['chains', 'b'];
			} else if(of==4) {
				return ['fire', 'r'];
			} else if(of==5) {
				if(g==0) {
					return ['slice', 'g'];
				} else {
					return ['slice', 'b'];
				}

				
			} else if(of==6) {
				return ['fireball', 'r'];
			}
		}

		function playGame(mi) {

			if(!mi) {
				return;
			}
			var dur=4;


			if(!isMobile) {
				setProgressBarDuration(0.333);
			} else {
				setProgressBarDuration(0.0);
			}
				
				setTextTitleDuration(0);
				setTextTitleScale(0);
				
				

				setTimeout(async function() {
					setTextTitleDuration(0.333);
					
					if(mi.result==4) {
						setIsRTombstone(true);
					} else if(mi.result==3) {
						setIsLTombstone(true);
					}
					//setRfTombHeight(0);
					
				}, dur*1000*0.9);

				setTimeout(async function() {
					setRewardLinesScale(1);
					setTextTitleScale(1);

					if(mi.result==4) {
						setRfTombY(0);
					} else if(mi.result==3) {
						setLfTombY(0);
					}
					

					

					setEndOfFightAnimation(true);



					resetFighterPositions();

					//if(!addysEqual(EVM.connectedAccount, vars.devAdd)) {
						// TURN THIS ALL ON FOR TO DISABLE REPLAY
						ReactSession.set("duelling_token_is_fighting", false);
						ReactSession.set("duelling_fight_over_leftId", false);
						ReactSession.set("duelling_fight_over_rightId", false);
						ReactSession.set("dueling_with_token", false);
						ReactSession.set("duelling_chose_fighter", false);
					//}

					

				}, dur*1000);

				setTimeout(async function() {


					setProgressXScale(1.0);
					setProgressX(0.0);
					

					

					//divide change into 100 steps
					if(!isMobile) {

						animateLevelChangeCounter = 0;

						animateLevelChangeStep = (changeInOurLevel*1000) / 40;

						//console.log(changeInOurLevel);


						clearInterval(animateLevelChangeInterval);
						animateLevelChangeInterval=setInterval(async function() {

							animateLevelChangeCounter = animateLevelChangeCounter + animateLevelChangeStep;
							if(animateLevelChangeCounter >= changeInOurLevel*1000) {
								animateLevelChangeCounter = changeInOurLevel*1000;
							}


							//console.log('animateLevelChangeCounter: '+animateLevelChangeCounter);

							setAnimateToChangeInOurLevel(Math.round(animateLevelChangeCounter));

							if(animateLevelChangeCounter == changeInOurLevel*1000 && changeInOurLevel > 0) {
								clearInterval(animateLevelChangeInterval);
							}
						}, 25);

					} else {
						setAnimateToChangeInOurLevel(Math.round(changeInOurLevel*1000));
					}

				}, dur*1000+500);


				var lSpell = spellEffectForFighter(someRightFighter);
				var rSpell = spellEffectForFighter(someLeftFighter);

				var whoGetsFirstHit = Math.floor(Math.random() * 2);


				var rEffectImage;
				var lEffectImage;
				if(lSpell[1]=='r') {
					lEffectImage=someLeftFighter.imageRed;
				} else if(lSpell[1]=='g') {
					lEffectImage=someLeftFighter.imageGreen;
				} else if(lSpell[1]=='b') {
					lEffectImage=someLeftFighter.imageBlue;
				}
				if(rSpell[1]=='r') {
					rEffectImage=someRightFighter.imageRed;
				} else if(rSpell[1]=='g') {
					rEffectImage=someRightFighter.imageGreen;
				} else if(rSpell[1]=='b') {
					rEffectImage=someRightFighter.imageBlue;
				}


				var lFlipStr='';
				if(lSpell[0]=='fireball') {
					lFlipStr='r';
				}
				var rFlipStr='';
				if(rSpell[0]=='fireball') {
					rFlipStr='r';
				}

				//var timings = [0.5*1000, 1.0*1000, 1.3*1000, 1.8*1000, 2.1*1000, 2.9*1000];
				var timings = [0.5*1000, 1.0*1000, 1.6*1000, (1.6+0.5)*1000, 2.7*1000, (2.7+0.5)*1000];

				setTimeout(async function() {
					if(whoGetsFirstHit==0) {
						setLeftGifSrc('/img/gif/'+lSpell[0]+'32m'+lFlipStr+'_once.gif');
						setRightGifSrc('/img/gif/blank.gif');
						//setFeEffectLeft('fight_img_red');
						
						setLeftInnerEffect({backgroundImage: 'url('+lEffectImage+')'});
						setRightInnerEffect({backgroundImage: 'url()'});
						// setLeftFighterImg(leftFighter.imageRed);
						

					} else {
						setLeftGifSrc('/img/gif/blank.gif');
						setRightGifSrc('/img/gif/'+rSpell[0]+'32m'+rFlipStr+'_once.gif');
						//setFeEffectLeft('fight_img_red');

						setLeftInnerEffect({backgroundImage: 'url()'});
						setRightInnerEffect({backgroundImage: 'url('+rEffectImage+')'});

						
					}
					
					
					
				}, timings[0]);
				setTimeout(async function() {
					//setFeEffectLeft('');
					// setLeftFighterImg(leftFighter.image32);
					setLeftInnerEffect({backgroundImage: 'url()'});
					setRightInnerEffect({backgroundImage: 'url()'});

					setLeftGifSrc('/img/gif/blank.gif');
					setRightGifSrc('/img/gif/blank.gif');

					
				}, timings[1]);

				setTimeout(async function() {
					if(whoGetsFirstHit==0) {
						setLeftGifSrc('/img/gif/blank.gif');
						setRightGifSrc('/img/gif/'+rSpell[0]+'32m'+rFlipStr+'_once.gif');

						setLeftInnerEffect({backgroundImage: 'url()'});
						setRightInnerEffect({backgroundImage: 'url('+rEffectImage+')'});

					
					} else {
						setLeftGifSrc('/img/gif/'+lSpell[0]+'32m'+lFlipStr+'_once.gif');
						setRightGifSrc('/img/gif/blank.gif');

						setLeftInnerEffect({backgroundImage: 'url('+lEffectImage+')'});
						setRightInnerEffect({backgroundImage: 'url()'});

						
					}
				}, timings[2]);
				setTimeout(async function() {
					setLeftInnerEffect({backgroundImage: 'url()'});
					setRightInnerEffect({backgroundImage: 'url()'});

					setLeftGifSrc('/img/gif/blank.gif');
					setRightGifSrc('/img/gif/blank.gif');

				
				}, timings[3]);	

				setTimeout(async function() {

					if(mi.result==0 || mi.result==3) {
						setLeftGifSrc('/img/gif/'+lSpell[0]+'32m'+lFlipStr+'_once.gif');
						setRightGifSrc('/img/gif/blank.gif');

						setLeftInnerEffect({backgroundImage: 'url('+lEffectImage+')'});
						setRightInnerEffect({backgroundImage: 'url()'});

					} else if(mi.result==1 || mi.result==4) {
						setLeftGifSrc('/img/gif/blank.gif');
						setRightGifSrc('/img/gif/'+rSpell[0]+'32m'+rFlipStr+'_once.gif');

						setLeftInnerEffect({backgroundImage: 'url()'});
						setRightInnerEffect({backgroundImage: 'url('+rEffectImage+')'});
					} else if(mi.result==2) {

						setLeftInnerEffect({backgroundImage: 'url('+lEffectImage+')'});
						setRightInnerEffect({backgroundImage: 'url('+rEffectImage+')'});

						setLeftGifSrc('/img/gif/'+lSpell[0]+'32m'+lFlipStr+'_once.gif');
						setRightGifSrc('/img/gif/'+rSpell[0]+'32m'+rFlipStr+'_once.gif');
					}


					
					
				}, timings[4]);
				setTimeout(async function() {
					setLeftInnerEffect({backgroundImage: 'url()'});
					setRightInnerEffect({backgroundImage: 'url()'});

					setLeftGifSrc('/img/gif/blank.gif');
					setRightGifSrc('/img/gif/blank.gif');
				}, timings[5]);			

				console.log(lastMatchInfo.result);

				if(mi.result==4) {
					setLfRepeat(false);
					setLfDuration(dur);
					setLfX([0, lungeDistance, 0, 0, lungeDistance, 0, 0, lungeDistance, 0, 0, 0, 0]);
					setLfY([0,0,0,0,0,0,0,0,0,0,0,0]);
					setLfScale(1);
					setLfSkew(0);

					setRfRepeat(false);
					setRfDuration(dur);
					setRfX([0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, -lungeDistance, lungeDistance*4, lungeDistance*4, lungeDistance*4, lungeDistance*4]);
					setRfY([0,0,0,0,0,0,0,0,-300,-300,-300,-300]);
					setRfScale(1);
					setRfSkew(0);
				} else if(mi.result==3) {
					setRfRepeat(false);
					setRfDuration(dur);
					setRfX([0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, 0, 0]);
					setRfY([0,0,0,0,0,0,0,0,0,0,0,0]);
					setRfScale(1);
					setRfSkew(0);

					setLfRepeat(false);
					setLfDuration(dur);
					setLfX([0, lungeDistance, 0, 0, lungeDistance, 0, 0, lungeDistance, -lungeDistance*4, -lungeDistance*4, -lungeDistance*4, -lungeDistance*4]);
					setLfY([0,0,0,0,0,0,0,0,-300,-300,-300,-300]);
					setLfScale(1);
					setLfSkew(0);
				}	else if(mi.result==1) {
					setLfRepeat(false);
					setLfDuration(dur);
					setLfX([0, lungeDistance, 0, 0, lungeDistance, 0, 0, lungeDistance, 0, 0, 0, 0]);
					setLfY([0,0,0,0,0,0,0,0,0,0,0,0]);
					setLfScale(1);
					setLfSkew(0);

					setRfRepeat(false);
					setRfDuration(dur);
					setRfX([0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, -lungeDistance, lungeDistance*4, lungeDistance*4, lungeDistance*4, 0]);
					setRfY([0,0,0,0,0,0,0,0,-300,-300,0,0]);
					setRfScale(1);
					setRfSkew(0);
				}	else if(mi.result==0) {
					setRfRepeat(false);
					setRfDuration(dur);
					setRfX([0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, 0, 0]);
					setRfY([0,0,0,0,0,0,0,0,0,0,0,0]);
					setRfScale(1);
					setRfSkew(0);

					setLfRepeat(false);
					setLfDuration(dur);
					setLfX([0, lungeDistance, 0, 0, lungeDistance, 0, 0, lungeDistance, -lungeDistance*4, -lungeDistance*4, -lungeDistance*4, 0]);
					setLfY([0,0,0,0,0,0,0,0,-300,-300,0,0]);
					setLfScale(1);
					setLfSkew(0);
				}	else if(mi.result==2) {
					setRfRepeat(false);
					setRfDuration(dur);
					setRfX([0, -lungeDistance, 0, 0, -lungeDistance, 0, 0, -lungeDistance, lungeDistance*4, lungeDistance*4, lungeDistance*4, 0]);
					setRfY([0,0,0,0,0,0,0,0,-300,-300,0,0]);
					setRfScale(1);
					setRfSkew(0);

					setLfRepeat(false);
					setLfDuration(dur);
					setLfX([0, lungeDistance, 0, 0, lungeDistance, 0, 0, lungeDistance, -lungeDistance*4, -lungeDistance*4, -lungeDistance*4, 0]);
					setLfY([0,0,0,0,0,0,0,0,-300,-300,0,0]);
					setLfScale(1);
					setLfSkew(0);
				}	
		}

		function resetFighterPositions() {


			setGetReadyText('');
			setGetReadyTextY(0);
			setGetReadyTextScale(1);

			setTimeout(async function() {
					setLfRepeat(false);
					setLfDuration(0.0);
					setLfX(0);
					setLfY(0);
					setLfScale(1);
					setLfSkew(0);

					setRfRepeat(false);
					setRfDuration(0.0);
					setRfX(0);
					setRfY(0);
					setRfScale(1);
					setRfSkew(0);

				}, 1000);


			
		}

		

		const [getReadyTextDuration, setGetReadyTextDuration] = useState(0.75);
		const [getReadyTextScale, setGetReadyTextScale] = useState(1);
		const [getReadyText, setGetReadyText] = useState('');
		const [getReadyTextY, setGetReadyTextY] = useState(0);
		const [getReadyTextRepeat, setGetReadyTextRepeat] = useState(Infinity);

		const [countdownText, setCountdownText] = useState('');



		function noFighters() {
			if(possibleFighters.length<=0 && !isLoadingChoices) {
				return (<>You have no eligible duelists!</>);
			} else {
				return(<></>);
			}
		}
		function duelCountdownText() {
			return countdownText;
		}

		function printMatchId() {

			if(!endOfFightAnimation) {
				return '';
			}

			// if(lastMatchId > -1) {
			// 	return (<span className="matchNum"><a href={"/duel/"+lastMatchId}>Match #{lastMatchId}</a></span>);
			// }

			return '';
			
		}

		function browseAwayToLeft() {
			// ReactSession.set("duelling_token_is_fighting", false);
			// ReactSession.set("duelling_fight_over_leftId", false);
			// ReactSession.set("duelling_fight_over_rightId", false);

			//ReactSession.set("dueling_with_token", false);
			//ReactSession.set("duelling_chose_fighter", false);
			window.open('/token/'+leftFighter.id, '_blank');
		}
		function browseAwayToRight() {
			// ReactSession.set("duelling_token_is_fighting", false);
			// ReactSession.set("duelling_fight_over_leftId", false);
			// ReactSession.set("duelling_fight_over_rightId", false);

			//ReactSession.set("dueling_with_token", false);
			window.open('/token/'+rightFighter.id, '_blank');
		}

		function leftNameAndLevel() {
			if(!leftFighter.hash) {
				return;
			}
			return (<>
				<div className="duel_name_level_holder">
					
					
					<div className={"nameBox rarityColor_" + rollNum(leftFighter.hash.toString())+"_roll"} alt={leftFighter.id}><a onClick={() => browseAwayToLeft()}>{leftName()}</a></div>
	        <div className={"levelBox "+(((endOfFightAnimation || gameState <2) && leftFighter.id && (gameState < 1 || endOfFightAnimation)) ? 'inline-block' : 'hidden')}><div className="duellerNumBox">#{leftFighter.id}</div>{lfLevel()}</div>
  				<div className="buff_bar_container_left"><BuffBar 
					key={'BB_l'+leftFighter.owner+gameState} 
					tkey={'BB_l'+leftFighter.owner+gameState} 
					acc={leftFighter.owner}
					marginTop={-10}
					iconSize={32}
					/></div>
  			</div>
  			</>);
		}


		function rightNameAndLevel() {
			if(!rightFighter.hash) {
				return;
			}
			return (<>
				<div className="duel_name_level_holder">
					
					
					<div className={"nameBox rarityColor_" + rollNum(rightFighter.hash.toString())+"_roll"} alt={rightFighter.id}><a onClick={() => browseAwayToRight()}>{rightName()}</a></div>
			    <div className={"levelBox "+(((endOfFightAnimation || gameState <2) && rightFighter.id &&  (gameState < 1 || endOfFightAnimation)) ? 'inline-block' : 'hidden')}><div className="duellerNumBox">#{rightFighter.id}</div>{rfLevel()}</div>
		    	<div className="buff_bar_container_right"><BuffBar 
					key={'BB_r'+rightFighter.owner+gameState} 
					tkey={'BB_r'+rightFighter.owner+gameState} 
					acc={rightFighter.owner}
					marginTop={-10}
					iconSize={32}
					/></div>
		    </div></>);
		}

		function showBar() {

			var of = ourFighter();

			var changeStr;
			if(animateToChangeInOurLevel <=0) {
				changeStr=''+animateToChangeInOurLevel.toString()+' XP';
			} else {
				changeStr='+'+animateToChangeInOurLevel.toString()+' XP';
			}

			return (<>
				
				<div className="level_bar_of">{of.name}</div>
				<div className={"level_bar_duel "}>
					<div className="level_bar_duel_xp">{changeStr}</div>
					<div className="level_bar_duel_level">Level <b>{Math.floor(of.level)}</b></div>

					<motion.div
				      animate={{
				        scaleX: progressXScale,
				        x: progressX
				      }}
				      transition={{ repeat: false, delay: 0.0, duration: progressBarDuration }}
				    >
	        	<div  className={"level_bar_inner_duel "} style={{width: levelProgress.toString()+'%'}}>
	          
	          </div>

	        </motion.div>


	          
	        </div>


			</>);
		}
	// return (<>
	// 		<div style={{color: '#FFF', width: '480px', textAlign: 'center', margin: '0 auto'}}><h1>Dueling Not Available</h1>
	// 		<p>Duelling is currently disabled due to high operating costs and current market conditions.</p><br/>
	// 		<h4>Do Not Requeue Your Duellers</h4>
	// 		<p>We will continue to process duels for a time to return your Fantums to your wallet but if you requeue them they could end up stuck in the queue for an unknown length of time.</p>
	// 		<br/><br/></div>
	// 		</>);

	function printWarning() {
		if(gameState<1) {
			return 'DUELING MAY RESULT IN THE DEATH OF YOUR FANTUM, PROCEED WITH CAUTION!';
		} else {
			return '';
		}
	}



	return (<>

		{modal(openChoose, () => setOpenChoose(false), 'CHOOSE YOUR DUELIST', function() {
        return (<>
        	<div className="fighterSelectModal">
        	<div className={isLoadingChoices ? '' : 'hidden'}>
        		<div id="rotatingSkelly" className={!isLoadingChoices ? '' : 'rotate'}></div>
        	</div>
          {possibleFighters.map((token, index) => (
						<SelectFighterFantum key={index} token={token} rowsize={60} onFighterClick={() => choseFighter(token.id)} />
					))}
					{noFighters()}
          </div>
          </>);
      })}



		
		 <div className="appOperaStreets" render={render}>
        <div id="operaHolderStreets">

        

		    <div className={(isDeterminingState || !EVM.connectedAccount) ? '' : 'hidden'}>
		    <br/><br/><br/><br/><br/><br/><br/><br/><br/>
		  		<div id="rotatingSkellyDuel" className={'rotate'}></div>
		  		 <br/><br/><br/>
		  	</div>
        
        <div className={(!window.ethereum) ? '' : 'hidden'}>
        <br/><br/><br/><br/><br/>
        <h5>Please connect MetaMask to the Fantom network.</h5>
        </div>
        <div className={(!isDeterminingState && EVM.connectedAccount) ? '' : 'hidden'}>
        
        <div id="versus" className={((renderedGameState<2 || gameState < 2) ? 'hidden' : '')}>VS</div>


        <div id="duel_get_ready_and_countdown" className={((gameState==2) && !endOfFightAnimation ? '' : 'minusOneZ')}>

	        <div id="duel_get_ready">
	        <motion.div
				      animate={{
				        scale: getReadyTextScale,
				        y: getReadyTextY
				      }}
				      transition={{ repeat: getReadyTextRepeat, repeatType: "reverse", delay: 0.0, duration: getReadyTextDuration }}
				    >
	        	<i><b>{getReadyText}</b></i>
	        </motion.div>
	        </div>

	        <div id="duel_countdown">
	        <motion.div
				      animate={{
				        scale: countdownTextScale,
				      }}
				      transition={{ repeat: false, delay: 0.0, duration: countdownTextDuration }}
				    >
	        	{duelCountdownText()}
	        </motion.div>
	        </div>

        </div>


        <br/><br/>



        <div id="duel_title_holder">
        <motion.div
						      animate={{
						        scale: textTitleScale,
						      }}
						      transition={{ repeat: false,  repeatType: "reverse", delay: 0.25, duration: textTitleDuration }}
						    >
		        {titleText()}
		       <div className={"duel_subtitle2 "}>
		       <br/>
		        	<b>{printWarning()}</b>
			        <b>{printMatchInfo()}</b>
			        <p>{printMatchId()}</p>

		        </div>
         </motion.div>
         </div>

        

        <br/><br/><br/><br/>
        <div className={(isFighting ? 'hidden' : '')}>
        	<br/>
        	</div>
        	<div id="clip_width" className={(gameState < 1 && !endOfFightAnimation) ? 'move_question_mark_up' : 'move_duel_left_back'}>
        	<div id="left_fighter_holder" >
        	<div className={isLoadingLeftFighter ? '' : 'hidden'}>
        		<div id="rotatingSkelly" className={!isLoadingLeftFighter ? '' : 'rotate'}></div>
        		</div>

        		

        		<motion.div
				      animate={{
				        scale: lfScale,
				        skewX: lfSkew,
				        x: lfX,
				        y: lfY,

				      }}
				      transition={{ repeat: lfRepeat,  repeatType: "reverse", delay: 0.25, duration: lfDuration }}
				    >
				   
				    
				      <div className={"fighterBox "+((!isLTombstone) ? 'inline-block' : 'hidden')}>
				      	{leftNameAndLevel()}<br/>
	        			{renderLeftFighterImg()}

	        			
	        		</div>


				    </motion.div>


				    <div id="lTombMask" className={(isLTombstone ? 'inline-block' : 'hidden')}>
	        			{leftNameAndLevel()}<br/>
					    <motion.div
						      animate={{
						        height: lfTombHeight,
						        x: lfTombX,
						        y: lfTombY,

						      }}
						      transition={{ repeat: lfTombRepeat,  repeatType: "reverse", delay: 0.25, duration: lfTombDuration }}
						    >
		        			<img className={'fight_img tombstones '+(isLTombstone ? '' : 'hidden')}  alt="Left Fighter"  src='/img/dead.png' />
	        		</motion.div>
	        		
	        		</div>



        		
        	</div>

        		

        		<div id={"offensive_spell"} className={((!removeWeapon) ? 'inline-block' : 'hidden')}>

        		<div className={'fighter_queue '+((!removeWeapon && queuedFighters.length > 0 && gameState<2) ? 'inline-block' : 'hidden')+' hide_if_mobile'}>
        		  <p>Queued Duellers:</p>
			        {queuedFighters.map((qedF, index) => (
			        	<p key={index}><a href={"/token/"+qedF[0]} target="_blank">{qedF[1]}</a></p>
							))}
			      </div>

			        <img src={'/img/gif/'+offensiveSpell} className={"offensive_gif offensive_top_offset_"+offensiveSpell.replace('_forever.gif', '')} />
			      </div>

	        	<div id="right_fighter_holder" className={(rightFighter ? 'inline-block' : 'hidden')}>
	        		
	        	<motion.div
				      animate={{
				        scale: rfScale,
				        skewX: rfSkew,
				        x: rfX,
				        y: rfY,

				      }}
				      transition={{ repeat: rfRepeat,  repeatType: "reverse", delay: 0.25, duration: rfDuration }}
				    >
	        		<div className={"fighterBox "+((rightFighter && !isRTombstone) ? 'inline-block' : 'hidden')}>
	        			{rightNameAndLevel()}<br/>
	        			{renderRightFighterImg()}
	        		</div>




	        	</motion.div>

		        	<div id="rTombMask" className={(isRTombstone ? 'inline-block' : 'hidden')}>
		        	{rightNameAndLevel()}<br/>
		        		
		        	<motion.div
						      animate={{
						        scaleY: rfTombHeight,
						        x: rfTombX,
						        y: rfTombY,

						      }}
						      transition={{ repeat: rfTombRepeat,  repeatType: "reverse", delay: 0.25, duration: rfTombDuration }}
						    >
		        			<img className={'fight_img tombstones '+(isRTombstone ? '' : 'hidden')}  alt="Right Fighter" src='/img/dead.png' />
		        		</motion.div>
		        		
	        		</div>
	        	</div>
	        	</div>


        	<br/><br/>
        	
        	<div >
	        	<br/>
	        	<div className={"duel_button "+(isFighting ? 'hidden' : '')}>
	        	<ul className="button_list">
	        		{renderSteps()}
	        	</ul>
	        	<br/><br/>
	        	</div>
        	</div>

        	

        		

        	<div className="underfight_text">
        	{underText()}
        	</div>

        	<div className={(!isFighting ? 'hidden' : '')+' '+(lastMatchInfo.lfId ? 'hidden' : '')}>
        	<br/><br/>
        		<Button className="duel_reset" size="lg" variant="primary" onClick={() => resetFightChangeFighter()}><b><i>QUEUE UP ANOTHER?</i></b></Button>
	       		<br/><br/><br/>
        	</div>


        	<motion.div
						      animate={{
						        scale: rewardLinesScale,
						      }}
						      transition={{ repeat: false,  repeatType: "reverse", delay: 0.25, duration: rewardLinesDuration }}
						    >

	        	<div className={"duel_subtitle "+((lastMatchInfo.lfId && !matchIdFromURL) ? '' : 'hidden')}>
	        		<br/>
	        		{showBar()}
	        		<br/>
			        <b>{printRewards()}</b>
			        <b>{printDeathToken()}</b>
			        <br/>
			        <Button className="duel_reset" size="lg" variant="primary" onClick={() => resetDepends()}><b><i>{resetDependsText()}</i></b></Button>
		       		<br/><br/><br/><br/>
		        </div>

		      </motion.div>
	        
        </div>

        <p><span className="white_link"><a href="/about_duels">LEARN MORE ABOUT DUELS</a></span></p><br/><br/>

       </div>
		</div>

	</>);

}

export default Duel;