import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";

function Browse(props) {

	let { num, ob, o, q } = useParams();

	if(!ob) {
		ob='id';
	}
	if(!o) {
		o='desc';
	}
	// if(num=='query') {
	// 	num=15;
	// }
	if(!num) {
		num = 20;
	}
	if(num>100) {
		num=100;
	}


	document.title = subs.properSiteName+": Browse";

	if(vars.comingSoonSiteMode) { return(<></>); }

	return (<>
        <div className="white_page">
            <Grid title="All" name="browse" num={num} orderBy={ob} order={o} query={q} />
		</div>
    </>);

}

export default Browse;