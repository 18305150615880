import React, {useState, useEffect} from 'react';
import GridFantum from './GridFantum';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { rollName, rollNum, rScore, getObjFromJSONUrl } from './../helpers.js'

import * as colors from './../colors.js';
import 'react-responsive-modal/styles.css';
import TextField from "@material-ui/core/TextField";
import { StylesProvider } from "@material-ui/core/styles";
import "./../styles.css";

import { ReactSession } from 'react-client-session';


const fetch = require('node-fetch');


var gettingGrid=false;

var receivedContent = 0;
var lastConnectedAccount=false;

var currPage = 1;

//

var orderBy;
var order;

var setQueryBasedOnUrl=false;


//var receivedAPIInfo=false;

var prevEnteredSearch='';

function Grid(props) {

	ReactSession.setStoreType("localStorage");

	orderBy = props.orderBy;
	order = props.order;



	const [pageTitle, setPageTitle] = useState(props.title);

	const [gridCount, setGridCount] = useState(0);

	const [render, setRender] = useState(0);
	var [gridTokens, setGridTokens] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [enteredSearch, setEnteredSearch] = useState(prevEnteredSearch);



	function load() {




		if(gettingGrid) {
			return;
		}
		if(props.name=='wallet' && !EVM.connectedAccount) {
			return;
		}

		console.log('load();');


		gettingGrid = true;

		setIsLoading(true);
		
			

		(async () => {

			console.log(props.name);

			//getTotalFantums();

			var addr = '';
			if(props.name=='wallet') {
				addr = EVM.connectedAccount.toString();
			}
			var forSale='';
			if(props.name=='market') {
				forSale=1;
			}
			if(orderBy=='price' && props.name == 'browse') {
				setPageTitle('For Sale');
			}
			if(orderBy!='price' && props.name == 'browse') {
				setPageTitle('All');
			}
			if(orderBy=='price' && props.name == 'wallet') {
				setPageTitle('My For Sale');
			}
			if(orderBy!='price' && props.name == 'wallet') {
				setPageTitle('My');
			}

			var isGYString = '';
			if(props.name=='graveyard') {
				isGYString = '&gy=1';
			}

			// if(addr==false || !addr==false) {
			// 	addr='';
			// }

			var qThis='';

			if(!enteredSearch) {
				setEnteredSearch(prevEnteredSearch);
			}

			console.log('enteredSearch: '+enteredSearch);
			var qThis = enteredSearch;

			if(props.query && !setQueryBasedOnUrl) {
				setQueryBasedOnUrl=true;
				
				if(props.query.length==1 && isNaN(props.query)) {
			  	alert('Search string is too short, please make it longer!');
			  } else {

				qThis = props.query;
				setEnteredSearch(qThis);
			  }
			}
			if(!qThis) {
				qThis=prevEnteredSearch;
			}

			prevEnteredSearch=qThis;
			console.log('qThis: '+qThis);
			console.log('address: '+addr);


			var tokensObj;
			if(ReactSession.get("subsite")=='rarity') {

			}
			tokensObj = await getObjFromJSONUrl(
				subs.apis[subs.publicApiId].url+'tokens/page='+currPage+'&num='+props.num+'&orderBy='+orderBy+'&order='+order+'&addr='+addr+'&forSale='+forSale.toString()+isGYString+'&q='+encodeURIComponent(qThis)
			);



			if(orderBy=='price') {
				forSale=1;
			}

			var addr='';
			if(props.name=='wallet') {
				addr=EVM.connectedAccount.toString();
			}



	    	var tokens = [];
			//setGridTokens(tokens);

	    	for(var i=0;i<tokensObj[0].length;i++) {
	    		var tokenObj = tokensObj[0][i];
	    		var tokenURI = subs.apis[subs.apiId].url+'token/'+tokenObj.id;
	    		const newTokens = tokens.concat({
			      id: tokenObj.id, 
			      hash: tokenObj.hash, 
			      owner: tokenObj.owner,
			      former_owner: tokenObj.former_owner,
			      name: tokenObj.name,
			      tokenURI: tokenURI,
			      image: tokenObj.image,
			      image32: tokenObj.image32,
			      rarity: tokenObj.rarity,
			      price: tokenObj.price,
			      dead: tokenObj.dead,
			      level: tokenObj.level,
			      replay: tokenObj.replay,
			      summonerClass: tokenObj.summonerClass,
			      totalFantums: parseInt(tokenObj.totalFantums)
			    }); 
			    tokens=newTokens;
	    	}


	    	setGridCount(tokensObj[1]);

	    	console.log('Setting grid tokens... '+tokens.length);

	    	receivedContent=1;
			setGridTokens(tokens);
			setRender(render+1);


			gettingGrid = false;
			setIsLoading(false);
	    	
			

    	})();

		

	}

	useEffect(() => {

		setInterval(function() {

			if(EVM.connectedAccount != lastConnectedAccount) {
				receivedContent=0;
				load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(receivedContent==0) {
				load();
			}

			// if(vars.apiInfo && !receivedAPIInfo) {
			// 	receivedAPIInfo=true;
			// 	//load();
			// }

			// if(gridTokens.length > 0) {
			// 	setRender(render+1);
			// }

		}, 200);

	}, []);

	function nextPage() {
		var numPages = Math.floor(gridCount / props.num);

		if(gridCount % props.num != 0) {
			numPages = numPages +1;
		}

		if(currPage <= numPages-1) {
			currPage = currPage+1;
			receivedContent=0;

			setIsLoading(true);
			setGridTokens([]);
		}
	}
	function prevPage() {
		if(currPage > 1) {
			currPage = currPage-1;
			receivedContent=0;
			setIsLoading(true);
			setGridTokens([]);
		}
	}
	function gotoNumPages() {
		var numPages = Math.floor(gridCount / props.num);

		if(gridCount % props.num != 0) {
			numPages = numPages +1;
		}
		//setCurrentPage(numPages);
		currPage = numPages;
		receivedContent=0;
		setIsLoading(true);
		setGridTokens([]);
	}

	function pagination() {

		if(enteredSearch != '') {
			//console.log('Not adding pagination...');
			//return;
		}

		var numPages = Math.floor(gridCount / props.num);

		if(gridCount % props.num != 0) {
			numPages = numPages +1;
		}
		// console.log('currPage: '+currPage);
		// console.log('numPages: '+numPages);
		// console.log('gridCount: '+gridCount);
		if(gridCount > 0) {
		
			if(currPage == numPages && currPage > 1) {
				return (<><span className="pagination"><a onClick={prevPage}>{'<<'} Previous</a> - Page {currPage} of <a onClick={gotoNumPages}>{numPages}</a></span></>);
			} else if(currPage == 1 && currPage < numPages) {
				return (<><span className="pagination">Page {currPage} of <a onClick={gotoNumPages}>{numPages}</a> - <a onClick={nextPage}>Next {'>>'}</a></span></>);
			} else if(currPage == numPages && currPage == 1) {
				return (<><span className="pagination">Page {currPage} of <a onClick={gotoNumPages}>{numPages}</a></span></>);
			} else {
				return (<><span className="pagination"><a onClick={prevPage}>{'<<'} Previous</a> - Page {currPage} of <a onClick={gotoNumPages}>{numPages}</a> - <a onClick={nextPage}>Next {'>>'}</a></span></>);
			}
		}
	}

	function num_per_page(num) {


		window.open('/'+props.name+'/'+num+'/'+orderBy+'/'+order+'/'+enteredSearch, '_self');
		 

	}
	async function submitSearch(event) {

	  if(!EVM.fum_contract) {
	    return;
	  }
	  event.preventDefault();

	  if(enteredSearch.length==1 && isNaN(enteredSearch)) {
	  	alert('Search string is too short, please make it longer!');
	  } else {
	  	currPage=1;
	  	receivedContent=0;
	  	setGridTokens([]);
	  	console.log('Submitted search?');
	  	load();
	  }

	  

	}

	function price_low_to_high() { 
		//orderBy='price'; order='asc'; load();
		window.open('/'+props.name+'/'+props.num+'/price/asc/'+enteredSearch, '_self'); }
	function rank_low_to_high() { 
		//orderBy='rank'; order='asc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/rank/asc/'+enteredSearch, '_self'); }
	function roll_low_to_high() { 
		//orderBy='roll'; order='asc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/roll/asc/'+enteredSearch, '_self'); }
	function price_high_to_low() { 
		//orderBy='price'; order='desc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/price/desc/'+enteredSearch, '_self'); }
	function rank_high_to_low() { 
		//orderBy='rank'; order='desc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/rank/desc/'+enteredSearch, '_self'); }
	function roll_high_to_low() { 
		//orderBy='roll'; order='desc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/roll/desc/'+enteredSearch, '_self'); }
	function id_high_to_low() { 
		//orderBy='id'; order='asc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/id/asc/'+enteredSearch, '_self'); }
	function id_low_to_high() { 
		//orderBy='id'; order='desc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/id/desc/'+enteredSearch, '_self'); }
	function level_high_to_low() { 
		//orderBy='id'; order='asc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/level/desc/'+enteredSearch, '_self'); }
	function level_low_to_high() { 
		//orderBy='id'; order='desc'; load(); 
		window.open('/'+props.name+'/'+props.num+'/level/asc/'+enteredSearch, '_self'); }


	function noResult() {
		if(gridTokens.length <= 0) {
			return (<p>No Results.</p>);
		}
	}

	function resultsCount() {
		if(gridTokens.length > 0 && !isLoading) {
			return ( <><p> {gridTokens.length} results </p><br/><br/></> );
		} else {
			return (<></>);
		}
	}

	var seatW=128;
	var seatH=172;
	// if(props.seat_test=='yes') { 
	// 	seatW=128/3;
	// 	seatH=172/3;
	// }
		
	return (<>
    
        <div id="gridFantumHolder" render={render}>

        	<div className="gridHeader">
	        	<div className={"gridHeaderLeft "+(props.name=='graveyard' ? 'hidden' : 'inline-block')}>
	        		<div className="grid_title_line_one"><b>{pageTitle}</b></div><div className="grid_title_line_two">Fantums</div>
	        	</div>
	        	<div className={"gridHeaderRight "+(props.name=='graveyard' ? 'hidden' : 'inline-block')}>
	           
		            <StylesProvider injectFirst>
		            <form onSubmit={submitSearch}>
			          <TextField inputProps={{style: { textAlign: "right", fontSize: '16px' }}} className="gridSearch" placeholder="eg. John Smith, #1234" value={enteredSearch} label="" onChange={(e) => { setEnteredSearch(e.target.value); prevEnteredSearch=e.target.value;  }} />
			        	 <Button type="Submit" size="sm" variant="primary">Go</Button>
			        

				        

						<DropdownButton variant="light" className="dropdown_rpp" size="sm" id="dropdown-basic-button" title={props.num}>
						  <Dropdown.Item onClick={() => {num_per_page(10)}}>10 per Page</Dropdown.Item>
						  <Dropdown.Item onClick={() => {num_per_page(20)}}>20 per Page</Dropdown.Item>
						  <Dropdown.Item onClick={() => {num_per_page(30)}}>30 per Page</Dropdown.Item>
						  <Dropdown.Item onClick={() => {num_per_page(50)}}>50 per Page</Dropdown.Item>
						  <Dropdown.Item onClick={() => {num_per_page(100)}}>100 per Page</Dropdown.Item>
						</DropdownButton>

						<DropdownButton variant="light" className="dropdown_sort" size="sm" id="dropdown-basic-button" title="☰">
						<Dropdown.Item onClick={id_low_to_high}>Mint # ▼</Dropdown.Item>
						<Dropdown.Item onClick={id_high_to_low}>Mint # ▲</Dropdown.Item>
							
						<Dropdown.Item onClick={price_high_to_low}>Price ▼</Dropdown.Item>
						<Dropdown.Item onClick={price_low_to_high}>Price ▲</Dropdown.Item>
						  
						  <div className={!subs.subsite || subs.subsite=='rarity'? '':'hidden'}>

							  

							  <Dropdown.Item onClick={rank_high_to_low}>Rank ▼</Dropdown.Item>
							  <Dropdown.Item onClick={rank_low_to_high}>Rank ▲</Dropdown.Item>
							  
							  <Dropdown.Item onClick={roll_high_to_low}>Rarity ▼</Dropdown.Item>
							  <Dropdown.Item onClick={roll_low_to_high}>Rarity ▲</Dropdown.Item>


						  	<Dropdown.Item onClick={level_high_to_low}>Level ▼</Dropdown.Item>
						  	<Dropdown.Item onClick={level_low_to_high}>Level ▲</Dropdown.Item>

						  </div>
						  <div className={!subs.subsite || subs.subsite=='rarity'? 'hidden':''}>


							  <Dropdown.Item onClick={rank_high_to_low}>Rarity ▼</Dropdown.Item>
							  <Dropdown.Item onClick={rank_low_to_high}>Rarity ▲</Dropdown.Item>


						  </div>

						  
						</DropdownButton>

					</form>
			        </StylesProvider>

		        </div>
	        </div>

			<div className={isLoading ? '' : 'hidden'}>
            <div id="rotatingSkelly" className={!isLoading ? '' : 'rotate'}></div>
            </div>
				<div id="fantumHolderBrowse" className={isLoading ? 'hidden' : ''}>


				{gridTokens.map((token, index) => (

					<GridFantum key={index} token={token} width={seatW} height={seatH} pageName={props.name} />
				))}

				{noResult()}
				</div>
			

			<div className={"paginationDiv " + (isLoading ? 'hidden' : '')}>
				<p> {pagination()} </p><br/>{resultsCount()}<br/>

			</div>

			<div className="clear"></div>
		</div>
	
    </>);

}

export default Grid;