import React, {useState, useEffect} from 'react';
import TradeRow from './TradeRow';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rollName, rollNum, rScore, getObjFromJSONUrl } from './../helpers.js'
import {
  useParams
} from "react-router-dom";

var receivedContent = 0;
var lastConnectedAccount=false;

var loadingEvents=false;

var haveFighters = [];

var pageNum=1;

var totalEvents=0;

function TradeHistory() {

	let { num } = useParams();
	if(num) {
		pageNum = num;
	}



	var [events, setEvents] = useState([]);

	var [eventDetails, setEventDetails] = useState([]);

	const [render, setRender] = useState(0);
	
	const [isLoading, setIsLoading] = useState(true);

	const [haveTheseFighters, setHaveTheseFighters] = useState([]);

	async function getFighter(tokenId) {
		console.log('Getting fighter... '+tokenId);
		const response = await fetch(subs.apis[subs.apiId].url+'token/'+tokenId);
	    var fighter = await response.json();
	   	return fighter;
	}


	function load() {



		if(!EVM.connectedAccount) {
			return;
		}
		if(loadingEvents) {
			return;
		}

		console.log('load()?');

		setIsLoading(true);
		loadingEvents=true;

		(async () => {
			var eventsURL = subs.apis[subs.apiId].url+'account_events/'+EVM.connectedAccount.toString();
	        const eventsURLResp = await fetch(eventsURL);
	        var eventsObj = await eventsURLResp.json();
	        //console.log(eventsObj.length);



			if(eventsObj.length > 0) {

				totalEvents = eventsObj.length;
				eventsObj = eventsObj.slice((pageNum-1)*10, (pageNum-1)*10+10);



				 if(EVM.ftc_contract && EVM.connectedAccount) {
					// Get prices and store in array..

					var someEventDetails = [];
			    	for(var i=0;i<eventsObj.length;i++) {
			    		
			    		var event = eventsObj[i];

			    		//console.log(event);

			    		if(event) {
			    			var data = event.data.substr(2, event.data.length-2);

    						var d1 = data.substr(64*0, 64);
    						var tokenId = parseInt(d1, 16);

    						var haveThisFighter=false;
								for(var j=0;j<haveFighters.length;j++) {
									if(tokenId==parseInt(haveFighters[j].id)) {
										haveThisFighter=true;
									}
								}
								if(!haveThisFighter) {
									var f = await getFighter(tokenId);
									haveFighters.push(f);
								}


				    		//var fantumName = await EVM.fum_contract.methods.nameOf(tokenId).call();


				    		someEventDetails[i] = tokenId;
				    		// const newEventDetails = someEventDetails.concat({
						    //   id: i, 
						    //   name: fantumName
						    // }); 
						    // someEventDetails=newEventDetails;
			    		}



			    		  
			    	}

			    	setEventDetails(someEventDetails);
			    	

			    }

			    setEvents(eventsObj);

		        //console.log(eventsObj);
		    	
				
				setHaveTheseFighters(haveFighters);
				setRender(render+1);
				
			}
			receivedContent=1;
			setIsLoading(false);
			loadingEvents=false;
		})();
	}


	useEffect(() => {

		setInterval(function() {



			if(EVM.connectedAccount != lastConnectedAccount) {
				receivedContent=0;
				load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(receivedContent==0) {
				load();
			}

			// if(gridTokens.length > 0) {
			// 	setRender(render+1);
			// }

		}, 1000);

	}, []);


	function fighterForId(tokenId) {
		for(var j=0;j<haveTheseFighters.length;j++) {
			if(tokenId==haveTheseFighters[j].id) {
				return haveTheseFighters[j];
			}
		}
		return false;
	}

	function prevPage() {

	var totalPages = Math.ceil(totalEvents / 10);
	var hasNext=false;
	if(events.length > 0 && totalPages > pageNum && !isLoading) {
		hasNext=true;
	}


	if(pageNum>1 && !isLoading && hasNext) {
		return (<><a href={"/trade_history/"+(parseInt(pageNum)-1)}>Previous</a> · </>);
	} else if(pageNum>1 && !isLoading) {
		return (<><a href={"/trade_history/"+(parseInt(pageNum)-1)}>Previous</a></>);
	}

	return (<></>);
}

function nextPage() {

	var totalPages = Math.ceil(totalEvents / 10);
	//console.log('totalPages: '+totalPages);

	if(events.length > 0 && totalPages > pageNum && !isLoading) {
		return (<><a href={"/trade_history/"+(parseInt(pageNum)+1)}>Next</a></>);
	}
}

if(!window.ethereum) {
	return (<><div id="operaHolderStreets"><br/><br/><br/><p>MetaMask not available.</p></div></>);
}

function noEvents() {
	if(events.length<=0 && !isLoading) {
		return (<><p>No trade history.</p></>);
	}
}
	
return ( 
<>
         <div className="white_page">


				<h3><b>Trade History</b></h3>
				<div className={(isLoading ? '' : 'hidden')}>
					<div id="rotatingSkelly" className='rotate'></div>
				</div>
				<br/>


				{events.map((event, index) => (
					<TradeRow key={index} event={event} details={eventDetails[index]} fighter={fighterForId(eventDetails[index])} index={index} />
				))}

				{noEvents()}
				<br/>
				<span className="trade_history_page_controls">{prevPage()} {nextPage()}</span>
				<br/><br/>
		</div>



        </>


);

}

export default TradeHistory;