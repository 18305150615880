import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";
import * as EVM from './../evm.js';
import Button from 'react-bootstrap/Button';
import { numberWithCommas, modal, callMethod, sleep, tryCallUntilSuccessful } from './../helpers.js';

var md5 = require('md5');


var lastConnectedAccount;


function FIActivateButton(props) {

	var [hasUse, setHasUse] = useState(false);
	var [hasAUse, setHasAUse] = useState(false);
	var [render, setRender] = useState(0);


	var [isApproving, setApproving] = useState(false);
	var [isActivating, setActivating] = useState(false);


	var [isApprovedForAll, setIsApprovedForAll] = useState(false);


	async function doesIdHaveUse() {

		if(!EVM.fia_contract) {
			return;
		}

		if(!props.tid) {
			return;
		}
		if(!EVM.connectedAccount) {
			return;
		}

		let balanceOf = await tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), props.tid));
    console.log('balanceOf ['+props.tid+']: '+balanceOf);


		console.log('Getting use for item: '+props.tid);
  	var has = await tryCallUntilSuccessful(EVM.fia_contract.methods.getUseForItem(props.tid));

  	if(has==0) {
  		has=false; 
  	}
  	console.log('hasUse: '+has);
  	setHasUse(has);

  	if(has) {
  		var theUse = await tryCallUntilSuccessful(EVM.fia_contract.methods.getUse(has));
  		setHasAUse(theUse);
  	}
  	


  	if(has) {
  		var someIsApprovedForAll = await tryCallUntilSuccessful(EVM.fi_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FIA_ADD[0]));
  		console.log('IsApprovedForAll? '+someIsApprovedForAll);
  		setIsApprovedForAll(someIsApprovedForAll);
  	}


  	props.updateState();
	}



	async function approve() {
		if(!EVM.fia_contract) {
			return;
		}

		setApproving(true);
		await callMethod(EVM.fi_contract.methods.setApprovalForAll(EVM.FIA_ADD[0], true), 0, EVM.connectedAccount,  600000, async function(err, res) {
    	

	    if(err) {
		  	setRender(render+1); setRender(render+1);
		    console.log(err.message);
  			setApproving(false);

	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var isApprovedForAllAfter = await tryCallUntilSuccessful(EVM.fi_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FIA_ADD[0]));
	    		//console.log('Waiting... '+isApprovedForAllBefore+' == '+isApprovedForAllAfter);

	    	} while(isApprovedForAllAfter == false);

	    	setApproving(false);
	   
	      console.log('Done! ');

	      doesIdHaveUse();
	   
	    }
	  });
	}


	async function activate() {
		if(!EVM.fia_contract) {
			return;
		}


		var activationsBefore = await tryCallUntilSuccessful(EVM.fia_contract.methods.activations(EVM.connectedAccount.toString(), hasAUse.uniqueId));
		console.log('activationsBefore: '+activationsBefore);

		setActivating(true);
		await callMethod(EVM.fia_contract.methods.useItem(hasUse), 0, EVM.connectedAccount,  600000, async function(err, res) {
    	
  

	    if(err) {
		  	setRender(render+1); setRender(render+1);
		    console.log(err.message);
		    setActivating(false);
	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var activationsAfter = await tryCallUntilSuccessful(EVM.fia_contract.methods.activations(EVM.connectedAccount.toString(), hasAUse.uniqueId));
					console.log('Waiting... activationsAfter: '+activationsAfter);

	    	} while(activationsAfter == activationsBefore);
	    	setActivating(false);

	   
	      console.log('Done! ');

	      doesIdHaveUse();
	   
	    }
	  });
	}

	useEffect(() => {


		doesIdHaveUse();
		

 	}, []);


	if(!props.tid || !hasUse) {
		console.log('no use, or no tid? '+props.tid+' '+hasUse);
		return (<></>);
	}



	if(isApprovedForAll) {

		return (<>
			<Button size="lg" variant="primary" onClick={() => { activate(); }}>{isActivating?'ACTIVATING ITEM...':'ACTIVATE ITEM'}</Button>
				</>
		);

	} else {

		return (<>
			<Button size="lg" variant="primary" onClick={() => { approve(); }}>{isApproving?'APPROVING...':'APPROVE TO ACTIVATE'}</Button>
				</>
		);

	}
	

	

}

export default FIActivateButton;