import React, {useState, useEffect} from 'react';
import FantumGen from './FantumGen';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";
import { rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'
import IconTint from 'react-icon-tint';


var receivedContent=false;
var loading=false;

function SnapMatch(props) {

    let { id } = useParams();

    var matchId = id.replace(":", "");

    //console.log('matchId: '+matchId);

    const [losingFighter, setLosingFighter] = useState(false);
    const [winningFighter, setWinningFighter] = useState(false);
    const [matchIsATie, setMatchIsATie] = useState(false);
    // const [matchText, setMatchText] = useState('');
    // const [matchText2, setMatchText2] = useState('');

    const [theMatch, setTheMatch] = useState(false);

    async function getFighter(tokenId) {
        if(tokenId <= 0 || !tokenId) {
            return;
        }
        const response = await fetch(subs.apis[subs.apiId].url+'token/'+tokenId);
        var fighter = await response.json();
        return fighter;
    }


    async function load() {

        if(!matchId) {
            return;
        }

        if(receivedContent) {
            return;
        }
        if(loading) {
            return;
        }

        loading=true;

        const matchInfo = await getObjFromJSONUrl(
            subs.apis[subs.apiId].url+'match/'+matchId
        );
        console.log(matchInfo);
        var lFighter = await getFighter(matchInfo.lfId);
        var rFighter = await getFighter(matchInfo.rfId);


        console.log(lFighter);
        console.log(rFighter);
        

        if(matchInfo.result==0) {
            // setMatchText('HAS BEATEN');
            // setMatchText2('IN DUEL #');
            setLosingFighter(lFighter);
            setWinningFighter(rFighter);
        } else if(matchInfo.result==1) {
            // setMatchText('HAS BEATEN');
            // setMatchText2('IN DUEL #');
            setLosingFighter(rFighter);
            setWinningFighter(lFighter);
        } else if(matchInfo.result==2) {
            // setMatchText('HAS TIED');
            // setMatchText2('IN DUEL #');
            setMatchIsATie(true);
            setLosingFighter(lFighter);
            setWinningFighter(rFighter);
        } else if(matchInfo.result==3) {
            // setMatchText('HAS KILLED');
            // setMatchText2('IN DUEL #');
            setLosingFighter(lFighter);
            setWinningFighter(rFighter);
        } else if(matchInfo.result==4) {
            // setMatchText('HAS KILLED');
            // setMatchText2('IN DUEL #');
            setLosingFighter(rFighter);
            setWinningFighter(lFighter);
        }


        setTheMatch(matchInfo);


        // console.log('lFighter: '+lFighter);
        // console.log('rFighter: '+rFighter);
        receivedContent=true;

        loading=false;
    }


    useEffect(() => {
        
        setInterval(async function() {

             (async () => {
                if(!receivedContent) {
                    await load();
                }
            })();


        }, 200);
    }, []);

    function didLoserDie() {
        if(theMatch.result==3 || theMatch.result==4) {
            
            return(<><div  className="left_flip"><img src="/img/dead.png" width="160" height="160" /></div></>);
           // return(<><div className="redXSnapMatch"><img src="/img/red_x.png" width="160" height="160" /></div><div className="theFantumTintSnapMatch"><IconTint color="#0054a6" maxWidth={160} maxHeight={160} src={losingFighter.image} /></div><img src={losingFighter.image} width="160" height="160" /></>);
        } else {
            return(<><div className="redXSnapMatch"><img src="/img/red_x.png" width="160" height="160" /></div><FantumGen hash={losingFighter.hash} tid={losingFighter.id} size={160} /></>);
        }
        
    }

    if(losingFighter.hash && winningFighter.hash) {

        if(matchIsATie) {
            return (
            <>
                <div id="contains_match_snaps">
                    <div className="contains_match_snaps_inner">

                        <div className={"winner_snap_name_equals nudge_tie_all_up rarityColor_" + rollNum(winningFighter.hash.toString())+"_roll"}>
                            {winningFighter.name}
                            <div className={"winner_snap_level_box_equals"}>
                                {Math.floor(winningFighter.level)}
                            </div>
                        </div>

                        <div className={"loser_snap_name_equals nudge_tie_all_up rarityColor_" + rollNum(losingFighter.hash.toString())+"_roll"}>
                            {losingFighter.name}
                            <div className="loser_snap_level_box_equals">
                                {Math.floor(losingFighter.level)}
                            </div>
                        </div>

                {/*        <div className="equalsSnapMatch">
                            <img src="/img/equals_icon.png" width="60" height="60" />
                        </div>*/}
                        <div className="left_flip snap_loser nudge_loser_if_tie nudge_tie_all_up">
                            <FantumGen hash={losingFighter.hash} tid={losingFighter.id} size={160} />
                        </div>
                        <div className="snap_winner nudge_winner_if_tie nudge_tie_all_up">
                            <FantumGen hash={winningFighter.hash} tid={winningFighter.id} size={160} />
                        </div>

                    </div>
                </div>
                </>
            );
        } else {
            return (
            <>
                <div id="contains_match_snaps">
                    <div className="contains_match_snaps_inner">
                        <div className={"winner_snap_name rarityColor_" + rollNum(winningFighter.hash.toString())+"_roll"}>
                            {winningFighter.name}
                            <div className={"winner_snap_level_box"}>
                                {Math.floor(winningFighter.level)}
                            </div>
                        </div>

                        <div className={"loser_snap_name rarityColor_" + rollNum(losingFighter.hash.toString())+"_roll"}>
                            {losingFighter.name}
                            <div className="loser_snap_level_box">
                                {Math.floor(losingFighter.level)}
                            </div>
                        </div>
                        

                        <div  className="left_flip snap_loser">
                        {didLoserDie()}
                        </div>
                        
                        <div className="snap_winner">
                        <FantumGen hash={winningFighter.hash} tid={winningFighter.id} size={320} />
                        </div>

                    </div>
                </div>
                </>
            );
        }

        
    } else {
        return (
            <>

            </>
        );
    }


}
  
export default SnapMatch;