import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import IngredientIcon from './IngredientIcon.js';
import { numberWithCommas, modal, callMethod, sleep, tryCallUntilSuccessful } from './../helpers.js';
import {
  useParams
} from "react-router-dom";
var md5 = require('md5');




function BuffBar(props) {

	var [activations, setActivations] = useState([]);

	async function getActivationsForFantumId() {

		if(!EVM.fia_contract) {
			return;
		}

		if(!props.acc) {
			return;
		}

		//var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, props.address);
	  var totalUses = await tryCallUntilSuccessful(EVM.fia_contract.methods.totalUses());

	  var newActivations = [];

	  var doneFlags = 0;


	  for(var i=0;i<totalUses;i++) {



	  	tryCallUntilSuccessful(EVM.fia_contract.methods.uses(i)).then((aUse)=>{

	  		tryCallUntilSuccessful(EVM.fia_contract.methods.activations(props.acc, aUse.uniqueId)).then((activated_until) => {

		  		tryCallUntilSuccessful(EVM.fia_contract.methods.lastTokensActivated(props.acc, aUse.uniqueId)).then((last_token_id_activated)=>{
		  			if(activated_until >= Date.now()/1000 && aUse.itemId == last_token_id_activated) {
				  		console.log(aUse.id+' activated until: '+activated_until);

				  		newActivations.push({
				  			useId: aUse.id,
				  			itemId: aUse.itemId,
				  			lasts: aUse.lasts,
				  			uniqueId: aUse.uniqueId,
				  			activated_until: activated_until
				  		});
				  	} else {
				  		console.log(aUse.id+' not activated.');
				  	}
				  	doneFlags++;
		  		});

		  	});

	  	});

	  

	  	
	  }

	  do {
	  	await sleep(1000);
	  	console.log('Waiting for all uses to load... '+doneFlags);
	  } while(doneFlags < totalUses);


	  setActivations(newActivations);

	}

	useEffect(() => {

		//

		getActivationsForFantumId();

		



 	}, []);
	


  function actMinutesLeft(activated_until) {

  	var secondsLeft = activated_until - Date.now()/1000;

  	var minutesLeft = secondsLeft / 60;

  	var hoursLeft = secondsLeft / 60 / 60;

  	var daysLeft = secondsLeft / 60 / 60 / 24;


  	if(hoursLeft > 24) {
  		return Math.round(daysLeft).toString()+'d';
  	}

  	if(minutesLeft > 99) {
  		return Math.round(hoursLeft).toString()+'h';
  	}

  	return Math.round(minutesLeft).toString()+'m';

  }


  if(!EVM.isTester && subs.craftingPrivate) {
  	return (<></>);
  }


	return (
		<div style={{verticalAlign: 'middle', display: 'inline-block', marginTop: props.marginTop+'px', padding: '0px 0px'}}>
		
		{activations.map((activation, index) => (

    		<IngredientIcon
	    	key={'BB'+activation.itemId+EVM.FI_ADD[0]+props.acc+props.tkey}
	    	tkey={'BB'+activation.itemId+EVM.FI_ADD[0]+props.acc+props.tkey}
	    	tid={activation.itemId}
	    	address={EVM.FI_ADD[0]}
	    	size={props.iconSize}
	    	balance={actMinutesLeft(activation.activated_until)}
	    	pos={'br'}
	    	fontSize={24}

	    	/>
			
		))}

		</div>

		);

}

export default BuffBar;