import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';



function AboutDuels() {

	const [render, setRender] = useState(0);


	useEffect(() => {

		setTimeout(function() {
			setRender(true);
			

		}, 4000);
		setTimeout(function() {
			setRender(true);
			

		}, 2000);

	}, []);

	// if(EVM.duelsPrivate) {
	// 	return (<><h1><span style={{color: '#FFF'}}>Are you supposed to be here?</span></h1></>);
	// }
return ( 
<>
        <div className="appOperaDuelSplash">
            <div id="operaHolderDuelSplash">
            <div className="duels_splash_center_container">
	       	<span className="dueling_is_here">DUELING IS HERE</span><br/><br/><br/><br/>

	       	</div>
	       	<div className="center_an_img"><img src="/img/weaponized.png" height="160" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">WEAPONIZED FANTUMS</span><br/><br/>
	       	
	       	<h5>All Fantums with an item in their right hand (<i>now a weapon!</i>) can duel.</h5><br/>
	       	<h5>A Fantum’s dueling ability is determined by their rank, rarity, XP, their weapon, and the combination of attributes they have.</h5><br/>
	       	<h5>The only way to find your best duelist is to duel!</h5><br/><br/><br/>


	       	</div>
	       	<div className="center_an_img"><img src="/img/foo_icon.png" height="100" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">TO THE VICTOR GO THE SPOILS</span><br/><br/>
	       	
	       	<h5>Duelists must wager 150 <a href={"https://ftmscan.com/token/"+vars.fooContractAddress}>$FOO</a> to duel, and stand to gain at least 280 $FOO for winning. (Currently {vars.duelHousePercent}% fee, {vars.duelBurnPercent}% is burned.)</h5><br/><br/><br/>
	       		

	       	</div>
	       	<div className="center_an_img"><img src="/img/level_bar_sample.png" width="480" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">LEVEL UP</span><br/><br/>
	       	
	       	<h5>All Fantums have been assigned a level number (eg. "Level 1") which can be increased by winning duels and receiving experience points, "XP".</h5><br/><br/><br/>

	       	</div>
	       	<div className="center_an_img"><img src="/img/rip_only.png" height="100" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">THE GRAVEYARD</span><br/><br/>
	       	
	       	<h5>Be careful, because Fantums can die in battle! Dead Fantums are lost forever to the <a href="/graveyard">Graveyard</a>. (For real, be careful!)</h5><br/><br/><br/>

	       	</div>
	       	<div className="center_an_img"><img src="/img/rip_icon.png" height="100" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">LIFE AFTER DEATH</span><br/><br/>
	       	
	       	<h5>Although most Fantums are lost forever, some may be resurrected for the price of 20 Death Coins (<a href={"https://ftmscan.com/token/"+EVM.RIP_ADD[0]}>$RIP</a>).</h5><br/>
	       	<h5>Players who lose a Fantum in battle receive one Death Coin.</h5><br/><br/><br/>

	       	</div>
	       	<div className="center_an_img"><img src="/img/foo2_icon.png" height="100" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">BONUS <a href={"https://ftmscan.com/token/"+vars.fooContractAddress}>$FOO</a></span><br/><br/>
	       	
	       	<h5>Players receive additional <a href={"https://ftmscan.com/token/"+vars.fooContractAddress}>$FOO</a> bonuses for participating in matchmaking, for reaching higher skill levels, and for sending their opponents to the <a href="/graveyard">Graveyard</a>!</h5><br/>
	       	<br/><br/><br/>
			</div>


			
	       	<div className="center_an_img"><img src="/img/white_eyes_lantern.jpg" height="300" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">BLUE EYED FANTUMS</span><br/><br/>
	       	
	       	<h5>Fantums that have been resurrected become immune to death and possess blue eyes.</h5><br/><br/><br/>
			</div>

			{/*<h1>Stay tuned, DUELs launch soon!</h1><br/><br/><br/>
*/}

			<div className="center_an_img"><img src="/img/dueller_eyes.jpg" width="640" /><br/><br/></div>
			<div className="duels_splash_center_container">
			<span className="dueling_is_here">WHAT ARE YOU WAITING FOR?</span><br/><br/>
			<Button size="lg" variant="primary" className="start_dueling_now" onClick={() => window.open('/duel', '_self')}><b><i>START DUELING NOW!</i></b></Button>
			

			<br/><br/><br/>
		</div>
		</div>
	</div>

        </>


);

}

export default AboutDuels;