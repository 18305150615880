import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");
var subsite =false;
if(ReactSession.get("subsite")=='rarity') {
	subsite='rarity';
}
if(ReactSession.get("subsite")=='gothums') {
	subsite='gothums';
}

var craftingPrivate=false;

var siteIsPrivate=false;
if(subsite=='gothums') {
	siteIsPrivate=true;
}

var instantFindEnabled = true;
if(!subsite) {
	instantFindEnabled = true;
}

var showAttributeGuide = true;
if(!subsite) {
	showAttributeGuide = true;
}

// APIs
var apiId;
var publicApiId;
var apis;
if(!subsite) {
	apiId = 1;
	publicApiId = 2;
	apis = [
	  {
	    id: 0,
	    name: 'Development API',
	    url: 'http://site.fantums.com:9000/'
	  },
	  {
	    id: 1,
	    name: 'Production API',
	    url: 'https://site.fantums.com/'
	  },
	  {
	    id: 2,
	    name: 'Public API',
	    url: 'https://api.fantums.com/'
	  }
	];
} else if(subsite=='gothums') {
	apiId = 1;
	publicApiId = 2;
	apis = [
	  {
	    id: 0,
	    name: 'Development API',
	    url: 'http://site.gothums.com:9000/'
	  },
	  {
	    id: 1,
	    name: 'Production API',
	    url: 'https://site.gothums.com/'
	  },
	  {
	    id: 2,
	    name: 'Public API',
	    url: 'https://api.gothums.com/'
	  }
	];
} else if(subsite=='rarity') {
	apiId = 1;
	publicApiId = 2;
	apis = [
	  {
	    id: 0,
	    name: 'Development API',
	    url: 'http://site.fantums.com:9000/'
	  },
	  {
	    id: 1,
	    name: 'Production API',
	    url: 'https://site.fantums.com/'
	  },
	  {
	    id: 2,
	    name: 'Public API',
	    url: 'https://api.fantums.com/'
	  }
	];
}



var pages;
if(subsite=='gothums') {

  pages = [
    {
      id: 1,
      title: "Mint",
      url: "/mint",
      soon: false,
      connected: false
    },
    {
      id: 2,
      title: "Browse",
     url: "/browse",
      soon: false,
      connected: false
    },
    {
      id: 3,
      title: "Market",
      url: "/market",
      soon: false,
      connected: false
    },
    {
      id: 4,
      title: "ToT",
      url: "/trickortreat",
      soon: false,
      connected: true
    }
  ];

} else if(subsite=='rarity') {

  pages = [
    {
      id: 1,
      title: "Browse",
     url: "/browse",
      soon: false,
      connected: false
    },
    {
      id: 2,
      title: "Market",
      url: "/market",
      soon: false,
      connected: false
    },
    {
      id: 3,
      title: "Wallet",
      url: "/wallet",
      soon: false,
      connected: true
    }
  ];

} else {
  
  pages = [
    // {
    //   id: 1,
    //   title: "Mint",
    //   url: "/mint",
    //   soon: false,
    //   connected: false
    // },
    {
      id: 1,
      title: "Browse",
     url: "/browse",
      soon: false,
      connected: false
    },
    {
      id: 2,
      title: "Market",
      url: "/market",
      soon: false,
      connected: false
    },
    {
      id: 3,
      title: "Duel",
      url: "/duel",
      soon: false,
      connected: false
    },
    {
      id: 4,
      title: "Inventory",
      url: "/inventory",
      soon: false,
      connected: true,
      testerOnly: false
    }
  ];
}

var buttonColors;
if(subsite=='rarity') {

  buttonColors = [
    '#0096ff',
    '#4b697e'
  ];

} else if(subsite=='gothums') {

  buttonColors = [
    '#f78d15',
    '#e1861e'
  ];

} else {
  
  buttonColors = [
    '#910b0b',
    '#7b3e23'
  ];
}


var layerData;
var layerDataNames;
var layerDataGendered;

if(subsite=='gothums') {

	layerData = {
		"bg": 32,
		"base": 1,
		"skin": 8,
	  "eyes": 8,
	  "makeup": 12,
	  
	  "mouth": 13,
	  "mask": 23,
	  "shirt": 26,
	  "hair": 22,
	  "hat": 32,
	  "rh": 32,
	  "pet": 32
	};

	layerDataNames = [
	  "Background",
	  "Base",
	  "Skin",
	  "Eyes",
	  "Makeup",
	  
	  "Mouth",
	  "Mask",
	  "Shirt",
	  "Hair", 
	 	"Hat",
	 	"Right Hand",
	 	"Pet"
	];

	layerDataGendered = {
	  "bg": false,
	  "base": false,
	  "skin": false,
	  "eyes": false,
	  "makeup": true,
	  "mask": false,
	  "mouth": true,
	  "shirt": true,
	  "hair": true,
	  "hat": true,
	  "rh": true,
	  "pet": false
	};

} else if(subsite=='rarity') {


	layerData = {
	  "bg": 8,
	  "base": 4,
	  "eyes": 8,
	  "shirt": 8,
	  "cape": 6,
	  
	  
	  "neck": 5,
	  "mole": 5,
	  "mask": 7,
	  "hair": 5,
	  
	  "mouth": 5,
	  "hat": 9,
	  "lh": 1,
	  "rh": 7,
	  "dog": 16
	  
	};

	layerDataNames = [
	  "Background",
	  "Base",
	  "Eyes",
	  "Shirt",
	  "Cape",
	  
	  "Neck",
	  "Mole",
	  "Mask",
	  "Hair",
	  "Mouth",
	  "Hat",
	  "Left Hand",
	  "Right Hand",
	  "Pet"
	  
	];

	layerDataGendered = {
	  "bg": false,
	  "base": false,
	  "shirt": true,
	  "cape": true,
	  "eyes": false,
	  "neck": true,
	  "mole": true,
	  "mask": false,
	  "hair": true,
	  "mouth": true,
	  "hat": true,
	  "lh": false,
	  "rh": true,
	  "dog": false
	};

} else {

	layerData = {
	  "bg": 8,
	  "base": 4,
	  "shirt": 8,
	  "cape": 6,
	  "eyes": 8,
	  "neck": 5,
	  "mole": 5,
	  "mask": 7,
	  "hair": 5,
	  "mouth": 5,
	  "hat": 9,
	  "lh": 1,
	  "rh": 7,
	  "dog": 16
	};

	layerDataNames = [
	  "Background",
	  "Base",
	  "Shirt",
	  "Cape",
	  "Eyes",
	  "Neck",
	  "Mole",
	  "Mask",
	  "Hair",
	  "Mouth",
	  "Hat",
	  "Left Hand",
	  "Right Hand",
	  "Pet"
	  
	];
	layerDataGendered = {
	  "bg": false,
	  "base": false,
	  "shirt": true,
	  "cape": true,
	  "eyes": false,
	  "neck": true,
	  "mole": true,
	  "mask": true,
	  "hair": true,
	  "mouth": true,
	  "hat": true,
	  "lh": false,
	  "rh": true,
	  "dog": false
	};


}



var salt='';
if(subsite=='gothums') {
	salt='salty';
}

// CHECK BEFORE DEPLOYING
var difficultyBytes=30;
var difficultyZeros=0;
if(subsite=='gothums') {
	difficultyZeros=4;
}

var properSiteName='Fantums';
if(subsite=='gothums') {
	properSiteName='Gothums';
}
if(subsite=='rarity') {
	properSiteName='Fantums: Rarity Adventure';
}

var nameOfFantums='FANTUMS';
if(subsite=='gothums') {
	nameOfFantums='GOTHUMS';
}

var singularNameOfFantums='FANTUM';
if(subsite=='gothums') {
	singularNameOfFantums='GOTHUM';
}

var singularLowercaseNameOfFantums='Fantum';
if(subsite=='gothums') {
	singularLowercaseNameOfFantums='Gothum';
}


export {
	craftingPrivate,

	singularLowercaseNameOfFantums,
	singularNameOfFantums,
	difficultyZeros,
	salt,
	nameOfFantums,
	properSiteName,
	apiId,
	publicApiId,
	apis,
	showAttributeGuide,
	instantFindEnabled,
	siteIsPrivate,
	layerData,
	layerDataNames,
	layerDataGendered,
	subsite,
	pages,
	difficultyBytes,
	buttonColors
}