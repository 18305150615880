import React from 'react';
import { hairStrFromHashArr, rScoreGivenHash, outfitForLayerName, arrayOfHashCharacters, genderFromHashArr, getRemappedVals, bowStrFromHashArr } from './generate.js'
import * as vars from './vars.js'; import * as subs from './subs.js';
import * as EVM from './evm.js';

import { Modal } from 'react-responsive-modal';

import * as colors from './colors.js';

import { StylesProvider } from "@material-ui/core/styles";
import "./styles.css";

const keccak256 = require('keccak256');

const fetch = require('node-fetch');

export function addysEqual(address1, address2) {
    if(address1.toString().toLowerCase()==address2.toString().toLowerCase()) {
        return true;
    }
    return false;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export async function getObjFromJSONUrl(jsonURL) {

    const response = await fetch(jsonURL);
    let obj = await response.json();
    
    return obj;
}

// export function fullModal(open, onCloseModal, title, modalContent) {

//     return (
//         <StylesProvider injectFirst>
//         <Modal open={open} onClose={onCloseModal} center style={{width: '100%'}}>
//             <div id="center" style={colors.colorStyles}>
//             <h2>{title}</h2>
//                 <div className="modal_content_width">
//                     {modalContent()}
//                 </div>
//             </div>
//         </Modal>
//         </StylesProvider>
//     );
// }

export function modal(open, onCloseModal, title, modalContent) {

    return (
        <StylesProvider injectFirst>
        <Modal open={open} onClose={onCloseModal} center>
            <div id="center" style={colors.colorStyles}>
            <h2>{title}</h2>
                <div className="modal_content_width">
                    {modalContent()}
                </div>
            </div>
        </Modal>
        </StylesProvider>
    );
}

// export async function callMethodWithNonce(contractMethodArgs, value, from, gasLimit, nonce, callback) {

   

//     var sendArgs = {
//         value: value, 
//         from: from.toString(), 
//         gasLimit: gasLimit,
//         nonce: nonce
//     };


//      try {
        
//         contractMethodArgs.send(sendArgs, async function(err, res) {
//             await txReceipt(err, res, function(anErr, aRes) {
//                 if(anErr) {
//                     callback(anErr, false);
//                     //console.log(anErr.message);
//                 } else {
//                     callback(false, aRes);
//                 }
//             });
//         });

//       } catch (error) {
//         callback(error, false);
//         //console.log(error);
//       }
// }

export  async function tryCallUntilSuccessful(contractMethodArgs) {
      var res = false;
      var retVal;
        do {
            try {
                res = true;
                retVal = await contractMethodArgs.call();
            } catch(error) {
                res = false;
            };
        } while(!res); 

        return retVal;
    }

    
export async function callMethod(contractMethodArgs, value, from, gasLimit, callback) {

   

    var sendArgs = {
        value: value, 
        from: from.toString(), 
        gasLimit: gasLimit
    };


    try {
        
        contractMethodArgs.send(sendArgs, async function(err, res) {
            await txReceipt(err, res, function(anErr, aRes) {
                if(anErr) {
                    callback(anErr, false);
                    //console.log(anErr.message);
                } else {
                    callback(false, aRes);
                }
            });
        });

      } catch (error) {
        callback(error, false);
        //console.log(error);
      }
}

export async function txReceipt(err, res, callback) {
    if(!err) {

            var receipt=false;
            var tries = 0;
            do {
                //console.log('getting receipt?');
                var receipt = await EVM.web3.eth.getTransactionReceipt(res);
                tries++;
                await sleep(2000);

            } while(!receipt || tries>100);

         //.then(function(err2, res2) {
            if(receipt) {
                callback(false, receipt);
                //console.log('TX succeeded!');
            } else {
                callback({message: "Error getting tx receipt."}, false);
                //console.log('Err getting tx recept?');
            }
         

     } else {
        
        //console.log('Maybe user rejected?');

        var nonceErr = 'have the correct nonce. account has nonce of:';
        if(err.message.indexOf(nonceErr)>-1) {
            var hasString= 'account has nonce of: ';
            var posOfHas = err.message.indexOf(hasString);
            var posOfSpaceAfterHas = err.message.indexOf(' ', posOfHas+hasString.length);

            var hasNonce = err.message.substr(posOfHas, posOfSpaceAfterHas-posOfHas);

            var someNonce = err.message.substr(posOfHas+hasString.length, posOfSpaceAfterHas-posOfHas-hasString.length);

            alert('Nonce error, use this nonce instead: '+someNonce);
            callback({message: "Nonce error, use this nonce instead: "+hasNonce, nonce: someNonce}, false);
        } else {
            callback({message: "Maybe user rejected, did not follow through on tx?"}, false);
            //callback({message: err.message}, false);
        }
     }
}

export async function getContractInfoObj(info) {
    const response = await fetch(vars.contractInfoURL+info, { headers: {
       "Content-Type": "application/json"
    }});
    const body = await response.text();
    //console.log(body);
    return JSON.parse(body);
}

export function nameWithNonBreakingSpaces(name) {

    if(typeof name === 'string' || name instanceof String) {

    } else {
        return '';
    }

    var firstChar = name.substr(0, 1);
    var lastChar = name.substr(name.length-1, 1);

    var needsQuotes=false;
    if(firstChar==' ' || lastChar==' ') {
        needsQuotes=true;
    }

    // for(var i=0;i<10;i++) {
    //     name = name.replace(" ", String.fromCharCode(160));
    // }
    // for(var i=0;i<10;i++) {
    //     name = name.replace("\n", "-");
    // }
    // for(var i=0;i<10;i++) {
    //     name = name.replace("\n", "/");
    // }
    // for(var i=0;i<10;i++) {
    //     name = name.replace("\r", "\\");
    // }
    if(needsQuotes) {
        name = '"'+name+'"';
    }


    return name;
    
}

export const scale = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function descForFilename(filename) {

    if(!subs.subsite) {
        // FANTUMS

        if(filename=='0') { return 'Nothing'; }

        if(filename=='bg_5') { return 'Floorstanding Candelabra'; }
        if(filename=='bg_6') { return 'Chandelier'; }
        if(filename=='bg_7') { return 'Full Moon'; }


        if(filename=='shirt_5_1') { return 'Black Suit'; }
        if(filename=='shirt_1_1') { return 'Plain Shirt'; }
        if(filename=='shirt_4_1') { return 'Red Velour Suit'; }
        if(filename=='shirt_6_1') { return 'Military Uniform'; }
        if(filename=='shirt_7_1') { return 'Don Juan Suit'; }
        if(filename=='shirt_2_1') { return 'Green Velour Suit'; }
        if(filename=='shirt_3_1') { return 'Blue Velour Suit'; }

        if(filename=='shirt_1_0') { return 'Plain Dress'; }
        if(filename=='shirt_2_0') { return 'Green Dress'; }
        if(filename=='shirt_3_0') { return 'Blue Dress'; }
        if(filename=='shirt_4_0') { return 'Red Dress'; }
        if(filename=='shirt_5_0') { return 'White Dress'; }
        if(filename=='shirt_6_0') { return 'Fancy Dress'; }
        if(filename=='shirt_7_0') { return 'Rare Dress'; }


        if(filename=='cape_2_1') { return 'Green Cape'; }
        if(filename=='cape_3_1') { return 'Blue Cape'; }
        if(filename=='cape_4_1') { return 'Red Cape'; }
        if(filename=='cape_5_1') { return 'Black Cape'; }

        if(filename=='eyes_0') { return 'Eyes Ahead, Down'; }
        if(filename=='eyes_1') { return 'Eyes Ahead'; }
        if(filename=='eyes_2') { return 'Eyes Down'; }
        if(filename=='eyes_3') { return 'Eyes Up'; }
        if(filename=='eyes_4') { return 'Eyes Up Left'; }
        if(filename=='eyes_5') { return 'Eyes Up Right'; }
        if(filename=='eyes_6') { return 'Eyes Down Right'; }
        if(filename=='eyes_7') { return 'Eyes Down Left'; }

        if(filename=='neck_1_1') { return 'Green Bow Tie'; }
        if(filename=='neck_2_1') { return 'Blue Bow Tie'; }
        if(filename=='neck_3_1') { return 'Red Bow Tie'; }
        if(filename=='neck_4_1') { return 'Black Bow Tie'; }
        if(filename=='neck_1_0') { return 'Green Gem Necklace'; }
        if(filename=='neck_2_0') { return 'Blue Gem Necklace'; }
        if(filename=='neck_3_0') { return 'Red Gem Necklace'; }
        if(filename=='neck_4_0') { return 'White Gem Necklace'; }

        if(filename=='mole_4_0') { return 'Mole'; }

        if(filename=='mask_3') { return 'Mask Left'; }
        if(filename=='mask_2') { return 'Mask Right'; }
        if(filename=='mask_4_1') { return 'Mask Black'; }
        if(filename=='mask_5') { return 'Half and Half Mask'; }
        if(filename=='mask_6_1') { return 'Monocle'; }
        if(filename=='mask_4_0') { return 'Mask White'; }
        if(filename=='mask_6_0') { return 'Opera Glasses'; }

        if(filename=='hair_1_0_br') { return 'Brown'; }
        if(filename=='hair_1_0_bl') { return 'Black'; }
        if(filename=='hair_1_0_y') { return 'Blonde'; }
        if(filename=='hair_1_0_w') { return 'White'; }

        if(filename=='hair_1_1_br') { return 'Brown'; }
        if(filename=='hair_1_1_bl') { return 'Black'; }
        if(filename=='hair_1_1_y') { return 'Blonde'; }
        if(filename=='hair_1_1_w') { return 'White'; }

        if(filename=='hair_2_0_br') { return 'Brown'; }
        if(filename=='hair_2_0_bl') { return 'Black'; }
        if(filename=='hair_2_0_y') { return 'Blonde'; }
        if(filename=='hair_2_0_w') { return 'White'; }

        if(filename=='hair_2_1_br') { return 'Brown'; }
        if(filename=='hair_2_1_bl') { return 'Black'; }
        if(filename=='hair_2_1_y') { return 'Blonde'; }
        if(filename=='hair_2_1_w') { return 'White'; }

        if(filename=='hair_3_0_br') { return 'Brown'; }
        if(filename=='hair_3_0_bl') { return 'Black'; }
        if(filename=='hair_3_0_y') { return 'Blonde'; }
        if(filename=='hair_3_0_w') { return 'White'; }

        if(filename=='hair_3_1_br') { return 'Brown'; }
        if(filename=='hair_3_1_bl') { return 'Black'; }
        if(filename=='hair_3_1_y') { return 'Blonde'; }
        if(filename=='hair_3_1_w') { return 'White'; }

        if(filename=='hair_4_1_br') { return 'Brown'; }
        if(filename=='hair_4_1_bl') { return 'Black'; }
        if(filename=='hair_4_1_y') { return 'Blonde'; }
        if(filename=='hair_4_1_w') { return 'White'; }

        if(filename=='hair_4_0_br_r') { return 'Brown Red Bow'; }
        if(filename=='hair_4_0_br_g') { return 'Brown Green Bow'; }
        if(filename=='hair_4_0_br_b') { return 'Brown Blue Bow'; }
        if(filename=='hair_4_0_bl_r') { return 'Black Red Bow'; }
        if(filename=='hair_4_0_bl_g') { return 'Black Green Bow'; }
        if(filename=='hair_4_0_bl_b') { return 'Black Blue Bow'; }
        if(filename=='hair_4_0_y_r') { return 'Blonde Red Bow'; }
        if(filename=='hair_4_0_y_g') { return 'Blonde Green Bow'; }
        if(filename=='hair_4_0_y_b') { return 'Blonde Blue Bow'; }
        if(filename=='hair_4_0_w_r') { return 'White Red Bow'; }
        if(filename=='hair_4_0_w_g') { return 'White Green Bow'; }
        if(filename=='hair_4_0_w_b') { return 'White Blue Bow'; }

        if(filename=='hair_4_1_br_r') { return 'Brown Red Bow'; }
        if(filename=='hair_4_1_br_g') { return 'Brown Green Bow'; }
        if(filename=='hair_4_1_br_b') { return 'Brown Blue Bow'; }
        if(filename=='hair_4_1_bl_r') { return 'Black Red Bow'; }
        if(filename=='hair_4_1_bl_g') { return 'Black Green Bow'; }
        if(filename=='hair_4_1_bl_b') { return 'Black Blue Bow'; }
        if(filename=='hair_4_1_y_r') { return 'Blonde Red Bow'; }
        if(filename=='hair_4_1_y_g') { return 'Blonde Green Bow'; }
        if(filename=='hair_4_1_y_b') { return 'Blonde Blue Bow'; }
        if(filename=='hair_4_1_w_r') { return 'White Red Bow'; }
        if(filename=='hair_4_1_w_g') { return 'White Green Bow'; }
        if(filename=='hair_4_1_w_b') { return 'White Blue Bow'; }

        if(filename=='mouth_2') { return 'Tongue Hole'; }
        if(filename=='mouth_1_1_br') { return 'Fancy \'stache Brown'; }
        if(filename=='mouth_1_1_bl') { return 'Fancy \'stache Black'; }
        if(filename=='mouth_1_1_y') { return 'Fancy \'stache Blonde'; }
        if(filename=='mouth_1_1_w') { return 'Fancy \'stache White'; }
        if(filename=='mouth_1_0') { return 'Red Lips'; }
        if(filename=='mouth_3_0') { return 'Red O'; }
        if(filename=='mouth_0_0') { return 'Red Line'; }
        if(filename=='mouth_4_0') { return 'Toothy Smile'; }
        if(filename=='mouth_0_1') { return 'Black Line'; }

        if(filename=='mouth_3_1_br') { return 'Goatee Brown'; }
        if(filename=='mouth_3_1_bl') { return 'Goatee Black'; }
        if(filename=='mouth_3_1_y') { return 'Goatee Blonde'; }
        if(filename=='mouth_3_1_w') { return 'Goatee White'; }

        if(filename=='mouth_4_1_br') { return 'Moustache Brown'; }
        if(filename=='mouth_4_1_bl') { return 'Moustache Black'; }
        if(filename=='mouth_4_1_y') { return 'Moustache Blonde'; }
        if(filename=='mouth_4_1_w') { return 'Moustache White'; }

        if(filename=='hat_4_1') { return 'Red Top Hat'; }
        if(filename=='hat_4_0') { return 'Red Feather Hat'; }
        if(filename=='hat_3_1') { return 'Blue Top Hat'; }
        if(filename=='hat_3_0') { return 'Blue Feather Hat'; }
        if(filename=='hat_2_1') { return 'Green Top Hat'; }
        if(filename=='hat_2_0') { return 'Green Feather Hat'; }

        if(filename=='hat_8_1') { return 'Black Top Hat'; }
        if(filename=='hat_8_0') { return 'Black Feather Hat'; }

        if(filename=='hat_7_1') { return 'Red Stylish Hat'; }
        if(filename=='hat_7_0') { return 'Red Feathered Hairpiece'; }

        if(filename=='hat_6_1') { return 'Blue Stylish Hat'; }
        if(filename=='hat_6_0') { return 'Blue Feathered Hairpiece'; }

        if(filename=='hat_5_1') { return 'Green Stylish Hat'; }
        if(filename=='hat_5_0') { return 'Green Feathered Hairpiece'; }

        if(filename=='rh_2') { return 'Rose'; }
        if(filename=='rh_2_0') { return 'Rose'; }
        if(filename=='rh_2_1') { return 'Rose'; }
        if(filename=='rh_3') { return 'Lantern'; }
        if(filename=='rh_3_0') { return 'Lantern'; }
        if(filename=='rh_3_1') { return 'Lantern'; }
        if(filename=='rh_4') { return 'Torch'; }
        if(filename=='rh_4_0') { return 'Torch'; }
        if(filename=='rh_4_1') { return 'Torch'; }
        if(filename=='rh_6') { return 'Candelabra'; }
        if(filename=='rh_6_0') { return 'Candelabra'; }
        if(filename=='rh_6_1') { return 'Candelabra'; }
        if(filename=='rh_5_0') { return 'Masquerade Mask'; }
        if(filename=='rh_5_1') { return 'Sword'; }

        if(filename=='dog_14') { return 'White Poodle'; }
        if(filename=='dog_15') { return 'Black Poodle'; }
    } else {
        if(filename=='0') { return 'Nothing'; }

        if(filename=='bg_6') { return 'Black Spider'; }
        if(filename=='bg_7') { return 'Black Bat'; }
        if(filename=='bg_8') { return 'Spooky Tree'; }
        if(filename=='bg_9') { return 'Cross'; }
        if(filename=='bg_10') { return 'Moon'; }
        if(filename=='bg_11') { return 'Balloon'; }
        if(filename=='bg_12') { return 'Purple Ghost'; }
        if(filename=='bg_13') { return 'Green Ghost'; }
        if(filename=='bg_14') { return 'Orange Ghost'; }
        if(filename=='bg_15') { return 'Red Ghost'; }
        if(filename=='bg_16') { return 'White Spider'; }
        if(filename=='bg_17') { return 'Casket'; }
        if(filename=='bg_31') { return 'Demon'; }

        if(filename=='skin_3') { return 'Pale White Skin'; }
        if(filename=='skin_4') { return 'Sesame Seed Skin'; }
        if(filename=='skin_5') { return 'Pale, Wrinkly Skin'; }
        if(filename=='skin_6') { return 'Potato Skin'; }
        if(filename=='skin_7') { return 'Bandage Skin'; }
     
        if(filename=='eyes_0') { return 'Eyes Ahead, Down'; }
        if(filename=='eyes_1') { return 'Eyes Up Left'; }
        if(filename=='eyes_2') { return 'Eyes Down'; }
        if(filename=='eyes_3') { return 'Eyes Up Right'; }
        if(filename=='eyes_4') { return 'Eyes Down Right'; }
        if(filename=='eyes_5') { return 'Eyes Down Left'; }
        if(filename=='eyes_6') { return 'Cross-Eyed'; }
        if(filename=='eyes_7') { return 'Eyes Ahead, Yellow'; }


        

        if(filename=='makeup_9_0') { return 'Green Eyeshadow'; }
        if(filename=='makeup_11_0') { return 'Red Eyeshadow'; }
        if(filename=='makeup_10_0') { return 'Orange Eyeshadow'; }
        if(filename=='makeup_8_0') { return 'Purple Eyeshadow'; }
        if(filename=='makeup_7_1') { return 'Clown Eyeshadow'; }

        if(filename=='makeup_7_0') { return 'Full-Face Makeup'; }

        if(filename=='makeup_3') { return 'Black Eyeshadow'; }
       //if(filename=='makeup_3_1') { return 'Male Black Eyeshadow'; }

        if(filename=='makeup_4') { return 'Goth Makeup'; }
        //if(filename=='makeup_4_0') { return 'Female Goth Makeup'; }

        if(filename=='makeup_5') { return 'Cheek Bone Makeup'; }
        //if(filename=='makeup_5_1') { return 'Male Cheek Bone Makeup'; }

        if(filename=='makeup_6') { return 'Eyelashes + Eyeshadow'; }
        //if(filename=='makeup_6_1') { return 'Male Eyelashes + Eyeshadow'; }



        

        if(filename=='mouth_1') { return 'Stitches'; }
        //if(filename=='mouth_1_0') { return 'Female Stitches'; }

        if(filename=='mouth_2') { return 'Fangs'; }
        //if(filename=='mouth_2_0') { return 'F Two Tooth'; }

        if(filename=='mouth_4') { return 'Plus Mouth'; }
        //if(filename=='mouth_4_0') { return 'Female Plus Mouth'; }

        if(filename=='mouth_5') { return 'One Tooth'; }
        //if(filename=='mouth_5_0') { return 'F One Tooth'; }

        if(filename=='mouth_6') { return 'Tongue in Mouth'; }
        //if(filename=='mouth_6_1') { return 'M Tongue in Mouth'; }

        if(filename=='mouth_7') { return 'Muzzle'; }
        //if(filename=='mouth_7_0') { return 'F Muzzle'; }

        

        if(filename=='mouth_9_1') { return 'Clown Lips'; }
        if(filename=='mouth_9_0') { return 'Green Lipstick'; }
        if(filename=='mouth_8_0') { return 'Purple Lipstick'; }
        if(filename=='mouth_10_0') { return 'Orange Lipstick'; }
        if(filename=='mouth_11_0') { return 'Red Lipstick'; }
        if(filename=='mouth_12_0') { return 'Red Lips'; }
        if(filename=='mouth_11_1') { return 'Joker\'s Smile'; }
        if(filename=='mouth_0_0') { return 'Red Line'; }
        if(filename=='mouth_3_0') { return 'Goth Lips'; }
        if(filename=='mouth_12_1') { return 'White Moustache'; }
        if(filename=='mouth_3_1') { return 'Black Moustache'; }

        if(filename=='mouth_0_1') { return 'Black Line'; }
        if(filename=='mouth_10_1') { return 'Black Line'; }
        if(filename=='mouth_8_1') { return 'Black Line'; }


        if(filename=='mask_4') { return 'Bloody Eye'; }
        if(filename=='mask_5') { return 'Black Sunglasses'; }
        if(filename=='mask_6') { return 'Skull Cap'; }
        if(filename=='mask_7') { return 'Grey Mask'; }
        if(filename=='mask_8') { return 'Wolf Mask'; }
        if(filename=='mask_9') { return 'Frankenstein Mask'; }
        if(filename=='mask_10') { return 'Knife in Eye'; }
        if(filename=='mask_11') { return 'Clown Mask'; }
        if(filename=='mask_12') { return 'Cousin IT Face'; }
        if(filename=='mask_13') { return 'Dog Face'; }
        if(filename=='mask_14') { return 'Purple Sunglasses'; }
        if(filename=='mask_15') { return 'Green Sunglasses'; }
        if(filename=='mask_16') { return 'Orange Sunglasses'; }
        if(filename=='mask_17') { return 'Red Sunglasses'; }
        if(filename=='mask_18') { return 'Mangy Wolf Mask'; }
        if(filename=='mask_19') { return 'Skull Mask'; }
        if(filename=='mask_20') { return 'Guy Fawkes Mask'; }
        if(filename=='mask_21') { return 'Scream Mask'; }
        if(filename=='mask_22') { return 'Jason Mask'; }
  
        if(filename=='shirt_1_1') { return 'Dracula\'s Tuxedo'; }
        if(filename=='shirt_9_0') { return 'Hoop Skirt Dress'; }
        if(filename=='shirt_10_0') { return 'Purple Goth Dress'; }

        if(filename=='shirt_3_1') { return 'Jack Skeleton\'s Shirt'; }

        if(filename=='shirt_8_0') { return 'Red Riding Hood'; }
        if(filename=='shirt_6_0') { return 'Goddess Dress'; }
        if(filename=='shirt_21_0') { return 'Devil Dress'; }

        if(filename=='shirt_2_0') { return 'Booberella Dress'; }
        if(filename=='shirt_20_0') { return 'Dark Angel'; }
        if(filename=='shirt_3_0') { return 'Nun'; }
        
        if(filename=='shirt_1_0') { return 'Wednesday Addam\'s Dress'; }
        if(filename=='shirt_14_1') { return 'Skeleton Outfit'; }
        if(filename=='shirt_5_1') { return 'Wolf Costume'; }
        if(filename=='shirt_24_1') { return 'Orange Dracula Tuxedo'; }
        if(filename=='shirt_9_1') { return 'Prison Jumpsuit'; }
        if(filename=='shirt_6_1') { return 'Joker\'s Suit'; }
        if(filename=='shirt_2_1') { return 'Michael\'s Jumpsuit'; }
        if(filename=='shirt_15_1') { return 'Frankenstein\'s Shirt'; }
        if(filename=='shirt_19_1') { return 'Chucky\'s Overalls'; }
        if(filename=='shirt_19_0') { return 'Light Angel'; }
        if(filename=='shirt_23_0') { return 'Green Dress'; }
        if(filename=='shirt_24_0') { return 'Orange Dress'; }
        if(filename=='shirt_21_1') { return 'Billy Butcherson\'s Shirt'; }
        if(filename=='shirt_23_1') { return 'Green Dracula Tuxedo'; }
        if(filename=='shirt_7_0') { return 'Black Demon Dress'; }
        if(filename=='shirt_4_0') { return 'Morticia Addams Dress'; }
        if(filename=='shirt_5_0') { return 'F Wolf Costume'; }
        if(filename=='shirt_17_1') { return 'Fancy White Shirt'; }
        if(filename=='shirt_22_1') { return 'Purple Dracula Tuxedo'; }
        if(filename=='shirt_11_0') { return 'Green Goth Dress'; }
        
        if(filename=='shirt_22_0') { return 'Purple Dress'; }
        
        if(filename=='shirt_4_1') { return 'Black Parade'; }
        if(filename=='shirt_14_0') { return 'Winnifred'; }
        if(filename=='shirt_18_1') { return 'Pumpkin'; }
        
        if(filename=='shirt_7_1') { return 'Black Suit'; }
        if(filename=='shirt_18_0') { return 'Poison Ivy'; }
        if(filename=='shirt_15_0') { return 'Black Rogue'; }
        if(filename=='shirt_12_0') { return 'Orange Goth Dress'; }
        
        if(filename=='shirt_13_0') { return 'Red Goth Dress'; }
        if(filename=='shirt_25_1') { return 'Red Dracula Tuxedo'; }
        if(filename=='shirt_25_0') { return 'Red Dress'; }
        if(filename=='shirt_20_1') { return 'Clown Suit'; }
        if(filename=='shirt_8_1') { return 'Cousin IT Shirt'; }
        if(filename=='shirt_17_0') { return 'Ghost Dress'; }

        //if(filename=='shirt_16_1') { return 'Hamburger'; }
        if(filename=='shirt_16') { return 'Hamburger'; }
        
        if(filename=='shirt_10_1') { return 'Freddy Krueger\'s Shirt'; }
        if(filename=='shirt_11_1') { return 'Freddy Krueger\'s Shirt'; }
        if(filename=='shirt_12_1') { return 'Freddy Krueger\'s Shirt'; }
        if(filename=='shirt_13_1') { return 'Freddy Krueger\'s Shirt'; }

        
        



        if(filename=='hair_1_0') { return 'Black Angel Hair'; }
        if(filename=='hair_12_0') { return 'Red Winnifred Hair'; }
        if(filename=='hair_18_1') { return 'Red Frankenstein Hair'; }
         if(filename=='hair_9_0') { return 'Green Goth Hair'; }
        if(filename=='hair_10_0') { return 'Purple Pigtails'; }
        if(filename=='hair_3_0') { return 'Brown Angel Hair'; }
        if(filename=='hair_20_0') { return 'Orange Winnifred'; }
         if(filename=='hair_4_1') { return 'Little Rascal'; }
        if(filename=='hair_9_1') { return 'Neon Emo Hair'; }
        if(filename=='hair_2_0') { return 'Patricia Hair'; }
        if(filename=='hair_7_0') { return 'Blue Streaks'; }
         if(filename=='hair_6_0') { return 'Booberella'; }
        if(filename=='hair_6_1') { return 'Black Frankenstein'; }
        if(filename=='hair_11_0') { return 'Frankenstein\'s Bride'; }
        if(filename=='hair_7_1') { return 'Dracula\'s Hair'; }
         if(filename=='hair_11_1') { return 'Mad Goth'; }
        if(filename=='hair_17_0') { return 'Orange Ivy'; }
        if(filename=='hair_14_0') { return 'Wednesday Addams'; }
        if(filename=='hair_3_1') { return 'Basic Brown'; }
         if(filename=='hair_15_0') { return 'Purple Ivy'; }
        if(filename=='hair_17_1') { return 'Orange Frankenstein'; }
        if(filename=='hair_1_1') { return 'Black Dracula'; }
        if(filename=='hair_13_1') { return 'Billy Butcherson\'s Hair'; }
         if(filename=='hair_20_1') { return 'Beetlejuice'; }
        if(filename=='hair_12_1') { return 'IT Clown Hair'; }
        if(filename=='hair_18_0') { return 'Red Ivy'; }
         if(filename=='hair_2_1') { return 'Unkempt Hair'; }
        if(filename=='hair_14_1') { return 'Black Basic Hair'; }
        if(filename=='hair_8_0') { return 'High Pigtails'; }
         if(filename=='hair_4_0') { return 'Curly Hair'; }
        if(filename=='hair_16_0') { return 'Green Ivy'; }
        if(filename=='hair_10_1') { return 'Chucky\'s Hair'; }
        if(filename=='hair_8_1') { return 'Pompadour'; }
         if(filename=='hair_19_0') { return 'Gas-Mask Girl Hair'; }
        if(filename=='hair_21_1') { return 'Distinguished Frankenstein'; }
        if(filename=='hair_19_1') { return 'White Emo'; }
        if(filename=='hair_16_1') { return 'Green Frankenstein'; }
         if(filename=='hair_13_0') { return 'Medusa Hair'; }
        if(filename=='hair_15_1') { return 'Purple Frankenstein'; }
        if(filename=='hair_21_0') { return 'Distinguished Booberella'; }

        if(filename=='hair_5') { return 'Shaggy Emo'; }
        if(filename=='hair_5_0') { return 'Shaggy Emo'; }
        if(filename=='hair_5_1') { return 'Shaggy Emo'; }
        
       
       
        if(filename=='hat_6_0') { return 'Red Riding Hood'; }
        if(filename=='hat_9_1') { return 'Green Skull Hat'; }

        if(filename=='hat_12_0') { return 'Dark Angel Halo'; }
        if(filename=='hat_8_0') { return 'Purple Witch Hat'; }
        if(filename=='hat_9_0') { return 'Green Witch Hat'; }
        if(filename=='hat_7_1') { return 'Gas Mask Hat'; }

        if(filename=='hat_8_1') { return 'Purple Skull Hat'; }
        
        
        if(filename=='hat_5_1') { return 'Cousin IT Hat'; }

        if(filename=='hat_4_1') { return 'Freddy Krueger\'s Hat'; }
        if(filename=='hat_5_0') { return 'Bunny Ears'; }
        if(filename=='hat_4_0') { return 'Nun Cowl'; }
        if(filename=='hat_13_0') { return 'Light Halo'; }

        if(filename=='hat_11_0') { return 'Red Witch Hat'; }
        if(filename=='hat_6_1') { return 'Little Hat White Ribbon'; }
        
        if(filename=='hat_10_1') { return 'Orange Skull Hat'; }

        if(filename=='hat_11_1') { return 'Red Skull Hat'; }
        if(filename=='hat_10_0') { return 'Purple Witch Hat'; }
        if(filename=='hat_7_0') { return 'Devil Horns'; }

        if(filename=='hat_14') { return 'Butcher\'s Knife'; }
        if(filename=='hat_14_0') { return 'Butcher\'s Knife'; }
        if(filename=='hat_14_1') { return 'Butcher\'s Knife'; }

        if(filename=='hat_31') { return 'Pumpkin'; }
        if(filename=='hat_31_0') { return 'Pumpkin'; }
        if(filename=='hat_31_1') { return 'Pumpkin'; }
       


        
        if(filename=='rh_3') { return 'Candy Bag'; }
        if(filename=='rh_3_0') { return 'Candy Bag'; }
        if(filename=='rh_3_1') { return 'Candy Bag'; }


        if(filename=='rh_6') { return 'Ball & Chain'; }
        if(filename=='rh_6_1') { return 'Ball & Chain'; }
        if(filename=='rh_6_0') { return 'Ball & Chain'; }
        if(filename=='rh_7') { return 'Reaper\'s Scythe'; }
        if(filename=='rh_7_1') { return 'Reaper\'s Scythe'; }
        if(filename=='rh_7_0') { return 'Reaper\'s Scythe'; }
        if(filename=='rh_8') { return 'Shotgun'; }
        if(filename=='rh_8_0') { return 'Shotgun'; }
        if(filename=='rh_8_1') { return 'Shotgun'; }
        if(filename=='rh_9') { return 'Bow & Arrow'; }
        if(filename=='rh_9_0') { return 'Bow & Arrow'; }
        if(filename=='rh_9_1') { return 'Bow & Arrow'; }
        if(filename=='rh_10') { return 'Pumpkin Bag'; }
        if(filename=='rh_10_1') { return 'Pumpkin Bag'; }
        if(filename=='rh_10_0') { return 'Pumpkin Bag'; }


        if(filename=='rh_12') { return 'Hammer'; }
        if(filename=='rh_12_1') { return 'Hammer'; }
        if(filename=='rh_12_0') { return 'Hammer'; }
        if(filename=='rh_13') { return 'White Cross'; }
        if(filename=='rh_13_1') { return 'White Cross'; }
        if(filename=='rh_13_0') { return 'White Cross'; }
        if(filename=='rh_14') { return 'Purple Orb'; }
        if(filename=='rh_14_1') { return 'Purple Orb'; }
        if(filename=='rh_14_0') { return 'Purple Orb'; }
        if(filename=='rh_15') { return 'Green Orb'; }
        if(filename=='rh_15_1') { return 'Green Orb'; }
        if(filename=='rh_15_0') { return 'Green Orb'; }
        if(filename=='rh_16') { return 'Orange Orb'; }
        if(filename=='rh_16_0') { return 'Orange Orb'; }
        if(filename=='rh_16_1') { return 'Orange Orb'; }
        if(filename=='rh_17') { return 'Red Orb'; }
        if(filename=='rh_17_1') { return 'Red Orb'; }
        if(filename=='rh_17_0') { return 'Red Orb'; }
        if(filename=='rh_18') { return 'Purple Cauldron'; }
        if(filename=='rh_18_1') { return 'Purple Cauldron'; }
        if(filename=='rh_18_0') { return 'Purple Cauldron'; }
        if(filename=='rh_19') { return 'Green Cauldron'; }
        if(filename=='rh_19_1') { return 'Green Cauldron'; }
        if(filename=='rh_19_0') { return 'Green Cauldron'; }
        if(filename=='rh_20') { return 'Orange Cauldron'; }
        if(filename=='rh_20_0') { return 'Orange Cauldron'; }
        if(filename=='rh_20_1') { return 'Orange Cauldron'; }
        if(filename=='rh_21') { return 'Red Cauldron'; }
        if(filename=='rh_21_1') { return 'Red Cauldron'; }
        if(filename=='rh_21_0') { return 'Red Cauldron'; }
        if(filename=='rh_22') { return 'Spilled Candy Bag'; }
        if(filename=='rh_22_1') { return 'Spilled Candy Bag'; }
        if(filename=='rh_22_0') { return 'Spilled Candy Bag'; }
        if(filename=='rh_23') { return 'Lollipop'; }
        if(filename=='rh_23_0') { return 'Lollipop'; }
        if(filename=='rh_23_1') { return 'Lollipop'; }
        if(filename=='rh_24') { return 'Purple Wand'; }
        if(filename=='rh_24_1') { return 'Purple Wand'; }
        if(filename=='rh_24_0') { return 'Purple Wand'; }
        if(filename=='rh_25') { return 'Green Wand'; }
        if(filename=='rh_25_1') { return 'Green Wand'; }
        if(filename=='rh_25_0') { return 'Green Wand'; }
        if(filename=='rh_26') { return 'Orange Wand'; }
        if(filename=='rh_26_0') { return 'Orange Wand'; }
        if(filename=='rh_26_1') { return 'Orange Wand'; }
        if(filename=='rh_27') { return 'Red Wand'; }
        if(filename=='rh_27_0') { return 'Red Wand'; }
        if(filename=='rh_27_1') { return 'Red Wand'; }
        
        if(filename=='rh_29') { return 'Crying Mask'; }
        if(filename=='rh_30') { return 'Crying Mask'; }
        if(filename=='rh_31') { return 'Crying Mask'; }
        if(filename=='rh_29_0') { return 'Crying Mask'; }
        if(filename=='rh_30_0') { return 'Crying Mask'; }
        if(filename=='rh_31_0') { return 'Crying Mask'; }
        if(filename=='rh_29_1') { return 'Crying Mask'; }
        if(filename=='rh_30_1') { return 'Crying Mask'; }
        if(filename=='rh_31_1') { return 'Crying Mask'; }
        //if(filename=='rh_31_1') { return 'Crying Mask'; }
        

        

        if(filename=='rh_11_0') { return 'Devilish Trident'; }

        
        
        if(filename=='rh_5_0') { return 'Withering Rose'; }

         
        
        if(filename=='rh_5_1') { return 'Dynamite'; }

        
        
        if(filename=='rh_4_0') { return 'Broom'; }
       
        if(filename=='rh_4_1') { return 'Bloody Dagger'; }

        if(filename=='rh_11_1') { return 'Krueger Claw'; }











        
        if(filename=='pet_20') { return 'Purple Cat'; }
        if(filename=='pet_21') { return 'Green Cat'; }
        if(filename=='pet_22') { return 'Orange Cat'; }
        if(filename=='pet_23') { return 'Red Cat'; }
        if(filename=='pet_31') { return 'White Cat'; }



    }

    

    return '';
}





export function oddsForLayerUsingHash(name, hash) {

    if(!subs.subsite) {

        var arrOfHash = arrayOfHashCharacters(hash); //60 characters

        var outfit = outfitForLayerName(name, arrayOfHashCharacters(hash));
        //var gender = genderFromHashArr(arrayOfHashCharacters(hash));
        //var hairStr = hairStrFromHashArr(arrayOfHashCharacters(hash));

        var hairNumber = outfitForLayerName('hair', arrayOfHashCharacters(hash));
        var shirtNumber = outfitForLayerName('shirt', arrayOfHashCharacters(hash));

         var hairStr='';
        // IF THERE IS HAIR PERIOD, IT NEEDS A COLOR
        if(name=='hair' && hairNumber > 0){
            hairStr='_'+hairStrFromHashArr(arrOfHash);
            //console.log('hairStr: '+hairStr);
        }

        var gender;
        if(arrOfHash) {
            gender = genderFromHashArr(arrOfHash);
        }

        // These are the male mouths with different hair colours
        if(gender==1 && name=='mouth' && (outfit ==1 || outfit ==3 || outfit ==4)) {
            hairStr='_'+hairStrFromHashArr(arrOfHash);
            //console.log('hairStr: '+hairStr);
        }

        var bowStr='';
        // This is a special female hairstyle with a coloured bow
        if(gender==0 && name=='hair' && outfit==4) {
            bowStr=bowStrFromHashArr(arrOfHash);
        }

        // This is a special male outfit that cannot have a cape
        if(gender==1 && name=='cape' && shirtNumber==6) {
            outfit=0;
        }
        //This is a special male outfit that cannot have a cape
        if(gender==1 && name=='cape' && shirtNumber==7) {
            outfit=0;
        }
        //This is a special male outfit that cannot have a cape
        if(gender==1 && name=='neck' && shirtNumber==7) {
            outfit=0;
        }

       var gendered='';
       if(name=='shirt' || name=='mouth' || name=='hat' || name=='neck' || name=='mask' || name=='rh' || name=='hair' || name=='cape' || name=='mole') {
        gendered='_'+gender;
       }

       if(name=='hat' && gender==0 && hairNumber==4) {
        outfit=0;
       }

       outfit = returnMergedFantumsOutfit(gender, name, outfit);
       gender = returnMergedFantumsGender(gender, name, outfit);

       var odds = Math.round(oddsOfLayerAndOutfit(gender, name, outfit, hairStr, bowStr)*10000)/100;

       //return name+'_'+outfit+gendered+hairStr+bowStr;

       return odds;

    } else {

        var arrOfHash = arrayOfHashCharacters(hash); //60 characters
        var gender = genderFromHashArr(arrOfHash);
        var outfit = outfitForLayerName(name, arrOfHash);


        var hairNumber = outfitForLayerName('hair', arrOfHash, subs.subsite);
        var hatNumber = outfitForLayerName('hat', arrOfHash, subs.subsite);
        var maskNumber = outfitForLayerName('mask', arrOfHash, subs.subsite);

        if(name=='hat' && maskNumber >= 19) {
            // FIRST MASK TIER, HIDE HATS
            outfit=0;
        }
        if(name=='hair' && maskNumber >= 21) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='hat' && (hairNumber==20 || hairNumber==12 || hairNumber==13 || (hairNumber==19 && gender==0) || (hairNumber==8 && gender==0) || (hairNumber==3 && gender==0) || (hairNumber==1 && gender==0) || (hairNumber==10 && gender==0))) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='hair' && hatNumber == 31) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='mask' && hatNumber == 31) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }



       outfit = returnMergedGothumsOutfit(gender, name, outfit);
       gender = returnMergedGothumsGender(gender, name, outfit);

       var odds = Math.round(oddsOfLayerAndOutfitGothums(gender, name, outfit)*10000)/100;

       return odds;

    }

    
}

export function fileKeyForLayerUsingHash(name, hash) {

    if(!subs.subsite) {

        // FANTUMS

        var arrOfHash = arrayOfHashCharacters(hash); //60 characters

        var outfit = outfitForLayerName(name, arrayOfHashCharacters(hash));
        //var gender = genderFromHashArr(arrayOfHashCharacters(hash));
        //var hairStr = hairStrFromHashArr(arrayOfHashCharacters(hash));

        var hairNumber = outfitForLayerName('hair', arrayOfHashCharacters(hash));
        var shirtNumber = outfitForLayerName('shirt', arrayOfHashCharacters(hash));

         var hairStr='';
        // IF THERE IS HAIR PERIOD, IT NEEDS A COLOR
        if(name=='hair' && hairNumber > 0){
            hairStr='_'+hairStrFromHashArr(arrOfHash);
            //console.log('hairStr: '+hairStr);
        }

        var gender;
        if(arrOfHash) {
            gender = genderFromHashArr(arrOfHash);
        }

        // These are the male mouths with different hair colours
        if(gender==1 && name=='mouth' && (outfit ==1 || outfit ==3 || outfit ==4)) {
            hairStr='_'+hairStrFromHashArr(arrOfHash);
            //console.log('hairStr: '+hairStr);
        }

        var bowStr='';
        // This is a special female hairstyle with a coloured bow
        if(gender==0 && name=='hair' && outfit==4) {
            bowStr=bowStrFromHashArr(arrOfHash);
        }

        // This is a special male outfit that cannot have a cape
        if(gender==1 && name=='cape' && shirtNumber==6) {
            outfit=0;
        }
        //This is a special male outfit that cannot have a cape
        if(gender==1 && name=='cape' && shirtNumber==7) {
            outfit=0;
        }
        //This is a special male outfit that cannot have a cape
        if(gender==1 && name=='neck' && shirtNumber==7) {
            outfit=0;
        }

       var gendered='';
       if(name=='shirt' || name=='mouth' || name=='hat' || name=='neck' || name=='mask' || name=='rh' || name=='hair' || name=='cape' || name=='mole') {
        gendered='_'+gender;
       }

       if(name=='hat' && gender==0 && hairNumber==4) {
        outfit=0;
       }


       outfit = returnMergedFantumsOutfit(gender, name, outfit);
       gender = returnMergedFantumsGender(gender, name, outfit);


       return name+'_'+outfit+gendered+hairStr+bowStr;
   } else {

        // NEW SETS


        var arrOfHash = arrayOfHashCharacters(hash); //60 characters

       // var outfit = outfitForLayerName(name, arrayOfHashCharacters(hash));

        // GET OUTFIT FOR LAYER NAME
        var outfit = outfitForLayerName(name, arrOfHash, subs.subsite);

        // GET GENDER FROM HASH
        var gender; if(arrOfHash) { gender = genderFromHashArr(arrOfHash); }


        var hairNumber = outfitForLayerName('hair', arrOfHash, subs.subsite);
        var hatNumber = outfitForLayerName('hat', arrOfHash, subs.subsite);
        var maskNumber = outfitForLayerName('mask', arrOfHash, subs.subsite);

        if(name=='hat' && maskNumber >= 19) {
            // FIRST MASK TIER, HIDE HATS
            outfit=0;
        }
        if(name=='hair' && maskNumber >= 21) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='hat' && (hairNumber==20 || hairNumber==12 || hairNumber==13 || (hairNumber==19 && gender==0) || (hairNumber==8 && gender==0) || (hairNumber==3 && gender==0) || (hairNumber==1 && gender==0) || (hairNumber==10 && gender==0))) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='hair' && hatNumber == 31) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }
        if(name=='mask' && hatNumber == 31) {
            // SECOND MASK TIER, HIDE HAIR
            outfit=0;
        }

        var gendered='';
        if(subs.layerDataGendered[name]) {
            gendered='_'+gender;
          }


       outfit = returnMergedGothumsOutfit(gender, name, outfit);
       gender = returnMergedGothumsGender(gender, name, outfit);


       return name+'_'+outfit+gendered;
   }

    

}



export function returnMergedGothumsOutfit(gender, name, outfit) {

     if(name=='bg' && outfit <= 5) {
        outfit=0;
    }
    if(name=='bg' && outfit >= 18 && outfit < 31) {
        outfit=0;
    }

    if(name=='skin' && outfit <= 2) {
        outfit=0;
    }

    if(name=='makeup' && outfit <= 2) {
        outfit=0;
    }
    if(name=='makeup' && gender==1 && outfit >= 8 && outfit <= 11) {
        outfit=0;
    }

    if(name=='mask' && outfit <= 3) {
        outfit=0;
    }

    if(name=='shirt' && outfit == 0) {
        outfit=0;
    }

    if(name=='hat' && outfit <= 3) {
        outfit=0;
    }
    if(name=='hat' && gender==1 && (outfit == 12 || outfit == 13)) {
        outfit=0;
    }
    if(name=='hat' && outfit >= 15 && outfit <= 30) {
        outfit=0;
    }

    if(name=='rh' && outfit <= 2) {
        outfit=0;
    }
     if(name=='rh' && outfit >= 28 && outfit < 29) {
        outfit=0;
    }



    if(name=='pet' && outfit <= 19) {
        outfit=0;
    }
    if(name=='pet' && outfit >= 24 && outfit < 31) {
        outfit=0;
    }

    if(name=='mouth' && gender==1 && (outfit == 0 || outfit == 10 || outfit == 8)) {
        outfit=0;
    }

    if(name=='shirt' && gender==1 && outfit >= 10 && outfit <= 13) {
        outfit=10;
    }

    if(name=='rh' && (outfit==29 || outfit==30 || outfit==31)) {
        outfit=29;
    }


    return outfit;
}

function returnMergedGothumsGender(gender, name, outfit) {


    if(name=='makeup' && outfit <= 2) {
        return '';
    }
    if(name=='makeup' && gender==1 && outfit >= 8 && outfit <= 11) {
        return '';
    }
    if(name=='makeup' && outfit >= 3 && outfit <= 6) {
        return '';
    }

    if(name=='shirt' && outfit == 0) {
        return '';
    }
    if(name=='shirt' && outfit ==16) {
        return '';
    }

    if(name=='hair' && outfit == 0) {
        return '';
    }
    if(name=='hair' && outfit == 5) {
        return '';
    }

    if(name=='hat' && outfit <= 3) {
        return '';
    }

    if(name=='hat' && gender==1 && (outfit == 12 || outfit == 13)) {
        return '';
    }
    if(name=='hat' && outfit >= 15 && outfit <= 30) {
        return '';
    }

    if(name=='rh' && outfit <= 2) {
        return '';
    }
    if(name=='rh' && outfit >= 28 && outfit < 29) {
        return '';
    }

    if(name=='mouth' && (outfit==1 || outfit==2 || outfit==4 || outfit==5 || outfit==6 || outfit==7)) {
        return '';
    }

    if(name=='hat' && (outfit == 14 || outfit == 31)) {
        return '';
    }
    
    if(name=='rh' && (outfit == 3)) {
        return '';
    }
    if(name=='rh' && (outfit >= 6 && outfit<=10)) {
        return '';
    }
    if(name=='rh' && (outfit >= 12 && outfit<=27)) {
        return '';
    }
    if(name=='rh' && (outfit == 29 || outfit == 30 || outfit == 31)) {
        return '';
    }



    return gender;

  }

export function returnMergedFantumsOutfit(gender, name, outfit) {
    //There are no chick capes...
    if(gender==0 && name=='cape') {
        outfit = 0; 
    }
    if(gender==1 && name=='cape' && outfit<2) {
        outfit = 0; 
    }
    if(gender==1 && name=='mole') {
        outfit = 0; 
    }
    if(name=='mole' && outfit < 4) {
        outfit=0;
    }
    if(name=='bg' && outfit < 5) {
        outfit=0;
    }
    if(name=='dog' && outfit < 14) {
        outfit=0;
    }
    if(name=='mask' && outfit < 2) {
        outfit=0;
    }

    if(name=='rh' && outfit < 2) {
        outfit=0;
    }
    if(name=='hat' && outfit < 2) {
        outfit=0;
    }
    if(name=='base') {
        outfit=0;
    }

    return outfit;
}

function returnMergedFantumsGender(gender, layerName, outfit) {
    if(layerName=='cape' && gender==0) {
        return '';
    }
    if(layerName=='cape' && outfit < 2) {
        return '';
    }
    if(layerName=='mole' && gender==1) {
        return '';
    }
    if(layerName=='mole' && outfit == 0) {
        return '';
    }
    if(layerName=='mask' && outfit == 0) {
        return '';
    }
    if(layerName=='neck' && outfit == 0) {
        return '';
    }

    if(layerName=='mask' && outfit == 2) {
        return '';
    }
    if(layerName=='mask' && outfit == 3) {
        return '';
    }
    if(layerName=='mask' && outfit == 5) {
        return '';
    }

    if(layerName=='shirt' && outfit == 0) {
        return '';
    }
    if(layerName=='hat' && outfit < 2) {
        return '';
    }
    if(layerName=='rh' && outfit < 2) {
        return '';
    }
    if(layerName=='hair' && outfit == 0) {
        return '';
    }
    if(layerName=='rh' && (outfit < 2 || outfit!=5)) {
        return '';
    }

    return gender;
  }


export function oddsOfLayerAndOutfit(gender, name, outfit, hairStr, bowStr) {



    var numberOfStylesForLayer = subs.layerData[name];

    var gendered=false;
    if(name=='shirt' || name=='mouth' || name=='hat' || name=='neck' || name=='mask' || name=='rh' || name=='hair' || name=='cape' || name=='mole') {
        gendered=true;
    }
    //Lets assume purely random byte value
    //Outfit is out of 16, find times out of 256
    var count=0;
    var restCount=0;
    for(var j=0;j<256;j++) {

        if(!gender) {
            gender=Math.floor(Math.random() * 2);
        }

        //Which outfit does this turn into?
        //var outOf16 = Math.floor(convertRange(Math.pow(i, 2), [0, 255*255], [1, 16]));

        var remappedVals = getRemappedVals();
        var outfitForByte = Math.round(scale(remappedVals[j], 1, 16, 1, numberOfStylesForLayer))-1;
        //scale(j, in_min, in_max, out_min, out_max)

        var mo = returnMergedFantumsOutfit(gender, name, outfitForByte);

        gender = returnMergedFantumsGender(gender, name, mo);



        outfitForByte=mo;

        if(outfitForByte==outfit) {
            count++;
        } else {
            restCount++;
        }
    }

    if(name=='hair' && outfit > 0) {
        count = count / 4;
    }

    if(name=='hair' && outfit==4) {
        count = count /3;
    }

    // ISSUE WITH GENDER SELECTION HERE
    if(name=='mouth' && outfit==1 && gender==1) {
        count = count / 4;
    } else if(name=='mouth' && outfit==3 && gender==1) {
        count = count / 4;
    } else if(name=='mouth' && outfit==4 && gender==1) {
        count = count / 4;
    }

    if(name=='mouth' && outfit==2) {
        count = count * 2;
    }
    if(name=='mask' && (outfit==2 || outfit==3 || outfit==5)) {
        count = count *2;
    }


    if(name=='rh' && outfit!=5 && outfit!=0) {
        count = count * 2;
    }

    // if(name=='mouth') {
    //     return count/256/2;
    // }

    // if(hairStr!='') {
    //     //Different multiples for hairStr
    //     if(hairStr=='y') {

    //         for(var j=0;j<256;j++) {
    //             //var retVal = valueBetween0And255(arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString());
    //             var hairNum = Math.round(convertRange(j, [0, 255], [0, 3]));
    //         }

            
    //     }

    // }



    if(gendered && (outfit > 0 || (name=='mouth' && outfit!=1 && outfit!=3 && outfit!=4)) && name!='cape') {
        return count/256/2;
    }


    return count/256;

}

export function oddsOfLayerAndOutfitGothums(gender, name, outfit) {



    var numberOfStylesForLayer = subs.layerData[name];

    var gendered=false;
    if(subs.layerDataGendered[name]) {
        gendered='_'+gender;
    }
    
    //Lets assume purely random byte value
    //Outfit is out of 16, find times out of 256
    var count=0;
    var restCount=0;
    for(var j=0;j<256;j++) {

        if(!gender) {
            gender=Math.floor(Math.random() * 2);
        }

        var remappedVals = getRemappedVals(32);
        var outfitForByte = Math.round(scale(remappedVals[j], 1, 32, 1, numberOfStylesForLayer))-1;
        //scale(j, in_min, in_max, out_min, out_max)

        var mo = returnMergedGothumsOutfit(gender, name, outfitForByte);

        gender = returnMergedGothumsGender(gender, name, mo);



        outfitForByte=mo;

        if(outfitForByte==outfit) {
            count++;
        } else {
            restCount++;
        }
    }

    // if(name=='hair' && outfit > 0) {
    //     count = count / 4;
    // }

    // if(name=='hair' && outfit==4) {
    //     count = count /3;
    // }

    // // ISSUE WITH GENDER SELECTION HERE
    // if(name=='mouth' && outfit==1 && gender==1) {
    //     count = count / 4;
    // } else if(name=='mouth' && outfit==3 && gender==1) {
    //     count = count / 4;
    // } else if(name=='mouth' && outfit==4 && gender==1) {
    //     count = count / 4;
    // }

    // if(name=='mouth' && outfit==2) {
    //     count = count * 2;
    // }
    // if(name=='mask' && (outfit==2 || outfit==3 || outfit==5)) {
    //     count = count *2;
    // }


    // if(name=='rh' && outfit!=5 && outfit!=0) {
    //     count = count * 2;
    // }

    // if(name=='mouth') {
    //     return count/256/2;
    // }

    // if(hairStr!='') {
    //     //Different multiples for hairStr
    //     if(hairStr=='y') {

    //         for(var j=0;j<256;j++) {
    //             //var retVal = valueBetween0And255(arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString());
    //             var hairNum = Math.round(convertRange(j, [0, 255], [0, 3]));
    //         }

            
    //     }

    // }



    if(gendered && outfit > 0) {
        return count/256/2;
    }


    return count/256;

}



    export function percentileNum(percentile) {

        if(percentile >= vars.percentileLevels[4]) {
            return 5;
        } else if(percentile >= vars.percentileLevels[3]) {
            return 4;
        } else if(percentile >= vars.percentileLevels[2]) {
            return 3;
        } else if(percentile >= vars.percentileLevels[1]) {
            return 2;
        } else if(percentile >= vars.percentileLevels[0]) {
            return 1;
        } else {
            return 0;
        }

    }

    export function rankToName(rank) {
        var rarNum = rankNum(rank);
        if(rarNum==0) {
            return ( vars.rollNames[0] );
        }
        if(rarNum==1) {
            return ( vars.rollNames[1] );
        }
        if(rarNum==2) {
            return ( vars.rollNames[2] );
        }
        if(rarNum==3) {
            return ( vars.rollNames[3] );
        }

    }

    export function rankNum(rank) {
        

        if(rank <= 10) { 
            return 3;
        } else if(rank <= 150) { 
            return 2;
        } else if(rank <= 500) { 
            return 1;
        } else {
            return 0;
        }

    }

    export function rollName(hash) {
        var rarNum = rollNum(hash);
        if(rarNum==0) {
            return ( vars.rollNames[0] );
        }
        if(rarNum==1) {
            return ( vars.rollNames[1] );
        }
        if(rarNum==2) {
            return ( vars.rollNames[2] );
        }
        if(rarNum==3) {
            return ( vars.rollNames[3] );
        }

    }



    export function rollNum(hash) {
        var rScore = rScoreGivenHash(hash);
        var maxVal = 16*Object.keys(subs.layerData).length;

        if(rScore > maxVal * vars.rollLevels[2]) { 
            return 3;
        } else if(rScore > maxVal * vars.rollLevels[1]) { 
            return 2;
        } else if(rScore > maxVal * vars.rollLevels[0]) { 
            return 1;
        } else {
            return 0;
        }

    }

  export function rScoreDiff(hash) {

    var rScore = rScoreGivenHash(hash);

     var maxVal = 16*Object.keys(subs.layerData).length;

    var absoluteDiffFromMid =Math.sqrt(Math.pow((maxVal/2) - rScore, 2));

    return Math.floor(100 - absoluteDiffFromMid / 192*100);
  }

  export function rScore(hash) {

    var rScore = rScoreGivenHash(hash);


    return rScore;
  }




export function useInterval(callback, delay) {
  const intervalId = React.useRef(null);
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  });
  React.useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalId.current);
    }
  }, [delay]);
  return intervalId.current;
}

export const HSBToRGB = (h, s, b) => {
  s /= 100;
  b /= 100;
  const k = (n) => (n + h / 60) % 6;
  const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
  return [255 * f(5), 255 * f(3), 255 * f(1)];
};

export class Color {
    constructor(r, g, b) { this.set(r, g, b); }
    toString() { return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`; }

    set(r, g, b) {
        this.r = this.clamp(r);
        this.g = this.clamp(g);
        this.b = this.clamp(b);
    }

    hueRotate(angle = 0) {
        angle = angle / 180 * Math.PI;
        let sin = Math.sin(angle);
        let cos = Math.cos(angle);

        this.multiply([
            0.213 + cos * 0.787 - sin * 0.213, 0.715 - cos * 0.715 - sin * 0.715, 0.072 - cos * 0.072 + sin * 0.928,
            0.213 - cos * 0.213 + sin * 0.143, 0.715 + cos * 0.285 + sin * 0.140, 0.072 - cos * 0.072 - sin * 0.283,
            0.213 - cos * 0.213 - sin * 0.787, 0.715 - cos * 0.715 + sin * 0.715, 0.072 + cos * 0.928 + sin * 0.072
        ]);
    }

    grayscale(value = 1) {
        this.multiply([
            0.2126 + 0.7874 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 - 0.0722 * (1 - value),
            0.2126 - 0.2126 * (1 - value), 0.7152 + 0.2848 * (1 - value), 0.0722 - 0.0722 * (1 - value),
            0.2126 - 0.2126 * (1 - value), 0.7152 - 0.7152 * (1 - value), 0.0722 + 0.9278 * (1 - value)
        ]);
    }

    sepia(value = 1) {
        this.multiply([
            0.393 + 0.607 * (1 - value), 0.769 - 0.769 * (1 - value), 0.189 - 0.189 * (1 - value),
            0.349 - 0.349 * (1 - value), 0.686 + 0.314 * (1 - value), 0.168 - 0.168 * (1 - value),
            0.272 - 0.272 * (1 - value), 0.534 - 0.534 * (1 - value), 0.131 + 0.869 * (1 - value)
        ]);
    }

    saturate(value = 1) {
        this.multiply([
            0.213 + 0.787 * value, 0.715 - 0.715 * value, 0.072 - 0.072 * value,
            0.213 - 0.213 * value, 0.715 + 0.285 * value, 0.072 - 0.072 * value,
            0.213 - 0.213 * value, 0.715 - 0.715 * value, 0.072 + 0.928 * value
        ]);
    }

    multiply(matrix) {
        let newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
        let newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
        let newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
        this.r = newR; this.g = newG; this.b = newB;
    }

    brightness(value = 1) { this.linear(value); }
    contrast(value = 1) { this.linear(value, -(0.5 * value) + 0.5); }

    linear(slope = 1, intercept = 0) {
        this.r = this.clamp(this.r * slope + intercept * 255);
        this.g = this.clamp(this.g * slope + intercept * 255);
        this.b = this.clamp(this.b * slope + intercept * 255);
    }

    invert(value = 1) {
        this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255);
        this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255);
        this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255);
    }

    hsl() { // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
        let r = this.r / 255;
        let g = this.g / 255;
        let b = this.b / 255;
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if(max === min) {
            h = s = 0;
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            } h /= 6;
        }

        return {
            h: h * 100,
            s: s * 100,
            l: l * 100
        };
    }

    clamp(value) {
        if(value > 255) { value = 255; }
        else if(value < 0) { value = 0; }
        return value;
    }
}


export class Solver {
    constructor(target) {
        this.target = target;
        this.targetHSL = target.hsl();
        this.reusedColor = new Color(0, 0, 0); // Object pool
    }

    solve() {
        let result = this.solveNarrow(this.solveWide());
        return {
            values: result.values,
            loss: result.loss,
            filter: this.css(result.values)
        };
    }

    solveWide() {
        const A = 5;
        const c = 15;
        const a = [60, 180, 18000, 600, 1.2, 1.2];

        let best = { loss: Infinity };
        for(let i = 0; best.loss > 25 && i < 3; i++) {
            let initial = [50, 20, 3750, 50, 100, 100];
            let result = this.spsa(A, a, c, initial, 1000);
            if(result.loss < best.loss) { best = result; }
        } return best;
    }

    solveNarrow(wide) {
        const A = wide.loss;
        const c = 2;
        const A1 = A + 1;
        const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
        return this.spsa(A, a, c, wide.values, 500);
    }

    spsa(A, a, c, values, iters) {
        const alpha = 1;
        const gamma = 0.16666666666666666;

        let best = null;
        let bestLoss = Infinity;
        let deltas = new Array(6);
        let highArgs = new Array(6);
        let lowArgs = new Array(6);

        for(let k = 0; k < iters; k++) {
            let ck = c / Math.pow(k + 1, gamma);
            for(let i = 0; i < 6; i++) {
                deltas[i] = Math.random() > 0.5 ? 1 : -1;
                highArgs[i] = values[i] + ck * deltas[i];
                lowArgs[i]  = values[i] - ck * deltas[i];
            }

            let lossDiff = this.loss(highArgs) - this.loss(lowArgs);
            for(let i = 0; i < 6; i++) {
                let g = lossDiff / (2 * ck) * deltas[i];
                let ak = a[i] / Math.pow(A + k + 1, alpha);
                values[i] = fix(values[i] - ak * g, i);
            }

            let loss = this.loss(values);
            if(loss < bestLoss) { best = values.slice(0); bestLoss = loss; }
        } return { values: best, loss: bestLoss };

        function fix(value, idx) {
            let max = 100;
            if(idx === 2 /* saturate */) { max = 7500; }
            else if(idx === 4 /* brightness */ || idx === 5 /* contrast */) { max = 200; }

            if(idx === 3 /* hue-rotate */) {
                if(value > max) { value = value % max; }
                else if(value < 0) { value = max + value % max; }
            } else if(value < 0) { value = 0; }
            else if(value > max) { value = max; }
            return value;
        }
    }

    loss(filters) { // Argument is array of percentages.
        let color = this.reusedColor;
        color.set(0, 0, 0);

        color.invert(filters[0] / 100);
        color.sepia(filters[1] / 100);
        color.saturate(filters[2] / 100);
        color.hueRotate(filters[3] * 3.6);
        color.brightness(filters[4] / 100);
        color.contrast(filters[5] / 100);

        let colorHSL = color.hsl();
        return Math.abs(color.r - this.target.r)
            + Math.abs(color.g - this.target.g)
            + Math.abs(color.b - this.target.b)
            + Math.abs(colorHSL.h - this.targetHSL.h)
            + Math.abs(colorHSL.s - this.targetHSL.s)
            + Math.abs(colorHSL.l - this.targetHSL.l);
    }

     css(filters) {
        function fmt(idx, multiplier = 1) { return Math.round(filters[idx] * multiplier); }
        return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`;
    }
}


//export default HSBToRGB;