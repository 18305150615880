import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
// import Fantum from './Fantum';
// import * as EVM from './../evm.js';
import * as vars from './../vars.js'; import * as subs from './../subs.js';

import { genderStrFromHashArr } from './../generate.js'

const fetch = require('node-fetch');

var tokenIds=false;
//var isLoading = true;

function About() {

    //const [hash, setHash] = useState(['']);

    const [maleTokenObj, setMaleTokenObj] = useState(false);
    const [femaleTokenObj, setFemaleTokenObj] = useState(false);

    const [render, setRender] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    function getTwoFantums() {

        if(isLoading) {
            return;
        }


        setIsLoading(true);

        if(vars.comingSoonSiteMode) {
            return false;
        }

        console.log('Loading fantum...');

        (async () => {


            var totalFantumsURL = subs.apis[subs.apiId].url+'totalFantums';
            if(!tokenIds) {
                const response1 = await fetch(totalFantumsURL);
                const totalObj = await response1.json();
                tokenIds = totalObj[0];
                console.log('tokenIds: '+tokenIds);
            }
            

          try {

            if(tokenIds) {
                var maleObj;
                var femaleObj;
                const response = await fetch(subs.apis[subs.publicApiId].url+'token1/'+parseInt(tokenIds).toString());
                maleObj = await response.json();
                setMaleTokenObj(maleObj);

                const response2 = await fetch(subs.apis[subs.publicApiId].url+'token1/'+parseInt(tokenIds-1).toString());
                femaleObj = await response2.json();
                setMaleTokenObj(maleObj);
                setFemaleTokenObj(femaleObj);

                setRender(render+1);
            }

            

            
          } catch (error) {
            console.log(error);
          }
        })();
        //}
    }

    setInterval(function() {

        if(!vars.comingSoonSiteMode) {

             if(maleTokenObj && femaleTokenObj) {
                setIsLoading(false);
               }
        }

        

      }, 1000);

    useEffect(() => {
       getTwoFantums();
    }, []);
    
    return (
        <>
        <div className={(!subs.subsite || subs.subsite=='rarity')?"appOperaAbout":"appOperaMansion"}>
            <div id={(!subs.subsite || subs.subsite=='rarity')?"operaHolderAbout":"operaHolderMansion"}>
            <div id={(!subs.subsite || subs.subsite=='rarity')?"curtainSquareAbout":"curtainSquareMansion"}>
                <span className="cs_3_about trans_font">EXPLORE THE DEPTHS OF THE<br/> OPERA CHAIN</span><br/>
                <span className="cs_3_about trans_font">DISCOVER{!subs.subsite ? ' OPERATIC' : ''} CRYPTO COLLECTIBLES</span>
                <br/>
                <span className={(!subs.subsite || subs.subsite=='rarity')? 'hidden' : 'inline-block'}> <br/> <br/> <br/><br/> <br/> <br/><br/></span>
                {renderLiveSite()}
                
                
            </div>
            </div>
        </div>
        <div className="find_dirt_about desktop">
            <div className="about_contains_info">
                <br/>
                <div className="clear"></div>

                <div className="float_icon_left">
                    <img alt="NFT on Phone" width="80" height="160" src="/img/nft_on_phone.png" />
                </div>
                <div className="float_text_right">
                    <h4><b>FANTUMS ARE NFTs</b></h4>
                    <p>Fantums are standardized Non-Fungible Tokens (ERC-721). This type of token is integrated into desktop and mobile wallets, blockchain explorers, and online collectible marketplaces.</p>
                    <br/>
                    <p>Fantums are on the Fantom blockchain, one of the fastest and cheapest EVM-compatible chains to use.</p>
                    <br/>
                    <p>We hope this combination of a popular standard and the use of an efficient blockchain create a lasting, worthwhile collectible.</p>
                </div>

                <div className="clear"></div>
                <br/><br/>

                <div className="float_text_left">
                    <h4><b>FANTUMS ARE UNIQUE</b></h4>
                    <p>Fantums are generatively created art, each based on a completely unique Keccak-256 hash.</p>
                    <br/>
                    <p>These hashes are also <i>mined</i> during the Fantum Finding process.</p>
                </div>
                <div className="float_icon_right non_fantum_rendering">
                    <img alt="Unique Icon" width="90" height="90" src="img/unique_icon.png" />
                </div>

                
                
                <div className="clear"></div>
                <br/><br/>
                <div className="float_icon_left non_fantum_rendering">
                    <img alt="Mint Icon" width="90" height="90" src="img/mint_icon.png" />
                </div>
                <div className="float_text_right">
                    <h4><b>THE SUPPLY OF FANTUMS</b></h4>
                    <p>There will only be 11,000 Fantums in existence.</p>
                    
                </div>

                <div className="clear"></div>
                <br/><br/>
                <div className="float_text_left">
                    <h4><b>NAMING FANTUMS</b></h4>
                    <p>Add your own customized touch to Fantums by naming them.</p><br/>
                    <p>They can be named and renamed, but the cost doubles each time.</p>
                </div>
                <div className="float_icon_right non_fantum_rendering">
                    <img alt="Naming Icon" width="90" height="90" src="img/naming_icon.png" />
                </div>

                

                <div className="clear"></div>
                <br/>
            </div>
        </div>

        <div className="find_black_about desktop">
            <div className="about_contains_info">
                <div className="clear"></div>
                    <br/>
                    <div className="float_icon_left non_fantum_rendering">
                        <img alt="FOO Icon" width="90" height="90" src="img/foo_icon.png" />
                        <p>$FOO</p>
                    </div>
                    <div className="float_text_right">
                        <h4><b>REVENUE & ECOSYSTEM</b></h4>
                        <p>Revenue generated is used to buyback and burn $FOO from the market.</p><br/>
                        <p>This is done through Sushiswap, during the mint or naming transaction.</p><br/>
                        <p>We're hoping to develop a $FOO ecosystem with profitable products and shared revenue.</p><br/>
                        <p>Future products may include Fantom blockchain based NFT wallets, marketplaces, and more...</p>
                    </div>
                    
                    <div className="clear"></div>
                    <br/>
            </div>

        </div>


        <div className="find_dirt_about mobile">
            <div className="about_contains_info_mobile">
                <br/>
                <div className="clear"></div>

                <div className="float_icon_left_mobile">
                    <img alt="NFT on Phone" width="80" height="160" src="/img/nft_on_phone.png" />
                </div>
                <div className="float_text_right_mobile">
                    <h4><b>FANTUMS ARE NFTs</b></h4>
                    <p>Fantums are standardized Non-Fungible Tokens (ERC-721). This type of token is integrated into desktop and mobile wallets, blockchain explorers, and online collectible marketplaces.</p>
                    <br/>
                    <p>Fantums are on the Fantom blockchain, one of the fastest and cheapest EVM-compatible chains to use.</p>
                    <br/>
                    <p>We hope this combination of a popular standard and the use of an efficient blockchain create a lasting, worthwhile collectible.</p>
                </div>

                <div className="clear"></div>
                <br/><br/>

                <div className="float_icon_right_mobile non_fantum_rendering">
                    <img alt="Unique Icon" width="90" height="90" src="img/unique_icon.png" />
                </div>
                <div className="float_text_left_mobile">
                    <h4><b>FANTUMS ARE UNIQUE</b></h4>
                    <p>Fantums are generatively created art, each based on a completely unique Keccak-256 hash.</p>
                    <br/>
                    <p>These hashes are also <i>mined</i> during the Fantum Finding process.</p>
                </div>
                

                
                
                <div className="clear"></div>
                <br/><br/>
                <div className="float_icon_left_mobile non_fantum_rendering">
                    <img alt="Mint Icon" width="90" height="90" src="img/mint_icon.png" />
                </div>
                <div className="float_text_right_mobile">
                    <h4><b>THE SUPPLY OF FANTUMS</b></h4>
                    <p>There will only be 11,000 Fantums in existence.</p>
                    
                </div>

                <div className="clear"></div>
                <br/><br/>
                <div className="float_icon_right_mobile non_fantum_rendering">
                    <img alt="Naming Icon" width="90" height="90" src="img/naming_icon.png" />
                </div>
                <div className="float_text_left_mobile">
                    <h4><b>NAMING FANTUMS</b></h4>
                    <p>Add your own customized touch to Fantums by naming them.</p><br/>
                    <p>They can be named and renamed, but the cost doubles each time.</p>
                </div>
                

                

                <div className="clear"></div>
                <br/>
            </div>
        </div>

        <div className="find_black_about mobile">
            <div className="about_contains_info_mobile">
                <div className="clear"></div>
                    <br/>
                    <div className="float_icon_left_mobile non_fantum_rendering">
                        <img alt="FOO Icon" width="90" height="90" src="img/foo_icon.png" />
                        <p>$FOO</p>
                    </div>
                    <div className="float_text_right_mobile">
                        <h4><b>REVENUE & ECOSYSTEM</b></h4>
                        <p>Revenue generated is used to buyback and burn $FOO from the market.</p><br/>
                        <p>This is done through Sushiswap, during the mint or naming transaction.</p><br/>
                        <p>We're hoping to develop a $FOO ecosystem with profitable products and shared revenue.</p><br/>
                        <p>Future products may include Fantom blockchain based NFT wallets, marketplaces, and more...</p>
                    </div>
                    
                    <div className="clear"></div>
                    <br/>
            </div>

        </div>

        </>
    );

    function renderLiveSite() {
        if(!vars.comingSoonSiteMode) {

        
        return(<><br/><Button size="lg" variant="primary" href={(!subs.subsite || subs.subsite=='rarity')?"https://fantums.com/browse":"/mint"}>{!subs.subsite?'BROWSE':'FIND'} {subs.nameOfFantums} NOW</Button>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className={isLoading ? '' : 'hidden'}>
                    <div id="rotatingSkellyAbout" className={!isLoading ? '' : 'rotate'}></div>
                </div>
                <div className={!isLoading ? '' : 'hidden'}>
                {haveHashes(maleTokenObj.hash, femaleTokenObj.hash)}
                </div>
                <br/></>);
        } else {

            return (<>
                <span className="cs_2_about">COMING SOON</span>
                <div id="bob_christine_container">

                <img alt="Bob Fantum" src="img/Bob.png" width="160" height="160"/>
                <img alt="Christine Fantum" src="img/Christine.png" width="160" height="160"/>
                </div></>

                );

        }
    }
  function haveHashes(male, female) {
    //console.log('checking have male: '+male);
    //console.log(maleTokenObj);
      if(male!==undefined && female!==undefined) {
        //console.log('have noth');
        return (  <>
            <div className="aboutFantumContainer">


                <div className="aboutMale">
                <a href={'/token/'+maleTokenObj.id}><img alt="Male Fantum" width="160" height="160" src={maleTokenObj.image} /></a>
                <a href={'/token/'+maleTokenObj.id}>{maleTokenObj.name}</a>
                </div>
                <div className="aboutFemale">
                <a href={'/token/'+femaleTokenObj.id}><img alt="Female Fantum" width="160" height="160" src={femaleTokenObj.image} /></a>
                <a href={'/token/'+femaleTokenObj.id}>{femaleTokenObj.name}</a>
                </div>
                </div>
        
            </> );
      }
        
    }


}
  
export default About;