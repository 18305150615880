import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { addysEqual, rollNum, modal, getObjFromJSONUrl, callMethod, callMethodWithNonce, sleep } from './../helpers.js'
import {
  useParams
} from "react-router-dom";
import SelectTotGothum from './SelectTotGothum';
import { motion } from "framer-motion";

var selectedFantum;
var gettingPossibleFighters;
var receivedContent;
var lastConnectedAccount;

var lastDoorId;


var gameOverMode=false;

var qm = 'https://gothums.com/img/question_mark.png';

function TrickOrTreat(props) {

    const [renderGothum, setRenderGothum] = useState(false);

    const [messageScale, setMessageScale] = useState(1.0);

    const [messageY, setMessageY] = useState(0.0);

    const [lfX, setLfX] = React.useState(0);
    const [lfY, setLfY] = React.useState([-2, 2]);
    const [lfScale, setLfScale] = React.useState(1);

    const [lfDuration, setLfDuration] = React.useState(0.25);
    

    const [openChoose, setOpenChoose] = useState(false);
    const [isLoadingChoices, setIsLoadingChoices] = useState(false);

    var [possibleFighters, setPossibleFighters] = useState([]);

    var [lastRes, setLastRes] = useState(false);


    const [isToting, setIsToting] = useState(false);

    const [doorColors, setDoorColors] = useState(['Grey','Grey','Grey','Grey']);



    function selectAGothum() {
        setOpenChoose(true);
        getUsersGothums();

        setLfX(0);
        setLfY([-2, 2]);
        setLfScale(1);
        setLfDuration(0.25);
    }

    async function getUsersGothums() {

        if(!EVM.connectedAccount) {
            return;
        }
        if(gettingPossibleFighters) {
            return;
        }

        setIsLoadingChoices(true);

        gettingPossibleFighters = true;
        const tokensObj = await getObjFromJSONUrl(
            subs.apis[subs.apiId].url+'tokens/page=1&num=1000&orderBy=level&order=DESC&addr='+EVM.connectedAccount.toString()+'&forSale=&duel=1&q='
        );
        //console.log(tokensObj[0]);
        var tokens = [];

        for(var i=0;i<tokensObj[0].length;i++) {
            var tokenObj = tokensObj[0][i];
            var tokenURI = subs.apis[subs.apiId].url+'token/'+tokenObj.id;

            var newTokens = [];
            //if(tokenObj.price <= 0) {
            newTokens = tokens.concat({
              id: tokenObj.id, 
              hash: tokenObj.hash, 
              owner: tokenObj.owner,
              name: tokenObj.name,
              tokenURI: tokenURI,
              image: tokenObj.image,
              image32: tokenObj.image32,
              rarity: tokenObj.rarity,
              price: tokenObj.price,
              level: tokenObj.level,
              totalFantums: parseInt(tokenObj.totalFantums),
              receivedCandy: tokenObj.receivedCandy
            }); 
        //}
        
            tokens=newTokens;
        }
    setPossibleFighters(tokens);

    gettingPossibleFighters = false;

    receivedContent = true;


    setIsLoadingChoices(false);


    }

    async function getGothum(tokenId) {

        if(tokenId <= 0 || !tokenId) {
            return;
        }

        const response = await fetch(subs.apis[subs.apiId].url+'token/'+tokenId);
        var fighter = await response.json();


        return fighter;
    }

    async function setDoorResult(doorId) {



        if(doorId==0) {
            setDoorColors(['Open','Grey','Grey','Grey']);
        } else if(doorId==1) {
            setDoorColors(['Grey','Open','Grey','Grey']);
        } else if(doorId==2) {
            setDoorColors(['Grey','Grey','Open','Grey']);
        } else if(doorId==3) {
            setDoorColors(['Grey','Grey','Grey','Open']);
        }

        // setMessageScale(0.0);
        // //setMessageY(0.0);

        // setInterval(function() {

        //     setMessageScale(1.0);
        //     //setMessageY(0.0);

        // }, 1000);


        

    }


    async function colorDoorsIfPlayed(tokenId) {

        if(!EVM.tot_contract) {
            return;
        }

        let recentlyPlayed = await EVM.tot_contract.methods.recentlyPlayed(tokenId, parseInt(Date.now()/1000)).call();
        console.log('recentlyPlayed: '+recentlyPlayed);



        if(recentlyPlayed) {
            gameOverMode=true;

            lastDoorId = await EVM.tot_contract.methods.lastDoorId(tokenId).call();
            console.log('lastDoorId: '+lastDoorId);
            setDoorResult(lastDoorId);
            let lastResult = await EVM.tot_contract.methods.lastResult(parseInt(tokenId)).call();
            console.log('lastRes: '+lastResult);
            setLastRes(lastResult);
            

            //setDoorColors(['Grey','Grey','Grey','Grey']);
        } else {
            setDoorColors(['Red','Green','Purple','Orange']);
        }

    }

    async function choseGothum(tokenId) {

        
  


        lastDoorId=-1;

        gameOverMode=false;

        setOpenChoose(false);

        await colorDoorsIfPlayed(tokenId);

        console.log('choseGothum('+tokenId+')');

        if(!EVM.fum_contract) {
            return;
        }

        if(!tokenId) {
            return;
        }

        selectedFantum = await getGothum(tokenId);


        // setLfX(0);
        // setLfY([-2, 2]);
        // setLfScale(1.0);
        // setLfDuration(0.25);

        setRenderGothum(selectedFantum);

    }

    async function selectDoor(doorId) {


        if(!selectedFantum) {
            selectAGothum();
            return;
        }

        if(!EVM.tot_contract) {
            return;
        }

        if(gameOverMode) {
            selectAGothum();
            return;
        }

        let recentlyPlayed = await EVM.tot_contract.methods.recentlyPlayed(parseInt(selectedFantum.id), parseInt(Date.now()/1000)).call();
        console.log('recentlyPlayed: '+recentlyPlayed)

        if(recentlyPlayed) {
            alert('Oops, you have played in the last 24 hours! Please wait!')
            return;
        }


        setIsToting(true);


        await callMethod(EVM.tot_contract.methods.trickOrTreat(parseInt(selectedFantum.id), parseInt(doorId)), 0, EVM.connectedAccount, 150000, async function(err3, res3) {

           


            if(err3) {
                console.log(err3.message);
                 setIsToting(false);

            } else {

                do {
                    await sleep(1000);

                    var found=false;
                    let lastTrickOrTreated = await EVM.tot_contract.methods.lastTrickOrTreated(parseInt(selectedFantum.id)).call();
                    console.log('lastTrickOrTreated: '+lastTrickOrTreated);
                    if(lastTrickOrTreated >= parseInt(Date.now()/1000)-240) {
                        found=true;
                    }
                } while(!found);

                 setIsToting(false);

                gameOverMode=true;
                lastDoorId = doorId;
                setDoorResult(doorId);
                let lastResult = await EVM.tot_contract.methods.lastResult(parseInt(selectedFantum.id)).call();
                console.log('lastRes: '+lastResult);
                setLastRes(lastResult);

            }
        });
            
        
    }
    

    useEffect(() => {

        setInterval(async function() {

        if(EVM.connectedAccount != lastConnectedAccount) {
            getUsersGothums();
            lastConnectedAccount = EVM.connectedAccount;
        }

        }, 500);

    }, []);


    function printDoor(doorId) {



        if(lastDoorId==doorId) {
            

            if(lastRes==0) {
                return (
                        <div className="tot_result"><b>TRICKED!<br/><br/><img src="/img/mm_riplogo512.png" width="30" height="30" /></b><br/><br/>NO CANDY FOR YOU!</div>
                        );
            }
            if(lastRes==25) {
                return (
                    
                        <div className="tot_result">WIN!<br/><br/><img src="/img/Candy512.png" width="30" height="30" /><br/><br/> +25 CANDY</div>);
            }
            if(lastRes==50) {
                return (
                        <div className="tot_result">WIN!<br/><br/><img src="/img/Candy512.png" width="30" height="30" /><br/><br/> +50 CANDY</div>);
            }
            if(lastRes==100) {
                return (
                        <div className="tot_result">WIN!<br/><br/><img src="/img/Candy512.png" width="30" height="30" /><br/><br/> +100 CANDY</div>
                        );
            }

            
        } else {
            return (<></>);
        }

    }


    // if(!EVM.isTester) {
    //     return (<></>);
    // }


    return (

        

        <>

        {modal(openChoose, () => setOpenChoose(false), 'CHOOSE A GOTHUM', function() {
        return (<>
                <div className="fighterSelectModal">
                <div className={isLoadingChoices ? '' : 'hidden'}>
                    <div id="rotatingSkelly" className={!isLoadingChoices ? '' : 'rotate'}></div>
                </div>
              {possibleFighters.map((token, index) => (
                    <SelectTotGothum key={index} token={token} rowsize={60} onFighterClick={() => choseGothum(token.id)} />
                ))}
              </div>
              </>);
          })}

        <div className="">
        <div className="appOperaGothumsFind ">
            <div className={(!subs.subsite?"find_dirt":"find_dirt2")+" tot_solid"}>
            <br/><br/>
                <span className="pickADoor">TRICK OR TREAT</span><br/>
                <span className="pickADoor2">SELECT A GOTHUM, PICK A DOOR.</span><br/>
                <span className="pickADoor2"><i>FIND SOME CANDY,</i> <br/><b>MAYBE MORE!</b></span>

                


                <div id="doors">
                    <div id="d1" className={"doors door"+doorColors[0]} onClick={() => {selectDoor(0)}}>
                        {printDoor(0)}
                    </div>
                    <div id="d2" className={"doors door"+doorColors[1]} onClick={() => {selectDoor(1)}}>
                        {printDoor(1)}
                    </div>
                    <div id="d3" className={"doors door"+doorColors[2]} onClick={() => {selectDoor(2)}}>
                        {printDoor(2)}
                    </div>
                    <div id="d4" className={"doors door"+doorColors[3]} onClick={() => {selectDoor(3)}}>
                        {printDoor(3)}
                    </div>
                </div>

                <div className={isToting?'hidden':''}>
                    <div id="gum_track">
                        <div className="gum_holder">

                        <motion.div
                          animate={{
                            scale: lfScale,
                            x: lfX,
                            y: lfY,

                          }}
                          transition={{ repeat: Infinity,  repeatType: "reverse", delay: 0.25, duration: lfDuration }}
                        >
                       
                        
                            <div className="gum_tot_name">{!renderGothum ? '' : renderGothum.name}</div>
                            <div className="gum_tot_img"><img width="280" height="280" src={!renderGothum ? qm : renderGothum.image} /></div>
           


                        </motion.div>


                            
                        </div>
                    </div>
                </div>

                <div className={!isToting?'hidden':''}>
                    <div id="rotatingSkelly" className='rotate'></div><br/><br/><br/><br/>
                </div>
                
                <Button size="lg" variant="primary" onClick={() => {selectAGothum()}}>{!renderGothum ? 'SELECT A GOTHUM' : 'CHANGE GOTHUM'}</Button>
                <br/>
                <br/><br/><br/>
                <span className="pickADoor3">*TRICK-OR-TREAT ONCE PER GOTHUM PER DAY*</span><br/>
                <br/><br/><br/>
            </div>
        </div>
        </div>
        </>
    );

 


}
  
export default TrickOrTreat;