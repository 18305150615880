import React, {useState, useEffect} from 'react';
import Match from './Match';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rollName, rollNum, rScore, getObjFromJSONUrl } from './../helpers.js'
import {
  useParams
} from "react-router-dom";

var receivedContent = 0;

var haveFighters = [];

var gettingMatches=false;

var pageNum=1;

var totalMatches=0;

var mpp = 5;

function DuelHistory() {

	let { num } = useParams();
	if(num) {
		pageNum = num;
	}


	var [matches, setMatches] = useState([]);


	const [isLoading, setIsLoading] = useState(true);

	const [haveTheseFighters, setHaveTheseFighters] = useState([]);


	async function getFighter(tokenId) {

		console.log('Getting fighter... '+tokenId);

		const response = await fetch(subs.apis[subs.apiId].url+'token/'+tokenId);
	    var fighter = await response.json();
	   	return fighter;
	}

	function fighterForId(tokenId) {
		for(var j=0;j<haveTheseFighters.length;j++) {
			if(tokenId==haveTheseFighters[j].id) {
				return haveTheseFighters[j];
			}
		}
		return false;
	}

	async function load() {



		if(!EVM.connectedAccount) {
			return;
		}

		if(gettingMatches) {
			return;
		}

		setIsLoading(true);
		gettingMatches=true;
		var matchesObj = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'matchesForAddress/'+EVM.connectedAccount.toString()
		);

		totalMatches = matchesObj.length;

		matchesObj = matchesObj.slice((pageNum-1)*mpp, (pageNum-1)*mpp+mpp);

		for(var i=0;i<matchesObj.length;i++) {
			var theMatch = matchesObj[i];

			var haveThisFighter=false;
			for(var j=0;j<haveFighters.length;j++) {
				//console.log('in here '+j);
				if(parseInt(theMatch.lfId)==parseInt(haveFighters[j].id)) {
					haveThisFighter=true;
				}
			}
			if(!haveThisFighter) {
				//console.log('We do not have this fighter: '+theMatch.lfId);
				var lf = await getFighter(theMatch.lfId);
				haveFighters.push(lf);
			}

			//console.log(haveFighters);
			var haveThisFighter2=false;
			for(var j=0;j<haveFighters.length;j++) {
				if(parseInt(theMatch.rfId)==parseInt(haveFighters[j].id)) {
					haveThisFighter2=true;
				}
			}
			if(!haveThisFighter2) {
				var rf = await getFighter(theMatch.rfId);
				haveFighters.push(rf);
			}
		}


		setHaveTheseFighters(haveFighters);

		setIsLoading(false);
		setMatches(matchesObj);
		receivedContent=1;
		gettingMatches=false;
		
	}


		const [render, setRender] = useState(0);



	useEffect(() => {
		setTimeout(function() {
			setRender(true);
			

		}, 2000);

		setInterval(function() {


			if(receivedContent==0) {
				load();
			}

		}, 1000);

	}, []);

function prevPage() {

	var totalPages = Math.ceil(totalMatches / mpp);
	var hasNext=false;
	if(matches.length > 0 && totalPages > pageNum && !isLoading) {
		hasNext=true;
	}


	if(pageNum>1 && !isLoading && hasNext) {
		return (<><a href={"/duel_history/"+(parseInt(pageNum)-1)}>Previous</a> · </>);
	} else if(pageNum>1 && !isLoading) {
		return (<><a href={"/duel_history/"+(parseInt(pageNum)-1)}>Previous</a></>);
	}

	return (<></>);
}

function nextPage() {

	var totalPages = Math.ceil(totalMatches / mpp);
	//console.log('totalPages: '+totalPages);

	if(matches.length > 0 && totalPages > pageNum && !isLoading) {
		return (<><a href={"/duel_history/"+(parseInt(pageNum)+1)}>Next</a></>);
	}
}

function noEvents() {
	if(matches.length<=0 && !isLoading) {
		return (<><p>No matches.</p></>);
	}
}

if(!window.ethereum) {
	return (<><div id="operaHolderStreets"><br/><br/><br/><p>MetaMask not available.</p></div></>);
}
// if(EVM.duelsPrivate) {
// 	return (<><h1><span style={{color: '#FFF'}}>Are you supposed to be here?</span></h1></>);
// }


return ( <>
<div id="operaHolderStreets">
	<div className="leader_container">
		<h3><b>DUEL HISTORY</b></h3>
		<span className={EVM.connectedAccount ? '' : 'hidden'}><p>Matches for {EVM.connectedAccount.toString().substr(0, 6)+'...'+EVM.connectedAccount.toString().substr(38, 4)}</p><br/><br/></span>
		<div className={(isLoading ? '' : 'hidden')}>
			<div id="rotatingSkelly" className='rotate'></div>
		</div>
		{matches.map((match, index) => (
			<Match key={index} match={match} index={index} lf={fighterForId(match.lfId)} rf={fighterForId(match.rfId)} />
		))}
		{noEvents()}
		<br/>
		<span className="duel_history_page_controls">{prevPage()} {nextPage()}</span>
		<br/><br/>
	</div>
</div>
</>);


// return ( 
// <>
//          <div className="white_page">
//        <div className="doc_container">

//        <div className="doc_align_left">

// 				<h3><b>Duel History</b></h3>
// 				<br/>

// 				{matches.map((match, index) => (
// 					<div key={index} className="match_history">
// 					<a href={"/duel/"+match.id}>Match #{match.id} - Result: {match.result} - Left Fighter: {match.lfId} - Right Fighter: {match.rfId}</a>
// 					</div>
// 				))}
// 				<br/>
// 				<br/>
// 				<div className="clear">
// 				</div>
// 				</div>
// 		</div>
// 		</div>



//         </>


// );

}

export default DuelHistory;