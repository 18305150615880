import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";
var md5 = require('md5');

function CircleIcon(props) {

	useEffect(() => {

		var canvas = document.getElementById(md5("circle"+props.src+props.href));
    var context = canvas.getContext("2d");
    var rect = canvas.getBoundingClientRect();
    var posx = props.size/2;
    var posy = props.size/2;

    context.imageSmoothingEnabled = true;

    context.fillStyle = subs.buttonColors[0];
    context.beginPath();
    context.arc(posx, posy, props.size/2, 0, 2 * Math.PI);
    context.fill();



 	}, []);
	

   


	return (<div style={{verticalAlign: 'middle', display: 'inline-block', marginTop: '4px', padding: '0px 8px'}}>
		<canvas id={md5("circle"+props.src+props.href)} width={props.size} height={props.size}>
		</canvas>
		<a href={props.href}><img src={props.src} width={props.size} height={props.size} style={{marginLeft: '-'+props.size+'px', position: 'absolute'}} /></a>
		
		</div>);

}

export default CircleIcon;