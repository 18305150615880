import * as vars from './vars.js'; import * as subs from './subs.js';

var Color = require('color');

var rareColorVariants = [];
  for(var i=0;i<vars.rarityTextColors.length;i++) {
    if(!rareColorVariants[i]) {
      rareColorVariants[i]=[];
    }
    rareColorVariants[i][0] = Color(vars.rarityTextColors[i]).hex();
    rareColorVariants[i][1] = Color(vars.rarityTextColors[i]).lighten(0.25).hex();
    rareColorVariants[i][2] = Color(vars.rarityTextColors[i]).darken(0.1).hex();
    rareColorVariants[i][3] = Color(vars.rarityTextColors[i]).darken(0.2).hex();
  }

  


  const colorStyles = {

    '--primaryColor' : Color(subs.buttonColors[0]).hex(),
    '--primaryColorLighter' : Color(subs.buttonColors[0]).lighten(0.25).hex(),
    '--primaryColorDarken' : Color(subs.buttonColors[0]).darken(0.1).hex(),
    '--primaryColorDarkenMore' : Color(subs.buttonColors[0]).darken(0.2).hex(),

    '--secondaryColor' : Color(subs.buttonColors[1]).hex(),
    '--secondaryColorLighter' : Color(subs.buttonColors[1]).lighten(0.25).hex(),
    '--secondaryColorDarken' : Color(subs.buttonColors[1]).darken(0.1).hex(),
    '--secondaryColorDarkenMore' : Color(subs.buttonColors[1]).darken(0.2).hex(),


    '--rare0': rareColorVariants[0][0],
    '--rare0Lighter': rareColorVariants[0][1],
    '--rare0Darken': rareColorVariants[0][2],
    '--rare0DarkenMore': rareColorVariants[0][3],

    '--rare1': rareColorVariants[1][0],
    '--rare1Lighter': rareColorVariants[1][1],
    '--rare1Darken': rareColorVariants[1][2],
    '--rare1DarkenMore': rareColorVariants[1][3],

    '--rare2': rareColorVariants[2][0],
    '--rare2Lighter': rareColorVariants[2][1],
    '--rare2Darken': rareColorVariants[2][2],
    '--rare1DarkenMore': rareColorVariants[2][3],

    '--rare3': rareColorVariants[3][0],
    '--rare3Lighter': rareColorVariants[3][1],
    '--rare3Darken': rareColorVariants[3][2],
    '--rare1DarkenMore': rareColorVariants[3][3],

    '--rare0New': vars.rarityTextColorsNew[0],
    '--rare1New': vars.rarityTextColorsNew[1],
    '--rare2New': vars.rarityTextColorsNew[2],
    '--rare3New': vars.rarityTextColorsNew[3],
    '--rare4New': vars.rarityTextColorsNew[4],
    '--rare5New': vars.rarityTextColorsNew[5],

    '--tokenPageColorsBlack0': vars.tokenPageColors['black'][0],
    '--tokenPageColorsBlack1': vars.tokenPageColors['black'][1],
    '--tokenPageColorsBlack2': vars.tokenPageColors['black'][2],
    '--tokenPageColorsBlack3': vars.tokenPageColors['black'][3],
    '--tokenPageColorsWhite0': vars.tokenPageColors['white'][0],
    '--tokenPageColorsWhite1': vars.tokenPageColors['white'][1],
    '--tokenPageColorsWhite2': vars.tokenPageColors['white'][2],
    '--tokenPageColorsWhite3': vars.tokenPageColors['white'][3],
    '--tokenPageColorsOpera0': vars.tokenPageColors['opera'][0],
    '--tokenPageColorsOpera1': vars.tokenPageColors['opera'][1],
    '--tokenPageColorsOpera2': vars.tokenPageColors['opera'][2],
    '--tokenPageColorsOpera3': vars.tokenPageColors['opera'][3],
    '--tokenPageColorsOperaGM0': vars.tokenPageColors['opera_gm'][0],
    '--tokenPageColorsOperaGM1': vars.tokenPageColors['opera_gm'][1],
    '--tokenPageColorsOperaGM2': vars.tokenPageColors['opera_gm'][2],
    '--tokenPageColorsOperaGM3': vars.tokenPageColors['opera_gm'][3],
  };


  export {
    colorStyles
    }