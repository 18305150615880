import Web3 from "web3";
import * as vars from './vars.js';
import { getContractInfoObj, sleep } from './helpers.js'

import FI_ABI from './json/FI_ABI.json';
import FI_ADD from './json/FI_ADD.json';
import FIA_ABI from './json/FIA_ABI.json';
import FIA_ADD from './json/FIA_ADD.json';

import FUM_ABI from './json/FUM_ABI.json';
import FUM_ADD from './json/FUM_ADD.json';

import GUM_ABI from './json/GUM_ABI.json';
import GUM_ADD from './json/GUM_ADD.json';

import FTC_ABI from './json/FTC_ABI.json';
import FTC_ADD from './json/FTC_ADD.json';

import GTC_ABI from './json/GTC_ABI.json';
import GTC_ADD from './json/GTC_ADD.json';

import CNDY_ABI from './json/CNDY_ABI.json';
import CNDY_ADD from './json/CNDY_ADD.json';
import TOT_ABI from './json/TOT_ABI.json';
import TOT_ADD from './json/TOT_ADD.json';


import FLF_ABI from './json/FLF_ABI.json';
import FLF_ADD from './json/FLF_ADD.json';
import FLF2_ABI from './json/FLF2_ABI.json';
import FLF2_ADD from './json/FLF2_ADD.json';
import ERC20_ABI from './json/ERC20_ABI.json';


import DUEL_ABI from './json/DUEL_ABI.json';
import DUEL_ADD from './json/DUEL_ADD.json';
import GY_ABI from './json/GY_ABI.json';
import GY_ADD from './json/GY_ADD.json';
import RIP_ABI from './json/RIP_ABI.json';
import RIP_ADD from './json/RIP_ADD.json';

import FMR_ABI from './json/FMR_ABI.json';
import FMR_ADD from './json/FMR_ADD.json';

import RRA_ABI from './json/RRA_ABI.json';
import RRA_ADD from './json/RRA_ADD.json';

import FAD_ABI from './json/FAD_ABI.json';
import FAD_ADD from './json/FAD_ADD.json';

import SFOO_ABI from './json/SFOO_ABI.json';
import SFOO_ADD from './json/SFOO_ADD.json';

import RGLD_ABI from './json/RGLD_ABI.json';
import RGLD_ADD from './json/RGLD_ADD.json';

import RMGLD_ABI from './json/RMGLD_ABI.json';
import RMGLD_ADD from './json/RMGLD_ADD.json';

import RAR_ABI from './json/RAR_ABI.json';
import RAR_ADD from './json/RAR_ADD.json';

import FANTUM_ITEMS from './json/FANTUM_ITEMS.json';

var ftc_contract;
var gtc_contract;

var tot_contract;
var cndy_contract;

var fum_contract;
var gum_contract;
var foo_contract;
var flf_contract;
var flf2_contract;
var slp_contract;
var wftm_contract;

var duel_contract;
var gy_contract;
var rip_contract;

var fmr_contract;
var rra_contract;
var fad_contract;
var sfoo_contract;

var rgld_contract;
var rmgld_contract;
var rar_contract;


var fi_contract;
var fia_contract;

var isTester=false;

const fetch = require('node-fetch');

var ingredients;

(async () => {
  do {
    await sleep(100);
    ftc_contract = new web3.eth.Contract(FTC_ABI.abi, FTC_ADD[0]);
    gtc_contract = new web3.eth.Contract(GTC_ABI.abi, GTC_ADD[0]);

    fum_contract = new web3.eth.Contract(FUM_ABI.abi, FUM_ADD[0]);
    gum_contract = new web3.eth.Contract(GUM_ABI.abi, GUM_ADD[0]);
    flf_contract = new web3.eth.Contract(FLF_ABI.abi, FLF_ADD[0]);
    flf2_contract = new web3.eth.Contract(FLF2_ABI.abi, FLF2_ADD[0]);
    slp_contract = new web3.eth.Contract(ERC20_ABI, vars.slpContractAddress);
    foo_contract = new web3.eth.Contract(ERC20_ABI, vars.fooContractAddress);
    wftm_contract = new web3.eth.Contract(ERC20_ABI, vars.wftmContractAddress);

    duel_contract = new web3.eth.Contract(DUEL_ABI.abi, DUEL_ADD[0]);
    gy_contract = new web3.eth.Contract(GY_ABI.abi, GY_ADD[0]);
    rip_contract = new web3.eth.Contract(RIP_ABI.abi, RIP_ADD[0]);

    fmr_contract = new web3.eth.Contract(FMR_ABI.abi, FMR_ADD[0]);
    rra_contract = new web3.eth.Contract(RRA_ABI.abi, RRA_ADD[0]);
    fad_contract = new web3.eth.Contract(FAD_ABI.abi, FAD_ADD[0]);

    sfoo_contract = new web3.eth.Contract(SFOO_ABI.abi, SFOO_ADD[0]);

    rgld_contract = new web3.eth.Contract(RGLD_ABI.abi, RGLD_ADD[0]);
    rmgld_contract = new web3.eth.Contract(RMGLD_ABI, RMGLD_ADD[0]);

    rar_contract = new web3.eth.Contract(RAR_ABI, RAR_ADD[0]);

    cndy_contract = new web3.eth.Contract(CNDY_ABI.abi, CNDY_ADD[0]);
    tot_contract = new web3.eth.Contract(TOT_ABI.abi, TOT_ADD[0]);

    fi_contract = new web3.eth.Contract(FI_ABI.abi, FI_ADD[0]);
    fia_contract = new web3.eth.Contract(FIA_ABI.abi, FIA_ADD[0]);

    ingredients = FANTUM_ITEMS;
    


  } while(!ftc_contract || !fum_contract || !foo_contract || !fmr_contract);
})();


var web3;

web3 = new Web3(Web3.givenProvider);

var connectedAccount=false;

var accountsHaveChanged = false;

if (window.ethereum) { //check if Metamask is installed
    
  window.ethereum.on('accountsChanged', function(accounts) {
    //console.log('Accounts changed...');
    //console.log(accounts);
    if(!accounts[0]) {

      connectedAccount=false;
    } else {
      connectedAccount=accounts[0];
    }




  });

}

export function ingredientForAddressAndId(address, id) {
  for(var i=0;i<ingredients.length;i++) {
    var ing = ingredients[i];
    if(!ing.address) {
      ing.address = FI_ADD[0];
    }
    if(ing.id==id && ing.address == address) {

      return ing;
    }
  }
  return false;
}

const addFoo = async () => {

  if(connectedAccount) {

  

    web3.currentProvider.sendAsync({
      method: 'metamask_watchAsset',
      params: {
         type: 'ERC20',
          options: {
            "address": vars.fooContractAddress,
            "symbol": "FOO",
            "decimals": 18,
            "image": 'https://fantums.com/mm_logo512.png'
          }
      },
    }, console.log);

  }
}


const addRip = async () => {

  if(connectedAccount) {

  

    web3.currentProvider.sendAsync({
      method: 'metamask_watchAsset',
      params: {
         type: 'ERC20',
          options: {
            "address": RIP_ADD[0],
            "symbol": "RIP",
            "decimals": 18,
            "image": 'https://fantums.com/mm_riplogo512.png'
          }
      },
    }, console.log);

  }
}

const addSFoo = async () => {

  if(connectedAccount) {

  

    web3.currentProvider.sendAsync({
      method: 'metamask_watchAsset',
      params: {
         type: 'ERC20',
          options: {
            "address": SFOO_ADD[0],
            "symbol": "sFOO",
            "decimals": 18,
            "image": 'https://fantums.com/mm_slogo512.png'
          }
      },
    }, console.log);

  }
}

const addCndy = async () => {

  if(connectedAccount) {

  

    web3.currentProvider.sendAsync({
      method: 'metamask_watchAsset',
      params: {
         type: 'ERC20',
          options: {
            "address": CNDY_ADD[0],
            "symbol": "CDNY",
            "decimals": 18,
            "image": 'https://fantums.com/img/Candy512.png'
          }
      },
    }, console.log);

  }
}




const connectWallet = async () => {

  if(connectedAccount) {
    return false;
  }

  //console.log('Even here?');
  if (window.ethereum) { //check if Metamask is installed

        try {
            const address = await window.ethereum.enable(); //connect Metamask
            const obj = {
                    connectedStatus: true,
                    status: "",
                    address: address
                }
                connectedAccount=address;

                //console.log('Checking if tester...');
               
                for(var i=0;i<vars.testingAddresses.length;i++) {
                  var testingAddress = vars.testingAddresses[i];
                  if(connectedAccount.toString().toLowerCase()==testingAddress.toLowerCase()) {
                    
                    isTester = true;
                  }
                }
                

                return obj;
              
        } catch (error) {
            return {
                connectedStatus: false,
                status: "🦊 Connect to Metamask using the button on the top right."
            }
        }
        
  } else {
        return {
            connectedStatus: false,
            status: "🦊 You must install Metamask into your browser: https://metamask.io/download.html"
        }
   } 
};

// console.log(FTC_ABI);
// console.log(FUM_ABI);




export {
  ingredients,
  CNDY_ABI,
  CNDY_ADD,
  TOT_ABI,
  TOT_ADD,
  cndy_contract,
  tot_contract,
  isTester,
  FUM_ABI,
  FUM_ADD,
  GUM_ABI,
  GUM_ADD,
  FTC_ABI,
  FTC_ADD,
  GTC_ABI,
  GTC_ADD,
  FLF_ABI,
  FLF_ADD,
  FLF2_ABI,
  FLF2_ADD,
  DUEL_ABI,
  DUEL_ADD,
  GY_ABI,
  GY_ADD,
  RIP_ABI,
  RIP_ADD,
  FMR_ABI,
  FMR_ADD,
  RRA_ABI,
  RRA_ADD,
  FAD_ABI,
  FAD_ADD,
  SFOO_ABI,
  SFOO_ADD,
  RGLD_ABI,
  RGLD_ADD,
  RMGLD_ABI,
  RMGLD_ADD,
  RAR_ABI,
  RAR_ADD,
  FI_ABI,
  FI_ADD,
  FIA_ABI,
  FIA_ADD,
  ERC20_ABI,
  fi_contract,
  fia_contract,
  rar_contract,
  rgld_contract,
  rmgld_contract,
  sfoo_contract,
  fad_contract,
  rra_contract,
  fmr_contract,
  duel_contract,
  gy_contract,
  rip_contract,
  foo_contract,
  ftc_contract,
  gtc_contract,
  fum_contract,
  gum_contract,
  flf_contract,
  flf2_contract,
  slp_contract,
  wftm_contract,
  addFoo,
  addRip,
  addSFoo,
  addCndy,
  accountsHaveChanged,
  connectedAccount,
  connectWallet,
  Web3,
  web3,
  FANTUM_ITEMS
}