import React, {useState, useEffect} from 'react';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';

function Menu(props) {

  const [currAccount, setCurrAccount] = useState("");

  useEffect(() => {

      setInterval(function() {

        //console.log(EVM.connectedAccount);
          if(EVM.connectedAccount.toString()!=currAccount) {

            setCurrAccount(EVM.connectedAccount.toString());
          }

        }, 100);


    }, []);
  


    
    return props.pages.map(function(page) {
      
      var siteModePassed=true;
      if(vars.comingSoonSiteMode) {
        if(page.soon) {
          siteModePassed=true;
        } else {
          siteModePassed=false;
        }

      }



      var hideWallet = false;
      if(page.connected && !EVM.connectedAccount) {
        hideWallet=true;
      }

      var passTester=true;
      if(page.testerOnly) {
        if(EVM.isTester) {
          passTester=true;
        } else {
          passTester=false;
        }
      }


      if(siteModePassed && !hideWallet && passTester) {

        var showDash = false;

        if(page.id < props.pages.length) {
          showDash=true;
        }

        if(subs.showAttributeGuide) {
          if(page.id == 4) {
            showDash=false;
          }
        } else {
          if(page.id == 3) {
            showDash=false;
          }
        }

        var showLink=true;


        if(page.id==4) {
          //showLink=false;
        }
        
        if(showLink) {
          if(showDash) {



              return (
                  <div key={page.id} className="menu_item_cont">

                  <div className={'menu_img'}></div>
                  <a href={page.url} className="desktop_m menu_item"><img className="menu_img_a" src={"/img/icons/"+page.title.toString().toLowerCase()+'.png'} />{page.title}</a>
                  <a href={page.url} className="mobile_m menu_item"><img className="menu_img_ant" src={"/img/icons/"+page.title.toString().toLowerCase()+'.png'} /></a>
                  
                  
                     <span className="menu_dash"></span>
                  

                  </div>
              );
            } else {
              return (
                  <div key={page.id} className="menu_item_cont">
                  <div className={'menu_img'}></div>
                  <a href={page.url} className="desktop_m menu_item"><img className="menu_img_a" src={"/img/icons/"+page.title.toString().toLowerCase()+'.png'} />{page.title}</a>
                  <a href={page.url} className="mobile_m menu_item"><img className="menu_img_ant" src={"/img/icons/"+page.title.toString().toLowerCase()+'.png'} /></a>
                  
                   

                  </div>
              );
            }
          }
        } else {
          return (<div key={page.id}></div>);
        }
        

      
      
    }); 


   
  }
  
export default Menu;