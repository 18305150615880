import React, {useState, useEffect} from 'react';
import FantumGen from './FantumGen';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";

function Snap(props) {

    let { hash } = useParams();

    hash = hash.replace(":", "");

    
    
    return (
        <>
        <FantumGen hash={hash} size={320} />

        </>
    );

 


}
  
export default Snap;