import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";

var md5 = require('md5');

function Notification(props) {

	useEffect(() => {



 	}, []);
	

	function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}


	//console.log(props.timestamp);

	return (<>
		<div className="noteBg" onClick={()=>{ window.open(props.link, '_self'); }}>
      <div className="noteLeft">
        <img className="renderPixelated" width="100" src={props.imageUrl} />
      </div>
      <div className="noteRight">
        <div className="noteHeadline">{props.title}</div>
        <div className="noteMessage">{props.message}</div>
        <div className="noteMessage">{timeDifference(Date.now(), props.timestamp*1000)}</div>
      </div>
     </div>
	</>);

}

export default Notification;