import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import {
  useParams
} from "react-router-dom";
import { getObjFromJSONUrl } from './../helpers.js'

function Graveyard(props) {

	const [fooBurned, setFooBurned] = useState(0);

	let { num, ob, o, q } = useParams();

	if(!ob) {
		ob='id';
	}
	if(!o) {
		o='desc';
	}
	// if(num=='query') {
	// 	num=15;
	// }
	if(!num) {
		num = 30;
	}
	if(num>100) {
		num=100;
	}


	

	document.title = "Fantums: Graveyard";


	useEffect(() => {

		getFooBurned();
    	
    }, []);

	if(vars.comingSoonSiteMode) { return(<></>); }


	
	function numberWithCommas(x) {
	    x = x.toString();
	    var pattern = /(-?\d+)(\d{3})/;
	    while (pattern.test(x))
	        x = x.replace(pattern, "$1,$2");
	    return x;
	}

	async function getFooBurned() {
		const burnedObj = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'fooBurned'
		);
		setFooBurned(burnedObj[0]);

	}

	

	
	// if(EVM.duelsPrivate) {
	// 	return (<><h1><span style={{color: '#FFF'}}>Are you supposed to be here?</span></h1></>);
	// }

	return (<>
        <div className="blue_page">
        	<h2>THE GRAVEYARD</h2><br/>
        	<p>Where FUMs are laid to rest.</p><br/>

        	<br/><br/><br/>
            <Grid title="Dead" name="graveyard" num={num} orderBy={ob} order={o} query={q} />
            <div id="fooBurnedCounter">
	        	<b>{numberWithCommas(Math.round(fooBurned/Math.pow(10,18)))}<div className="fooBurnedText">FOO RESTS HERE</div></b>
	        	</div>
        	<br/><br/><br/>

		</div>
    </>);

}

export default Graveyard;