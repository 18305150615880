import React, {useState, useEffect} from 'react';
import LeaderRow from './LeaderRow';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { addysEqual, rollNum, modal, getObjFromJSONUrl, callMethod, callMethodWithNonce, sleep } from './../helpers.js'
import {
  useParams
} from "react-router-dom";

var gettingTop100 = false;

var secondaryInterval;

var receivedContent=false;

var pageNum=1;

var totalLeaders=0;

var npp = 20;

function Leaders() {

	let { num } = useParams();
	if(num) {
		pageNum = num;
	}

	const [top100, setTop100] = useState([]);
	const [loadingLeaders, setLoadingLeaders] = useState(false);

	const [render, setRender] = useState(0);



	useEffect(() => {
		setTimeout(function() {
			setRender(true);
			

		}, 4000);
	    	clearInterval(secondaryInterval);
	    	secondaryInterval = setInterval(async function() {

	    		if(EVM.connectedAccount && !receivedContent) {
					getTop100();
				}


		}, 200);
	}, []);



	async function getTop100() {

		if(!EVM.connectedAccount) {
			return;
		}
		if(gettingTop100) {
			return;
		}

		setLoadingLeaders(true);

		gettingTop100 = true;
		const tokensObj = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'tokens/page='+pageNum+'&num='+npp+'&orderBy=level&order=DESC&addr=&forSale=&q='
		);
		var tokens = [];

		totalLeaders = tokensObj[1];
		for(var i=0;i<tokensObj[0].length;i++) {

			


	
			var tokenObj = tokensObj[0][i];
			var tokenURI = subs.apis[subs.apiId].url+'token/'+tokenObj.id;
			var newTokens = [];

			//console.log(tokenObj.owner.toString().toLowerCase()+' - '+vars.duelAIAddress.toString().toLowerCase());

			// var ownerToCompare = tokenObj.owner;
			// if(addysEqual(tokenObj.owner, EVM.DUEL_ADD[0])) {
			// 	// token is on duelling contract, see who owns it
			// 	ownerToCompare = await EVM.duel_contract.methods.ownerOfToken(tokenObj.id).call();
			// }


			//if(!addysEqual(ownerToCompare, vars.duelAIAddress)) {
				
				//if(tokenObj.price <= 0) {
					newTokens = tokens.concat({
					  index: i+((pageNum-1)*npp)+1,
				      id: tokenObj.id, 
				      hash: tokenObj.hash, 
				      owner: tokenObj.owner,
				      name: tokenObj.name,
				      tokenURI: tokenURI,
				      image: tokenObj.image,
				      image32: tokenObj.image32,
				      rarity: tokenObj.rarity,
				      price: tokenObj.price,
				      level: tokenObj.level,
				      lwtdk: tokenObj.lwtdk,
				      totalFantums: parseInt(tokenObj.totalFantums)
				    }); 

				    //console.log(newTokens);

				    tokens=newTokens;

				    // if(tokens.length >=20) {
				    // 	break;
				    // }
				//}

			//}


			
    		


		}
		setTop100(tokens);

		gettingTop100 = false;

		receivedContent = true;


		setLoadingLeaders(false);

	}

	function prevPage() {

		var totalPages = Math.ceil(totalLeaders / npp);
		var hasNext=false;
		if(top100.length > 0 && totalPages > pageNum && !loadingLeaders) {
			hasNext=true;
		}


		if(pageNum>1 && !loadingLeaders && hasNext) {
			return (<><a href={"/leaders/"+(parseInt(pageNum)-1)}>Previous</a> · </>);
		} else if(pageNum>1 && !loadingLeaders) {
			return (<><a href={"/leaders/"+(parseInt(pageNum)-1)}>Previous</a></>);
		}

		return (<></>);
	}

	function nextPage() {

		var totalPages = Math.ceil(totalLeaders / npp);
		//console.log('totalPages: '+totalPages);

		if(top100.length > 0 && totalPages > pageNum && !loadingLeaders) {
			return (<><a href={"/leaders/"+(parseInt(pageNum)+1)}>Next</a></>);
		}
	}

	
return ( <>
<div id="operaHolderStreets">
	<div className="leader_container">
		<h3><b>LEADERBOARD</b></h3>
		<p>Top 20 Duelists</p><br/><br/>
		<div className={(loadingLeaders ? '' : 'hidden')}>
			<div id="rotatingSkelly" className='rotate'></div>
		</div>
		{top100.map((token, index) => (
			<LeaderRow key={index} token={token} index={index} number={token.index} />
		))}
		<br/>
		<span className="leaders_page_controls">{prevPage()} {nextPage()}</span>
		<br/>
		<br/>
	</div>
</div>
</>);

}

export default Leaders;