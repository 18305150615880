import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { modal, callMethod, getObjFromJSONUrl } from './../helpers.js'

import 'react-responsive-modal/styles.css';

import TextField from "@material-ui/core/TextField";


//import * as colors from './../colors.js';

//var receivedContent = 0;
var lastConnectedAccount=false;

var getPriceOnce = false;
var fooPrice = 0;

var loaded = false;

var gettingAPR = false;
function Farm() {


	var [pendingHarvest, setPendingHarvest] = useState(0);
	var [deposited, setDeposited] = useState(0);

	var [slpBalance, setSlpBalance] = useState(0);

	var [allowance, setAllowance] = useState(0);

	// MODAL STUFF
	const [openWithdraw, setOpenWithdraw] = useState(false);  
	const [enteredWithdrawAmount, setEnteredWithdrawAmount] = useState("");
	const [withdrawing, setWithdrawing] = useState(false); //tracks TX
	// MODAL STUFF
	const [openDeposit, setOpenDeposit] = useState(false);  
	const [enteredDepositAmount, setEnteredDepositAmount] = useState("");
	const [depositing, setDepositing] = useState(false); //tracks TX

	const [openApprove, setOpenApprove] = useState(false);
	const [approving, setApproving] = useState(false); //tracks TX


	const [harvesting, setHarvesting] = useState(false); //tracks TX


	//calculate APY
	//const [fooPriceAt, setFooPriceAt] = useState(0);

	// const [totalSlpTokens, setTotalSlpTokens] = useState(0);
	// const [totalSlpTokensInFarm, setTotalSlpTokensInFarm] = useState(0);

	const [currentAPY, setCurrentAPY] = useState(0);


	async function setAPY() {

		if(gettingAPR) {
			return false;
		}

		gettingAPR = true;
		const serverAPY = await getObjFromJSONUrl(
			subs.apis[subs.apiId].url+'lpAPY'
		);

		gettingAPR=false;
		setCurrentAPY(serverAPY*100);
	}


	useEffect(() => {

		setInterval(function() {

			if(EVM.connectedAccount != lastConnectedAccount) {
				//receivedContent=0;

				loaded=false;
				load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(!loaded) {
				load();
			}

		}, 2000);

	}, []);

	async function load() {


		if(!EVM.connectedAccount) {
			return;
		}

		if(!EVM.flf2_contract) {
			return;
		}

		if(loaded) {
			return;
		}

		//console.log("loading?");


		if(!getPriceOnce) {
			getPriceOnce=true;
			//console.log('GETTING FOO PRICE???');
			const fooPrice1 = await getObjFromJSONUrl(
				subs.apis[subs.apiId].url+'fooPrice'
			);


			//setFooPriceAt(fooPrice[0]);
			fooPrice = fooPrice1;
			//console.log('fooPrice: '+fooPriceAt+' '+fooPrice[0]);
		}
		



		try {
			var pending = await EVM.flf2_contract.methods.pending(0, EVM.connectedAccount.toString()).call();
			// function deposited(uint256 _pid, address _user) external view returns (uint256) {
			var deposited = await EVM.flf2_contract.methods.deposited(0, EVM.connectedAccount.toString()).call();

			var balanceOfSLP = await EVM.slp_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();

			

			// let approved = await EVM.slp_contract.methods.getApproved(EVM.connectedAccount.toString()).call();
   //          if(approved=='0x0000000000000000000000000000000000000000') {
   //            approved=false;
   //          }
            let allowance1 = await EVM.slp_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.FLF2_ADD[0]).call();
            // if(allowance1 == 0) {
            // 	allowance1 = -1;
            // }

            setSlpBalance(balanceOfSLP);

			setDeposited(deposited);

			setPendingHarvest(pending);

            setAllowance(allowance1/Math.pow(10,18));

            setAPY();
            
            loaded=true;

            //console.log('aTotalSlpTokens: '+aTotalSlpTokens);
            //console.log('balanceOfSlpOnFarm: '+balanceOfSlpOnFarm);


            //console.log('allowance: '+allowance);
           // console.log('enteredDepositAmount: '+enteredDepositAmount);

		} catch(error) {
			console.log(error);
		}




	}


	async function submitHarvest(event) {

	  if(!EVM.flf2_contract) {
	    return;
	  }
	  event.preventDefault();
	 // setOpenWithdraw(false);

	  setHarvesting(true);


	  // withdraw(uint256 _pid, uint256 _amount)
	  //var withdrawAmount =  EVM.web3.utils.toWei(enteredWithdrawAmount, 'ether');


	  await callMethod(EVM.flf2_contract.methods.withdraw(0, 0), 0, EVM.connectedAccount, 500000, function(err, res) {
	    setHarvesting(false);
	    if(err) {
	          console.log(err.message);
	          
	        } else {
	        	loaded=false;
	          load();
	        }
	  });
	}


	async function submitWithdraw(event) {

	  if(!EVM.flf2_contract) {
	    return;
	  }
	  event.preventDefault();
	  setOpenWithdraw(false);

	  setWithdrawing(true);


	  // withdraw(uint256 _pid, uint256 _amount)
	  var withdrawAmount =  EVM.web3.utils.toWei(enteredWithdrawAmount, 'ether');


	  await callMethod(EVM.flf2_contract.methods.withdraw(0, withdrawAmount), 0, EVM.connectedAccount, 500000, function(err, res) {
	    setWithdrawing(false);
	    if(err) {
	          console.log(err.message);
	          
	        } else {
	        	loaded=false;
	          load();
	        }
	  });
	}

	async function submitApproveAndDeposit(event) {
		event.preventDefault();

		var depositAmount =  EVM.web3.utils.toWei(enteredDepositAmount, 'ether');





		if(allowance < enteredDepositAmount) {

			console.log('Approving '+EVM.FLF2_ADD[0]+' with amount '+depositAmount);

		  setApproving(true);
		  await callMethod(EVM.slp_contract.methods.approve(EVM.FLF2_ADD[0], depositAmount), 0, EVM.connectedAccount, 500000, function(err, res) {
		    setApproving(false);
		    if(err) {
	          console.log(err.message);
	        } else {
	        	loaded=false;
	        	load();

	        }
		  });


		} else {
			console.log('Depositing...');

			

			  setDepositing(true);


			  // withdraw(uint256 _pid, uint256 _amount)
			  //var depositAmount =  EVM.web3.utils.toWei(enteredDepositAmount, 'ether');


			  await callMethod(EVM.flf2_contract.methods.deposit(0, depositAmount), 0, EVM.connectedAccount, 500000, function(err, res) {
			    setDepositing(false);
			    if(err) {
			          console.log(err.message);
			          
			        } else {
			        	loaded=false;
			          load();
			          setOpenDeposit(false);

			        }
			  });

		}

	}



function haveMetamask() {
	if(EVM.connectedAccount) {
		return (<>		<p><a href="https://app.sushi.com/add/0xFbc3c04845162F067A0B6F8934383E63899c3524/ETH">Get FOO-FTM LP Tokens on SushiSwap</a></p>
			<br/>
		<h4>{Math.round(currentAPY*100)/100}% APR</h4>
		<br/>
		<Button size="lg" variant="primary" onClick={() => setOpenDeposit(true)}>DEPOSIT LP TOKENS ({Math.floor(slpBalance/Math.pow(10,18)*100)/100})</Button>
		<br/><br/>
		<Button size="lg" variant="warning" onClick={submitHarvest}>HARVEST REWARDS ({Math.floor(pendingHarvest/Math.pow(10,18)*100)/100})</Button>

		<br/><br/>
		<Button size="lg" variant="success"  onClick={() => setOpenWithdraw(true)}>WITHDRAW LP TOKENS ({Math.floor(deposited/Math.pow(10,18)*100)/100})</Button>
		<br/><br/></>);
	} else if (window.ethereum) {
		return (<><div id="rotatingSkelly" className='rotate'></div><br/><p><b>Connecting to MetaMask...</b></p></>);
	} else {
		return (<><br/><br/><p><b>Please connect MetaMask and select the Fantom Opera network to farm!</b></p></>);
	} 


}

	
return ( 
<>

{/*{modal(openApprove, () => setOpenApprove(false), 'SELL YOUR FANTUM', function() {
        return (<><br/>
          <span className="modal_text">Would you like to sell this Fantum? If so, Approve the Fantums exchange contract...</span><br/>
          <form onSubmit={submitApprove}>
          <br/>
          <Button type="Submit" size="lg" variant="primary">{approveText()}</Button>
        </form>
          </>);
      })}*/}

{modal(openDeposit, () => setOpenDeposit(false), 'DEPOSIT YOUR LP TOKENS', function() {
    return (<><br/>
      <span className="modal_text">Would you like to deposit your LP tokens (rewards will be harvested)?</span><br/>
      


		
		<br/>
		ENTER A DEPOSIT AMOUNT UP TO {Math.floor(slpBalance/Math.pow(10,18)*10000)/10000}:<TextField value={enteredDepositAmount} label="" onChange={(e) => { setEnteredDepositAmount(e.target.value); }} /><br/><br/>
		
		<form className={(allowance < enteredDepositAmount || allowance == 0) ? '' : 'hidden' } onSubmit={submitApproveAndDeposit}>
		<Button type="Submit" size="lg" variant="primary">APPROVE</Button>
		</form>
		<form className={(allowance < enteredDepositAmount || allowance == 0) ? 'hidden' : '' } onSubmit={submitApproveAndDeposit}>
		<Button type="Submit" size="lg" variant="primary">DEPOSIT</Button>
		</form>


      </>);
  })}

{modal(openWithdraw, () => setOpenWithdraw(false), 'WITHDRAW YOUR LP TOKENS', function() {
    return (<><br/>
      <span className="modal_text">Would you like to withdraw your LP tokens (rewards will be harvested)?</span><br/>
      <form onSubmit={submitWithdraw}>
      <br/>
      ENTER A WITHDRAW AMOUNT UP TO {deposited/Math.pow(10,18)}:<TextField value={enteredWithdrawAmount} label="" onChange={(e) => { setEnteredWithdrawAmount(e.target.value); }} /><br/><br/>
      <Button type="Submit" size="lg" variant="primary">WITHDRAW</Button>
    </form>
      </>);
  })}


 <div className="appOperaFarm">
            <div id="operaHolderFarm">
           

                


	<div className="doc_container">
		<h3>Farm</h3>
		<p>Receive $FOO rewards for offering FOO-FTM liquidity on SushiSwap!</p><br/>
		<br/>


		{haveMetamask()}
		
		<br/>
	</div>



          
            </div>
        </div>
</>
);

}

export default Farm;