import React, {useState, useEffect} from 'react';
import FantumGen from './FantumGen';
import MintAboveText from './MintAboveText';
import Button from 'react-bootstrap/Button';
import { useInterval } from './../helpers.js'

import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { getObjFromJSONUrl, rollName, rollNum, rScore, rScoreDiff, sleep, callMethod } from './../helpers.js'
import { arrayOfHashCharacters, genderStrFromHashArr } from './../generate.js'

// Import your worker 
import { ReactSession } from 'react-client-session';
import worker from 'workerize-loader!./../worker'; // eslint-disable-line import/no-webpack-loader-syntax


//const axios = require('axios');

//var BigNumber = require('big-number');
const BigNumber = require('bignumber.js');


var tookSecs;

const leftPad = require('left-pad')

const keccak256 = require('keccak256')

//var looking = true;
var needHashing = false;

var nonce = 0;

var price = 0;

const fetch = require('node-fetch');

var random_name = require('node-random-name');


var foundFantums = [];

var totalWorkers=0;


var useNumWorkers=4;

var timestampBeforeFinding=0;

var searchingDonationBin=false;
//var updatePriceOnce=false;

var tooManyRequests=false;

//const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');

var workersFinished = [];

var foundStoredFantum = false;

var removedThisStoredFantum = false;

var dontPassThisFantumAlong = false;

var currentWorkerInstances = false;

function Mint() {


    ReactSession.setStoreType("localStorage");

    const [donationBinEmpty, setDonationBinEmpty] = useState(false);


    const [thisIsDonation, setThisIsDonation] = useState(false);

    document.title = subs.properSiteName+": Find";

    function safeHexToUtf8(hex) {


        return EVM.web3.utils.hexToString(hex);
    }

    async function mintFantum() {

        (async () => {

            var tokenIds;
            if(!subs.subsite) {
                tokenIds = await EVM.fum_contract.methods.totalFantums().call();
            } else {
                tokenIds = await EVM.gum_contract.methods.totalFantums().call();
            }

            BigNumber.set({ DECIMAL_PLACES: 0, ROUNDING_MODE: 0 });
            var price;
            if(!subs.subsite) {
                price =  BigNumber(await EVM.fum_contract.methods.priceOf(parseInt(tokenIds)+1).call());
            } else {
                price =  BigNumber(await EVM.gum_contract.methods.priceOf(parseInt(tokenIds)+1).call());
            }
            
            setSomePrice(price/Math.pow(10, 18));
            setIsMinting(true);

            console.log('nonce: '+nonce);
            console.log('shortName: '+shortName.toUpperCase());
            console.log('price: '+price.toString()+' tokenIds: '+tokenIds);

            if(foundStoredFantum) {
                removedThisStoredFantum=true;
                var storedFantum = await getObjFromJSONUrl(
                    subs.apis[subs.apiId].url+'usedStoredFantum/'+hash
                );
            }

            dontPassThisFantumAlong=true;

            var mintMethod;
            if(subs.subsite) {
                mintMethod = EVM.gum_contract.methods.mintGothum(nonce+'', shortName.toUpperCase());
                console.log('contract: '+EVM.GUM_ADD[0]);

                var onHold = await EVM.gum_contract.methods.onHold().call();
                console.log('onHold: '+onHold);
                if(onHold) {
                    console.log('Minting is not yet available!');
                    alert('Minting is not yet available.... Please wait until 8PM UTC!');
                    return;
                }
                
                // if(price.div(BigNumber(Math.pow(10, 18)))<10) {
                //     price = price.plus(BigNumber(2*Math.pow(10, 18)));
                // } else {
                //     price = price.times(1.05);
                // }
                
            } else {
                mintMethod = EVM.fum_contract.methods.mintFantum(nonce+'', shortName.toUpperCase());
            }

            await callMethod(mintMethod, price.toString(), EVM.connectedAccount,  1500000, function(err, res) {
                if(err) {
                    console.log(err.message);
                    setIsMinting(false);
                    alert("Sorry, minting failed.");
                } else {
                    var mintedId;
                    console.log(res);
                    if(res.logs.length > 0) {
                        for(var i=0;i<32;i++) {
                            var log = res.logs[i];
                            if(log) {
                                for(var j=0;j<10;j++) {
                                    var topic = log.topics[j];
                                    
                                    if(topic) {
                                        var hex = topic.replace("0x", "");
                                        console.log('hex: '+hex);
                                        var pureData = log.data.substr(2, log.data.length-2);
                                        var d1 = pureData.substr(0*64, 64);
                                        var d2 = pureData.substr(1*64, 64);
                                        var d3 = pureData.substr(2*64, 64);
                                        var d4 = pureData.substr(3*64, 64);

                                        console.log('d1: '+d1+' '+parseInt(d1)+' '+parseInt(d1, 16));
                                        console.log('d2: '+d2+' '+parseInt(d2)+' '+parseInt(d2, 16));
                                        console.log('d3: '+d3+' '+parseInt(d3)+' '+parseInt(d3, 16));
                                        console.log('d4: '+d4+' '+parseInt(d4)+' '+parseInt(d4, 16));

                                        if(hex=='a588db3cab48facbfbacb8161506673b3f7a6d8d23c94f4f09f2feed0d4ddfff') {
                                            //mintedId = parseInt(log.data);
                                            mintedId = parseInt(d1, 16);
                                            ReactSession.set("justMintedId", mintedId);
                                        }
                                        //"0x297bf1e5f88275d6949f7bad520c4a0247494f1012c30d1e4a8d0f8b0fcc294a"
                                        if(hex=='297bf1e5f88275d6949f7bad520c4a0247494f1012c30d1e4a8d0f8b0fcc294a') {



                                            //"0x00000000000000000000000000000000000000000000000000000000000000070000000000000000000000000000000000000000000000001bc16d674ec800000000000000000000000000000000000000000000000000000000000000000060000000000000000000000000000000000000000000000000000000000000000f5452414345592042495244455454450000000000000000000000000000000000"
                                           

                                            mintedId = parseInt(d1, 16);
                                            ReactSession.set("justMintedId", mintedId);
                                        }
                                        
                                        //console.log(j+' '+topic+' '+parseInt(log.data, 16)+' '+parseInt(log.data));
                                        //console.log()
                                    }
                                    
                                }
                            }
                        }
                    }
                    if(mintedId) {
                        var tokenURI = subs.apis[subs.apiId].url+'token/'+mintedId;
                        fetch(tokenURI).then(function() {
                            window.open("/token/"+mintedId, '_self');
                        });
                    } else {
                        console.log('Did TX fail?');
                        setIsMinting(false);
                        alert("Sorry, minting failed.");
                    }
                }
            });


        })();

    }



    const [isMinting, setIsMinting] = useState(false);

    const [hash, setHash] = useState([]);

    const [somePrice, setSomePrice] = useState(0);

    const [shortName, setShortName] = useState('');


    function hashing() {

        



        if(needHashing) {
            needHashing=false;

            //const randomName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals] }).replace("_", " ").replace("_", " ").toUpperCase();

            timestampBeforeFinding = Math.floor(Date.now() / 100);

            //console.log('foundFantums.length: '+foundFantums.length);
            if(foundFantums.length > 0) {
                console.log('No need to add workers, we have saved Fantums...');
                returnAFantum();
            } else if(totalWorkers>0) {
                //Workers are still out

                (async () => {
                    do {
                        console.log('Workers are still out... ('+totalWorkers+')');
                        var didIt = returnAFantum();
                        await sleep(1000);
                    } while (didIt == false);

                })();

            } else {




                var workerInstances = [];

                var workerSetId = Math.floor((Math.random() * 99999999) + 1);

                workersFinished[workerSetId]=false;

                for(var i=0;i<useNumWorkers;i++) {

                    console.log('Starting worker: '+i);
                    totalWorkers++;



                    var aHash;
                    var randomName;
                    var someNonce;

                    // Create an instance of your worker
                    workerInstances[i] = worker();
                    // Attach an event listener to receive calculations from your worker
                    workerInstances[i].addEventListener('message', (message) => {
                        //console.log('New Message: ', message.data);

                        if(message.data[0]!='found') {
                            return;
                        }

                        var shouldReturn=true;
                        if(workersFinished[workerSetId]) {
                            console.log('Working already finished... '+workerSetId);
                            shouldReturn=false;
                        }

                        workersFinished[workerSetId] = true;


                        someNonce = message.data[1];
                        aHash = message.data[2];
                        randomName = message.data[3];
                        saveFantum(someNonce, aHash, randomName, shouldReturn);

                        totalWorkers--;


                        
                        

                    });
                    // Run your calculations
                    workerInstances[i].getHash(subs);

                }
    
                currentWorkerInstances = workerInstances;
            }


           
        }
        
    }

    function saveFantum(aNonce, aHash, randomName, shouldReturn) {
        console.log('Saving '+randomName+' for later....');
        foundFantums.push([aNonce, aHash, randomName]);

        //console.log('after saving foundFantums.length: '+foundFantums.length);
        if(shouldReturn)
            returnAFantum();
    }

    function returnAFantum() {


        if(foundFantums.length < 1) {
            return false;
        }

        var foundFantum = foundFantums[0];
        
        


        nonce = foundFantum[0];
        var aHash = foundFantum[1];
        var randomName = foundFantum[2];

        const timestampAfterFinding = Math.floor(Date.now() / 100);
        tookSecs = (timestampAfterFinding-timestampBeforeFinding)/10;

        setFound(false);

        setLooking(true);

        setHash(aHash);
        setShortName(randomName);

        if(randomName) {
            document.title = subs.properSiteName+": Found "+randomName;

        }
        
        console.log('took: '+tookSecs);

        console.log('Potential Fantum ('+subs.difficultyBytes+'): '+randomName+' - '+nonce+' - '+aHash);

        foundFantums = foundFantums.slice(1);

        //console.log('after returning foundFantums.length: '+foundFantums.length);


        return true;
    }

    //var [nonce, setNonce] = useState(0);

    var [looking, setLooking] = useState(true);
    var [found, setFound] = useState(true);


    async function passedOnFantum() {

        if(hash && shortName && nonce && !removedThisStoredFantum && !dontPassThisFantumAlong) {
            await getObjFromJSONUrl(
                subs.apis[subs.apiId].url+'passedOnFantum/'+hash+'/'+shortName+'/'+nonce
            );
        }

        
    }



    async function storedFantum() {

        searchingDonationBin=true;

        try {
            var storedFantumRes = await getObjFromJSONUrl(
                subs.apis[subs.apiId].url+'storedFantum'
            );
        } catch(error) {
            //console.log('we here???');
            // setFound(false);
            // setLooking(true);
            tooManyRequests=true;
        }

        // if(!tooManyRequests && storedFantumRes[0]) {
        //     tooManyRequests=false;
        // }

        if(storedFantumRes && storedFantumRes[1]) {
            storedFantum = storedFantumRes[1];
        } else {
            storedFantum=false;
        }
        
        
        searchingDonationBin=false;



        if(!storedFantum || !storedFantum.nonce || !storedFantum.name || !storedFantum.hash) {
            

            if(!storedFantum && !tooManyRequests) {
                //donationBinEmpty=true;
                setDonationBinEmpty(true);
              

            }

            //removedThisStoredFantum = true;
            //console.log('we here?');

            

            setFound(true);
            setLooking(true);

            return false;
        }

        foundStoredFantum=true;

        
        setThisIsDonation(true);

        nonce = storedFantum.nonce;
        setFound(false);
        setLooking(true);

        setHash(storedFantum.hash);
        setShortName(storedFantum.name);

        if(storedFantum.name) {
            document.title = subs.properSiteName+": Found "+storedFantum.name;
        }
        

        return true;
        

    }

    //var [findButtonTitle, setFindButtonTitle] = useState('FIND FANTUMS');
    function stopFinding() {


        for(var i=0;i<currentWorkerInstances.length;i++) {

            if (typeof currentWorkerInstances[i].terminate !== "undefined") { 
                currentWorkerInstances[i].terminate();
            }


            
        }
        totalWorkers=0;

        setFound(true);
        setLooking(true);



    }

    function findHash(stored) {

        //console.log('subs.subsite: '+subs.subsite);

        passedOnFantum();

        tooManyRequests=false;
        foundStoredFantum=false;    
        removedThisStoredFantum=false;
        dontPassThisFantumAlong=false;
        setDonationBinEmpty(false);
        setHash(false);
        setThisIsDonation(false);


        
        setLooking(false);
        setFound(true);


        if(!stored) {
            needHashing=true;
        } else {
            storedFantum();
        }


        



         if (EVM.connectedAccount) {
          (async () => {
            try {

              var account = EVM.connectedAccount;

              var addy = account.toString();
              
                var tokenIds;
                if(!subs.subsite) {
                    tokenIds = await EVM.fum_contract.methods.totalFantums().call();
                } else {
                    tokenIds = await EVM.gum_contract.methods.totalFantums().call();
                }
                



                if(tokenIds) {
                    //var price = await contract.methods.priceOf(tokenIds).call();
                    BigNumber.set({ DECIMAL_PLACES: 0, ROUNDING_MODE: 0 });
                    var price;
                    if(subs.subsite) {
                        price =  BigNumber(await EVM.gum_contract.methods.priceOf(parseInt(tokenIds)+1).call());
                    } else {
                        price =  BigNumber(await EVM.fum_contract.methods.priceOf(parseInt(tokenIds)+1).call());
                    }
    
                    //console.log('price: '+price);
                    setSomePrice(price/Math.pow(10, 18));

                    //updatePriceOnce=false;
                }

                

        } catch (error) {
              console.log(error);
            }
          })();
      }



    }



    useInterval(() => {
   hashing();
  }, 3000);


function setWorkersTo2(event) {
    event.preventDefault();
    useNumWorkers=2; console.log(useNumWorkers); return false;
}
function setWorkersTo4(event) {
     event.preventDefault(); useNumWorkers=4; console.log(useNumWorkers); return false;
}
function setWorkersTo8(event) {
     event.preventDefault(); useNumWorkers=8;
     console.log(useNumWorkers);
    return false;
}
function setWorkersTo16(event) {
     event.preventDefault(); useNumWorkers=16;
     console.log(useNumWorkers);
    return false;
}
function setWorkersTo32(event) {
     event.preventDefault(); useNumWorkers=32;
     console.log(useNumWorkers);
    return false;
}

function mintButtonText() {

    if(isMinting) {
        return (
        <>
        MINTING...
        </>
        );
    } else {
        return (
        <>
        MINT FOR {somePrice} FTM
        </>
        );
    }
    
}

function getAnotherDonation() {
    return(<>INSTANT FIND</>);
}




function donationBinOrOperaChain() {

    if(searchingDonationBin) {
        return(<>ATTEMPTING INSTANT FIND...<br/><i>[THIS SHOULD BE QUICK!]</i></>);
    } else {
        return(<>SEARCHING BENEATH THE OPERA CHAIN...<br/><i>[THIS CAN TAKE A WHILE!]</i></>);
    }

    
}

function beginText() {
    if(tooManyRequests) {
        return (<>TOO MANY REQUESTS, WAIT FOR A MINUTE OR FIND A NEW FANTUM!</>);
    } else if(donationBinEmpty) {
        return (<>NO INSTANT FINDS AVAILABLE, YOU'LL HAVE TO FIND A NEW FANTUM!</>);
    } else {
        return (<>BEGIN YOUR SEARCH FOR {subs.nameOfFantums} HERE!</>);
    }
    
}

if(vars.comingSoonSiteMode) {
        return(<></>);
    }

return (
        <>
        <div className={!subs.subsite?"":"appOperaGothumsFind"}>
        <div className={!subs.subsite?"find_dirt":"find_dirt2"}>
        
        <div id="operaHolderFind">

        <div className="findSquare">

            <div className={found ? '' : 'hidden'}>
            <div id="rotatingSkelly" className={looking ? '' : 'rotate'}></div>
            
            </div>
                
                  <div className={found? '' : 'hidden'}>
                        <br/><br/> <br/><br/>
                        <div className={looking? '' : 'hidden'}>
                        <span className="cs_4 trans_font">{beginText()}</span>
                        </div>
                        
                        <div className={looking? 'hidden' : ''}>
                        <span className="cs_4 trans_font">{donationBinOrOperaChain()}</span>
                        </div>
                        <br/><br/>
                        <span className={looking ? 'hidden' : ''}><Button size="" variant="primary" onClick={() => {stopFinding()}}>STOP</Button></span>


                    </div>
                        

                    


                    <div className={found ? 'hidden' : ''}>

                        <MintAboveText tookSecs={tookSecs} 
                        thisIsDonation={thisIsDonation} 
                        hash={hash} 
                        shortName={shortName} 
                        tooManyRequests={tooManyRequests} 
                        foundStoredFantum={foundStoredFantum} 
                        donationBinEmpty={donationBinEmpty} />
                        
                        
                        <FantumGen hash={hash} size={320} hideRarity={thisIsDonation? "1" : '0'} />


                        <br/><br/><br/>
                        
                         

                          <div className="two_buttons">
                          <div className="pad_two_buttons_left">
                          
                        <div className={!EVM.connectedAccount ? 'hidden' : ''}>
                          <Button  size="" variant="success" onClick={mintFantum} disabled={isMinting}>{mintButtonText()}</Button></div>
                        </div>
                        <div className={EVM.connectedAccount ? 'hidden' : ''}>
                        
                        <span className="found_line_two">Connect MetaMask to mint!</span>
                        <br/><br/>
                        </div>
                        <div>
                          <Button size="" variant="primary" onClick={() => {findHash(false)}} disabled={isMinting}>FIND ANOTHER</Button>
                          <br/><br/>
                          {/*<span className="mint_note">*Due to rapidly increasing price an extra 5% sent during Mint, will be returned if successful.*</span>*/}
                        <span className={!subs.instantFindEnabled ? 'hidden' : ''}><Button size="" variant="outline-light" onClick={() => {findHash(true)}}>{getAnotherDonation()}</Button></span>
                        </div>
                        </div>

                    </div>

                    <br/>
                    <div className={(!found || !looking) ? 'hidden' : ''}>
                    <Button size="lg" variant="primary" onClick={() => {findHash(false)}}>FIND {subs.nameOfFantums}</Button><br/><br/>
                    <span className={donationBinEmpty || !subs.instantFindEnabled ? 'hidden' : ''}><Button size="" variant="outline-light" onClick={() => {findHash(true)}}>INSTANT FIND</Button></span>
                    </div>

            <br/><br/>

            
                

        </div>
        </div>
        </div>
        <span className="workersSelect">Set Threads: <a href="" onClick={setWorkersTo2}>2</a> - <a href="" onClick={setWorkersTo4}>4</a> - <a href="" onClick={setWorkersTo8}>8</a> - <a href="" onClick={setWorkersTo16}>16</a> - <a href="" onClick={setWorkersTo32}>32</a></span>
        <br/><br/>
        </div>
        </>
    );



}

export default Mint;