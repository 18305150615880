import React, {useState, useEffect} from 'react';
import Grid from './Grid';

import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import Button from 'react-bootstrap/Button';
import { numberWithCommas, modal, callMethod, sleep, tryCallUntilSuccessful } from './../helpers.js';
import IngredientIcon from './IngredientIcon.js';
import {
  useParams
} from "react-router-dom";
import IconTint from 'react-icon-tint';

var lastConnectedAccount;

var theFlowState = 0;

var lastTokenObj=false;
var lastBalIndex=false;

function Crafting(props) {

	document.title = subs.properSiteName+": Crafting";


	var [isLoading, setIsLoading] = useState(true);

	var [render, setRender] = useState(0);


	var [combos, setCombos] = useState([]);

	var [currentCombo, setCurrentCombo] = useState({inputs: [], makes: {description: ''}});

	var [allInputsQualify, setAllInputsQualify] = useState(false);


	async function updateSomeCombo(someCombo) {

		if(!EVM.fi_contract) {
			return;
		}
		
		//console.log('someCombo.idToMint: '+someCombo.idToMint);


		var someComboInputIds = await tryCallUntilSuccessful(EVM.fi_contract.methods.getComboInputIds(someCombo.id));
		

		
  	someCombo.inputIds = someComboInputIds;

  	var makesIngredient = EVM.ingredientForAddressAndId(EVM.FI_ADD[0], someCombo.idToMint);

  	var itemToMint;
  	var comboName='';
  	if(someCombo.idToMint>0) {
  		try {
  			itemToMint = await tryCallUntilSuccessful(EVM.fi_contract.methods.getItem(someCombo.idToMint));
  			//itemToMint = await EVM.fi_contract.methods.getItem(someCombo.idToMint).call();
  			comboName = itemToMint.name;
  		} catch(error) {

  		
  			comboName = false;
  		}
  		
  		
  	}

  	

  	

  	var someInputs=[];

  	var anOldCombo=false;
  	for(var i=0;i<combos.length;i++) {
      	var oldCombo = combos[i];
      	if(oldCombo.id == someCombo.id) {
      		anOldCombo = oldCombo;
      	}
      }


  	var newCombo;
  	
  	for(var j=0;j<someCombo.inputIds.length;j++) {
  		var inputId = someCombo.inputIds[j];
  		//console.log('Querying inputId: '+inputId);

  		var input = await tryCallUntilSuccessful(EVM.fi_contract.methods.getInput(inputId));
 

    	var name='';
    	var useQuantity = input.quantity;
    	var myBalance = 0;
    	var className='icon_tint_fadeno';
    	var className2='text_tint_fadeno';
    	var someAllowance = '0';
    	var someIsApprovedForAll = false;
    	var balanceToShow = 0;

    	var validOldInput = false;
    	if(anOldCombo) {
    		for(var i=0;i<anOldCombo.inputIds.length;i++) {
	    		var oldInput = anOldCombo.inputs[i];
	    		if(oldInput && oldInput.id == inputId) {
	    			validOldInput = oldInput;
	    		}
	    	}
    	}
    	
    	

    	var image = '';
    	var balIndex = 0;
    	var tokenObj=false;
    	if(validOldInput) {
    		image = validOldInput.image;
    		balIndex = validOldInput.balIndex;
    	}

    	if(vars.itemVarieties[input.variety]=='ERC20') {
    		var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, input.tokenAddress);

    		name = await tryCallUntilSuccessful(erc20_contract.methods.symbol());
    		//name = await erc20_contract.methods.symbol().call();
    		//input.multiTokenId=0;
    		useQuantity = EVM.web3.utils.fromWei(input.quantity.toString());

    		var aBal = await tryCallUntilSuccessful(erc20_contract.methods.balanceOf(EVM.connectedAccount.toString()));
    		//var aBal = await .call();

    		myBalance = Math.round(EVM.web3.utils.fromWei(aBal.toString()));

    		someAllowance = await tryCallUntilSuccessful(erc20_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
    		//someAllowance = await erc20_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.FI_ADD[0]).call();

    	} else if(vars.itemVarieties[input.variety]=='ERC721') {
    		//console.log('get in ERC721??');
    		var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, input.tokenAddress);
    		//var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, input.tokenAddress);

    		name = await tryCallUntilSuccessful(erc721_contract.methods.symbol());
    		//name = await .call();
    		//input.multiTokenId=0;
    		//useQuantity = EVM.web3.utils.fromWei(input.quantity.toString());
    		var aBal = await tryCallUntilSuccessful(erc721_contract.methods.balanceOf(EVM.connectedAccount.toString()));
    		//var aBal = await .call();

    		useQuantity = 1;
    		//console.log(input.tokenAddress+' '+aBal);
    		myBalance = aBal;

    		someIsApprovedForAll = await tryCallUntilSuccessful(erc721_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
    		//someIsApprovedForAll = await .call();

    		// IF WE HAVE ONE OF THESE GRAB THE FIRST TOKENID FROM THEIR WALLET
    		if(aBal > 0) {
    			//tokenOfOwnerByIndex(address owner, uint256 index)

    			if(!lastTokenObj) {

    				var firstTokenId = await tryCallUntilSuccessful(erc721_contract.methods.tokenOfOwnerByIndex(EVM.connectedAccount.toString(), 0));
    				//var firstTokenId = await .call();

    				var tokenUri = await tryCallUntilSuccessful(erc721_contract.methods.tokenURI(firstTokenId));
	    			//var tokenUri = await .call();

	    			
	    			const tokenResp = await fetch(tokenUri);
	          tokenObj = await tokenResp.json();
	          image = tokenObj.image;
    			} else {
    				tokenObj = lastTokenObj;
    				balIndex = lastBalIndex;
    				image = tokenObj.image;
    			}

    			
          //console.log(tokenObj);
          
    		}

    	} else if(vars.itemVarieties[input.variety]=='ERC1155') {
    		var erc1155_contract = new EVM.web3.eth.Contract(EVM.FI_ABI.abi, input.tokenAddress);
    		var multiItem = await tryCallUntilSuccessful(erc1155_contract.methods.getItem(input.multiTokenId));
    		//var multiItem = await .call();
    		name = multiItem.name;
    		var aBal = await tryCallUntilSuccessful(erc1155_contract.methods.balanceOf(EVM.connectedAccount.toString(), input.multiTokenId));
    		
    		someIsApprovedForAll = await tryCallUntilSuccessful(erc1155_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
    		//someIsApprovedForAll = await .call();
    		myBalance = aBal;
    	}




    	// How many do I have




    	someInputs.push({ id: inputId, image: image, balIndex: balIndex, tokenObj: tokenObj, name: name, action: input.action, variety: input.variety, tokenAddress: input.tokenAddress, quantity: input.quantity, useQuantity: useQuantity, multiTokenId: input.multiTokenId, myBalance: myBalance, className: className, className2: className2, someAllowance: someAllowance, someIsApprovedForAll: someIsApprovedForAll});
    }


   if(!someCombo.multiplier) {
   	someCombo.multiplier = 1;
   }

    //console.log(makesIngredient);

    
    if(vars.mintTypes[someCombo.mintType]=='MINT_SELF') {
    	balanceToShow = someCombo.amountToMint*someCombo.multiplier;
    } else if(vars.mintTypes[someCombo.mintType]=='SEND_ERC20') {
    	var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, someCombo.typeAddress);

    	var erc20Name = await tryCallUntilSuccessful(erc20_contract.methods.symbol());
    	//var erc20Name = await .call();
    	comboName = erc20Name;
    	balanceToShow = EVM.web3.utils.fromWei((someCombo.amountToMint*someCombo.multiplier).toString());
    } else if(vars.mintTypes[someCombo.mintType]=='SEND_ERC721') {
    	var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, someCombo.typeAddress);
    	var erc721Name = await tryCallUntilSuccessful(erc721_contract.methods.symbol());
    	//var erc721Name = await .call();

    	comboName = erc721Name;

    	balanceToShow = 1;
    } else if(vars.mintTypes[someCombo.mintType]=='SEND_ERC1155') {
    	var erc1155_contract = new EVM.web3.eth.Contract(EVM.FI_ABI.abi, someCombo.typeAddress);
    	var aMultiItem = await tryCallUntilSuccessful(erc1155_contract.methods.getItem(someCombo.typeTokenId));
    	//var aMultiItem = await .call();
    	comboName = aMultiItem.name;
    	balanceToShow = someCombo.amountToMint*someCombo.multiplier;
    }

    console.log('balanceToShow: '+balanceToShow);


  	newCombo = {
  		balanceToShow: balanceToShow,
  		makes: makesIngredient,
  		id: someCombo.id, 
  		name: comboName,
  		mintType: someCombo.mintType,
  		typeAddress: someCombo.typeAddress,
  		typeTokenId: someCombo.typeTokenId,
  		idToMint: someCombo.idToMint,
  		amountToMint: someCombo.amountToMint, 
  		inputIds: someCombo.inputIds, 
  		multiplier: someCombo.multiplier,
  		inputs: someInputs,
  	};

  	//checkInputAmounts(newCombo);

  	//console.log('adding inputs again');
  	//console.log(newCombo);
  	return newCombo;


	}



	async function getNextImage(input) {



		if(input.variety == 1) {

			console.log('Get next token for input: ');
			console.log(input);

			var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, input.tokenAddress);

			var balIndex = input.balIndex;
			balIndex++;
			if(balIndex > input.myBalance-1) {
				balIndex=0;
			}

			var nextTokenId = await tryCallUntilSuccessful(erc721_contract.methods.tokenOfOwnerByIndex(EVM.connectedAccount.toString(), balIndex));

			//var nextTokenId = await .call();

			var tokenUri = await tryCallUntilSuccessful(erc721_contract.methods.tokenURI(nextTokenId));
			//var tokenUri = await .call();

			
			const tokenResp = await fetch(tokenUri);
      const tokenObj = await tokenResp.json();
      console.log(tokenObj);
      var image = tokenObj.image;

      var newCC = currentCombo;
      console.log('newCC: ');
      console.log(newCC);
      for(var i=0;i<newCC.inputs.length;i++) {
      	var anInput = newCC.inputs[i];
      	if(anInput.id == input.id) {
      		console.log('Changing token for input: '+anInput.id+' to: ');
      		console.log(tokenObj);
      		newCC.inputs[i].image = image;
      		newCC.inputs[i].balIndex = balIndex;
      		newCC.inputs[i].tokenObj = tokenObj;

      		lastTokenObj=tokenObj;
      		lastBalIndex=balIndex;
      	}
      }

      setCurrentCombo(newCC);
      checkInputAmounts(newCC);
      setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
		}

		


	}

	function isPromise(p) {
	  return p && Object.prototype.toString.call(p) === "[object Promise]"
	}

	async function getCombos() {
      if(!EVM.fi_contract) { return; }

      let totalCombos = await tryCallUntilSuccessful(EVM.fi_contract.methods.totalCombos());
      //let totalCombos = await .call();

      console.log('Total Combos: '+totalCombos);

      var newCombos=[];

      var tctu = 9;
      // if(EVM.isTester) {
      // 	tctu = totalCombos;
      // }
      tctu = totalCombos;


      var doneFlags=0;

      for(var i=0;i<tctu;i++) {

      	tryCallUntilSuccessful(EVM.fi_contract.methods.combos(i)).then((someCombo)=>{

      		updateSomeCombo(someCombo).then((newCombo) => {
	      		newCombos.push(newCombo);
	      		doneFlags++;
	      	});

      	});

      	
      	

      	

      }

      do {
      	await sleep(1000);

      	

      	console.log('Waiting for all combos to load... '+doneFlags);
      	console.log(newCombos);
      } while(doneFlags < totalCombos);


      console.log(newCombos);
      setCombos(newCombos);

      for(var i=0;i<newCombos.length;i++) {
      	var aCombo = newCombos[i];
      	if(aCombo.id == currentCombo.id) {
      		setCurrentCombo(aCombo);
      		checkInputAmounts(aCombo);
      	}
      }

      setIsLoading(false);

      setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
      // setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));

      console.log('Got combos...');


  }	


	useEffect(() => {

	    setInterval(async function() {

	    if(EVM.connectedAccount != lastConnectedAccount) {
				getCombos();
				lastConnectedAccount = EVM.connectedAccount;
	    }

	    }, 500);

	}, []);


	function getCombo(id) {
	  for(var i=0;i<vars.combos.length;i++) {
	    if(vars.combos[i].id==id) {
	      return vars.combos[i];
	    }
	  }
	  return {name: ''};
	}


	const [recipeDialogStyles, setRecipeDialogStyles] = useState({display: 'none'});

	async function increaseMultiplier(id) {


		var newCC = currentCombo;

		newCC.multiplier++;
		if(newCC.multiplier < 1) {
			newCC.multiplier=1;
		}

		var newerCC = await updateSomeCombo(newCC);
		setCurrentCombo(newerCC);
		setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));

		checkInputAmounts(newerCC);

	}
	async function decreaseMultiplier(id) {


		var newCC = currentCombo;

		newCC.multiplier--;
		if(newCC.multiplier < 1) {
			newCC.multiplier=1;
		}

		var newerCC = await updateSomeCombo(newCC);
		setCurrentCombo(newerCC);
		setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));

		checkInputAmounts(newerCC);

	}

	async function checkInputAmounts(combo) {

		// console.log('any inputs? ');
		// console.log(combo.inputs);



		var newCC = combo;

		if(!newCC.inputs) {
			return;
		}

		var doesAnInputFail=false;
		for(var i=0;i<newCC.inputs.length;i++) {
			var input = newCC.inputs[i];
			console.log('input.myBalance: '+input.myBalance+' < '+(input.useQuantity*newCC.multiplier));
			if(input.myBalance < input.useQuantity*newCC.multiplier) {
				newCC.inputs[i].className='icon_tint_fade';
				newCC.inputs[i].className2='text_tint_fade';
				newCC.inputs[i].style = 1;
				doesAnInputFail=true;
			} else {
				newCC.inputs[i].className='icon_tint_fadeno';
				newCC.inputs[i].className2='text_tint_fadeno';
				newCC.inputs[i].style = 0;
			}
		}

		setAllInputsQualify(!doesAnInputFail);

		// var newerCC = await updateSomeCombo(newCC);
		// setCurrentCombo(newerCC);
		//console.log(newCC);

		setRender(render+Math.random(1,10));
		//setRender(render++);
		setFlowState(Math.random(1,99999));

		//console.log('Checked input amounts...');
		
	}

	var [openCraftWindow, setOpenCraftWindow] = useState(false);

	var [flowState, setFlowState] = useState(0);

	function returnCodeForNextApproval() {

		console.log('Entering returnCodeForNextApproval...');


		
		var howFar=0;
		for(var i=0;i<currentCombo.inputs.length;i++) {
			var input = currentCombo.inputs[i];

			// console.log('input ');
			// console.log(input);
			var varietyApproved=false;
			// GET VARIETY AND SEE IF APPROVED
			if(vars.itemVarieties[input.variety]=='ERC20') {
    		if(EVM.web3.utils.fromWei(input.someAllowance) >= input.useQuantity*currentCombo.multiplier) {
    			console.log(input.name+' ERC20 approved for '+EVM.web3.utils.fromWei(input.someAllowance));
    			varietyApproved=true;
    			howFar=howFar+2;
    		}

    	} else if(vars.itemVarieties[input.variety]=='ERC721') {
    		//var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, props.address);
    		if(input.someIsApprovedForAll == true) {
    			console.log(input.name+' ERC721 all approved!');
    			varietyApproved=true;
    			howFar=howFar+2;
    		}

    	} else if(vars.itemVarieties[input.variety]=='ERC1155') {
    		if(input.someIsApprovedForAll == true) {
    			console.log(input.name+' ERC1155 all approved!');
    			varietyApproved=true;
    			howFar=howFar+2;
    		}
    	}

    	if(!varietyApproved) {


    		theFlowState = howFar;
    		console.log(vars.itemVarieties[input.variety]+' not approved! '+EVM.web3.utils.fromWei(input.someAllowance)+' all: '+input.someIsApprovedForAll);

    		if(vars.itemVarieties[input.variety]=='ERC20') {

    			return (<>
    			
	    			<span className="modal_text">We need to approve {input.useQuantity*currentCombo.multiplier} x {input.name}...</span><br/><br/>
	    			<Button size="lg" variant="primary" onClick={() => {  approveErc20Amount(input.tokenAddress, EVM.web3.utils.toWei((input.useQuantity*currentCombo.multiplier).toString())); }}>{'INFINITE APPROVE '+input.name}</Button>

	    		</>);

    		} else if(vars.itemVarieties[input.variety]=='ERC721') {

    			return (<>
    			
	    			<span className="modal_text">We need to approve {input.useQuantity*currentCombo.multiplier} x {input.name}...</span><br/><br/>
	    			<Button size="lg" variant="primary" onClick={() => {  approveErc721(input.tokenAddress); }}>{'APPROVE '+(input.useQuantity*currentCombo.multiplier)+' '+input.name}</Button>

	    		</>);

    		} else {

    			return (<>
    			
	    			<span className="modal_text">We need to approve {input.useQuantity*currentCombo.multiplier} x {input.name}...</span><br/><br/>
	    			<Button size="lg" variant="primary" onClick={() => {  approveErc1155(input.tokenAddress); }}>{'APPROVE '+input.name.toUpperCase()}</Button>

	    		</>);

    		}

    		
    	}

    	

		}

		theFlowState = theFlowState + 2;

    console.log('theFlowState '+theFlowState);



		return false;
	}

	async function approveErc20Amount(erc20, amount) {
		if(!EVM.fi_contract) { return; }

		//infinite approval
		amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';


		var fsBefore=theFlowState;

		theFlowState = theFlowState+1;
		console.log('advancing theFlowState: '+theFlowState);
    setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));

    //

    var erc20_contract = new EVM.web3.eth.Contract(EVM.ERC20_ABI, erc20);

    //var allowanceBefore = await erc20_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.FI_ADD[0]).call();

    await callMethod(erc20_contract.methods.approve(EVM.FI_ADD[0], amount), 0, EVM.connectedAccount,  300000, async function(err, res) {
    	
	    if(err) {
		    theFlowState = fsBefore; setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
		    console.log('Error '+theFlowState);
		    console.log(err.message);
	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    			
	    		var allowanceAfter = await tryCallUntilSuccessful(erc20_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
	    		//var allowanceAfter = await .call();
	    		console.log('Waiting... '+allowanceAfter.toString()+' == '+amount.toString());

	    	} while(allowanceAfter.toString() != amount.toString());


	    	theFlowState = theFlowState+1;
	    	///getCombos();
	    	var newCombo = await updateSomeCombo(currentCombo);
	    	setCurrentCombo(newCombo);
	    	checkInputAmounts(newCombo);
	    	setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
	      
	      console.log('Done ERC20 Approval! '+theFlowState);
	   
	    }
	  });

	}
	async function approveErc721(erc721) {
		if(!EVM.fi_contract) { return; }



		var fsBefore=theFlowState;

		theFlowState = theFlowState+1;
    setRender(render+1);

    //var isApprovedForAllBefore = await EVM.fi_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]).call();
    var erc721_contract = new EVM.web3.eth.Contract(EVM.FUM_ABI.abi, erc721);

    await callMethod(erc721_contract.methods.setApprovalForAll(EVM.FI_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err, res) {
    	
    	
	    
	      

	    if(err) {
		    theFlowState = fsBefore; setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
		    console.log('Error '+theFlowState);
		    console.log(err.message);
	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var isApprovedForAllAfter = await tryCallUntilSuccessful(erc721_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
	    		//var isApprovedForAllAfter = await .call();
	    		//console.log('Waiting... '+isApprovedForAllBefore+' == '+isApprovedForAllAfter);

	    	} while(isApprovedForAllAfter == false);


	    	theFlowState = theFlowState+1;
	    	var newCombo = await updateSomeCombo(currentCombo);
	    	setCurrentCombo(newCombo);
	    	checkInputAmounts(newCombo);
	    	setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
	       
	      console.log('Done! '+theFlowState);
	   
	    }
	  });

	}

	async function approveErc1155(erc1155) {
		if(!EVM.fi_contract) { return; }



		var fsBefore=theFlowState;

		theFlowState = theFlowState+1;
    setRender(render+1);

    //var isApprovedForAllBefore = await EVM.fi_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]).call();
    var erc1155_contract = new EVM.web3.eth.Contract(EVM.FI_ABI.abi, erc1155);

    await callMethod(erc1155_contract.methods.setApprovalForAll(EVM.FI_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err, res) {
    	
    	
	    
	      

	    if(err) {
		    theFlowState = fsBefore; setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
		    console.log('Error '+theFlowState);
		    console.log(err.message);
	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var isApprovedForAllAfter = await tryCallUntilSuccessful(erc1155_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FI_ADD[0]));
	    		//var isApprovedForAllAfter = await .call();
	    		//console.log('Waiting... '+isApprovedForAllBefore+' == '+isApprovedForAllAfter);

	    	} while(isApprovedForAllAfter == false);


	    	theFlowState = theFlowState+1;
	    	var newCombo = await updateSomeCombo(currentCombo);
	    	setCurrentCombo(newCombo);
	    	checkInputAmounts(newCombo);
	    	setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
	       
	      console.log('Done! '+theFlowState);
	   
	    }
	  });

	}

	async function craft() {

		if(!EVM.fi_contract) { return; }

		
    
		theFlowState = theFlowState+1;
    	setRender(render+1);
    	var mintItemBalBefore = await tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), currentCombo.idToMint));
    //var mintItemBalBefore = await .call();

    var sendingTokenIds=[];
    for(var i=0;i<currentCombo.inputs.length;i++) {
    	var anInput = currentCombo.inputs[i];
    	if(anInput.tokenObj) {
    		sendingTokenIds.push(anInput.tokenObj.id);
    	} else {
    		sendingTokenIds.push(0);
    	}
     	
    }

    await callMethod(EVM.fi_contract.methods.mintFromCombo(currentCombo.id, currentCombo.multiplier, sendingTokenIds), 0, EVM.connectedAccount,  2000000, async function(err, res) {
    	
    	
	    
	      

	    if(err) {
		    theFlowState = currentCombo.inputs.length*2; setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
		    console.log('Error '+theFlowState);
		    console.log(err.message);
	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var mintItemBalAfter = await tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), currentCombo.idToMint));
	    		//var mintItemBalAfter = await .call();
	    		console.log('Waiting... '+mintItemBalAfter+' == '+mintItemBalBefore);

	    	} while(mintItemBalAfter == mintItemBalBefore);


	    	theFlowState = theFlowState+1;


	    	// var newCombo = await updateSomeCombo(currentCombo);
	    	// setCurrentCombo(newCombo);
	    	
	    	getCombos();
	    	checkInputAmounts(currentCombo);
	      setRender(render+1); setRender(render+1); setFlowState(Math.random(1,99999));
	      console.log('Done! '+theFlowState);
	   
	    }
	  });

	}


	function craftModal() {

		//console.log('craftModal');

		if(!currentCombo) {
			return (<></>);
		}
		if(!currentCombo.inputs) {
			return (<></>);
		}

		var numInputs = currentCombo.inputs.length;

    if(theFlowState < numInputs*2) {
    	console.log('theFlowState: '+theFlowState);
    	// THIS IS AN APPROVAL OR APPROVAL WAITING STATE
    	if(theFlowState%2==0) {
    		// THIS IS AN APPROVAL STATE
    		var code = returnCodeForNextApproval();
    		

    		if(code) {
    			return code;
    		}

    		return craftModal();
    		
    		
    		// ADVANCE TO NEXT STATE
    		//console.log('setFlowState... '+flowState);
    		
    	} else {
    		// THIS IS A APPROVAL WAITING STATE
    		//console.log('Approval Waiting State');

    		var waitingOnInput = currentCombo.inputs[Math.floor(theFlowState/2)];

    		console.log('flowState: '+Math.floor(theFlowState/2));


    		if(vars.itemVarieties[waitingOnInput.variety]=='ERC20') {

    			return (<>

    			
	    			<span className="modal_text">Approving... {waitingOnInput.name}</span><br/>
	    			{/*<span className="modal_text">Approved Amount: {EVM.web3.utils.fromWei(waitingOnInput.someAllowance.toString())} / {waitingOnInput.useQuantity*currentCombo.multiplier}</span><br/>*/}

	    		</>);

    		} else if(vars.itemVarieties[waitingOnInput.variety]=='ERC721') {

    			return (<>

    			
    			<span className="modal_text">Approving... {waitingOnInput.name}</span><br/>
    			{/*<span className="modal_text">Approved for All: {waitingOnInput.someIsApprovedForAll?'True':'False'}</span><br/>*/}

    		</>);

    		} else if(vars.itemVarieties[waitingOnInput.variety]=='ERC1155') {

    			return (<>

    			
    			<span className="modal_text">Approving... {waitingOnInput.name}</span><br/>
    			{/*<span className="modal_text">Approved for All: {waitingOnInput.someIsApprovedForAll?'True':'False'}</span><br/>*/}

    		</>);

    		}

    		
    	}
    } else if(theFlowState == numInputs*2 || theFlowState == numInputs*2+1 || theFlowState == numInputs*2+2) {
    	// THIS IS ONE OF TWO FINAL STATES, CRAFTING OR WAITING FOR CRAFTING
    	//console.log('One of final two states');

    	if(theFlowState == numInputs*2 || theFlowState == (numInputs*2)+1) {

    		return (<>
    			
    			<span className="modal_text">All ingredients are approved.<br/></span><br/>
    			<IngredientIcon

							key={'R2C'+currentCombo.idToMint+EVM.FI_ADD[0]+(currentCombo.amountToMint*currentCombo.multiplier)+currentCombo.balanceToShow}
							tkey={'R2C'+currentCombo.idToMint+EVM.FI_ADD[0]+(currentCombo.amountToMint*currentCombo.multiplier)+currentCombo.balanceToShow}
							bgColor="#271e1c"
							bgColorOver="#4c403d"
							hasTooltip={false}
							shiftY={-130}
							//address={EVM.FI_ADD[0]}
							address={(currentCombo.typeAddress!='0x0000000000000000000000000000000000000000'?currentCombo.typeAddress:EVM.FI_ADD[0])}
							tid={currentCombo.idToMint} 
							size={120}
							balance={currentCombo.balanceToShow}
							/><br/>
					<span className="modal_text">Craft <b>{currentCombo.name}</b>?</span><br/><br/>
    			<Button size="lg" variant="primary" onClick={() => {  craft(); }}>{theFlowState==numInputs*2?'CRAFT!':'CRAFTING...'}</Button>
    		</>);

    	} else {
    		return (<>
    			
    			<span className="modal_text">SUCCESS! - <a href="/inventory">Go check your inventory!</a></span><br/>
    			<br/>
    			<Button size="sm" variant="primary" onClick={() => {  theFlowState=0; setOpenCraftWindow(false); }}>{'OKAY'}</Button>
    			
    		</>);
    	}

    	
    }

  }
	if(!EVM.isTester && subs.craftingPrivate) { return(<>
		<div className="dark_page_crafting">
		<h2>Crafting</h2><br/>
		<span className="white_link"><a href="/about_crafting">Learn more about Crafting.</a></span>
		<div className={(isLoading ? '' : 'hidden')}>
			<div id="rotatingSkelly" className='rotate'></div>
		</div>
		</div>
	</>); }


	if(!currentCombo || !currentCombo.inputs || !currentCombo.makes) {
		return (<></>);
	}

	return (<>

			

			{modal(openCraftWindow, () => setOpenCraftWindow(false), getCombo(currentCombo.id).name, function() {  return craftModal(); })}

			<div className="dark_page_crafting">
				
				<div className="recipe_dialog" style={recipeDialogStyles}>
					<div className="inner_recipe_dialog">
						<div className="close_box_recipe_dialog" onClick={() => { setRecipeDialogStyles({display: 'none'}); }}>
						<Button size="lg" variant="light" onClick={() => { setRecipeDialogStyles({display: 'none'}); }}>X</Button>
						</div>
						<br/><br/><br/><br/>
						<h2>{getCombo(currentCombo.id).name}</h2><br/>
						<h4>{(currentCombo.makes?currentCombo.makes.description:'')}</h4><br/>
						<IngredientIcon

							key={'IngToMake'+currentCombo.idToMint+EVM.FI_ADD[0]+(currentCombo.amountToMint*currentCombo.multiplier)+currentCombo.balanceToShow}
							tkey={'IngToMake'+currentCombo.idToMint+EVM.FI_ADD[0]+(currentCombo.amountToMint*currentCombo.multiplier)+currentCombo.balanceToShow}
							bgColor="#271e1c"
							bgColorOver="#4c403d"
							hasTooltip={true}
							shiftY={-130}
							//address={EVM.FI_ADD[0]}
							address={(currentCombo.typeAddress!='0x0000000000000000000000000000000000000000'?currentCombo.typeAddress:EVM.FI_ADD[0])}
							tid={currentCombo.idToMint} 
							size={120}
							balance={currentCombo.balanceToShow}
							/><br/>
						<br/><h4><b>{currentCombo.name}</b></h4><br/>
						<h6>Requires:</h6>
						<div className="requires_container">
							{currentCombo.inputs.map((input, index) => (
								
								<div key={index} className="ingredient_box">

									<IngredientIcon 
									key={'Ing'+input.multiTokenId+input.tokenAddress+(input.useQuantity*currentCombo.multiplier)+input.style+input.image}
									tkey={'Ing'+input.multiTokenId+input.tokenAddress+(input.useQuantity*currentCombo.multiplier)+input.style+input.image}
									tid={input.multiTokenId}
									address={input.tokenAddress}
									customImage={input.image}
									bgColor={!input.style?"#271e1c":'#5a1c12'}
									bgColorOver={!input.style?'#4c403d':'#5a1c12'}
									hasTooltip={true}
									customName={(input.variety==1?'FUM #'+input.tokenObj.id:false)}
									shiftY={-130}
									size={80}
									fontSize={12}
									onClick={() => { getNextImage(input); }}
									balance={(input.useQuantity*currentCombo.multiplier)}
									/><br/>
									<b>{(input.variety!=1?input.name+' ':'')}{(input.tokenObj?(input.tokenObj.name?input.tokenObj.name:''):'')}</b>
								</div>
								
							))}
						</div><br/>
						<Button size="lg" variant="dark" onClick={() => { increaseMultiplier(currentCombo.id); }}>+</Button>&nbsp;
						<Button size="lg" variant="dark" onClick={() => { decreaseMultiplier(currentCombo.id); }}>-</Button>&nbsp;
						<Button size="lg" disabled={(allInputsQualify?0:1)} variant={(allInputsQualify?"primary":"primary")} onClick={() => { setOpenCraftWindow(true); }}>{(allInputsQualify?"CRAFT ITEM":"NOT ENOUGH")}</Button>
						<br/><br/><br/>
					</div>		
				</div>
				<h2>Crafting</h2><br/>
				<div className={(isLoading ? '' : 'hidden')}>
					<div id="rotatingSkelly" className='rotate'></div>
				</div>
				<div className="recipe_container">
				{combos.map((aCombo, index) => (
					<div key={index} className="recipe_line" onClick={() => { 
						
	      		theFlowState=0; setRecipeDialogStyles({display: 'block'}); setCurrentCombo(aCombo); checkInputAmounts(aCombo); console.log('Selected combo: '+aCombo.id); }}>
						<div className="recipe_line_left_box">
							<IngredientIcon
								key={'Craft'+aCombo.idToMint+EVM.FI_ADD[0]+index}
								tkey={'Craft'+aCombo.idToMint+EVM.FI_ADD[0]+index}
								tid={aCombo.idToMint}
								address={(aCombo.typeAddress!='0x0000000000000000000000000000000000000000'?aCombo.typeAddress:EVM.FI_ADD[0])}
								size={48}
								bgColor="#271e1c"
								bgColorOver="#483c39"
								hasTooltip={true}
								shiftY={10}
								onClick={() => { }}
								balance={aCombo.balanceToShow}
							/>

							
						</div>
						<div className="recipe_line_right_box">{getCombo(aCombo.id).name}</div>
					</div>
				))}
				</div>
				<br/><br/>
				<span className="white_link"><a href="/about_crafting">Learn more about Crafting.</a></span>
      </div>
    </>);

}

export default Crafting;