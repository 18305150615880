import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import Button from 'react-bootstrap/Button';
import { numberWithCommas, modal, callMethod, sleep, tryCallUntilSuccessful } from './../helpers.js';
import BuffBar from './BuffBar';
import {
  useParams
} from "react-router-dom";
import IngredientIcon from './IngredientIcon.js';
import FIActivateButton from './FIActivateButton.js';
import FITransferButton from './FITransferButton.js';

var lastConnectedAccount;


var dialogClosed=true;
function Inventory(props) {

	document.title = subs.properSiteName+": Inventory";

	var [isLoading, setIsLoading] = useState(true);

	const [receivedItems, setReceivedItems] = useState(false);

	const [items, setItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState({ingredient: {id: 0, address: ''}});
	const [fooBalance, setFooBalance] = useState('');
  const [ripBalance, setRipBalance] = useState('');
  const [cndyBalance, setCndyBalance] = useState('');
 	const [recipeDialogStyles, setRecipeDialogStyles] = useState({display: 'none'});
 	var [render, setRender] = useState(0);

	useEffect(() => {

	    setInterval(async function() {

		    if(EVM.connectedAccount != lastConnectedAccount) {
		        getItems();
		        getCurrencies();
		        lastConnectedAccount = EVM.connectedAccount;
		    }

	    }, 500);

	    setInterval(async function() {

		    if(EVM.connectedAccount && items.length > 0) {
		    		if(dialogClosed) {
		    			updateBalancesUsingRPC(items);
		    		} else {
		    			console.log('Dialog open.');
		    		}
		        
		    }

	    }, 5000);


	    

	}, []);

	function setSelected(item) {
		setSelectedItem(item);
	}
	async function getItems() {

		if(EVM.connectedAccount) {

			// const itemsResp = await fetch(subs.apis[subs.apiId].url+'inventory/'+EVM.connectedAccount.toString());
   //    var newItems = await itemsResp.json();





      if(!EVM.fi_contract) {
          return;
      }
      if(!EVM.fia_contract) {
          return;
      }

      let totalItems = await tryCallUntilSuccessful(EVM.fi_contract.methods.totalItems());

      var newItems=[];

      var doneFlags = 0;

      for(var i=0;i<totalItems;i++) {
      	tryCallUntilSuccessful(EVM.fi_contract.methods.items(i)).then((item)=>{
      		tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), item.id)).then((balanceOf)=>{
      			var ingredient = EVM.ingredientForAddressAndId(EVM.FI_ADD[0], item.id);

		      	//console.log(ingredient);


		      
		      

		      	var newItem = {id: item.id, name: item.name, count: balanceOf, ingredient: ingredient, address: ingredient.address};

		      	//console.log(newItem);

		      	if(balanceOf > 0) {
		      		newItems.push(newItem);
		      	}
		      	doneFlags++;
      		});
      	});

      	//var balanceOf = await ;


      	//let balanceOf = await callMethodCached('FI', 'balanceOf', '"'+player.toString()+'","'+item.id+'"', 60);
      	
      	//console.log('balanceOf ['+item.id+']: '+balanceOf);
      	//let itemName = await EVM.fi_contract.methods.itemNames(i).call();

      	

      	
      }


      do {
		  	await sleep(1000);
		  	console.log('Waiting for all items to load... '+doneFlags);
		  } while(doneFlags < totalItems);


		  newItems.sort((a, b) => {
			    return a.id - b.id;
			});

      reloadItems(newItems);
   	
   setReceivedItems(true);

   setIsLoading(false);


   	
   await updateBalancesUsingRPC(newItems);



    

   }

  }

  function reloadItems(newItems) {
  	setItems(newItems);

    setRender(render+Math.floor(Math.random() * 10)); setRender(render+Math.floor(Math.random() * 10));

    for(var i=0;i<newItems.length;i++) {
    	var aNewItem = newItems[i];

    	if(aNewItem.id == selectedItem.id) {
    		setSelectedItem(aNewItem);
    	}
    }
  }

  async function updateBalancesUsingRPC(newItems) {

  	for(var i=0;i<newItems.length;i++) {
    	var anItem = newItems[i];
    	let balanceOf = await tryCallUntilSuccessful(EVM.fi_contract.methods.balanceOf(EVM.connectedAccount.toString(), anItem.id));

    	newItems[i].count = balanceOf;
    }

    reloadItems(newItems);

  }


  function renderNoItems() {
  	if(!receivedItems) {
  		return (<><h5>Getting inventory...</h5></>);
  	} else if(items.length == 0) {
  		return (<><h5>You have no items!</h5></>);
  	}
  }

  async function  getCurrencies() {

  	if(EVM.connectedAccount) {

	  	// const allBalancesResp = await fetch(subs.apis[subs.apiId].url+'allBalances/'+EVM.connectedAccount.toString());
	   //  var allBalances = await allBalancesResp.json();

	   //  setFooBalance(numberWithCommas(Math.round(allBalances[0]/Math.pow(10,18))));
	   //  setRipBalance(numberWithCommas(Math.round(allBalances[1]/Math.pow(10,18))));
	   //  setCndyBalance(numberWithCommas(Math.round(allBalances[2]/Math.pow(10,18))));

	  //}


  

      let balance =  await EVM.foo_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setFooBalance(numberWithCommas(Math.round(balance/Math.pow(10,18))));

      let balance2 =  await EVM.rip_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setRipBalance(numberWithCommas(Math.round(balance2/Math.pow(10,18))));

      let balance4 =  await EVM.cndy_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setCndyBalance(numberWithCommas(Math.round(balance4/Math.pow(10,18))));
    }
}



	if(!EVM.isTester && subs.craftingPrivate) { return(<>
		
		<div className="dark_page_crafting">
		<h2>Inventory</h2>
			<span className="white_link"><a href="/about_crafting">Learn more about Crafting.</a></span>
			<div className={(isLoading ? '' : 'hidden')}>
				<div id="rotatingSkelly" className='rotate'></div>
			</div>
		</div>

	</>); }

	return (<>
			<div className="dark_page">
			<div className="recipe_dialog" style={recipeDialogStyles}>
				<div className="inner_recipe_dialog">
					<div className="close_box_recipe_dialog" onClick={() => { setRecipeDialogStyles({display: 'none'}); dialogClosed=true; }}>
					<Button size="lg" variant="light" onClick={() => { setRecipeDialogStyles({display: 'none'}); dialogClosed=true; }}>X</Button>
					</div>
					<div className="item_inspector">
	        	<IngredientIcon
	        	key={'InvInsp'+selectedItem.ingredient.id+selectedItem.ingredient.address+selectedItem.count}
	        	tkey={'InvInsp'+selectedItem.ingredient.id+selectedItem.ingredient.address+selectedItem.count}
	        	tid={selectedItem.ingredient.id}
	        	address={selectedItem.ingredient.address}
	        	size={128}
	        	balance={selectedItem.count}

	        	/>
	        	<br/><b>{selectedItem.name}</b><br/>{selectedItem.ingredient.description}
	        	
	        </div>
	        <FIActivateButton key={'FIAB'+selectedItem.ingredient.id} tkey={'FIAB'+selectedItem.ingredient.id} tid={selectedItem.ingredient.id} updateState={getItems} />
	        {/*<br/><br/><FITransferButton key={'FITB'+selectedItem.ingredient.id} tkey={'FITB'+selectedItem.ingredient.id} ting={selectedItem.ingredient} updateState={getItems} />*/}
	        <br/><br/><br/>
	        {/*<span className={((!subs.hideBuffsCrafting && !subs.inventoryOff) ? '' : 'hidden')}>*/}
	        <p>Your Active Buffs:</p><BuffBar 
					key={'BB'+EVM.connectedAccount.toString()+selectedItem.count} 
					tkey={'BB'+EVM.connectedAccount.toString()+selectedItem.count} 
					acc={EVM.connectedAccount.toString()}
					marginTop={0}
					iconSize={64}
					/>
					{/*</span>*/}
	        <br/><br/><br/>
				</div>
			</div>


			<h2>Inventory</h2>
			
				<div className="currency_holder">
				{fooBalance} <div className="currency_icon" style={{backgroundImage: "url('/mm_logo512.png')"}}></div> {cndyBalance} <div className="currency_icon" style={{backgroundImage: "url('/Candy512.png')"}}></div> {ripBalance} <div className="currency_icon" style={{backgroundImage: "url('/mm_riplogo512.png')"}}></div>
				</div>

				{renderNoItems()}
				<div className={(isLoading ? '' : 'hidden')}>
					<div id="rotatingSkelly" className='rotate'></div>
				</div>
        <div className="bag_holder">
        	{items.map((item, index) => (
						<div key={index} className="item_cont">
						<IngredientIcon
							key={'Inv'+item.id+item.address+item.count}
							tkey={'Inv'+item.id+item.address+item.count}
							tid={item.id}
							address={item.address}
							size={64}
							bgColor="#271e1c"
							bgColorOver="#483c39"
							hasTooltip={true}
							shiftY={10}
							onClick={() => {setSelectedItem(item); setRecipeDialogStyles({display: 'block'}); dialogClosed=false; }}
							balance={item.count}
						/>
						<b>{item.name}</b>
						</div>
					))}
        </div>
        <br/><br/>
        <span className="white_link"><a href="/about_crafting">Learn more about Crafting.</a></span>
      </div>
    </>);

}

export default Inventory;