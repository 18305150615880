import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'


function SelectFighterFantum(props) {

	var token = props.token;



	
	return (

		<div className="selectFighterFantum" onClick={() => props.onFighterClick()}>

			<div className={"select_fantum_image_left"}>

				<img width={props.rowsize-10} height={props.rowsize-10} src={token.image32} />

			</div>
			<div className={"select_fantum_text_middle"}>

				{token.name}<br/><span className={"select_fantum_text_stats"}>#{token.id}</span> <span className={"select_fantum_text_stats rarityColor_" + percentileNum(Math.round(100-token.rarity/token.totalFantums*100))+"_rank"}>#{token.rarity}</span> <span className={"select_fantum_text_stats rarityColor_" + rollNum(token.hash.toString())+"_roll"}>{rollName(token.hash.toString()).toUpperCase()}</span>
				

			</div>
			<div className={"select_fantum_level_right"}>

				<span className="select_fum_level_color">{Math.floor(token.level)}</span>
			</div>
			
			
	    	
	    </div>

	);

}

export default SelectFighterFantum;