import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'


function LeaderRow(props) {

	var token = props.token;


	var size = 80;
	
	return (

		<div className="leaderboard_fantum" onClick={() => window.open('/token/'+token.id, '_self')}>
			<div className={"leader_fantum_rank"}>
			{props.number} 
			</div>
			<div className={"leader_fantum_image_left"}>

				<img width={size-20} height={size-20} src={token.image32} />

			</div>
			<div className={"leader_fantum_text_middle"}>

				{token.name}<br/><span className={"leader_fantum_text_stats"}><span className="leader_darker_num">#{token.id}</span> {token.lwtdk[1]+token.lwtdk[4]}W {token.lwtdk[0]+token.lwtdk[3]}L {token.lwtdk[2]}T {token.lwtdk[4]}K {token.lwtdk[3]}D<br/> </span> <span className={"select_fantum_text_stats rarityColor_" + percentileNum(Math.round(100-token.rarity/token.totalFantums*100))+"_rank"}>#{token.rarity}</span> <span className={"select_fantum_text_stats rarityColor_" + rollNum(token.hash.toString())+"_roll"}>{rollName(token.hash.toString()).toUpperCase()}</span>
				

			</div>
			<div className={"leader_fantum_level_right"}>

				<span className="select_fum_level_color">{Math.floor(token.level)}</span>
			</div>
			
			
	    	
	    </div>

	);

}

export default LeaderRow;