import React, {useState, useEffect} from 'react';
import About from './components/About';
import Mint from './components/Mint';
import Market from './components/Market';
import Browse from './components/Browse';
import Wallet from './components/Wallet';
import Token from './components/Token';
import FAQ from './components/FAQ';
import Header from './components/Header';
import Footer from './components/Footer';
import Snap from './components/Snap';
import SnapRM from './components/SnapRM';
import SnapMatch from './components/SnapMatch';
import TradeHistory from './components/TradeHistory';
import DuelHistory from './components/DuelHistory';
import Guide from './components/Guide';
import OldFarm from './components/OldFarm';
import Farm from './components/Farm';
import Stake from './components/Stake';
import Duel from './components/Duel';
import Graveyard from './components/Graveyard';
import Leaders from './components/Leaders';
import AboutDuels from './components/AboutDuels';
import AboutCrafting from './components/AboutCrafting';
import SnapBurned from './components/SnapBurned';
import TrickOrTreat from './components/TrickOrTreat';
import Inventory from './components/Inventory';
import Crafting from './components/Crafting';

import * as vars from './vars.js'; import * as subs from './subs.js';
import * as EVM from './evm.js';
import * as colors from './colors.js';
import { ReactSession } from 'react-client-session';

import favicon from './favicon.ico';
import favicon_gm from './favicon_gm.ico';
import favicon_rm from './favicon_rm.ico';

import { Helmet, HelmetProvider } from 'react-helmet-async';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  Redirect
} from "react-router-dom";




function App() {

  const [hideForTesters, setHideForTesters] = useState(subs.siteIsPrivate);

  const helmetContext = {};

  const connect = async() => {
    let result = await EVM.connectWallet();
  }

    var subsite = false;
    ReactSession.setStoreType("localStorage");

    //console.log('window.location: '+window.location);
    //console.log(window.location.host);
    // console.log(window.location.host.split("."));
    // console.log(window.location.host.split(".")[0]);


    if(window.location.host.split(".")[0]=='rarity') {
      subsite='rarity';
    }

    // if(window.location.host.split(".")[0]=='h' && window.location.host.split(".")[1]=='gothums') {
    //   subsite='hothums';
    // }

    if(window.location.host.split(".")[0]=='gothums') {
      subsite='gothums';
    }



    ReactSession.set("subsite", subsite);

    var skipTest=false;
    if(window.location.toString().indexOf("snap")!==-1) {
      skipTest=true;
    }

      //console.log('window.location.host.split(".")[0]: '+window.location.host.split(".")[0]);

    useEffect(() => {
      connect();

      setInterval(function() {

        // console.log('EVM.isTester: '+EVM.isTester);
        // console.log('EVM.connectedAccount: '+EVM.connectedAccount.toString());

        if(skipTest || (EVM.connectedAccount && subs.siteIsPrivate && EVM.isTester)) {
          
          setHideForTesters(false);
        }

      }, 1000);

  
    }, []);


    // if(hideForTesters) {
    //   return (<><h1>Gothums will launch soon...</h1></>);
    // } else {
   return (

        <div id="center" style={colors.colorStyles}>
        <HelmetProvider context={helmetContext}>
        <Helmet>
            <meta charSet="utf-8" />
            <link rel="icon" type="image/png" href={!subsite?favicon:(subsite=='rarity'?favicon_rm:favicon_gm)} sizes="16x16" />
            <title>{!subsite ? 'Fantums' : (subsite=='rarity' ? 'Fantums: Rarity Adventure' : 'Gothums')}</title>
        </Helmet>
        </HelmetProvider>

        <Router>

          <Switch>

          <Route exact path="/">
            <Header />
            <About />
            <Footer />
          </Route>


          <Route path="/mint">
              <Header />
              <Mint />
              <Footer />
            </Route>

            <Route path="/searchMarket/:q" children={marketPage} />
            <Route path="/searchWallet/:q" children={walletPage} />

             <Route path="/search/:q" children={browsePage} />

             <Route path="/market/:num/:ob/:o/:q" children={marketPage} />
             <Route path="/market/:num/:ob/:o" children={marketPage} />
            <Route path="/market/:num" children={marketPage} />
          <Route path="/market">
              <Header />
              <Market />
              <Footer />
            </Route>

            <Route path="/browse/:num/:ob/:o/:q" children={browsePage} />
            <Route path="/browse/:num/:ob/:o" children={browsePage} />
            <Route path="/browse/:num" children={browsePage} />
          <Route path="/browse">
              <Header />
              <Browse />
              <Footer />
            </Route>


            <Route path="/about_duels">
              <Header />
              <AboutDuels />
              <Footer />
            </Route>

            <Route path="/about_crafting">
              <Header />
              <AboutCrafting />
              <Footer />
            </Route>


            <Route path="/duel/:num" children={duelPage} />
            <Route path="/duel">
              <Header />
              <Duel />
              <Footer />
            </Route>
          


              <Route path="/graveyard/:num/:ob/:o/:q" children={graveyardPage} />
            <Route path="/graveyard/:num/:ob/:o" children={graveyardPage} />
            <Route path="/graveyard/:num" children={graveyardPage} />
             <Route path="/graveyard">
              <Header />
              <Graveyard />
              <Footer />
            </Route>




            <Route path="/faq">
              <Header />
              <FAQ />
              <Footer />
            </Route>

            <Route path="/inventory">
              <Header />
              <Inventory />
              <Footer />
            </Route>

            <Route path="/crafting">
              <Header />
              <Crafting />
              <Footer />
            </Route>

            <Route path="/leaders/:num" children={leadersPage} />
            <Route path="/leaders">
              <Header />
              <Leaders />
              <Footer />
            </Route>

            <Route path="/wallet/:num/:ob/:o/:q" children={walletPage} />
            <Route path="/wallet/:num/:ob/:o" children={walletPage} />
             <Route path="/wallet/q/:q" children={walletPage} />
              <Route path="/wallet/:num" children={walletPage} />   
            <Route path="/wallet">
              <Header />
              <Wallet />
              <Footer />
            </Route>

          <Route path="/token/:id" children={tokenPage} />


          <Route path="/snapBurned">
              <SnapBurned />
            </Route>

          <Route path="/snapMatch:id" children={snapMatchPage} />
          <Route path="/snapRm:hash" children={snapRmPage} />
          <Route path="/snap:hash" children={snapPage} />
          
          
          

          <Route path="/trade_history/:num" children={tradeHistoryPage} />
           <Route path="/trade_history">
              <Header />
              <TradeHistory />
              <Footer />
            </Route>

            <Route path="/duel_history/:num" children={duelHistoryPage} />
            <Route path="/duel_history">
              <Header />
              <DuelHistory />
              <Footer />
            </Route>

            <Route exact path="/discord" render={() => (window.location = "https://discord.gg/P3DjzraRtd")} />

  {/*              <Route path="/seats">
              <Header />
              <Seats />
              <Footer />
            </Route>*/}




            




            <Route path="/guide">
              <Header />
              <Guide />
              <Footer />
            </Route>

            <Route path="/old_farm">
              <Header />
              <OldFarm />
              <Footer />
            </Route>

            <Route path="/farm">
              <Header />
              <Farm />
              <Footer />
            </Route>

            <Route path="/trickortreat">
              <Header />
              <TrickOrTreat />
              <Footer />
            </Route>

            <Route path="/stake">
              <Header />
              <Stake />
              <Footer />
            </Route>
          
          </Switch>

        </Router>
        </div>
  );


}
    
//}



function tradeHistoryPage() {
  return (
    <>
   <Header />
    <TradeHistory />
    <Footer />
    </>
    );
}

function duelHistoryPage() {
  return (
    <>
   <Header />
    <DuelHistory />
    <Footer />
    </>
    );
}

function duelPage() {
  return (
    <>
   <Header />
    <Duel />
    <Footer />
    </>
    );
}


function marketPage() {
  return (
    <>
    <Header />
    <Market />
    <Footer />
    </>
    );
}

function graveyardPage() {
  return (
    <>
    <Header />
    <Graveyard />
    <Footer />
    </>
    );
}


function browsePage() {
  return (
    <>
    <Header />
    <Browse />
    <Footer />
    </>
    );
}


function leadersPage() {
  return (
    <>
    <Header />
    <Leaders />
    <Footer />
    </>
    );
}


function walletPage() {
  return (
    <>
   <Header />
    <Wallet />
    <Footer />
    </>
    );
}

function tokenPage() {
  return (
    <>
    <Header />
    <Token />
    <Footer />
    </>
    );
}

function tokenPageByName() {
  return (
    <>
    <Header />
    <Token />
    <Footer />
    </>
    );
}

function snapPage() {
  return (
    <>
    <Snap />
    </>
    );
}
function snapRmPage() {
  return (
    <>
    <SnapRM />
    </>
    );
}
function snapMatchPage() {
  return (
    <>
    <SnapMatch />
    </>
    );
}




export default App;