import React, {useState, useEffect} from 'react';
import FantumGen from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { addysEqual, rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl, rankNum, rankToName } from './../helpers.js'
import IconTint from 'react-icon-tint';
import { ReactSession } from 'react-client-session';

// var Filter = require('bad-words'),
// filter = new Filter();

function GridFantum(props) {

	ReactSession.setStoreType("localStorage");

	var token = props.token;

	var yours=false;

	if(EVM.connectedAccount && token.former_owner && addysEqual(EVM.connectedAccount, token.former_owner)) {
		yours=true;
	}
	

	//var totalFantums = props.totalFantums;

	// const [newRarity, setNewRarity] = useState(-1);
	
			

	// //console.log(token);

	// useEffect(() => {

	// 	(async () => {

	// 		const rarity = await getObjFromJSONUrl(
	// 			'http://api.fantums.com:9000/rankN/'+token.id
	// 		);
	// 		setNewRarity(rarity[0]);


	// 	})();

	// }, []);

	// const lastMatchInfo = await getObjFromJSONUrl(
	// 	subs.apis[subs.apiId].url+'lastMatchWith/'+tokenIsFighting
	// );
	// lastMatchId = lastMatchInfo[0];

	var margin ='6px';


	function hasReplay() {

		if(token.replay!=undefined && token.dead) {
			return (<div className="viewDeathFantum"><a href={"/duel/"+token.replay}>VIEW DEATH</a></div>);
		} else {
			return (<></>);
		}

		
	}


	
	return (

		<div className={"gridFantum "+((yours && props.pageName=='graveyard') ? 'gridFantumYours' : '')} style={{width: 'calc('+props.width+'px - 12px)', 'minHeight': 'calc('+props.height+'px - 12px)', margin: margin}}>

			<div className={"numberFloatBrowse"}>
			#{token.id}
			</div>
			<div className={"numberForSale "+(token.price>0? '' : 'hidden')}>
			<a href={'/token/'+token.id}>
			{Math.round(token.price/Math.pow(10,18)*100)/100} FTM
			</a>
			</div>

			<div className={"theFantumTint "+(token.dead && props.pageName=='graveyard' ? '' : 'hidden')}>
				<a href={'/token/'+token.id}><IconTint color="#0054a6" maxWidth={(props.width*100/128).toString()} maxHeight={(props.height*100/172).toString()} src={(token.dead && props.pageName=='graveyard' ? ((ReactSession.get("subsite")!='rarity') ? token.image32 : (token.summonerClass ? token.image32.replace('/img32/', '/rm_img32/') : token.image32)) : '')} /></a>
			</div>
			<div className={"gridFantumPad theFantum "+(((token.dead && props.pageName=='graveyard') || (props.pageName!='graveyard' && !token.dead)) ? '' : 'hidden')}>
				<a href={'/token/'+token.id}><img width={props.width*100/128} height={props.height*100/172} src={((ReactSession.get("subsite")!='rarity') ? token.image32 : (token.summonerClass ? token.image32.replace('/img32/', '/rm_img32/') : token.image32))} /></a>
				{/*<a className={ReactSession.get("subsite")=='rarity'? '' : 'hidden'} href={'/token/'+token.id}><FantumGen blockGet={true} cursor={true} hash={token.hash} size={props.width*96/128} rm={token.summonerClass} /></a>*/}
			</div>
			<div className={"theFantum "+((token.dead && props.pageName!='graveyard') ? '' : 'hidden')}>
				<a href={'/token/'+token.id}><img width={props.width*100/128} height={props.height*100/172} src={'/img/dead.png'} /></a>
			</div>
			<div className={"theFantumName "}>
				

				<span className="blackFantumName"><a href={'/token/'+token.id} >{token.name}</a></span><br/>


				<a href={'/token/'+token.id} >
				<div className={(!subs.subsite || subs.subsite=='rarity'? '' : 'hidden')+" fantumRaritySmaller rarityColor_" + rollNum(token.hash.toString())+"_roll"}>{rollName(token.hash.toString()).toUpperCase()} ({rScore(token.hash.toString())})</div>
				<div className={(!subs.subsite || subs.subsite=='rarity'? 'hidden' : '')+" fantumRaritySmaller rarityColor_" + rankNum(parseInt(token.rarity))+"_roll"}>{rankToName(parseInt(token.rarity)).toUpperCase()} ({parseInt(token.rarity)})</div>

				<div className={(!subs.subsite || subs.subsite=='rarity'? '' : 'hidden')+" fantumRaritySmallerTwo rarityColor_" + percentileNum(Math.round(100-token.rarity/token.totalFantums*100))+"_rank "+(token.rarity>0? '' : 'hidden')}>RANK {token.rarity}</div>
				<span className={(!subs.subsite? '' : 'hidden')}><div className={"fantumRaritySmallerThree "+((token.rarity>0)? '' : 'hidden')+' '+((token.dead && props.pageName=='graveyard') ? 'isGy' : '')}>LEVEL {Math.floor(token.level)}</div></span>
				</a>


				{/*<div className={"fantumRaritySmallerTwo"}>RANK {newRarity}</div>*/}
				

				{hasReplay()} 
				
			</div>
	    	
	    </div>

	);

}

export default GridFantum;