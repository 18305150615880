import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';

import Menu from './Menu';
import Notification from './Notification';
import * as EVM from './../evm.js';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import { numberWithCommas, tryCallUntilSuccessful } from './../helpers.js'
import { ReactSession } from 'react-client-session';


var shownNotificationHashes=[];

function Header() {

  ReactSession.setStoreType("localStorage");

  if(ReactSession.get("shownNotificationHashes")) {
    shownNotificationHashes=ReactSession.get("shownNotificationHashes");
  }
  

  

  const [connectedAccount, setConnectedAccount] = useState(undefined);

  const [notifications, setNotifications] = useState([]);

  const [fooBalance, setFooBalance] = useState('');
  const [ripBalance, setRipBalance] = useState('');
  const [sFooBalance, setSFooBalance] = useState('');

  const [cndyBalance, setCndyBalance] = useState('');

  const [walletBgImage, setWalletBgImage] = useState({});

  useEffect(() => {
    connect();

    leaveWalletButton();


    
    setTimeout(function() {

      checkNotifications();
      

    }, 1000);
    setInterval(function() {

      checkNotifications();
      

    }, 15000);

  }, []);


async function  getFooBalance() {
  if(EVM.connectedAccount) {


      let balance = await tryCallUntilSuccessful(EVM.foo_contract.methods.balanceOf(EVM.connectedAccount.toString()));
      //let balance =  await .call();
      setFooBalance(numberWithCommas(Math.round(balance/Math.pow(10,18))));

      let balance2 = await tryCallUntilSuccessful(EVM.rip_contract.methods.balanceOf(EVM.connectedAccount.toString()));
      //let balance2 =  await EVM.rip_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setRipBalance(numberWithCommas(Math.round(balance2/Math.pow(10,18))));

      let balance3 = await tryCallUntilSuccessful(EVM.sfoo_contract.methods.balanceOf(EVM.connectedAccount.toString()));
      //let balance3 =  await EVM.sfoo_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setSFooBalance(numberWithCommas(Math.round(balance3/Math.pow(10,18))));

      let balance4 = await tryCallUntilSuccessful(EVM.cndy_contract.methods.balanceOf(EVM.connectedAccount.toString()));
      //let balance4 =  await EVM.cndy_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
      setCndyBalance(numberWithCommas(Math.round(balance4/Math.pow(10,18))));
    }
}

  const connect = async() => {
   let result = await EVM.connectWallet();
   if(result.connectedStatus) {
    //console.log(result);
    setConnectedAccount(result.address);
   }
  }
  
  setInterval(function() {

    if(EVM.connectedAccount) {
      setConnectedAccount(EVM.connectedAccount);
      //checkNotifications();

      if(!fooBalance) {
        getFooBalance();
      }
    } else {
      setConnectedAccount(undefined);
      
    }
    

  }, 1000);


  


  var showConnectButton=false;
  if(window.ethereum) {
    showConnectButton=true;
  }

  function enterWalletButton() {
    setWalletBgImage({backgroundImage: "url('/img/icons/wallet_black.png')"});
    //console.log('enter');
  }
  function leaveWalletButton() {
    setWalletBgImage({backgroundImage: "url('/img/icons/wallet.png')"});
    //console.log('leave');
  }

  function renderLiveSite() {

    //  if(!connectedAccount && window.ethereum) {
    //   return (
    //     <>
    //   <Button className={"desktop addFoo padMenuButtonRight btn-noborder" + (showConnectButton ? '' : 'hidden')} onClick={EVM.addFoo} variant="outline-dark"></Button>
    //   <Button onMouseEnter={enterWalletButton}  onMouseLeave={leaveWalletButton} className={showConnectButton ? '' : 'hidden'} variant="outline-light" onClick={connect}><div  style={walletBgImage} className="menu_img_w"></div> Connect</Button>

    //   </>
    //   );
    // } else if(window.ethereum) {
    //     return (
    //       <>
    //     <div className={"ripBal "+(EVM.duelsPrivate ? 'hidden' : '')}>{ripBalance}</div><Button className={"desktop addRip padMenuButtonRight btn-noborder " + ((showConnectButton && !EVM.duelsPrivate) ? '' : 'hidden')} onClick={EVM.addRip} variant="outline-dark"></Button>
    //     <div className="fooBal">{fooBalance}</div><Button className={"desktop addFoo padMenuButtonRight btn-noborder" + (showConnectButton ? '' : 'hidden')} onClick={EVM.addFoo} variant="outline-dark"></Button>
    //     <Button onMouseEnter={enterWalletButton}  onMouseLeave={leaveWalletButton}  className={showConnectButton ? '' : 'hidden'} href={vars.comingSoonSiteMode ? '' : "/wallet"} variant="outline-light"><div  style={walletBgImage} className="menu_img_w"></div> {connectedAccount.toString().substring(0, 5)+'...'+connectedAccount.toString().substring(39, 42)}</Button>
    //     </>);
    // } else {
    //     return (
    //       <></>);
    // }

    var tokenIconSize = 18;

    if(!connectedAccount && window.ethereum) {
      return (
        <>
      <Button className={"desktop addFoo padMenuButtonRight btn-noborder" + (showConnectButton ? '' : 'hidden')} onClick={EVM.addFoo} variant="outline-dark"></Button>
      <Button onMouseEnter={enterWalletButton}  onMouseLeave={leaveWalletButton} className={showConnectButton ? '' : 'hidden'} variant="outline-light" onClick={connect}><div  style={walletBgImage} className="menu_img_w"></div> Connect</Button>

      </>
      );
    } else if(window.ethereum) {
        return (
          <>
          <div id="header_contains_tokens">
          <div onClick={EVM.addFoo} className="fooBal">{fooBalance}<img className="header_token_icon" src="/mm_logo512.png" width={tokenIconSize} height={tokenIconSize} /></div><br/>
          <div  onClick={EVM.addRip} className={"ripBal "+((subs.subsite) ? ('hidden') : ('inline-block') )}>{ripBalance}<img className="header_token_icon" src="/img/mm_riplogo512.png" width={tokenIconSize} height={tokenIconSize} /></div>
          <div  onClick={EVM.addSFoo} className={"fooBal inline-block"}>{sFooBalance}<img className="header_token_icon" src="/img/mm_slogo512.png" width={tokenIconSize} height={tokenIconSize} /></div>
          <div  onClick={EVM.addCndy} className={"ripBal "+((subs.subsite=='gothums')?'inline-block':'hidden')}>{cndyBalance}<img className="header_token_icon" src="/img/Candy512.png" width={tokenIconSize} height={tokenIconSize} /></div>

        </div>
        <Button onMouseEnter={enterWalletButton}  onMouseLeave={leaveWalletButton}  className={showConnectButton ? '' : 'hidden'} href={vars.comingSoonSiteMode ? '' : "/wallet"} variant="outline-light"><div  style={walletBgImage} className="menu_img_w"></div> {connectedAccount.toString().substring(0, 5)+'...'+connectedAccount.toString().substring(39, 42)}</Button>
        </>);
    } else {
        return (
          <></>);
    }


  }

  function renderLogo() {

    var logoWidth = 246*51.17/50.44;

    var lw = 370;
    var lh = 76;

    var logoFile = 'logo.png';
    var logoMobileFile = 'logoMobile.png';
    if(ReactSession.get("subsite")=='rarity') {
      logoFile = 'logo_rm.png';
      logoMobileFile = 'logoMobile_rm.png';
    } else if(ReactSession.get("subsite")=='gothums') {
      logoFile = 'logo_gm.png';
      logoMobileFile = 'logoMobile_gm.png';
    } else if(ReactSession.get("subsite")=='hothums') {
      logoFile = 'logo_gm.png';
      logoMobileFile = 'logoMobile_gm.png';
    }

    if(window.ethereum) {
      return (<a href="/"><img className="desktop" src={"/"+logoFile} width={Math.floor(logoWidth)} height={Math.floor(lh/lw*logoWidth)} /><img className="mobile" src={"/"+logoMobileFile} width={Math.floor(93/76*58*300/340)} height={Math.floor(58*300/340)} /></a>);
    } else {
      return (<div className="centeredLogoContainer"><a href="/"><img src={"/"+logoFile} width={Math.floor(logoWidth)} height={Math.floor(lh/lw*logoWidth)} /></a></div>);
    }

  }

  async function checkNotifications() {

    if(!EVM.connectedAccount) {
      return;
    }

    const response = await fetch(subs.apis[subs.apiId].url+'recentNotifications/'+EVM.connectedAccount.toString());
    var recentNotifications = await response.json();

    //console.log(recentNotifications);

    var newNotifications=[];
    for(var i=0;i<recentNotifications.length;i++) {

      var shown=false;
      for(var j=0;j<shownNotificationHashes.length;j++) {
        if(recentNotifications[i].hash == shownNotificationHashes[j][0] && shownNotificationHashes[j][1] < Date.now()/1000-10) {
          shown=true;
        }
      }

      if(!shown) {
        shownNotificationHashes.push([recentNotifications[i].hash, Date.now()/1000]);
        newNotifications.push(recentNotifications[i]);
      }
      

 
    }

    ReactSession.set("shownNotificationHashes", shownNotificationHashes);

    //console.log(newNotifications);

    setNotifications(newNotifications);

  }


  var pageWidth = 640;


  return (
    <div>
        <div className="topHeader">
        
          {renderLogo()}
          
          <div className="connectContainer">
            
            {renderLiveSite()}
          </div>
          
        </div>
        <div id="contains_top">
          <div id="left_menu_top" className="left_menu_detail"></div>
          <div id="center_menu_top" className="page_width"></div>
          <div id="right_menu_top" className="left_menu_detail"></div>
        </div>
        <div className="menu_cont"><div className="menu_cont_inner">
          <Menu pages={subs.pages} />
        </div></div>
        <div id="contains_bot">
          <div id="left_menu_bot" className="left_menu_detail"></div>
          <div id="center_menu_bot" className="page_width"></div>
          <div id="right_menu_bot" className="left_menu_detail"></div>
        </div>
        <div className="notificationsOverlay">
        {notifications.map((notification, index) => (
          <Notification 
            key={index} 
            title={notification.title}
            message={notification.message}
            imageUrl={notification.imageUrl}
            link={notification.link} 
            timestamp={notification.timestamp}
          />
        ))}
        </div>
        
    </div>
  );

}

export default Header;