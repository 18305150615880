import React from 'react';
import * as vars from './vars.js'; import * as subs from './subs.js';

export const scale = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function byteForLayerName(name, hash, subsite) {
  var numberOfStylesForLayer = subs.layerData[name];
  var lbv = layerByteVal(name, numberOfStylesForLayer, hash, subsite);
  return lbv;
}

export function outfitForLayerName(name, hash, subsite) {
  var numberOfStylesForLayer = subs.layerData[name];
  var lbv = layerByteVal(name, numberOfStylesForLayer, hash, subsite);
  //console.log('lbv: '+lbv);
  
  var numToUse = 16;
  if(subsite=='gothums' || subsite=='hothums') {
    numToUse = 32;
  }
  var remappedVals = getRemappedVals(numToUse);
  var outfit = Math.round(scale(remappedVals[lbv], 1, numToUse, 1, numberOfStylesForLayer))-1;

  return outfit;
}



export function indexOfLayerName(name) {

  var i=0;
  for(var layerName in subs.layerData) {
    if(layerName == name) {
      return i;
    }
    i++;
  }
  return -1;
}

export function convertRange( value, r1, r2 ) { 
    return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
}

export function getRemappedVals(numToUse) {
  if(!numToUse) {
    numToUse=16;
  }
  var remappedVals=[];
  for(var i=0;i<256;i++) {
    remappedVals[i]=Math.floor(convertRange(Math.pow(i, 2), [0, 255*255], [1, numToUse]));
    //remappedVals[i]=Math.floor(convertRange(i, [0, 255], [1, 16]));
  }
  //console.log(remappedVals);
  return remappedVals;
}

export function genderStrFromHashArr(arr) {
  if(genderFromHashArr(arr)) {
    return "male";
  } else {
    return "female";
  }
}

export function hairStrFromHashArr(arr) {
  if(hairColorFromHashArr(arr)==0) {
    return "y";
  } else if(hairColorFromHashArr(arr)==1) {
    return "bl";
  } else if(hairColorFromHashArr(arr)==2) {
    return "br";
  } else if(hairColorFromHashArr(arr)==3) {
    return "w";
  }
}

export function bowStrFromHashArr(arr) {
  if(bowColorFromHashArr(arr)==0) {
    return "_g";
  } else if(bowColorFromHashArr(arr)==1) {
    return "_b";
  } else if(bowColorFromHashArr(arr)==2) {
    return "_r";
  }
}

export function genderFromHashArr(arr) {

  var lastTwoChars = arr[arr.length-2].toString()+arr[arr.length-1].toString();

  //console.log('Last two chars?: '+lastTwoChars);

  var retVal = valueBetween0And255(arr[arr.length-2].toString()+arr[arr.length-1].toString());

  return Math.round(convertRange(retVal, [0, 255], [0, 1]));

}

export function capeColorFromHashArr(arr) {

  var lastTwoChars = arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString();

  //console.log('Last two chars?: '+lastTwoChars);

  var retVal = valueBetween0And255(arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString());

  


  var capeNum = Math.round(convertRange(retVal, [0, 255], [0, 3]));

  //console.log('hairNum: '+hairNum);

  return capeNum;

}

export function hairColorFromHashArr(arr) {

  var lastTwoChars = arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString();

  //console.log('Last two chars?: '+lastTwoChars);

  var retVal = valueBetween0And255(arr[arr.length-2-2].toString()+arr[arr.length-1-2].toString());

  


  var hairNum = Math.round(convertRange(retVal, [0, 255], [0, 3]));

  //console.log('hairNum: '+hairNum);

  return hairNum;

}

export function bowColorFromHashArr(arr) {

  var lastTwoChars = arr[arr.length-2-4].toString()+arr[arr.length-1-4].toString();

  //console.log('Last two chars?: '+lastTwoChars);

  var retVal = valueBetween0And255(arr[arr.length-2-4].toString()+arr[arr.length-1-4].toString());

  
  var bowNum = Math.round(convertRange(retVal, [0, 255], [0, 2]));

  //console.log('hairNum: '+hairNum);

  return bowNum;

}

export function arrayOfHashCharacters(hash) {
     var aVarsArr = [];
    // Get 60 characters from the hash, starting at pos 4 (leave room for nonce)
    var dataStartPos = 8; // Room for up to 4 bytes of difficulty
    var dataLength = 66 - dataStartPos;

    for(var i=dataStartPos;i<66;i++) {
      var aVar = hash.substr(i, 1);
      aVarsArr.push(aVar);
    }
    //From 58 chars get up to 29 layers of 255 values, remapped to up to 16 styles per layer

    return aVarsArr;
  }

export function rScoreGivenHash(hash, subsite) {

    var layers = subs.layerData;

    var arrOfHash = arrayOfHashCharacters(hash);

    var rScore = 0;
    for(var name in layers) {
      var styles = layers[name];
      var lbv = layerByteVal(name, styles, arrOfHash, subsite);

      var numToUse = 16;
      if(subsite=='gothums' || subsite=='hothums') {
        numToUse = 32;
      }
      var remappedVals = getRemappedVals(numToUse); //255 item array that returns 1 to 16
      rScore = rScore + remappedVals[lbv]; // Score is 1-16 times the layers

      //console.log('layer score: '+remappedVals[lbv]);

    }

    return rScore;
}

export function layerByteVal(name, outfits, arrOfHash, subsite) {
  var layer = indexOfLayerName(name);
  //console.log(arrOfHash[layer].toString()+arrOfHash[layer+1].toString());

  if(subsite=='gothums') {
    return valueBetween0And255(arrOfHash[layer*2].toString()+arrOfHash[layer*2+1].toString()); //Value out of 16, map this to outfits
  }
  return valueBetween0And255(arrOfHash[layer].toString()+arrOfHash[layer+1].toString()); //Value out of 16, map this to outfits
}




export function alphabetArray() {
  var a = 97;
  var charArray = [];
  for (var i = 0; i<26; i++)
      charArray[i] = String.fromCharCode(a + i);

  return charArray;
}


export function valueBetween0And255(aVar) {
  var firstLetter = aVar.substr(0, 1);
  var secondLetter = aVar.substr(1, 1);
  var retVal = -1;
  var anInt = 0;
  for(var j=0;j<16;j++) {
    var firstVal = valueBetween1And16(firstLetter)-1;
    for(var k=0;k<16;k++) {
      var secondVal = valueBetween1And16(secondLetter)-1;
      retVal = firstVal*16 + secondVal;
    }
  }

  if(retVal < 0) {
    console.log('Error: 0-255 is < 0');
  }
  if(retVal > 255) {
    console.log('Error: 0-255 is > 255');
  }

  return retVal;

}




export function valueBetween1And16(aVar) {
  const alphArr = alphabetArray();
  var retVal = -1;
  alphArr.forEach(function(letter, j){
    if(letter==aVar && retVal == -1) {
      retVal = parseInt(j)+10+1;
    }
  });

  if(retVal == -1) {
    retVal = parseInt(aVar) + 1;
  }
  //console.log(retVal);
  return retVal;

}