import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { modal, callMethod, getObjFromJSONUrl, sleep } from './../helpers.js'

import 'react-responsive-modal/styles.css';

import TextField from "@material-ui/core/TextField";

import RangeSlider from 'react-bootstrap-range-slider';

const BigNumber = require('bignumber.js');

var lastConnectedAccount=false;
var loaded = false;

// var liveStakeLength=0;
// var liveStakeAmount=0;
var livePotentialFoo=0;

var receivedFooApproval=false;

function Stake() {

	const [isFooApproved, setIsFooApproved] = useState(false);

	const [minimumDays, setMinimumDays] = useState(7);

	const [sfooBalance, setSfooBalance] = useState(0);

	const [endDays, setEndDays] = useState(0);

	const [ stakeAmount, setStakeAmount ] = useState(0); 
	const [ stakeLength, setStakeLength ] = useState(7);
	const [ potentialFoo, setPotentialFoo ] = useState(0);
	const [ potentialApr, setPotentialApr ] = useState(0);
	const [ claimableFoo, setClaimableFoo ] = useState(0);
	const [ futureClaimableFoo, setFutureClaimableFoo ] = useState(0);

	const [ openStake, setOpenStake ] = useState(false);

	const [staking, setStaking] = useState(false);
	const [claiming, setClaiming] = useState(false);
	const [isApproving, setIsApproving] = useState(false);

	const [fooBalance, setFooBalance] = useState(0);

	useEffect(() => {

		setInterval(function() {

			if(EVM.connectedAccount && EVM.connectedAccount != lastConnectedAccount) {
				loaded=false;
				load();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(!loaded && EVM.connectedAccount) {
				load();
			}

		}, 2000);

	}, []);



	async function checkFooApproval() {

	  if(!EVM.connectedAccount) {
	    return;
	  }

	  if(!EVM.foo_contract) {
	    return;
	  }

	  let allowance = await EVM.foo_contract.methods.allowance(EVM.connectedAccount.toString(), EVM.SFOO_ADD[0]).call();
	  console.log(EVM.SFOO_ADD[0]+' allowance: '+allowance);
	  

	  console.log(BigNumber(allowance).div(BigNumber(Math.pow(10,18)))+' >= '+stakeAmount);
	  if(parseInt(BigNumber(allowance).div(BigNumber(Math.pow(10,18))).toString()) >= stakeAmount) {
	    //console.log('RIP IS APPROVED FOR A RES');
	    receivedFooApproval=true;
	    setIsFooApproved(true);
	  } else {
	  	receivedFooApproval=false;
	    setIsFooApproved(false);
	  }

	}

	async function approveFoo() {
	  if(!EVM.foo_contract) {
	    return;
	  }

	  //var dtc = await deathTokenCheck(); if(!dtc) { return; }

	  //console.log('resPrice: '+resPrice);

	  if(stakeAmount==0) {
	  	return;
	  }

	  setIsApproving(true);

	  await callMethod(EVM.foo_contract.methods.approve(EVM.SFOO_ADD[0], EVM.web3.utils.toWei(stakeAmount.toString(), 'ether')), 0, EVM.connectedAccount,  1000000, async function(err2, res2) {
	    if(err2) {
	    	setIsApproving(false);
	      console.log(err2.message);
	    } else {

	    	do {
	    		console.log('Waiting for FOO to approve...');
	    		await checkFooApproval();
	    		await sleep(1000);
	    	} while(!receivedFooApproval && !isFooApproved);
	     	console.log('Received Approval!');
	      setIsApproving(false);
	      await checkFooApproval();

	      loaded=false;
				load();
	    }
	  });
	}

	async function stakeFoo() {

		if(!EVM.sfoo_contract) {
	    return;
	  }


	  setStaking(true);


	  var cBlock = await EVM.web3.eth.getBlockNumber();
    var currBlock = await EVM.web3.eth.getBlock(cBlock);
    var currentTimestamp = currBlock.timestamp;

	  var untilDate = parseInt(currentTimestamp+stakeLength*60*60*24).toString();
	  console.log('curreDate: '+currentTimestamp);
		console.log('untilDate: '+untilDate);
		//return;

		var stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(EVM.connectedAccount.toString()).call();

		var lastLockedDate = stakerPosition.lastLockedDate;



	  await callMethod(EVM.sfoo_contract.methods.lock(EVM.web3.utils.toWei(stakeAmount.toString(), 'ether'), untilDate.toString()), 0, EVM.connectedAccount, 1000000, async function(err, res) {
	    if(err) {
        console.log(err.message);
        setStaking(false);
      } else {
      	var waitTimes=0;
      	do {
      		console.log('Waiting for staking transaction...');
      		stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(EVM.connectedAccount.toString()).call();
      		await sleep(1000);
      		waitTimes++;
      	} while(lastLockedDate == stakerPosition.lastLockedDate && waitTimes < 60);
      	setStaking(false);
      	setOpenStake(false);
      	setStakeAmount(0);
      	console.log('Found!');
      	loaded=false;
        load();
      }
	  });
	}

	async function claim() {

		if(!EVM.sfoo_contract) {
	    return;
	  }


	  setClaiming(true);

	 // var stakerPosition;

	  console.log('claimableFoo: '+claimableFoo+' - '+EVM.web3.utils.toWei(claimableFoo.toString(), 'ether'));

	  //var claimAmount = BigNumber(EVM.web3.utils.toWei(claimableFoo.toString(), 'ether')).
	  var stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(EVM.connectedAccount.toString()).call();

	  console.log(stakerPosition);

	  //console.log('stakerPosition.amountToRelease: '+BigNumber(stakerPosition.amountToRelease).minus(2));

	  await callMethod(EVM.sfoo_contract.methods.claim(stakerPosition.amountToRelease), 0, EVM.connectedAccount, 500000, async function(err, res) {
	    if(err) {
	          console.log(err.message);
	          setClaiming(false);
	        } else {
	        	do {
	        		console.log('Waiting for claim transaction...');
	        		stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(EVM.connectedAccount.toString()).call();
	        		await sleep(1000);

	        	} while(stakerPosition.lastLockedDate > 0);
	        	setClaiming(false);
	        	console.log(stakerPosition);
	        	console.log('Claimed!');
	        	loaded=false;
	          load();
	        }
	  });
	}

	async function load() {


		if(!EVM.connectedAccount || !EVM.flf2_contract) {
			return;
		}

		if(loaded) {
			return;
		}


		try {

			await checkFooApproval();
			await updatePotentialFoo();

			var fooBal = await EVM.foo_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
			setFooBalance(fooBal/Math.pow(10,18));

			//var pending = await EVM.flf2_contract.methods.pending(0, EVM.connectedAccount.toString()).call();

			loaded=true;

		} catch(error) {
			console.log(error);
		}




	}

	function tooltipFooAmount(val) {
		return val+' FOO';
	}

	function tooltipLabel1(val) {

		if(val < 7) {
			var days = val;
			var dayStr = Math.floor(days*100)/100+' Days';
			if(days <= 1) {
				dayStr = Math.floor(days*100)/100+' Day';
			}
			return dayStr;
		} else if(val < 31) {
			var weeks = Math.floor(val/7);
			var days = val - (weeks*7);

			var weekStr = Math.floor(weeks*100)/100+' Weeks';
			if(weeks <= 1) {
				weekStr = Math.floor(weeks*100)/100+' Week';
			}
			if(!days) {
				return weekStr;
			} else {
				return weekStr+', '+days+' Days';
			}
			
		} else if(val < 365) {

			var months = Math.floor(val/31);
			var days = val - (months*31);

			var monthStr = Math.floor(months*100)/100+' Months';
			if(months <= 1) {
				monthStr = Math.floor(months*100)/100+' Month';
			}
			if(!days) {
				return monthStr;
			} else {
				return monthStr+', '+days+' Days';
			}

		} else {
			var years = Math.floor(val/365);
			var days = val - (years  * 365);
			var yearStr = Math.floor(years*100)/100+' Years';
			if(years <= 1) {
				yearStr = Math.floor(years*100)/100+' Year';
			}
			if(!days) {
				return yearStr;
			} else {
				return yearStr+', '+days+' Days';
			}
		}


	}


	const [avgLockTime, setAvgLockTime] = useState(0);

	async function averageLockTime() {
		if(!EVM.connectedAccount) {
	    return;
	  }

	  if(!EVM.foo_contract) {
	    return;
	  }


	  var cBlock = await EVM.web3.eth.getBlockNumber();
    var currBlock = await EVM.web3.eth.getBlock(cBlock);
    var currentTimestamp = currBlock.timestamp;


	  var totalLockTime = 0;
	  var totalLockers = 0;


	  var totalLockSecondsPerTokenTimesTokens = 0;

	  var totalTokens = 0.0;
	  // var totalSeconds = 0;
	  // var totalSecondsPerTokens = 0;
	  //var tpl = await EVM.sfoo_contract.methods.totalPrincipleLocked().call();

	  var stakers = await EVM.sfoo_contract.methods.getStakers().call();
	  for(var i=0;i<stakers.length;i++) {
	  	//console.log(stakers[i]);

	  	var stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(stakers[i].toString()).call();
	  	if(stakerPosition.releaseDate > 0) {
	  		totalLockTime = totalLockTime + (stakerPosition.releaseDate - currentTimestamp);
	  		totalLockers++;


	  		var tokensLocked = BigNumber(stakerPosition.principle).div(BigNumber(Math.pow(10,18))).toFixed(2);
	  		totalTokens = totalTokens + parseInt(tokensLocked);

	  		//console.log('tokensLocked: '+tokensLocked);

	  		var lockSecondsPerToken = (stakerPosition.releaseDate - currentTimestamp);

	  		totalLockSecondsPerTokenTimesTokens += parseInt(lockSecondsPerToken) * tokensLocked;


	  		
	  		// var secondsPerToken = (stakerPosition.releaseDate - currentTimestamp) * tokensLocked;
	  		// totalSecondsPerTokens = totalSecondsPerTokens + secondsPerToken;



	  		// totalSeconds = totalSeconds + (stakerPosition.releaseDate - currentTimestamp);
	  	}
	  	
	  }

	  console.log('totalStakers: '+totalLockers);


	  console.log('totalLockSecondsPerTokenTimesTokens: '+totalLockSecondsPerTokenTimesTokens);



	  console.log('totalTokens: '+totalTokens);

	  var averageLockTime = (totalLockSecondsPerTokenTimesTokens / totalTokens) / (60*60*24*365);

	  // console.log('totalSeconds: '+totalSeconds);

	  // console.log('totalSecondsPerTokens: '+totalSecondsPerTokens);

	  // var avgSecondsPerToken = totalSecondsPerTokens / totalSeconds;

	  // console.log('avgSecondsPerToken: '+avgSecondsPerToken);

	  //var averageLockTime = (totalTimePerToken / totalLockers)/(60*60*24*365);

	  // var ttpt = (totalTimePerToken * totalTokens) / (60*60*24*365);
	  setAvgLockTime(averageLockTime);

	  



	}


	async function updatePotentialFoo() {
		if(!EVM.connectedAccount || !EVM.sfoo_contract) {
			return;
		}
		averageLockTime();
		//console.log('updatePotentialFoo...');
		//fooForAmountAndAddressAtDate(uint256 _amount, address _staker, uint256 _untilDate)
		console.log('stakeAmount: '+stakeAmount);
		console.log('stakeLength: '+stakeLength);
		var cBlock = await EVM.web3.eth.getBlockNumber();
    var currBlock = await EVM.web3.eth.getBlock(cBlock);
    var currentTimestamp = currBlock.timestamp;


    var sfooBal = await EVM.sfoo_contract.methods.balanceOf(EVM.connectedAccount.toString()).call();
    setSfooBalance(sfooBal/Math.pow(10,18));

    var anEndDate = await EVM.sfoo_contract.methods.endDate().call();
    var endDays = Math.floor((anEndDate-currentTimestamp)/(60*60*24));
    setEndDays(endDays);

    //console.log('currentTimestamp: '+currentTimestamp);

		var untilDate = parseInt(currentTimestamp+stakeLength*60*60*24).toString();
		

		var stakerPosition = await EVM.sfoo_contract.methods.stakerPositions(EVM.connectedAccount.toString()).call();

		var mustBeGreaterThanDate = currentTimestamp + 60*60*24*7;
		if(stakerPosition.releaseDate > mustBeGreaterThanDate) {
			mustBeGreaterThanDate = stakerPosition.releaseDate;
		}

		

		if(untilDate <= mustBeGreaterThanDate) {
			// How many days from now is mustBeDate
			var minDays = Math.ceil((mustBeGreaterThanDate - currentTimestamp)/(60*60*24));
			console.log('setting minDays: '+minDays);
			setMinimumDays(minDays);
			setStakeLength(minDays);

			untilDate = parseInt(mustBeGreaterThanDate) + 60*60*24;
		}
		console.log('untilDate: '+untilDate);

		var potFoo = await EVM.sfoo_contract.methods.fooForAmountAndAddressAtDate(EVM.web3.utils.toWei(stakeAmount.toString(), 'ether'), EVM.connectedAccount.toString(), untilDate).call();

		livePotentialFoo = potFoo;

		//console.log('livePotentialFoo: '+livePotentialFoo);


		var yearsStaked = stakeLength / 365;

		//console.log('yearsStaked: '+yearsStaked);

		

		//console.log('(stakeAmount+stakerPosition.amountToRelease/Math.pow(10,18)): '+(parseInt(stakeAmount)+stakerPosition.amountToRelease/Math.pow(10,18)));

		var potApr = ((((((livePotentialFoo)/(parseInt(stakeAmount)+stakerPosition.amountToRelease/Math.pow(10,18)))/Math.pow(10,18)))*100) - 100) /yearsStaked;

		if(isNaN(Math.round(potApr))) {
			setPotentialApr('??? APY');
		} else {
			setPotentialApr(Math.round(potApr)+'% APY');
		}
		

		setPotentialFoo(Math.round(potFoo/Math.pow(10,18)*100)/100);


		

		//console.log(stakerPosition);

		if(stakerPosition.releaseDate <= currentTimestamp) {
			setClaimableFoo(BigNumber(stakerPosition.amountToRelease).div(BigNumber(Math.pow(10,18))));
		} else {
			setClaimableFoo(0);
			
		}
		setFutureClaimableFoo(stakerPosition.amountToRelease/Math.pow(10,18));
	}
	


if(EVM.connectedAccount) {



	return ( 
	<>
		{modal(openStake, () => setOpenStake(false), 'ARE YOU SURE?', function() {
	    return (<><br/>
	      <span className="modal_text">Are you sure you would like to stake <b>{stakeAmount} FOO</b>?</span><br/>
	      <span className="modal_text"><b><i>These funds will not be available for {tooltipLabel1(stakeLength).toUpperCase()}!</i></b></span><br/>
	      <br/>
	      <span className="modal_text">You will receive <b>{Math.round((potentialFoo-sfooBalance)*100)/100} sFOO</b> today to mark your position.</span><br/><br/>
	      <span className="modal_text"><i>*You can add more FOO to your staked position but you cannot unlock your tokens sooner!*</i></span><br/><br/>
	      <br/>
	      <div className={isFooApproved ? 'hidden' : ''}>
	      	<Button type="Submit" size="lg" variant="primary" onClick={()=>{ approveFoo(); }}>{!isApproving ? 'Approve FOO' : 'Approving...'}</Button>
	      </div>
				<div className={isFooApproved ? '' : 'hidden'}>
	      	<Button type="Submit" size="lg" variant="primary" onClick={()=>{ stakeFoo(); }}>{!staking ? 'Stake FOO' : 'Staking...'}</Button>
	      </div>      
	      </>);
	  })}

	<div className="appOperaStake">
	    <div id="operaHolderStake">
	    	<div className="stakeSliderHolder"><br/><br/><br/>
		      <h2><b>Stake FOO</b></h2><br/>
					<h5>Receive additional $FOO by staking it for up to 4 years.</h5><br/>
					

					{/*<h5>Stakers <i>(and LPs)</i> receive special perks, more about these soon.</h5><br/>*/}
				</div>
				<br/>
				<br/>

	      <div className="stakeSliderHolder">
	      	<h5>How much of your FOO would you like to stake?</h5>
		      <RangeSlider
			      value={stakeAmount}
			      onChange={(changeEvent) => { setStakeAmount(changeEvent.target.value); } }
			      onAfterChange={updatePotentialFoo}
			      variant='danger'
			      size='lg'
			      min='0'
			      tooltipLabel={tooltipFooAmount}
			      max={fooBalance}
			    />
	      </div>

	      <div className="stakeSliderHolder">
	      	<h5>How long would you like to stake your FOO for?</h5>
		      <RangeSlider
			      value={stakeLength}
			      onChange={(changeEvent) => { setStakeLength(changeEvent.target.value); } }
			      onAfterChange={updatePotentialFoo}
			      variant='danger'
			      size='lg'
			      min={minimumDays}
			      step='1'
			      tooltipLabel={tooltipLabel1}
			      max={endDays}
			    />
	      </div>

	      <div className="stakeSliderHolder">
		      <div className="stakeReceiveLeft">
			      
			      <div className={""+(futureClaimableFoo > 0 ? 'inline-block' : 'hidden')}>
			     		<p>EXTEND LOCK</p>
					    <div className="bigStakeNumbersWhite">{Math.round(futureClaimableFoo*100)/100} FOO + {Math.round(stakeAmount*100)/100} FOO</div>
					  	<p>{tooltipLabel1(stakeLength).toUpperCase()}</p>
					  </div>
					  <div className={""+(futureClaimableFoo == 0 ? 'inline-block' : 'hidden')}>
					  	<p>LOCK</p>
					    <div className="bigStakeNumbersWhite">{Math.round(stakeAmount*100)/100} FOO</div>
					    <p>{tooltipLabel1(stakeLength).toUpperCase()}</p>
					  </div>
						
				    
			    </div>
			    <div className="stakeReceiveRight">
			      <p>RECEIVE</p>
						<div className="bigStakeNumbersWhite">{potentialFoo} FOO</div>
				    <p>({potentialApr})</p>
			    </div>
		    </div>
	      
		    <br/><br/><br/>

		     <div className="stakeSliderHolder">
		      <div className="stakeReceiveLeft">
			      <Button type="Submit" size="lg" variant="primary" onClick={()=>{setOpenStake(true); checkFooApproval();}}><b>STAKE</b><br/>{Math.round(stakeAmount*100)/100} FOO</Button>
	      		<br/>
			    </div>
			    <div className={"stakeReceiveRightNoBlock "+(claimableFoo > 0 ? 'inline-block' : 'hidden')}>
				    <div className={""+(!claiming ? 'inline-block' : 'hidden')}>
				      <Button type="Submit" size="lg" variant="primary" onClick={()=>{claim();}}><b>CLAIM</b> {Math.floor(claimableFoo*100)/100} FOO</Button>
			    	</div>
			    	<div className={""+(claiming ? 'inline-block' : 'hidden')}>
				      <Button type="Submit" size="lg" variant="primary" onClick={()=>{claim();}}>CLAIMING...</Button>
			    	</div>
			    </div>

			    <div className={"stakeReceiveRightNoBlock "+((futureClaimableFoo > 0 && claimableFoo==0) ? 'inline-block' : 'hidden')}>
				    
				      <Button type="Submit" size="lg" variant="outline-dark" disabled>{Math.round(futureClaimableFoo*100)/100} FOO<br/>LOCKED</Button>
			      
			    </div>

		    </div>


	      <br/>

	      
	    </div>


	    <br/><br/><p>sFOO / Staking Contract: <span className="stakePageLink"><a href={"https://ftmscan.com/address/"+EVM.SFOO_ADD[0]}>{EVM.SFOO_ADD[0].substr(0, 12)+'...'+EVM.SFOO_ADD[0].substr(30, 12)}</a></span></p><br/>
	    <p><i>*Special perks for Stakers and LPs coming soon...*</i></p><br/>
	    <p>Average Lock Time: {Math.round(avgLockTime*100)/100} years</p><br/><br/>
	</div>
	</>
	);

} else if (window.ethereum) {
	return (<>

		<div className="appOperaStake">
	    <div id="operaHolderStake">
	    	<div className="stakeSliderHolder"><br/><br/><br/>
	    	<div id="rotatingSkelly" className='rotate'></div><br/><p><b>Connecting to MetaMask...</b></p>

	    	</div>
	    	</div>	
	    	</div>


	    	</>);
} else {
	return (<>
		<div className="appOperaStake">
	    <div id="operaHolderStake">
	    	<div className="stakeSliderHolder"><br/><br/><br/>
	    	<p><b>Please connect MetaMask and select the Fantom Opera network to stake!</b></p>

	    	</div>
	    	</div>	
	    	</div>	
	</>);
} 

}

export default Stake;