import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { rollName, rollNum, rScore, percentileNum, oddsOfLayerAndOutfit, descForFilename } from './../helpers.js'


function Attribute(props) {


	var priceFloor;

	if(props.mcount && props.mcount[props.cname+'_'+props.outfit+'_pf']!==undefined) {
		priceFloor = props.mcount[props.cname+'_'+props.outfit+'_pf'];
	}
	
	var wcount;
	if(props.wcount && props.wcount[props.cname+'_'+props.outfit]!==undefined) {
		wcount = props.wcount[props.cname+'_'+props.outfit];
	}
	
	var mcount;
	if(props.mcount && props.mcount[props.cname+'_'+props.outfit]!==undefined) {
		mcount = props.mcount[props.cname+'_'+props.outfit];
	}
	

	var renderOutfit = props.outfit;

	//console.log('props.wcount['+props.cname+"_"+renderOutfit+']: '+props.wcount);
	//console.log('mcount: '+mcount);

	var fileName = props.cname+"_"+renderOutfit;

	var searchName = fileName;

	if(fileName==props.cname+'_0' && props.cname!='eyes') {
		fileName='0';
	}

	var outfit;

	var posOfOutfit = fileName.indexOf("_");
	var posOfNext = fileName.indexOf("_", posOfOutfit+1);
	if(posOfNext==-1) {
		posOfNext=fileName.length;
	}

	//console.log('posOfOutfit: '+posOfOutfit);
	//console.log('posOfNext: '+posOfNext);

	outfit = fileName.substr(posOfOutfit+1, posOfNext-(posOfOutfit+1));


	var gender = fileName.substr(posOfNext+1, 1);

	var posOfHairStr = fileName.indexOf("_", posOfNext+1);
	if(posOfHairStr==-1) {
		posOfHairStr=fileName.length;
	}
	var posOfBowStr = fileName.indexOf("_", posOfHairStr+1);
	if(posOfBowStr==-1) {
		posOfBowStr=fileName.length;
	}


	var hairStr = fileName.substr(posOfHairStr+1, posOfBowStr-(posOfHairStr+1));

	var bowStr = fileName.substr(posOfBowStr+1, 1);


	//var odds = Math.round(oddsOfLayerAndOutfit(gender, props.cname, outfit, hairStr, bowStr)*10000)/100+'%';

	var odds = Math.round(props.count / props.ttf * 100 * 100)/100+'%';

	console.log('props.ttf: '+props.ttf);


	var mySVG = "<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 230'><defs><style>.cls-1{fill:"+('#ffb400')+";}</style></defs><title>base</title><polygon class='cls-1' points='160 60 160 50 160 40 150 40 150 30 140 30 140 20 130 20 130 10 120 10 110 10 110 0 100 0 90 0 80 0 70 0 60 0 60 10 50 10 40 10 40 20 30 20 30 30 20 30 20 40 10 40 10 50 10 60 0 60 0 70 0 200 0 210 10 210 10 220 20 220 20 230 30 230 30 220 40 220 40 210 50 210 50 200 60 200 60 210 70 210 70 220 80 220 80 230 90 230 90 220 100 220 100 210 110 210 110 200 120 200 120 210 130 210 130 220 140 220 140 230 150 230 150 220 160 220 160 210 170 210 170 200 170 70 170 60 160 60'/></svg>";
	var mySVG64 = window.btoa(mySVG);


	var width320 = 170;
	var height320 = 230;

	var posX320 = 80;
	var posY320 = 70;

	var filter = 'none';

	var size = 130;

	var outfitStyleSVG ={'backgroundRepeat': "no-repeat", 'backgroundPosition': (size/320*posY320)+"px "+(size/320*posX320)+"px", 'backgroundImage': "url('data:image/svg+xml;base64," + mySVG64 + "')", filter: filter, 'backgroundSize': (size/320*width320)+'px '+(size/320*height320)+'px', width: size+'px', height: size+'px'};

	// if(fileName==0) {
	// 	return (<></>);
	// }
	//var odds=oddsForImageString(props.cname, fileName);



	function myCount() {
		//console.log(props.wcount);
		if(wcount !== undefined && EVM.connectedAccount) {
			return (<><a className={"myCountA"+(wcount>0 ? " countGreen" : " countRed")} href={/searchWallet/+searchName}>{wcount} in wallet</a><br/></>);
		} else {
			return (<></>);
		}
		
	}

	function inPop() {
		if(props.count !== undefined) {
			return (<><a className="myCountA" href={/search/+searchName}>~{odds} in pop.({props.count})</a><br/></>);
		} else {
			return (<></>);
		}
		
	}

	function onMarket() {
		if(mcount !== undefined) {
			return (<><a className="myCountA" href={/searchMarket/+searchName}>{mcount} on market</a><br/></>);
		} else {
			return (<></>);
		}
		
	}

	function viewFilename() {
		window.open('/search/'+searchName, '_self');
	}

	return (

		<div className="attribute">
			<div style={{width: '100%'}}>
				<b>{descForFilename(fileName)}</b>
			</div>
			<img style={outfitStyleSVG} className={"fantom_dims attribute_img_"+gender} width={size} height={size} src={subs.nameOfFantums.toLowerCase()+"/"+fileName+".png"} />
			<br/>{inPop()}
			{myCount()}{onMarket()}
			Floor: <span className={(priceFloor > 0 && priceFloor != '999999999999' ? '' : 'hidden')}>{priceFloor} FTM</span><span className={(priceFloor > 0 && priceFloor != '999999999999' ? 'hidden' : '')}>N/A</span>
		</div> 

	);

}

export default Attribute;