import React, {useState, useEffect} from 'react';
import Attribute from './Attribute';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash, indexOfLayerName } from './../generate.js'
import { rollName, rollNum, rScore, percentileNum, oddsOfLayerAndOutfit, getObjFromJSONUrl } from './../helpers.js'


var dudesPerLayer=0;
var chicksPerLayer=0;
var receivedWallet=0;
var lastConnectedAccount='';

var doKeyOnce=[];

var loadingWallet = false;


var resKey;

var resKeyM;

var cfkForAccounts = [];
var cfkForAccountsM = [];
//var countForKey=[];
function AttributeBlock(props) {


	dudesPerLayer=0;
	chicksPerLayer=0;

	console.log('propsttf: '+props.ttf);

	//var token = props.token;

	// const [dudesPerLayer, setDudesPerLayer] = useState(0);
	// const [chicksPerLayer, setChicksPerLayer] = useState(0);

	// console.log(props.index);
	// console.log(props.cmfk);


	const [outfitsInts, setOutfitsInts] = useState(props.obj);
	const [render, setRender] = useState(0);

	const [countForKey, setCountForKey] = useState(props.cfk);



	const [countMarketForKey, setCountMarketForKey] = useState(props.cmfk);
	//console.log('props:');
	//console.log(props.obj);

	

	async function loadedWallet() {


		if(loadingWallet) {
			return;
		}

		loadingWallet = true;


		// if(outfitsInts.length < 14) {
		// // 	return;
		// // }

		// //console.log('Loaded wallet: '+EVM.connectedAccount);

		// var fkString='';


		// for(var key in outfitsInts) {

		// 	fkString=fkString+props.cname+'_'+key+',';

			

		// }

		

		// if(!cfkForAccounts[EVM.connectedAccount+fkString]) {
		// 	resKey = await getObjFromJSONUrl(
		// 		subs.apis[subs.apiId].url+'attributeCount/'+EVM.connectedAccount+'&'+fkString
		// 	);
		// 	setCountForKey(resKey);
		// 	cfkForAccounts[EVM.connectedAccount]=true;
		// }
		

		// if(!cfkForAccountsM[fkString]) {
		// 	resKeyM = await getObjFromJSONUrl(
		// 		subs.apis[subs.apiId].url+'attributeCount/market&'+fkString
		// 	);
		// 	setCountMarketForKey(resKeyM);
		// 	cfkForAccountsM[fkString]=true;
		// }
		



		setRender(render+1);
		
		loadingWallet=false;

		receivedWallet=true;
	}

	useEffect(() => {

		//loadedWallet();

		setInterval(function() {

			if(EVM.connectedAccount != lastConnectedAccount) {
				receivedWallet=0;
				loadingWallet=false;
				loadedWallet();
				lastConnectedAccount = EVM.connectedAccount;
			}
			if(receivedWallet==0) {
				loadingWallet=false;
				loadedWallet();
			}

		}, 200);

	}, []);


	function getOdds(cname, key) {
		var renderOutfit = key;
		var fileName = cname+"_"+renderOutfit;

		if(fileName==cname+'_0') {
			fileName='0';
		}

		var outfit;

		var posOfOutfit = fileName.indexOf("_");
		var posOfNext = fileName.indexOf("_", posOfOutfit+1);
		if(posOfNext==-1) {
			posOfNext=fileName.length;
		}

		outfit = fileName.substr(posOfOutfit+1, posOfNext-(posOfOutfit+1));

		var gender = fileName.substr(posOfNext+1, 1);

		var posOfHairStr = fileName.indexOf("_", posOfNext+1);
		if(posOfHairStr==-1) {
			posOfHairStr=fileName.length;
		}
		var posOfBowStr = fileName.indexOf("_", posOfHairStr+1);
		if(posOfBowStr==-1) {
			posOfBowStr=fileName.length;
		}


		var hairStr = fileName.substr(posOfHairStr+1, posOfBowStr-(posOfHairStr+1));

		var bowStr = fileName.substr(posOfBowStr+1, 1);


		if(gender!='') {
			if(gender==0) {
				chicksPerLayer++;
			} else {
				dudesPerLayer++;
			}
		} else {
			dudesPerLayer++;
			chicksPerLayer++;
		}

		var odds = Math.round(oddsOfLayerAndOutfit(gender, cname, outfit, hairStr, bowStr)*10000)/100;

		return odds;
	}


	function odds() {

		var overall=0;

		for(var key in outfitsInts) {
			overall=overall+getOdds(props.cname, key);
		}
		return Math.round(overall);
	}


	if(!props.obj || props.cname=='lh' || props.cname=='base') {
		return (<></>);
	}


	
	return (

		<div className="attributeBlock" >
			<h4>{props.name}</h4>


			{Object.keys(outfitsInts).map((key, index) => (
			    <Attribute ttf={props.ttf} key={index} cname={props.cname} 
			    outfit={key} 
			    count={outfitsInts[key]} 
			    wcount={countForKey} 
			    mcount={countMarketForKey} />
			))}


			


			<div className="clear"></div>
{/*			<h6>{odds()}</h6>
			<h6>{dudesPerLayer} - {chicksPerLayer}</h6>*/}
	    	<br/>
	    </div>

	);

}

export default AttributeBlock;