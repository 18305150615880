import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { rScoreGivenHash } from './../generate.js'
import { addysEqual, rollName, rollNum, rScore, percentileNum, getObjFromJSONUrl } from './../helpers.js'


function Match(props) {

	var match = props.match;

	var size = 80;

	var lf = props.lf;
	var rf = props.rf;

	var of;
	var tf;

	var wf;
	if(match.result==0) {
		wf = rf;
	} else if(match.result==1) {
		wf = lf;
	} else if(match.result==2) {
		wf = false;
	} else if(match.result==3) {
		wf = rf;
	} else if(match.result==4) {
		wf = lf;
	}

	if(addysEqual(EVM.connectedAccount, lf.owner)) {
		of = lf;
		tf = rf;
	} else {
		of = rf;
		tf = lf;
	}


	function ofText() {
		return (<><a href={"/token/"+of.id}><b>{of.name}</b></a><br/></>);
		
	}
	function tfText() {
		return (<><a href={"/token/"+tf.id}><b>{tf.name}</b></a><br/></>);
		
	}


	function resultString() {

		var ourResult = match.result;
		if(!addysEqual(EVM.connectedAccount, lf.owner)) {
			if(match.result==0) {
				ourResult=1;
			} else if(match.result==1) {
				ourResult=0;
			} else if(match.result==3) {
				ourResult=4;
			} else if(match.result==4) {
				ourResult=3;
			}
		}


		if(ourResult==0) {
			return (<><span className="countRed">{ofText()}</span> lost to <br/><span className="countGreen">{tfText()}</span></>);
		} else if(ourResult==1) {
			return (<><span className="countGreen">{ofText()}</span> beat <br/><span className="countRed">{tfText()}</span></>);
		} else if(ourResult==2) {
			return (<><span className="countWhite">{ofText()}</span> tied with <br/><span className="countWhite">{tfText()}</span></>);
		} else if(ourResult==3) {
			return (<><span className="countRed">{ofText()}</span> died and lost to <br/><span className="countGreen">{tfText()}</span></>);
		} else if(ourResult==4) {
			return (<><span className="countGreen">{ofText()}</span> beat and killed <br/><span className="countRed">{tfText()}</span></>);
		} 

	

	}
	

	
	return (
		<>
		

		<div className="match_container">
			<div className="match_left_fum">

			<div className={"x_on_the_loser_match "+((of.id==wf.id || wf==false) ? 'hidden' : '')}><img src="/img/red_x.png" /></div>
			<a href={"/token/"+of.id}><img width={size-20} height={size-20} src={of.image} /></a>

			</div>
			<div className="match_vs_fum ">
			<span className="match_name">
			<a href={"/duel/"+match.id}>Match #{match.id}</a><br/>
			</span>
			{resultString()}
			</div>
			<div className="match_right_fum">
				<div className={"x_on_the_loser_match "+((tf.id==wf.id || wf==false) ? 'hidden' : '')}><img src="/img/red_x.png" /></div>
				<a href={"/token/"+tf.id}><img width={size-20} height={size-20} src={tf.image} /></a>
			</div>
		</div>
		</>

	);

}

export default Match;