import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';



function FAQ() {

	
return ( 
<>
       <div className="white_page">
       <div className="doc_container">
				<h3><b>Frequently Asked Questions</b></h3>
				<div className="doc_align_left">
				<p><b>Q: WHAT ARE FANTUMS?</b></p>
				<p>A: Fantums are Opera themed digital collectibles on the Fantom blockchain. These are also referred to as "NFTs" or "Non-Fungible Tokens".</p>
				<br/>
				<p><b>Q: CAN FANTUMS BE TRADED OR SOLD?</b></p>
				<p>A: Yes, Fantums are tradeable tokens that can be exchanged and sent directly from wallet to wallet, and also through NFT marketplace platforms <a href="/market">including our own</a>.</p>
				<br/>

				<p><b>Q: WHAT IS THE $FOO TOKEN?</b></p>
				<p>A: The Fantums of Opera token ($FOO) is an ecosystem fund with governance features, backed by the revenue generated by our projects. The token hopes to achieve fair distribution through an IDO, followed by an airdrop and ongoing staking rewards.</p>
				<br/>
				<p><b>Q: WHEN WILL STAKING $FOO BE AVAILABLE?</b></p>
				<p>A: LP incentives are now in place, (you can stake SushiSwap LP tokens to earn $FOO). - More coming...</p>
				<br/>
				<p><b>Q: WHERE IS THE DISCORD SERVER?</b></p>
				<p>A: Join our Discord here: <a href="https://fantums.com/discord">fantums.com/discord</a>, the #resources channel is filled with useful information!</p>
				<br/>
				<p><b>Q: WHERE CAN I LEARN MORE ABOUT FANTUM ATTRIBUTES?</b></p>
				<p>A: You should check out the <a href="/guide">Collector's Guide</a>!</p>
				<br/>

				<p><b>Q: WHAT IS THE ADDRESS OF THE $FOO TOKEN?</b></p>
				<p>A: <a href="https://ftmscan.com/token/0xfbc3c04845162f067a0b6f8934383e63899c3524">0xfbc3....3524</a></p>
				<br/>
				<p><b>Q: WHAT IS THE ADDRESS OF THE FANTUMS ERC-721 TOKEN ($FUM)?</b></p>
				<p>A: <a href="https://ftmscan.com/token/0x0C600D41b9C7656e60C0bf76D79B1532b4770D0C">0x0C60....0D0C</a></p>
				<br/>
				<p><b>Q: HOW WAS $FOO DISTRIBUTED?</b></p><br/>
				<p>A: We’d like to distribute $FOO as widely as possible. We’ve chosen to do this via DEX liquidity and an airdrop, followed by ongoing staking rewards.</p><br/>
				<p>At this time 2% of FOO was offered in an IDO, 5% was distributed via airdrops, and 90% is locked/vested over 4 years.</p>
				<br/>
				<p><b>Q: WHEN WAS THE IDO, THE AIRDROP?</b></p>
				<br/>
				<p>A: <b>2 million $FOO were supplied to Sushiswap on May 2nd, 2021</b> on Fantom as initial trading liquidity. </p>
				<br/>
				<p>On <b>Sunday, May 16, 2021 at 4:00PM UTC</b> a snapshot of the blockchain was taken, and two airdrops totalling 5 million $FOO were issued.</p>
				
				<br/><br/><br/><br/>
				</div>
			</div>
		</div>

        </>


);

}

export default FAQ;