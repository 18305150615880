import React, {useState, useEffect} from 'react';
import Grid from './Grid';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import {
  useParams
} from "react-router-dom";
import * as EVM from './../evm.js';
import Button from 'react-bootstrap/Button';
import { numberWithCommas, modal, callMethod, sleep, tryCallUntilSuccessful } from './../helpers.js';
import TextField from "@material-ui/core/TextField";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

var md5 = require('md5');


var lastConnectedAccount;

var transferAddressWasSet=false;

function FITransferButton(props) {

	const [openTransfer, setOpenTransfer] = useState(false);
	const [enteredTransferAddress, setEnteredTransferAddress] = useState('');
	const [approvingTransfer, setApprovingTransfer] = useState(false); //tracks TX
	const [transferring, setTransferring] = useState(false); //tracks TX

	var [hasUse, setHasUse] = useState(false);
	var [hasAUse, setHasAUse] = useState(false);
	var [render, setRender] = useState(0);


	var [isApproving, setApproving] = useState(false);
	var [isActivating, setActivating] = useState(false);

	//var [isApproved, setIsApproved] = useState(false);
  var [transferIsApproved, setTransferIsApproved] = useState(false);


	var [isApprovedForAll, setIsApprovedForAll] = useState(false);




	async function approve() {
		if(!EVM.fia_contract) {
			return;
		}

		setApproving(true);
		await callMethod(EVM.fi_contract.methods.setApprovalForAll(EVM.FIA_ADD[0], true), 0, EVM.connectedAccount,  300000, async function(err, res) {
    	

	    if(err) {
		  	setRender(render+1); setRender(render+1);
		    console.log(err.message);
  			setApproving(false);

	    } else {
	    	// PERFORM OUTCOME CHECK, SAVE MINT ITEM BALANCE BEFORE
	    	
	    	do {

	    		await sleep(2000);
	    		
	    		var isApprovedForAllAfter = await tryCallUntilSuccessful(EVM.fi_contract.methods.isApprovedForAll(EVM.connectedAccount.toString(), EVM.FIA_ADD[0]));
	    		//console.log('Waiting... '+isApprovedForAllBefore+' == '+isApprovedForAllAfter);

	    	} while(isApprovedForAllAfter == false);

	    	setApproving(false);
	   
	      console.log('Done! ');

	      //doesIdHaveUse();
	   
	    }
	  });
	}

	async function checkApprovalAndSetEnteredTransferAddress(address) {

	  if(!EVM.fum_contract) {
	    alert('MetaMask not ready yet...');
	    transferAddressWasSet=false;
	    return;
	  }


	  setEnteredTransferAddress(address);
	  transferAddressWasSet=address;

	  var useContract = EVM.fum_contract;
	  if(subs.subsite=='gothums') {
	    useContract = EVM.gum_contract;
	  }


	  // let approved = await useContract.methods.getApproved(fantum.id).call();
	  // if(approved==enteredTransferAddress.toString()) {
	  //   setTransferIsApproved(enteredTransferAddress.toString());
	  // }


	}

	function transferButtonText() {

	   //console.log('transferIsApproved: '+transferIsApproved);

	  if(transferIsApproved && transferIsApproved==enteredTransferAddress.toString()) {

	    if(transferring) {
	      return (<>Transferring...</>);
	    } else {
	      return (<>Transfer</>);
	    }

	  } else {

	     if(approvingTransfer) {
	      return (<>Approving...</>);
	    } else {
	      return (<>Approve</>);
	    }


	  }



	}
	

	useEffect(() => {

	

 	}, []);


	// if(!props.tid || !hasUse) {
	// 	console.log('no use, or no tid? '+props.tid+' '+hasUse);
	// 	return (<></>);
	// }



	if(isApprovedForAll) {

		return (<>

			{modal(openTransfer, () => setOpenTransfer(false), 'TRANSFER THIS '+subs.singularNameOfFantums, function() {
        return (<><br/>
          <span className="modal_text">Would you like to send this Fantum to another address? Enter that address here!</span><br/><br/>
          <span className="modal_text">Be careful, this cannot be undone!</span><br/><br/>
          <form>
          TRANSFER TO ADDRESS:<TextField value={enteredTransferAddress} label="" onChange={(e) => { checkApprovalAndSetEnteredTransferAddress(e.target.value); }} /><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{transferButtonText()}</Button><br/>
          </form>
          </>);
      })}



			<Button size="lg" variant="primary" onClick={() => { }}>{isActivating?'TRANSFERRING ITEM...':'TRANSFER ITEM'}</Button>
				</>
		);

	} else {



		return (<>

			{modal(openTransfer, () => setOpenTransfer(false), 'Transfer '+props.ting.name+'(s)', function() {
        return (<><br/>
          <span className="modal_text">Send {props.ting.name}(s) to another address!</span><br/><br/>
          <form onSubmit={approve}>

          <DropdownButton variant="light" className="dropdown_rpp" size="sm" id="dropdown-basic-button" title={props.ting.name}>
					  <Dropdown.Item onClick={() => {}}>10 per Page</Dropdown.Item>
					  
					</DropdownButton><br/><br/>
          
          TRANSFER TO ADDRESS:<TextField value={enteredTransferAddress} label="" onChange={(e) => { checkApprovalAndSetEnteredTransferAddress(e.target.value); }} /><br/><br/>
          <Button type="Submit" size="lg" variant="primary">{transferButtonText()}</Button><br/>
          </form>
          </>);
      })}


			<Button size="lg" variant="primary" onClick={() => { setOpenTransfer(true); }}>{isApproving?'APPROVING...':'TRANSFER'}</Button>
				</>
		);

	}
	

	

}

export default FITransferButton;