import React, {useState, useEffect} from 'react';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import { getObjFromJSONUrl, scale, HSBToRGB, Color, Solver, rollNum } from './../helpers.js'
import { byteForLayerName,  outfitForLayerName,  getRemappedVals, alphabetArray, layerByteVal, arrayOfHashCharacters, rScoreGivenHash, reweight0To255, bowStrFromHashArr, genderFromHashArr, indexOfLayerName, hairStrFromHashArr } from './../generate.js'


var sha256 = require('js-sha256');
var result;





var lastRenderedTime=0;

var arrOfHash = [];

var setupHash = false;

var cursor='default';


var hideRarity=false;

var gotFantum = false;

var tokenId = false;



var resultsForGettingHashes=[];

function FantumGen(props) {

	const [fantum, setFantum] = useState(false);

	const [summonerClass, setSummonerClass] = useState(false);


	if(props.tid!==undefined) {
		cursor='pointer';
	}

	var layerStyles = [];

	if(props.hash.length > 0) {
		setupHash=props.hash;
	   setupLayers(props.hash);
  	}

  	if(props.hideRarity!==undefined) {
  		hideRarity = true;
  	}
  	
  	console.log('id: '+props.tid);
  	console.log('hash: '+props.hash);

  	console.log('subs.subsite: '+subs.subsite);
	

	function addLayer(name, currentStyles) {

		// GET OUTFIT FOR LAYER NAME
    var outfit = outfitForLayerName(name, arrOfHash, subs.subsite);

    // GET GENDER FROM HASH
    var gender; if(arrOfHash) { gender = genderFromHashArr(arrOfHash); }

    // SET BASE TO SKIN COLOUR
    if(name=='base') { outfit = rollNum(props.hash); }

    var hairNumber;
    var shirtNumber;
    var hatNumber;
    var capeNumber;
    var maskNumber;

    var capeStr='';
    var hairStr='';
    var bowStr='';

    var gendered='';

    var deadEyes='';

    if(subs.subsite=='gothums') {
    	hairNumber = outfitForLayerName('hair', arrOfHash, subs.subsite);
    	hatNumber = outfitForLayerName('hat', arrOfHash, subs.subsite);
    	maskNumber = outfitForLayerName('mask', arrOfHash, subs.subsite);

	    if(name=='hat' && maskNumber >= 19) {
	    	// FIRST MASK TIER, HIDE HATS
	    	outfit=0;
	    }
	    if(name=='hair' && maskNumber >= 21) {
	    	// SECOND MASK TIER, HIDE HAIR
	    	outfit=0;
	    }
	    if(name=='hat' && (hairNumber==20 || hairNumber==12 || hairNumber==13 || (hairNumber==19 && gender==0) || (hairNumber==8 && gender==0) || (hairNumber==3 && gender==0) || (hairNumber==1 && gender==0) || (hairNumber==10 && gender==0))) {
	    	// SECOND MASK TIER, HIDE HAIR
	    	outfit=0;
	    }
	    if(name=='hair' && hatNumber == 31) {
	    	// SECOND MASK TIER, HIDE HAIR
	    	outfit=0;
	    }
	    if(name=='mask' && hatNumber == 31) {
	    	// SECOND MASK TIER, HIDE HAIR
	    	outfit=0;
	    }


    }

    if(subs.subsite==false) {
	    hairNumber = outfitForLayerName('hair', arrOfHash, false);
	    shirtNumber = outfitForLayerName('shirt', arrOfHash, false);

	    // IF THERE IS HAIR PERIOD, IT NEEDS A COLOR
	    if(name=='hair' && hairNumber > 0){
	    	hairStr='_'+hairStrFromHashArr(arrOfHash);
	    }

	    // These are the male mouths with different hair colours
			if(gender==1 && name=='mouth' && (outfit ==1 || outfit ==3 || outfit ==4)) {
				hairStr='_'+hairStrFromHashArr(arrOfHash);
			}

			// This is a special female hairstyle with a coloured bow
			if(gender==0 && name=='hair' && outfit==4) {
				bowStr=bowStrFromHashArr(arrOfHash);
			}

			// This is a special male outfit that cannot have a cape
			if(gender==1 && name=='cape' && shirtNumber==6) {
				outfit=0;
			}
			//This is a special male outfit that cannot have a cape
			if(gender==1 && name=='cape' && shirtNumber==7) {
				outfit=0;
			}
			//This is a special male outfit that cannot have a cape
			if(gender==1 && name=='neck' && shirtNumber==7) {
				outfit=0;
			}

			// Ladies hair style 4 gets no hat
			if(name=='hat' && gender==0 && hairNumber==4) {
		  	outfit=0;
		  }

		  // SET DEAD EYES
		  if((!fantum.dead && fantum.has_died) && name=='eyes') {
	    	deadEyes='_d';
	    }
	    // SET OPERA GLASSES DEAD EYES
	    if((!fantum.dead && fantum.has_died) && name=='mask' && outfit==6) {
	    	deadEyes='_d';
	    }

		}

		if(subs.layerDataGendered[name]) {
	  	gendered='_'+gender;
	  }

    var filter = 'none';

    if(hideRarity && name=='base') {
    	outfit=0;
    }

    var mySVG = "<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 230'><defs><style>.cls-1{fill:"+vars.rollColors[outfit]+";}</style></defs><title>base</title><polygon class='cls-1' points='160 60 160 50 160 40 150 40 150 30 140 30 140 20 130 20 130 10 120 10 110 10 110 0 100 0 90 0 80 0 70 0 60 0 60 10 50 10 40 10 40 20 30 20 30 30 20 30 20 40 10 40 10 50 10 60 0 60 0 70 0 200 0 210 10 210 10 220 20 220 20 230 30 230 30 220 40 220 40 210 50 210 50 200 60 200 60 210 70 210 70 220 80 220 80 230 90 230 90 220 100 220 100 210 110 210 110 200 120 200 120 210 130 210 130 220 140 220 140 230 150 230 150 220 160 220 160 210 170 210 170 200 170 70 170 60 160 60'/></svg>";
		var mySVG64 = window.btoa(mySVG);
		var width320 = 170; var height320 = 230; var posX320 = 80; var posY320 = 70;
		var shiftUp=0;
	    
		// HAIR SHIFT UP
		if(!subs.subsite) {
			if(hairNumber > 0 && name=='hat') {
	    	shiftUp=props.size/320*-10;
	    }
	  }
	  if(subs.subsite=='gothums') {
			if(hairNumber > 0 && name=='hat' && outfit < 31) {
	    	shiftUp=props.size/320*-10;
	    }
	  }

	  // RARITY MANIFESTED
    if(props.rm) {
			if(name=='neck') {
	    	outfit=0;
	    }
	    if(name=='hair' && (summonerClass=='Cleric' || summonerClass=='Wizard' || summonerClass=='Rogue' || summonerClass=='Druid' || summonerClass=='Bard')) {
	    	outfit=0;
	    }
	    if(name=='mask') {
	    	outfit=0;
	    }
	    if(name=='rh') {
	    	outfit=0;
	    }
	    if(name=='hat') {
	    	outfit=0;
	    }
	    if(name=='cape') {
	    	outfit=0;
	    }
	    if(name=='bg') {
	    	outfit=0;
	    }
	    if(name=='dog') {
	    	outfit=0;
	    }
    }

	   
    var fullImageUrl = '/fantums/'+name+'_'+outfit+gendered+hairStr+bowStr+deadEyes+'.png';

    // RARITY MANIFESTED 
    if(name=='shirt' && props.rm && summonerClass) {
    	fullImageUrl = '/summoners/'+summonerClass+'.png';
    }


    if(subs.subsite=='gothums') {
    	fullImageUrl = '/gothums/'+name+'_'+outfit+gendered+hairStr+capeStr+deadEyes+'.png';
    	var byte = byteForLayerName(name, arrOfHash, subs.subsite);
    	//console.log(name+'_'+outfit+gendered+' '+byte);
    }


    const newStyles = currentStyles.concat({
      id: currentStyles.length, 
      name: name, 
      outfit: outfit, 
      outfitStyle: {'backgroundImage': 'url("'+fullImageUrl+'")', filter: filter, 'backgroundSize': 'contain', width: props.size+'px', height: props.size+'px', 'backgroundPosition': (0)+"px "+(shiftUp)+"px", "backgroundRepeat": 'no-repeat'}, 
      total: subs.layerData[name],
      outfitStyleSVG: {'backgroundRepeat': "no-repeat", 'backgroundPosition': (props.size/320*posY320)+"px "+(props.size/320*posX320)+"px", 'backgroundImage': "url('data:image/svg+xml;base64," + mySVG64 + "')", filter: filter, 'backgroundSize': (props.size/320*width320)+'px '+(props.size/320*height320)+'px', width: props.size+'px', height: props.size+'px'}, 
      
    }); 

    layerStyles = newStyles;

    return newStyles;
  }


	  async function getFantumOnce(hash) {

	  	if(props.blockGet) {
	  		return;
	  	}

	  	if(hash=='') {
	  		//console.log('no hash: '+hash);
	  		return;
	  	}
	  	//console.log('tokenId: '+tokenId+' '+hash);
	  	if(resultsForGettingHashes[hash]==true) {
	  		return;
	  	}
	  	if((!tokenId || tokenId=='0') && hash!='') {
	  		const tokenIdObj = await getObjFromJSONUrl(
					subs.apis[subs.apiId].url+'tokenIdForHash/'+hash
				);
		  	tokenId = tokenIdObj[0];
		  	console.log('tokenId '+tokenId);
		  	resultsForGettingHashes[hash] = true;
	  	}

	  	if(tokenId  && tokenId > 0) {
	  		const fantumObj = await getObjFromJSONUrl(
					subs.apis[subs.apiId].url+'token2/'+tokenId
				);
		  	setFantum(fantumObj);
		  	setSummonerClass(fantumObj.summoner.className);
		  	console.log(fantumObj);
		  	if(tokenId!=undefined) {
		  		gotFantum=true;
		  	}
	  	}

	  	
	  	
	  }


	    	

  	useEffect(() => {

			setInterval(function() {


				if(!gotFantum && props.hash!=undefined) {
		  		getFantumOnce(props.hash);
		  	}
		  	//console.log(props.hash);

			}, 3000);

		}, []);


  async function setupLayers(hash) {

  	

  	



  	//console.log('--------------------------------------------------');

		arrOfHash = arrayOfHashCharacters(hash); //60 characters
		var newStyles = [];

		var ldtu = subs.layerData;
		for(var layerName in ldtu) {
		  newStyles = addLayer(layerName, newStyles);
		}

	  }

	  function clickFantum() {
	  	if(props.tid!==undefined) {
	  		window.open("/token/"+props.tid, "_self");
	  	}

	  	
	  }

return ( 
  <div>

  	
    

    <div className="fantom_centerer" style={{width: props.size+'px', height: props.size+'px', cursor: cursor}} onClick={clickFantum}>
      <div className="fantom_container">

      {layerStyles.map(function(layerStyle, index) {
        return (
          <div className="fantom_layer" key={'layer_'+layerStyle.id} id={'layer_'+layerStyle.id}>
          	<div className={(props.cursor ? 'cursor_pointer' : '')+" fantom_dims"} style={(layerStyle.name!='base')? layerStyle.outfitStyle : layerStyle.outfitStyleSVG}></div>
          </div>);
      })} 

      {/*<span className={"doneSubsiteLoad "+(subs.subsite=='gothums'?'':'hidden')}></span>*/}
      {subs.subsite=='gothums'?<span id="doneSubsiteLoad" style={{display: 'inline-block'}}></span>:''}
      </div>

  </div>

  </div>


);

}

export default FantumGen;