import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';

import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';
import { getObjFromJSONUrl, rollName, rollNum, rScore, rScoreDiff, sleep, callMethod } from './../helpers.js'




function MintAboveText(props) {



	function fantumLineOneText() {

	    if(props.tooManyRequests) {
	        return(<>TOO MANY REQUESTS, DID NOT FIND FANTUM!</>);
	    } else if(props.donationBinEmpty) {
	        return(<>DID NOT FIND FANTUM, NO INSTANT FINDS AVAILABLE!</>);
	    } else if(props.foundStoredFantum) {
	        return(<>FOUND FANTUM INSTANTLY</>);
	    } else {
	        return(<>FOUND FANTUM IN {props.tookSecs} SECONDS</>);
	    }

	    
	}

	console.log('subs.subsite: '+subs.subsite);

	if(!subs.subsite) {
		// SHOW RARITY SCORE ON FIRST VERSION

		if(props.thisIsDonation) {

			return (<>

			<span className="found_line_one">{fantumLineOneText()}</span>
			<br/><br/>
			<span className={!props.thisIsDonation ? 'hidden' : ''}>
				<span className={"found_line_one_one"}>[RARITY HIDDEN]</span><br/><br/>
				<span className={"found_name found_name_white_text"}>{props.shortName}</span>
			</span>
			      
			</>);


		} else {


			return (<>

			<span className="found_line_one">{fantumLineOneText()}</span>
			<br/><br/>
			<span className={props.thisIsDonation ? 'hidden' : ''}>
				<span className={"found_line_one_one"}>{rollName(props.hash.toString()).toUpperCase()} ({rScore(props.hash.toString())})</span><br/><br/>
				<span className={"found_name rarityColor_" + rollNum(props.hash.toString())+"_roll"}>{props.shortName}</span>
			</span>
			      
			</>);

		}

	} else {
		// HIDE RARITY SCORE ON SECOND VERSION

		if(props.thisIsDonation) {

			return (<>

			<span className="found_line_one">{fantumLineOneText()}</span>
			<br/><br/>
			<span className={!props.thisIsDonation ? 'hidden' : ''}>
				<span className={"found_line_one_one"}>[RARITY HIDDEN]</span><br/><br/>
				<span className={"found_name found_name_white_text"}>{props.shortName}</span>
			</span>
			      
			</>);


		} else {


			return (<>
				<br/>
			<span className="found_line_one">{fantumLineOneText()}</span>
			<br/><br/>
			<span className={props.thisIsDonation ? 'hidden' : ''}>
				<br/>
				<span className={"found_name found_name_white_text"}>{props.shortName}</span>
			</span>
			      
			</>);

		}

	}


	

}

export default MintAboveText;