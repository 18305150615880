import React, {useState, useEffect} from 'react';
import Fantum from './FantumGen';
import Button from 'react-bootstrap/Button';
import * as vars from './../vars.js'; import * as subs from './../subs.js';
import * as EVM from './../evm.js';



function AboutCrafting() {

	const [render, setRender] = useState(0);


	useEffect(() => {

		setTimeout(function() {
			setRender(true);
			

		}, 4000);
		setTimeout(function() {
			setRender(true);
			

		}, 2000);

	}, []);

	// if(EVM.duelsPrivate) {
	// 	return (<><h1><span style={{color: '#FFF'}}>Are you supposed to be here?</span></h1></>);
	// }
return ( 
<>
        <div className="appOperaDuelSplash">
            <div id="operaHolderCraftingSplash">

	       	<div className="center_an_img"><img src="/img/inventory_ss.jpg" width="400" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">NEW TOKENS</span><br/><br/>
	       	
	       	<h5>The beginning of a new expandable item collection.</h5><br/><br/><br/>
	       	</div>

	       	<div className="center_an_img"><img src="/img/buffs_about.jpg" width="300" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">NEW PURPOSES</span><br/><br/>
	       	
	       	<h5>New items will have uses throughout the Fantums Universe, starting with Duels.</h5><br/><br/><br/>
	       	</div>



	       	<div className="center_an_img"><img src="/img/ContractExplanation.jpg" width="500" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">NEW DEFI BUILDING BLOCK</span><br/><br/>
	       	
	       	<h5>A new contract which allows the creation, combination and destruction of standard tokens <i>(ERC20, ERC721, ERC1155)</i>!</h5><br/><br/><br/>

	       	</div>

	       	<div className="center_an_img"><img src="/img/elements.png" width="640" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">THE ELEMENTS</span><br/><br/>
	       	<h5>Farm Minor Elements in Duels, combine them into Major Elements; and utilize these in Crafting items, potions and more.</h5><br/><br/><br/>
	       	</div>

	       	<div className="center_an_img"><img src="/img/balances_eg.jpg" width="480" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">PUT YOUR CANDY TO WORK</span><br/><br/>
	       	<h5>The Candy token ($CNDY) can now be utilized as a common crafting ingredient.</h5><br/>
	       	<h5>The Fantums of Opera token ($FOO), and the Fantums Death Token ($RIP) are also utilized as well!</h5><br/>
	       	<br/><br/>
	       	</div>


	       	<div className="center_an_img"><img src="/img/new_pairs.jpg" width="400" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">ve(3,3)?</span><br/><br/>
	       	
	       	<h5>Proceeds from Crafting will help satisify a long-standing need for liquidity on our game tokens.</h5><br/>
	       	<h5>We will be adding the $FOO, $CNDY and $RIP received to new liquidity pools on our preferred DEX platforms (<i>Spooky/Sushi/Solidly.</i>)</h5><br/><br/><br/>

	       	</div>


	       	<div className="center_an_img"><img src="/img/live_today.jpg" height="400" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">THE ROLL-OUT</span><br/><br/>
	       	
	       	<h5>Live immediately, Minor Elements will drop in Duels, ability to Perform Seppuku with FUMs, ability to craft and use Death-Defying Potions, and the ability to craft the Chromatic Amulet.</h5><br/>
	       	<h5>More potions that will effect the outcome of Duels will launch soon.</h5><br/>
	       	<h5>Dueling potions are just the beginning. The crafting framework can be used to craft just about anything and the Fantums team already has a few more innovative uses in the pipeline.</h5><br/><br/><br/>


	       	
	       	</div>



	       	<div className="center_an_img"><img src="/img/trophies.jpg" width="400" /><br/><br/></div>
	       	<div className="duels_splash_center_container">
	       	<span className="dueling_subheading">CONTINUING OUR MISSION</span><br/><br/>
	       	
	       	<h5>Our goal is to continue to build out a novel DeFi game world that offers fun player experiences, the ability to earn; and added rewards for product loyalty and early adoption.</h5><br/>
	       	<h5>Crafting is the next step in this journey!</h5><br/><br/><br/>

	       	</div>
	       	
		</div>
	</div>

        </>


);

}

export default AboutCrafting;